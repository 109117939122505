import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import type {
	IEntityProductGroup,
	IPricingRequest,
	IPricingResponse,
	IStandardOrder,
	IStandardOrderCancelSubmissionApiParams,
	IStandardOrderGcSubmission,
	IStandardOrderGcSubmissionApiParams,
	IStandardOrderSave,
	TEntityProductGroupListFilter,
} from 'module/purchase';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { useCallback } from 'react';
import { CACHE_TIME } from 'appConstants';
import type { IEntityOrder } from 'module/orders';
import type { TChargeOrderId } from 'types';
import { productGroupEntityNormalizer } from 'module/purchase/normalizer';
import type { TApiListResponse } from 'types/api';
import type { AxiosResponse } from 'axios';
import { ApiMutationTypeEnum } from 'js/enums';

const API_PURCHASE_KEYS = {
	PRODUCT_LIST: 'product-list/:priceListCode',
	PRICING: 'price/calculate',
	PlACE_ORDER: 'standard-order',
	SAVE_ORDER: 'standard-order/save',
	GC_ORDER_SUBMISSION: 'charge-order/:chargeOrderId/global-commerce-order-submission',
	CANCEL_SUBMISSION: 'standard-order/:standardOrderId/cancel-submission',
	EXPORT: 'cart/export',
};

export const useApiProductGroupList: TUseApiListModule<IEntityProductGroup, TEntityProductGroupListFilter> = (
	props = {},
) =>
	useApiListQuery(API_PURCHASE_KEYS.PRODUCT_LIST, props, {
		queryConfig: {
			staleTime: CACHE_TIME.HIGH,
			select: useCallback(
				(response: AxiosResponse<TApiListResponse<IEntityProductGroup>>) => ({
					...response,
					data: productGroupEntityNormalizer.normalizeList(response.data),
				}),
				[],
			),
		},
	});

export const useApiPricing: TUseApiMutationModule<IPricingRequest, IPricingResponse> = (props) =>
	useApiMutationQuery(API_PURCHASE_KEYS.PRICING, ApiMutationTypeEnum.POST, props);

export const useApiPlaceOrder: TUseApiMutationModule<IStandardOrder, IEntityOrder> = (props) =>
	useApiMutationQuery(API_PURCHASE_KEYS.PlACE_ORDER, ApiMutationTypeEnum.POST, props);

export const useApiSaveOrder: TUseApiMutationModule<IStandardOrderSave, IEntityOrder> = (props) =>
	useApiMutationQuery(API_PURCHASE_KEYS.SAVE_ORDER, ApiMutationTypeEnum.POST, props);

export const useApiGcOrderSubmission: TUseApiMutationModule<
	IStandardOrderGcSubmission,
	TChargeOrderId,
	IStandardOrderGcSubmissionApiParams
> = (props) => useApiMutationQuery(API_PURCHASE_KEYS.GC_ORDER_SUBMISSION, ApiMutationTypeEnum.PATCH, props);

export const useApiCancelSubmission: TUseApiMutationModule<
	object,
	IEntityOrder,
	IStandardOrderCancelSubmissionApiParams
> = (props) => useApiMutationQuery(API_PURCHASE_KEYS.CANCEL_SUBMISSION, ApiMutationTypeEnum.PATCH, props);

export const useApiExportCart: TUseApiMutationModule<IStandardOrderSave, IEntityOrder> = (props) =>
	useApiMutationQuery(API_PURCHASE_KEYS.EXPORT, ApiMutationTypeEnum.POST, props, {
		config: { responseType: 'blob' },
	});
