import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type {
	IAutoBillingSourceResponse,
	IFirstPurchaseResponse,
	ISetPaymentSourceQuery,
	ISetPaymentSourceResponse,
} from 'module/account';
import { useApiListQuery } from 'js/queries/useApiListQuery';
import { CACHE_TIME } from 'appConstants';
import type { TUseApiCountryListModule } from 'js/entities/country/useApiCountry';
import { ApiMutationTypeEnum } from 'js/enums';

export const API_AUTO_BILLING_KEYS = {
	SOURCE: 'auto-billing/digital-river/source',
	PAYMENT_SOURCE: 'auto-billing/digital-river/payment-source',
	FIRST_PURCHASE: 'auto-billing/digital-river/first-purchase',
	COUNTRY_LIST: 'auto-billing/digital-river/country',
	DELETE: 'auto-billing/digital-river/source',
};

export const useApiAutoBillingSource: TUseApiDetailModule<IAutoBillingSourceResponse> = (props) => {
	return useApiDetailQuery(API_AUTO_BILLING_KEYS.SOURCE, props);
};

export const useApiAutoBillingFirstPurchase: TUseApiDetailModule<IFirstPurchaseResponse> = (props) => {
	return useApiDetailQuery(API_AUTO_BILLING_KEYS.FIRST_PURCHASE, props);
};

export const useApiAutoBillingSetPaymentSourceId: TUseApiMutationModule<
	ISetPaymentSourceQuery,
	ISetPaymentSourceResponse
> = (props) => {
	return useApiMutationQuery(API_AUTO_BILLING_KEYS.PAYMENT_SOURCE, ApiMutationTypeEnum.POST, props);
};

export const useApiAutoBillingDeleteSource: TUseApiMutationModule<object, ISetPaymentSourceResponse> = (props) => {
	return useApiMutationQuery(API_AUTO_BILLING_KEYS.DELETE, ApiMutationTypeEnum.DELETE, props, {
		invalidateQueries: [API_AUTO_BILLING_KEYS.SOURCE],
	});
};

export const useApiAutoBillingCountryList: TUseApiCountryListModule = (props) => {
	return useApiListQuery(API_AUTO_BILLING_KEYS.COUNTRY_LIST, props, {
		queryConfig: {
			staleTime: CACHE_TIME.EXTRA_HIGH,
		},
	});
};
