import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type {
	ILoginFormData,
	INewPasswordDataRequest,
	INewPasswordDataResponse,
	IResetPasswordData,
	TLoginAsAffiliateFormData,
} from 'module/security';
import type { IAuthTokenResponse } from 'js/reducers/authContextReducer';
import { ApiMutationTypeEnum } from 'js/enums';

const API_SECURITY_KEYS = {
	LOGIN: 'partner/login_check',
	LOGIN_AS_AFFILIATE: 'partner/login_check',
	LOGOUT: 'logout',
	PASSWORD_RESET: 'affiliate/password-reset/request',
	NEW_PASSWORD: 'affiliate/password-reset/reset',
};

/**
 * Hook for login to OMS app
 */
export const useApiLogin: TUseApiMutationModule<ILoginFormData, IAuthTokenResponse> = (props) =>
	useApiMutationQuery(API_SECURITY_KEYS.LOGIN, ApiMutationTypeEnum.POST, props, {
		config: { apiVersion: null, onNonAuthorized: null, withCredentials: true },
	});

export const useApiLoginAsAffiliate: TUseApiMutationModule<TLoginAsAffiliateFormData, IAuthTokenResponse> = (props) =>
	useApiMutationQuery(API_SECURITY_KEYS.LOGIN_AS_AFFILIATE, ApiMutationTypeEnum.POST, props, {
		config: { apiVersion: null, onNonAuthorized: null, withCredentials: true },
	});

export const useApiLogout: TUseApiMutationModule<object, null> = (props) =>
	useApiMutationQuery(API_SECURITY_KEYS.LOGOUT, ApiMutationTypeEnum.POST, props, {
		config: { apiVersion: null, onNonAuthorized: null, withCredentials: true, catchError: false },
	});

export const useApiResetPassword: TUseApiMutationModule<IResetPasswordData, null> = (props) =>
	useApiMutationQuery(API_SECURITY_KEYS.PASSWORD_RESET, ApiMutationTypeEnum.POST, props);

export const useApiNewPassword: TUseApiMutationModule<INewPasswordDataRequest, INewPasswordDataResponse> = (props) =>
	useApiMutationQuery(API_SECURITY_KEYS.NEW_PASSWORD, ApiMutationTypeEnum.POST, props);
