import { AsyncModal, type TAsyncModalContainerProps, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { ICustomerForm, IEntityCustomer } from 'module/customers';
import { CreateCustomerContainer } from 'module/customers/components/CreateCustomerContainer';
import type { TPartnerId } from 'types';

export type TAsyncCreateCustomerModalProps = {
	initialValues?: ICustomerForm | null;
	partnerId?: TPartnerId | null;
};

export const useAsyncCreateCustomerRef = () => useAsyncModalRef<TAsyncCreateCustomerModalProps, IEntityCustomer>();

export const AsyncCreateCustomerModal = (
	props: TAsyncModalContainerProps<TAsyncCreateCustomerModalProps, IEntityCustomer>,
) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncCreateCustomerModalProps, IEntityCustomer>
			ref={forwardedRef}
			testId="createCustomer"
			backdrop="static"
		>
			{(props) => (
				<CreateCustomerContainer
					partnerId={props.partnerId}
					initialValues={props.initialValues}
					onCreate={(customer) => forwardedRef.current?.onSuccess(customer)}
					onCancel={() => forwardedRef.current?.onCancel()}
				/>
			)}
		</AsyncModal>
	);
};
