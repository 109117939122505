import type React from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useApiOrderList } from 'module/orders/hooks/useApiOrders';
import { RouteName } from 'module/RouteName';
import type { IEntityOrder } from 'module/orders';
import { useRecentOrdersWidgetColumns } from 'submodule/widgets/tableColumns';
import type { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';
import type { TPartnerId } from 'types';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'js/contexts';
import { CardTable } from 'js/components/molecules/DataTable/CardTable';

type TRecentOrdersWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
	limit?: number;
	header?: string;
	columnVisibility?: VisibilityState;
	partnerId?: TPartnerId;
};

export const RecentOrdersWidget = (props: PropsWithChildren<TRecentOrdersWidgetProps>): ReactElement => {
	const { columns, columnVisibility: defaultColumnVisibility } = useRecentOrdersWidgetColumns();
	const { limit = 5, header, columnVisibility = defaultColumnVisibility, partnerId, ...rest } = props;

	const { t } = useTranslation(ordersConfig.trNamespace);
	const { authCompanyId, isGroupInternal } = useAuthContext();
	const navigate = useNavigate();

	const {
		data,
		query: { isFetching },
	} = useApiOrderList({
		filter: isGroupInternal ? { partnerId } : { partnerId: authCompanyId },
		config: { limit },
	});

	return (
		<CardTable<IEntityOrder>
			testId="recent orders widget"
			{...rest}
			columns={columns}
			caption={header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.ORDERS))}
			data={data?.items ?? []}
			onViewMore={() => navigate(RouteName.ORDER.LIST)}
			tableProps={{
				state: { columnVisibility },
				meta: {
					rowCount: limit,
					loading: isFetching,
					onRowClick(order) {
						navigate(ordersConfig.detailLink(order));
					},
					isScrollable: true,
				},
			}}
		/>
	);
};
