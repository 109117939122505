import type { IEntityPartnerTerms } from 'module/partners';
import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { partnerTermsEnumUtils } from 'module/partners/enums';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';

type TTableData = IEntityPartnerTerms;
export const useTermsCardColumns = (detailRef: TAsyncModalRef<IEntityPartnerTerms>): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(accountConfig.trNamespace);

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<IEntityPartnerTerms>();
		return [
			columnHelper.text('name', {
				id: 'name',
				meta: {
					formatters: [partnerTermsEnumUtils.getText],
				},
			}),
			columnHelper.actions(
				{
					button: {
						children: t('common:actions.show'),
						onClick: (terms) => detailRef.current?.show(terms),
						testId: 'show',
					},
				},
				{ meta: { sticky: null } },
			),
		];
	}, [t, detailRef]);
};
