import { numberFormatter, type TNumberFormatterOptions } from 'js/utils/number';
import type { TUiTableRow } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import { toNumber } from 'lodash';

export type TNumberOptions = TNumberFormatterOptions;
export const number =
	<Row extends TUiTableRow>(options?: TNumberOptions): TColumnFormatter<Row> =>
	(value) =>
		numberFormatter.format(toNumber(value), options);
