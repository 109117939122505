import { useStorage } from 'js/hooks/useStorage';
import { useCallback } from 'react';

export type TUseStorageToggle = {
	value: boolean;
	toggle: () => void;
	setValue: (value: boolean) => void;
};

export const useStorageToggle = (key: string, defaultValue: boolean): TUseStorageToggle => {
	const [value, setValue] = useStorage(key, defaultValue);

	// Define and memorize toggler function in case we pass down the comopnent,
	// This function change the boolean value to it's opposite value
	const toggle = useCallback(() => setValue((value) => !value), [setValue]);

	return { value: Boolean(value), toggle, setValue };
};
