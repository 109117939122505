import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import type {
	IAsyncExportOrderRequest,
	IEntityOmsOrder,
	IEntityOrder,
	IOrderCancelParams,
	IOrderCancelResponse,
	IOrderDiscountApprovalParams,
	IOrderDiscountApprovalQuery,
	IOrderLicensesCancelParams,
	IOrderLicensesCancelRequest,
	IOrderListApiFilter,
	IOrderListFilter,
} from 'module/orders';
import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { useCallback } from 'react';
import type { TDetailArguments } from 'types';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { orderEntityNormalizer, ordersFilterNormalizer, quotesFilterNormalizer } from 'module/orders/normalizer';
import type { AxiosResponse } from 'axios';
import type { IApiPaginatedListResponse } from 'types/api';
import { ApiMutationTypeEnum } from 'js/enums';
import type { IAsyncJob } from 'js/asyncJobs';

export const API_ORDERS_KEYS = {
	LIST: 'standard-order/cached-list',
	DETAIL: 'standard-order/:id',
	CANCEL: 'standard-order/:id/cancel',
	CANCEL_LICENSES: 'standard-order/:id/cancel-entitlements',
	APPROVE_DISCOUNT: 'standard-order/:standardOrderId/approve-discount',
	EXPORT: 'export/orders',
	DETAILED_EXPORT: 'export/detailed-orders',
};

export const useApiOrderList: TUseApiPaginatedListModule<IEntityOrder, IOrderListFilter, IOrderListApiFilter> = (
	props,
) =>
	useApiPaginatedListQuery(
		API_ORDERS_KEYS.LIST,
		props,
		{
			queryConfig: {
				select: useCallback(
					(response: AxiosResponse<IApiPaginatedListResponse<IEntityOrder>>) => ({
						...response,
						data: {
							...response.data,
							items: orderEntityNormalizer.normalizeList(
								(response.data as IApiPaginatedListResponse<IEntityOmsOrder>).items,
							),
						},
					}),
					[],
				),
			},
			apiFilterNormalizer: (filter) => ordersFilterNormalizer.denormalize(filter),
		},
		'orders',
	);

export const useApiQuoteList: TUseApiPaginatedListModule<IEntityOrder, IOrderListFilter, IOrderListApiFilter> = (
	props,
) =>
	useApiPaginatedListQuery(
		API_ORDERS_KEYS.LIST,
		props,
		{
			queryConfig: {
				select: useCallback(
					(response: AxiosResponse<IApiPaginatedListResponse<IEntityOrder>>) => ({
						...response,
						data: {
							...response.data,
							items: orderEntityNormalizer.normalizeList(
								(response.data as IApiPaginatedListResponse<IEntityOmsOrder>).items,
							),
						},
					}),
					[],
				),
			},
			apiFilterNormalizer: (filter) => quotesFilterNormalizer.denormalize(filter),
		},
		'quotes',
	);

export const useApiOrder: TUseApiDetailModule<IEntityOrder, TDetailArguments, IEntityOmsOrder> = (props) =>
	useApiDetailQuery(API_ORDERS_KEYS.DETAIL, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IEntityOmsOrder>) => ({
					...response,
					data: orderEntityNormalizer.normalize(response.data),
				}),
				[],
			),
		},
	});

export const useApiOrderCancel: TUseApiMutationModule<object, IOrderCancelResponse, IOrderCancelParams> = (props) =>
	useApiMutationQuery(API_ORDERS_KEYS.CANCEL, ApiMutationTypeEnum.PATCH, props);

export const useApiApproveDiscount: TUseApiMutationModule<
	IOrderDiscountApprovalParams,
	IEntityOrder,
	IOrderDiscountApprovalQuery
> = (props) => useApiMutationQuery(API_ORDERS_KEYS.APPROVE_DISCOUNT, ApiMutationTypeEnum.PATCH, props);

export const useApiOrderListExport: TUseApiMutationModule<IAsyncExportOrderRequest, IAsyncJob> = (props) =>
	useApiMutationQuery(API_ORDERS_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);

export const useApiOrderListExportDetailed: TUseApiMutationModule<IAsyncExportOrderRequest, IAsyncJob> = (props) =>
	useApiMutationQuery(API_ORDERS_KEYS.DETAILED_EXPORT, ApiMutationTypeEnum.POST, props);

export const useApiOrderLicensesCancel: TUseApiMutationModule<
	IOrderLicensesCancelRequest,
	object,
	IOrderLicensesCancelParams
> = (props) => useApiMutationQuery(API_ORDERS_KEYS.CANCEL_LICENSES, ApiMutationTypeEnum.POST, props);
