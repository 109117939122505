import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import type { ITaxExemption } from 'module/account';
import { CACHE_TIME } from 'appConstants';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type { IEntityPartnerTaxApplication } from 'module/partners';
import type { IPartnerRequestParams } from 'module/purchase';
import { ApiMutationTypeEnum } from 'js/enums';

const API_ACCOUNT_KEYS = {
	TAX_CERTIFICATE: 'partner/:partnerId/tax-exemption-certificate',
	AUTHORITY_LIST_BY_COUNTRY: 'tax/authority/:countryCode/list',
	UPLOAD_TAX_EXEMPTION: 'partner/:partnerId/tax-exemption-certificate',
};

export const useApiAuthorityListByCountry: TUseApiListModule<string, { countryCode?: string | null }> = (props) => {
	return useApiListQuery(API_ACCOUNT_KEYS.AUTHORITY_LIST_BY_COUNTRY, props, {
		queryConfig: { staleTime: CACHE_TIME.HIGH },
	});
};

export const useApiTaxExemptionList: TUseApiListModule<ITaxExemption, IPartnerRequestParams> = (props) => {
	return useApiListQuery(API_ACCOUNT_KEYS.TAX_CERTIFICATE, props);
};

export const useApiUploadTaxExemption: TUseApiMutationModule<
	FormData,
	IEntityPartnerTaxApplication,
	IPartnerRequestParams
> = (props) =>
	useApiMutationQuery(API_ACCOUNT_KEYS.UPLOAD_TAX_EXEMPTION, ApiMutationTypeEnum.POST, props, {
		config: {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
		invalidateQueries: [API_ACCOUNT_KEYS.TAX_CERTIFICATE],
	});
