import { type ReactElement, useState } from 'react';
import type { IApiPartnerCustomerListFilter, IEntityCustomer } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { useApiPartnerCustomerCount, useApiPartnerCustomerList } from 'module/customers/hooks/useApiCustomers';
import type { IEntityPartner } from 'module/partners';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import {
	getFilterCharsMessage,
	hasFilterEnoughChars,
	isCustomersApiLimitExceeded,
} from 'module/customers/utils/common';
import type { TableColumnsDef } from 'types';
import { SortDirectionEnum } from 'js/enums';
import { Col, Row } from 'js/components/atoms/Row';
import { Can } from 'js/components/molecules/Can';
import { Button } from '@avast/react-ui-components';
import { useAuthContext, useCommonContext } from 'js/contexts';

type TTableData = IEntityCustomer;
type TTableFilter = IApiPartnerCustomerListFilter;

type TSelectPartnerCustomerProps = {
	onSelect: (customer: IEntityCustomer) => void;
	selectedCustomerId?: IEntityCustomer['id'];
	partnerId?: IEntityPartner['id'];
	columns: TableColumnsDef<IEntityCustomer>;
	enableCreate?: boolean;
};

/**
 * Search customer for specified partner
 * @param {TSelectPartnerCustomerProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectPartnerCustomer = (props: TSelectPartnerCustomerProps): ReactElement => {
	const { onSelect, selectedCustomerId, columns } = props;
	const { createCustomerRef } = useCommonContext();
	const { isGroupPartner, authCompanyId } = useAuthContext();
	const partnerId = isGroupPartner ? authCompanyId : props.partnerId;

	// Component hooks
	const { t } = useTranslation(customersConfig.trNamespace);
	const [searchValue, setSearchValue] = useState<string>('');

	// Get count of customers
	const {
		data: customersCount,
		query: { isFetching },
	} = useApiPartnerCustomerCount({
		filter: { partnerId: partnerId ?? null },
		queryConfig: { enabled: Boolean(partnerId) },
	});

	// Get customer partner
	if (isFetching) {
		return <LoadingPlaceholder type="BAR" />;
	}

	return (
		<>
			<Row className="mb-2" justify="between" multi size="sm">
				<Col sm={6} lg={4}>
					<SearchBox size="sm" placeholder={t('select.filter.placeholder')} onChange={setSearchValue} autoFocus />
				</Col>
				<Col sm="auto">
					<Can create do={customersConfig.aclModule} isAllowed={Boolean(props.enableCreate)}>
						<Button
							size="sm"
							onClick={async () => {
								const customer = await createCustomerRef.current?.show({
									partnerId,
								});
								if (customer) {
									onSelect(customer);
								}
							}}
						>
							{t('actions.newCustomer')}
						</Button>
					</Can>
				</Col>
			</Row>

			{(() => {
				if (isCustomersApiLimitExceeded(customersCount)) {
					const hasPartner = Boolean(partnerId);
					const hasEnoughChars = hasFilterEnoughChars(searchValue);
					let requiredMessage: string | boolean = false;
					if (!hasPartner) {
						requiredMessage = t('common:filter.partnerIsRequired');
					}
					if (!hasEnoughChars) {
						requiredMessage = getFilterCharsMessage(t);
					}

					return (
						<ApiListTable<TTableData, TTableFilter>
							columns={columns}
							query={useApiPartnerCustomerList}
							filter={{ partnerId, filter: searchValue }}
							filterRequired={requiredMessage}
							filterIsEmpty={() => !hasPartner || !hasEnoughChars}
							limit={8}
							table={{
								testId: 'customers',
								meta: {
									nowrapCell: false,
									onRowClick: onSelect,
									isHighlightedRow: (row) => Boolean(selectedCustomerId) && row.id === selectedCustomerId,
								},
							}}
						/>
					);
				}

				return (
					<ApiListTable<TTableData, TTableFilter>
						columns={columns}
						query={useApiPartnerCustomerList}
						sort={{ direction: SortDirectionEnum.ASC, key: 'companyName' }}
						limit={8}
						filter={{ partnerId }}
						table={{
							testId: 'customers',
							onGlobalFilterChange: setSearchValue,
							state: { globalFilter: searchValue },
							meta: {
								onRowClick: onSelect,
								isHighlightedRow: (row) => Boolean(selectedCustomerId) && row.id === selectedCustomerId,
							},
						}}
						filterRequired
						filterIsEmpty={(filter) => !filter.partnerId}
					/>
				);
			})()}
		</>
	);
};
