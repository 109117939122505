import type { PropsWithChildren, ReactElement } from 'react';
import type { TDropdownItemProps } from '@avast/react-ui-components';
import { Dropdown } from '@avast/react-ui-components';
import { Link } from 'react-router';
import type { NavigateLink } from 'types';

// Add required to argument and remove href
export type TDropdownLinkItemProps = Omit<TDropdownItemProps, 'href'> & NavigateLink;

/**
 * Dropdown item with nav link
 * @param {TDropdownLinkItemProps} props
 * @return {React.ReactElement}
 * @constructor
 */
export const DropdownLink = (props: PropsWithChildren<TDropdownLinkItemProps>): ReactElement => (
	<Dropdown.Item {...props} as={Link} />
);
