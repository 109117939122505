import { AsyncContainer, type TAsyncContainerComponentProps } from 'js/components/molecules/Modal/AsyncContainer';
import type { IEntityLicense } from 'module/licenses';
import { LicenseOperationModal } from 'module/licenses/components/licenseOperation/LicenseOperationModal';
import { LicenseOperationContextProvider } from 'module/licenses/context/LicenseOperationContext';

export type TAsyncLicenseOperationContainerProps = {
	license: IEntityLicense;
};

export const AsyncLicenseOperationContainer = (
	props: TAsyncContainerComponentProps<TAsyncLicenseOperationContainerProps>,
) => {
	const { forwardedRef } = props;

	return (
		<AsyncContainer<TAsyncLicenseOperationContainerProps> ref={forwardedRef}>
			{({ license }) => (
				<LicenseOperationContextProvider license={license}>
					<LicenseOperationModal
						show={forwardedRef.current?.isOpen() || false}
						onHide={() => forwardedRef.current?.onCancel()}
					/>
				</LicenseOperationContextProvider>
			)}
		</AsyncContainer>
	);
};
