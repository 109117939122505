import type { MutableRefObject, PropsWithChildren, ReactNode } from 'react';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { Tooltip, useCellContentOverflow } from '@avast/react-ui-components';
import { ELLIPSIS_CELL_WIDTH } from 'appConstants';

export const ProductNameCell = (props: PropsWithChildren) => {
	const { isOverflown, cellRef, contentRef } = useCellContentOverflow({ enabled: true });

	const cell = (content: ReactNode | undefined) => (
		<CustomTable.Td
			className="fw-bold text-truncate"
			forwardedRef={cellRef as MutableRefObject<HTMLTableCellElement>}
			forwardedInnerRef={contentRef}
			style={{ maxWidth: ELLIPSIS_CELL_WIDTH }}
		>
			{content}
		</CustomTable.Td>
	);

	if (isOverflown) {
		return cell(
			<Tooltip placement="top" content={props.children}>
				<span>{props.children}</span>
			</Tooltip>,
		);
	}

	return cell(props.children);
};
