import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { DataFilter, type TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { Col, Row } from 'js/components/atoms/Row';
import type { IPromotionListFilter } from 'module/promotions';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { PromotionExtraFiltersForm } from 'module/promotions/form/PromotionExtraFiltersForm';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { promotionExtraFilterNormalizer } from 'module/promotions/normalizer';
import { SearchBox } from 'js/components/molecules/SearchBox';

type TFilter = IPromotionListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
} & TAsyncModalContainerProps;

/**
 * Filter for credit status list
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const PromotionFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const { controls, forwardedRef, ...rest } = props;
	const { t } = useTranslation(promotionsConfig.trNamespace);

	return (
		<DataFilter<TFilter> useLocation {...rest}>
			{({ values, updateFilter }) => (
				<>
					<Row multi size="sm" align="center">
						<Col sm={3}>
							<SearchBox
								size="sm"
								value={values.id ? values.id.toString() : ''}
								placeholder={t('entity.id')}
								onChange={(id) => updateFilter({ id: id === '' ? undefined : Number(id) })}
							/>
						</Col>
						<Col sm={3}>
							<SearchBox
								size="sm"
								value={values.name}
								placeholder={t('entity.name')}
								onChange={(name) => updateFilter({ name })}
							/>
						</Col>
						<Col sm={6} className="text-end align-self-end">
							{controls}
						</Col>
					</Row>

					<AsyncModal ref={forwardedRef}>
						<Modal.Header className="pb-0">{t('actions.extraFilters')}</Modal.Header>
						<Modal.Body className="py-md-4">
							<PromotionExtraFiltersForm
								onCancel={() => forwardedRef.current?.hide()}
								onSubmit={(values) => {
									updateFilter(promotionExtraFilterNormalizer.denormalize(values), true);
									forwardedRef.current?.hide();
								}}
							/>
						</Modal.Body>
					</AsyncModal>
				</>
			)}
		</DataFilter>
	);
};
