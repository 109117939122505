import type { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';

export const normalizeStateName = (stateName?: string | undefined): string | undefined => {
	if (stateName === 'N/A' || stateName === 'NA') {
		return undefined;
	}
	return stateName;
};

export const normalizeVariants = (values: TSelectVariantForm): TSelectVariantItem[] =>
	values.variant.filter(({ checked, quantity = 0, unit = 0 }) => checked && quantity !== 0 && unit !== 0);
