import { type PartnerSubTypeEnum, partnerSubTypeEnumUtils, PartnerTypeEnum } from 'module/partners/enums';
import type { TSelectOptions } from '@avast/react-ui-components';

export const getAccountSubTypeOptions = (
	selectedAccountTypes?: PartnerTypeEnum[],
	accountSubTypesWhiteList?: PartnerSubTypeEnum[],
): TSelectOptions<PartnerSubTypeEnum> => {
	const isDistributorSelected = selectedAccountTypes?.includes(PartnerTypeEnum.DISTRIBUTOR);
	const isResellerSelected = selectedAccountTypes?.includes(PartnerTypeEnum.RESELLER);

	if (isDistributorSelected && isResellerSelected) {
		return partnerSubTypeEnumUtils.getSelectOptions({ picked: accountSubTypesWhiteList });
	}
	if (isResellerSelected) {
		return partnerSubTypeEnumUtils.getResellerSelectOptions({ picked: accountSubTypesWhiteList });
	}
	if (isDistributorSelected) {
		return partnerSubTypeEnumUtils.getDistributorSelectOptions({ picked: accountSubTypesWhiteList });
	}
	return [];
};
