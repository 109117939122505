import { useFormikContext } from 'formik';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { useBillablePartyGuard } from './useBillablePartyGuard';
import { useEffect } from 'react';

type TUseExistingPartnerDiscountsGuard = {
	isSelected: boolean;
};

export const useExistingPartnerDiscountsGuard = (): TUseExistingPartnerDiscountsGuard => {
	const {
		values: { partner },
		setFieldValue,
	} = useFormikContext<IPriceCalculatorForm>();
	const billablePartyGuard = useBillablePartyGuard();

	// Update values
	useEffect(() => {
		if (partner) {
			setFieldValue('discount.tierCertification', partner?.tierCertification);
			setFieldValue('discount.special', Number(partner?.identifiers?.businessSpecialDiscount || 0) / 100);
		}
	}, [partner, setFieldValue]);

	return {
		isSelected: billablePartyGuard.isExistingPartner,
	};
};
