import { useAuthContext } from 'js/contexts';
import { useCallback, useMemo } from 'react';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import type { TCustomerSalesforceId, TPartnerId } from 'types';

type TLink = (partnerId?: TPartnerId | null) => string;
type TCustomerLink = (id: TCustomerSalesforceId | null | undefined, partnerId?: TPartnerId | null) => string;

type TCustomersLink = {
	list: () => string;
	detail: TCustomerLink;
	update: TCustomerLink;
	create: TLink;
};

export const useGetCustomerLink = (): TCustomersLink => {
	const { isGroupPartner } = useAuthContext();

	const list: TCustomersLink['list'] = useCallback(() => {
		return RouteName.CUSTOMERS.LIST;
	}, []);

	const detail: TCustomersLink['detail'] = useCallback(
		(id, partnerId) => {
			if (!isGroupPartner && partnerId) {
				return buildParametrizedRoute(RouteName.CUSTOMERS.PARTNER_DETAIL, { partnerId, id });
			}
			// For partners and Business End Customers
			return buildParametrizedRoute(RouteName.CUSTOMERS.DETAIL, id);
		},
		[isGroupPartner],
	);

	const update: TCustomersLink['update'] = useCallback(
		(id, partnerId) => {
			if (!isGroupPartner && partnerId) {
				return buildParametrizedRoute(RouteName.CUSTOMERS.PARTNER_UPDATE, { partnerId, id });
			}
			// For partners and Business End Customers
			return buildParametrizedRoute(RouteName.CUSTOMERS.UPDATE, id);
		},
		[isGroupPartner],
	);

	const create: TCustomersLink['create'] = useCallback(
		(partnerId) => {
			if (!isGroupPartner && partnerId) {
				return buildParametrizedRoute(RouteName.CUSTOMERS.PARTNER_CREATE, { partnerId });
			}
			// For partners and Business End Customers
			return RouteName.CUSTOMERS.CREATE;
		},
		[isGroupPartner],
	);

	return useMemo(() => ({ detail, update, create, list }), [list, detail, update, create]);
};
