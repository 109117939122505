import type { ReactElement } from 'react';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { FormikForm } from 'js/components/formik/FormikForm';
import type { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { retailConfig } from 'module/retail/retailConfig';
import { SubmitButton } from 'js/components/atoms/Button';
import {
	type ISelectProductVariantTableProps,
	SelectProductVariantTable,
} from 'js/components/table/selectProductVariant/SelectProductVariantTable';
import type { FormikHelpers } from 'formik/dist/types';
import type { ProductVariantModule } from 'js/components/table/selectProductVariant/useSelectProductVariantTableProps';
import { PriceListMixWarning } from 'module/purchase/components/selectProducts/PriceListMixWarning';
import { ProductGroupExceptions } from 'js/components/molecules/Exceptions';
import { TransactionTypeEnum } from 'module/licenses/enums';

type onSubmitOptions = {
	formikHelpers?: Partial<FormikHelpers<TSelectVariantForm>>;
	closeOnSubmit?: boolean;
};

export type TSelectProductVariantModalProps = Pick<ISelectProductVariantTableProps, 'productGroup'> & {
	onSubmit: (values: TSelectVariantForm, options?: onSubmitOptions) => void | Promise<void>;
	initialState: TSelectVariantItem[];
	module: ProductVariantModule;
};

const isSubmitDisabled = (values: TSelectVariantForm): boolean => !values.variant.some((variant) => variant.checked);

export const SelectProductVariantModal = (props: TSelectProductVariantModalProps): ReactElement | null => {
	const { onSubmit, productGroup, initialState, module } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	const isRetailModule = module === 'retail';
	const isPurchaseModule = module === 'purchase';

	if (productGroup === null) {
		return null;
	}

	return (
		<Formik<TSelectVariantForm>
			initialValues={{ variant: initialState }}
			onSubmit={(values, { setSubmitting }) =>
				onSubmit(values, { closeOnSubmit: false, formikHelpers: { setSubmitting } })
			}
		>
			{({ values }) => (
				<FormikForm testId="selectVariantForm">
					<Modal.Header>{productGroup.name}</Modal.Header>
					<Modal.Body>
						{isPurchaseModule && <PriceListMixWarning />}
						<ProductGroupExceptions transactionType={TransactionTypeEnum.NEW} productCode={productGroup.code} />
						<SelectProductVariantTable productGroup={productGroup} module={module} />
					</Modal.Body>
					<Modal.Footer
						cancelButton
						submitButton={
							<SubmitButton disabled={isSubmitDisabled(values)} size="sm" testId="addToCart">
								{t('actions.addToCart')}
							</SubmitButton>
						}
						additionalButtons={
							isRetailModule
								? [
										{
											id: 'addToCartAndClose',
											disabled: isSubmitDisabled(values),
											children: t(retailConfig.trPrefix('generate.products.addAndClose')),
											size: 'sm',
											testId: 'addToCartAndClose',
											variant: 'outline-primary',
											onClick() {
												onSubmit(values, { closeOnSubmit: true });
											},
										},
									]
								: undefined
						}
					/>
				</FormikForm>
			)}
		</Formik>
	);
};
