import { type ReactElement, useState } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import type {
	IEntityClosingBalance,
	IEntityClosingBalanceLicenses,
	IEntityClosingBalanceLicensesFilter,
} from 'module/closingBalance';
import { ButtonComposition } from '@avast/react-ui-components';
import { useApiClosingBalanceLicenses } from 'module/closingBalance/hooks/useApiClosingBalance';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncDetailViewModal,
	type TAsyncDetailViewModalProps,
} from 'module/closingBalance/components/modal/AsyncDetailViewModal';
import { ExportClosingBalanceButton } from 'module/closingBalance/components/buttons';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IListMetaDataValues } from 'types/utils';
import { useClosingBalanceDetailViewColumns } from 'module/closingBalance/hooks';
import { ClosingBalanceSearchKeyEnum } from 'module/closingBalance/enums';

type TTableData = IEntityClosingBalanceLicenses;
type TFilterData = IEntityClosingBalanceLicensesFilter;

export const DetailTab = (): ReactElement => {
	const { data: closingBalance } = usePageDetailContext<IEntityClosingBalance>();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TFilterData>>({});

	const detailViewRef = useAsyncModalRef<TAsyncDetailViewModalProps>();
	const columns = useClosingBalanceDetailViewColumns(detailViewRef);

	return (
		<>
			<DefaultContainer>
				<ButtonComposition marginY>
					<ExportClosingBalanceButton
						format="line-detail"
						listMetaData={{
							totalCount: listMetaData.totalCount,
							filter: {
								search: {
									key: ClosingBalanceSearchKeyEnum.ORDER_NUMBER,
									value: closingBalance.id,
								},
							},
						}}
					/>
				</ButtonComposition>
				<ApiPaginatedListTable<TTableData, TFilterData>
					columns={columns}
					query={useApiClosingBalanceLicenses}
					filter={{ id: closingBalance.id }}
					filterRequired
					table={{
						testId: 'detailView',
						enableSorting: false,
						meta: {
							paginationComponent: 'Full',
						},
					}}
					onMetaDataChange={setListMetaData}
				/>
			</DefaultContainer>

			<AsyncDetailViewModal forwardedRef={detailViewRef} />
		</>
	);
};
