import { API_AUTO_BILLING_KEYS, useApiAutoBillingDeleteSource } from 'module/account/hooks/useApiAutoBilling';
import { useCallback } from 'react';
import type { IDigitalRiverSource } from 'module/account';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { logError } from 'js/utils/app';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { API_PARTNERS_KEYS } from 'module/partners/hooks/useApiPartners';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const useAutoBillingDeleteSource = (source: IDigitalRiverSource | null) => {
	const { loadingModalRef, confirmationModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const { mutateAsync } = useApiAutoBillingDeleteSource();
	const invalidateQueries = useAsyncInvalidateQueries([API_AUTO_BILLING_KEYS.SOURCE, API_PARTNERS_KEYS.DETAIL]);

	return useCallback(async (): Promise<boolean> => {
		const confirm = await confirmationModalRef.current?.show({
			title: t('creditCard.delete.title'),
			messages: [t('creditCard.delete.confirmation', { lastFourDigits: source?.creditCard.lastFourDigits })],
			submitButtonText: t('common:actions.confirm'),
		});

		if (!confirm) {
			return false;
		}

		loadingModalRef.current?.show({
			title: t('creditCard.delete.loading'),
			ellipsis: true,
		});

		const response = await mutateAsync({}).catch((error) => {
			logError('Save source data failed', error);
			setError({ error });
		});

		await invalidateQueries();

		if (response) {
			toast.success(<Toast>{t('creditCard.message.successDelete')}</Toast>);
		}

		loadingModalRef.current?.hide();

		return Boolean(response);
	}, [source, mutateAsync, t, confirmationModalRef, loadingModalRef, setError, invalidateQueries]);
};
