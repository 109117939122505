import type { TAnnouncement } from 'submodule/Announcements/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useCCPaymentSuspensionAnnouncement = (): TAnnouncement => {
	const { t } = useTranslation('submodules');

	return {
		id: '3e4b09bb-5824-4386-ba38-20577268b071',
		show: true,
		variant: 'warning',
		caption() {
			return <Trans t={t}>{'announcements.ccSuspension.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p>
						<Trans t={t}>{'announcements.ccSuspension.contentTop'}</Trans>
					</p>
					<p>
						<Trans t={t}>{'announcements.ccSuspension.contentMiddle'}</Trans>
					</p>
					<p>
						<Trans t={t}>{'announcements.ccSuspension.contentBottom'}</Trans>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_PARTNER'],
	};
};
