import { useCallback } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { LinkButton } from 'js/components/atoms/Button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { Button, ButtonComposition } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useCheckoutButtons, useEditPriceButton } from 'module/purchase/hooks/usePaymentTypes';
import { useFormikContext } from 'formik';
import type { IStandardOrderInstance } from 'module/purchase';
import { useAsyncContainerRef } from 'js/components/molecules/Modal/AsyncContainer';
import { logDebug } from 'js/utils/app';
import { AsyncSubmitOrder, type TAsyncSubmitOrderProps } from 'module/purchase/components/submitOrder/AsyncSubmitOrder';
import { AsyncEditPricesModal } from 'module/purchase/components/editPrices/AsyncEditPricesModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncCustomerQuoteModal } from 'module/purchase/components/customerQuote/AsyncCustomerQuoteModal';
import { OrderHoldAlert } from 'module/security/components/OrderHoldAlert';
import { useExportCart } from 'module/purchase/hooks/useExportCart';
import { DiscountApprovalAlert } from 'module/purchase/components/DiscountApprovalAlert';
import { useOrderContext } from 'js/contexts';
import { STATUS_SUCCESS } from 'appConstants';

type TOrderActionsProps = {
	backLink?: string;
};

export const OrderActions = (props: TOrderActionsProps) => {
	const { backLink } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { isSubmitting } = useFormikContext<IStandardOrderInstance>();
	const { checkoutButtons, isLoading } = useCheckoutButtons();
	const editPriceButton = useEditPriceButton();
	const { setAction, isDiscountApprovalRequired, resetOrder } = useOrderContext();
	const submitOrderRef = useAsyncContainerRef<TAsyncSubmitOrderProps>();
	const customerQuoteRef = useAsyncModalRef();
	const editPricesRef = useAsyncModalRef();
	const exportCart = useExportCart();

	const onSubmit = useCallback(
		async (action: NonNullable<IStandardOrderInstance['action']>) => {
			if (action === 'CUSTOMER_QUOTE') {
				const response = await customerQuoteRef.current?.show();
				if (!response) {
					return;
				}
			}

			setAction(action);
			submitOrderRef.current?.show({ action }).then((result) => {
				logDebug('submitOrderRef', result);
				if (result === STATUS_SUCCESS) {
					// Race condition problem, we have to wait until redirect is finished and then we can reset order
					setTimeout(resetOrder, 50);
				}
			});
		},
		[submitOrderRef, customerQuoteRef, setAction, resetOrder],
	);

	return (
		<>
			<OrderHoldAlert className="my-3" simplified />
			{isDiscountApprovalRequired && <DiscountApprovalAlert className="my-3" />}

			<Row align="center" justify="between" multi size="sm" className="mt-3 flex-row-reverse">
				<Col xs="auto">
					<ButtonComposition wrap marginY>
						<Button
							loading={isLoading || isSubmitting}
							variant="outline-primary"
							size="sm"
							onClick={() => exportCart()}
							testId="exportCart"
						>
							{t('actions.exportCart')}
						</Button>
						{checkoutButtons?.reverse().map((button) => (
							<Button
								testId={button.action}
								key={button.action}
								loading={isLoading || isSubmitting}
								disabled={!button.isEnabled}
								variant={button.variant}
								size="sm"
								onClick={() => onSubmit(button.action)}
							>
								{button.name}
							</Button>
						))}
					</ButtonComposition>
				</Col>
				<Col xs="auto">
					<ButtonComposition>
						{backLink && (
							<LinkButton testId="back" size="sm" variant="outline-primary" iconLeftFa={faChevronLeft} to={backLink}>
								{t('common:actions.back')}
							</LinkButton>
						)}
						{editPriceButton && (
							<Button
								testId="editPrices"
								loading={isLoading || isSubmitting}
								disabled={!editPriceButton.isEnabled}
								variant={editPriceButton.variant}
								size="sm"
								onClick={() => {
									editPricesRef.current?.show().then((result) => {
										logDebug('submit with edited prices', result);
									});
								}}
							>
								{editPriceButton.name}
							</Button>
						)}
					</ButtonComposition>
				</Col>
			</Row>

			<AsyncSubmitOrder forwardedRef={submitOrderRef} />
			<AsyncCustomerQuoteModal forwardedRef={customerQuoteRef} />
			<AsyncEditPricesModal forwardedRef={editPricesRef} />
		</>
	);
};
