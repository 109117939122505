import { useMemo } from 'react';
import { type TUseApiCountryListModule, useApiCountryList } from 'js/entities/country/useApiCountry';
import type { TSelectOptions } from '@avast/react-ui-components';

type TUseCountryOptions = {
	countrySelectOptions: TSelectOptions;
	isLoading: boolean;
	isFetching: boolean;
};

type TUseCountrySelectOptionsProps = {
	customQuery?: TUseApiCountryListModule;
	onlyEnabled?: boolean | null;
};

/**
 * Get countries as select options
 * @param {TUseCountrySelectOptionsProps} props
 * @returns {TUseCountryOptions}
 */
export const useCountrySelectOptions = (props: TUseCountrySelectOptionsProps = {}): TUseCountryOptions => {
	const { customQuery, onlyEnabled = true } = props;
	const useQuery = customQuery || useApiCountryList;
	const {
		data: countries,
		query: { isFetching, isLoading },
	} = useQuery({
		filter: { enabled: onlyEnabled },
	});

	const countrySelectOptions: TUseCountryOptions['countrySelectOptions'] = useMemo(
		() =>
			countries.map((country) => ({
				value: country.code,
				label: country.name,
				disabled: !country.enabled,
			})),
		[countries],
	);

	return {
		countrySelectOptions,
		isLoading,
		isFetching,
	};
};
