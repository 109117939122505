import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import maintenanceImage from 'assets/image/maintenance.png';
import { ScheduledMaintenance } from 'module/security/components/maintenance/ScheduledMaintenance';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { securityConfig } from 'module/security/securityConfig';

export const PageMaintenance = (): ReactElement => {
	const { t } = useTranslation(securityConfig.trNamespace);

	return (
		<div className="text-center py-5">
			<ScheduledMaintenance />
			<div className="mt-6">
				<FixedWidthWrapper width={400} max>
					<img src={maintenanceImage} alt={t('page.maintenance.scheduled.title')} className="img-fluid" />
				</FixedWidthWrapper>
			</div>
		</div>
	);
};
