import type { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { RouteName } from 'module/RouteName';
import { SelectProducts } from 'module/purchase/components/selectProducts/SelectProducts';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { PriceListSwitch } from 'module/purchase/components/selectProducts/PriceListSwitch';
import { PriceListContextProvider } from 'js/priceList/PriceListContext';
import { useLocationState } from 'js/hooks/useLocationState';
import type { IPageProductsState } from 'module/purchase/index';
import { useOrderContext } from 'js/contexts';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { Alert } from '@avast/react-ui-components';

export const PageProducts = (): ReactElement => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const {
		hasOrderParties,
		orderState: { priceListCode },
		billableParty,
	} = useOrderContext();
	const locationState = useLocationState<IPageProductsState>();
	const showIndianVpnRestriction = billableParty?.countryCode === 'IN'; // Indie

	if (!hasOrderParties) {
		return <Navigate to={RouteName.PURCHASE.DEFAULT} replace />;
	}

	return (
		<PriceListContextProvider defaultPriceListCode={locationState?.priceListCode || priceListCode}>
			<DynamicPageTitleItem text={t('page.products.title')} />
			<DynamicBreadcrumbsItem href={RouteName.PURCHASE.PRODUCTS}>{t('page.products.title')}</DynamicBreadcrumbsItem>
			{showIndianVpnRestriction && (
				<div className="container-fluid pb-0">
					<Alert className="mb-0" caption={t('error.indiaVpnRestriction.title')} variant="warning">
						{t('error.indiaVpnRestriction.text')}
					</Alert>
				</div>
			)}
			<PriceListSwitch className="mt-1" />
			<DefaultContainer>
				<SelectProducts />
			</DefaultContainer>
		</PriceListContextProvider>
	);
};
