import type { ReactElement } from 'react';
import { Button, UiTable } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncTaxExemptionModal,
	type TAsyncTaxExemptionModalProps,
} from 'module/account/components/AsyncTaxExemptionModal';
import { Navigate } from 'react-router';
import { RouteName } from 'module/RouteName';
import type { ITaxExemption } from 'module/account';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { useApiTaxExemptionList } from 'module/account/hooks/useApiAccount';
import { Announcements } from 'submodule/Announcements';
import { useTaxExemptionColumns } from 'module/account/hooks';
import { useAuthContext } from 'js/contexts';

export const PageTaxExemption = (): ReactElement | null => {
	const { authCompany: company } = useAuthContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const ref = useAsyncModalRef<TAsyncTaxExemptionModalProps>();
	const { data, query } = useApiTaxExemptionList({
		filter: { partnerId: company?.id },
		queryConfig: { enabled: Boolean(company?.id) },
	});
	const columns = useTaxExemptionColumns();

	if (!company || !company.isTaxExemptEligible) {
		return <Navigate to={RouteName.ACCOUNT.DEFAULT} replace />;
	}

	return (
		<>
			<DynamicPageTitleItem text={t('page.taxExemption.title')} />
			<DynamicBreadcrumbsItem href={RouteName.ACCOUNT.TAX_EXEMPTION}>
				{t('page.taxExemption.title')}
			</DynamicBreadcrumbsItem>

			<Announcements placement="TAX_EXEMPTION_CERTIFICATE" />

			<div className="mb-3">
				<Button
					testId="submitNewTaxExemption"
					variant="primary"
					size="sm"
					onClick={async () => {
						if (await ref.current?.show()) {
							query.refetch();
						}
					}}
				>
					{t('page.taxExemption.action')}
				</Button>
			</div>

			<UiTable<ITaxExemption>
				data={data}
				columns={columns}
				enableSorting
				enablePagination
				initialState={{
					sorting: [{ id: 'startDate', desc: true }],
				}}
				meta={{
					isScrollable: true,
					nowrapCell: true,
					loading: query.isFetching,
					customError: query.isError,
					scrollTopOnPageChange: true,
				}}
			/>

			<AsyncModal ref={ref} testId="taxExemption">
				<AsyncTaxExemptionModal currentTaxExemption={data.find((n) => Boolean(n))} forwardedRef={ref} />
			</AsyncModal>
		</>
	);
};
