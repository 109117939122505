import React from 'react';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useTranslation } from 'react-i18next';

type TPromotionMessagePartProps = {
	promotionsArray: [string, string][][];
	boxVariant: 'white' | 'light';
	title: string;
};

export const PromotionMessagePart: React.FC<TPromotionMessagePartProps> = ({ promotionsArray, title, boxVariant }) => {
	const { t } = useTranslation(ordersConfig.trNamespace);

	if (!promotionsArray || promotionsArray.length === 0) {
		return null;
	}

	return (
		<>
			<p className="fs-sm mb-1 mt-2">{title}:</p>
			<div className={`bg-${boxVariant} fs-sm border p-3`}>
				{promotionsArray.map((promo, index) => (
					// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
					<React.Fragment key={index}>
						{index !== 0 && <hr className="text-muted" />}
						<div className="component__pricing-message-container">
							{promo.map(([key, value]) => {
								if (key === 'promotion') {
									return (
										<div key={key} className="fw-bold">
											{value}
										</div>
									);
								}
								return (
									<div key={key}>
										{t(`pricingMessage:${key}`)}: {value}
									</div>
								);
							})}
						</div>
					</React.Fragment>
				))}
			</div>
		</>
	);
};
