import { useCallback } from 'react';
import type { IEntityOrder } from 'module/orders';
import { isOrderInStatus } from 'module/orders/utils/common';
import { OrderStatusEnum } from 'module/orders/enums';
import { useAuthContext } from 'js/contexts';

export const useIsDiscountResolvable = () => {
	const isDiscountApprovalPending = useIsDiscountApprovalPending();
	const isDiscountApprovable = useIsDiscountApprovable();

	return useCallback(
		(order: IEntityOrder): boolean => isDiscountApprovalPending(order) || isDiscountApprovable(order),
		[isDiscountApprovalPending, isDiscountApprovable],
	);
};

const useIsDiscountApprovalPending = () => {
	const { isRoleSalesOperations, authPartner } = useAuthContext();
	const id = authPartner?.id || null;

	return useCallback(
		(order: IEntityOrder): boolean =>
			isRoleSalesOperations &&
			order.transaction?.ownerIndividualId !== id &&
			isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS) &&
			order.discount?.approvalFlag === 'Approval Required',
		[isRoleSalesOperations, id],
	);
};

export const useIsDiscountApprovable = () => {
	const { isRoleSalesOperations, authPartner } = useAuthContext();
	const id = authPartner?.id || null;

	return useCallback(
		(order: IEntityOrder): boolean =>
			isRoleSalesOperations &&
			order.transaction?.ownerIndividualId !== id &&
			isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS) &&
			order.discount?.approvalFlag === 'Rejected',
		[isRoleSalesOperations, id],
	);
};
