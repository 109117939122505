import type { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { ClosingBalanceRoutes } from 'module/closingBalance/ClosingBalanceRoutes';

export const ClosingBalanceContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={closingBalanceConfig} />
		<ClosingBalanceRoutes />
	</>
);
