import { useEffect, useState } from 'react';
import { slice } from 'lodash';
import type { IStandardOrderInstanceItem } from 'module/purchase';
import { useOrderContext } from 'js/contexts';

export type TOrderTablePagination = {
	total: number;
	pageCount: number;
	page: number;
	setPage(page: number): void;
	range: [number, number];
	isPaginated: boolean;
	pageItemsIds: IStandardOrderInstanceItem['id'][];
};

const LIMIT = 30;
const PER_PAGE = 10;

export const useOrderTablePagination = (): TOrderTablePagination => {
	const {
		orderState: { items },
	} = useOrderContext();
	const [page, setPage] = useState(0);
	const total = items.length;
	const pageCount = Math.ceil(total / PER_PAGE);
	const isPaginated = total > LIMIT;

	// Watch page
	useEffect(() => {
		if (page >= pageCount) {
			setPage(pageCount - 1);
		}
	}, [pageCount, page]);

	const from = page * PER_PAGE;
	const to = (page + 1) * PER_PAGE;

	return {
		total,
		pageCount,
		page: isPaginated ? page + 1 : 1,
		setPage(page: number) {
			setPage(Math.max(0, page - 1));
		},
		range: [from + 1, Math.min(to, total)],
		isPaginated,
		pageItemsIds: isPaginated ? slice(items, from, to).map((item) => item.id) : [],
	};
};
