import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { RefundReasonEnum, refundReasonEnumUtils } from 'submodule/refunds/enums';
import { PriceSummary } from 'js/components/molecules/PriceSummary';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import { useFormikContext } from 'formik';
import type { IInitiateRefundForm } from 'submodule/refunds';
import { getRefundAmount } from 'submodule/refunds/refundUtils';
import { useAuthContext } from 'js/contexts';

type TStepReviewProps = {
	onCancel: () => void;
};

export const StepReview = (props: TStepReviewProps) => {
	const { onCancel } = props;
	const { t, currency } = useInitiateRefundContext();
	const { values, dirty, isValid } = useFormikContext<IInitiateRefundForm>();
	const { isGroupPartner } = useAuthContext();
	const refundReasonOptions = refundReasonEnumUtils.getSelectOptions({
		omitted: isGroupPartner ? [RefundReasonEnum.TAX_EXEMPT] : [],
	});

	return (
		<>
			<Row size="md">
				<Col lg={5} md={6}>
					<FormikControl label={t('refunds.entity.reason')}>
						<FormikControl.SingleSelect<RefundReasonEnum>
							name="refundReason"
							options={refundReasonOptions}
							isClearable={false}
							required
						/>
					</FormikControl>
				</Col>
				<Col lg={12}>
					<FormikControl label={t('refunds.entity.reasonNote')}>
						<FormikControl.Textarea name="refundReasonNote" required rows={3} />
					</FormikControl>
				</Col>
			</Row>
			<PriceSummary
				headers={[t('refunds.entity.refundAmount')]}
				rows={[{ values: [getRefundAmount(values)] }]}
				currencyCode={currency}
				className="mt-5"
			/>
			<FormikFooterControls
				cancel={{ onClick: onCancel, children: t('common:actions.back') }}
				submit={{
					children: t('refunds.initiate.submit'),
					disabled: !(dirty && isValid),
				}}
			/>
		</>
	);
};
