import { useApiCountry } from 'js/entities/country/useApiCountry';
import { useMemo } from 'react';
import type { IEntityCurrency } from 'types/entity';

export const useCountrySupportedCurrencies = (countryCode?: string | null, enabled = true) => {
	const defaultValue: IEntityCurrency[] = useMemo(() => [], []);
	const { data: country, query } = useApiCountry({
		// @ts-ignore The hook runs query only when countryCode is not null
		filter: { countryCode },
		queryConfig: { enabled: Boolean(countryCode) && enabled },
	});

	return {
		supportedCurrencies: country?.supportedCurrencies || defaultValue,
		query,
	};
};
