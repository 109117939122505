import type { IEntityChargeOrder } from 'js/chargeOrder';
import type {
	IEntityRefundableAmount,
	IEntityRefundableItem,
	IInitiateRefundForm,
	IInitiateRefundItem,
	IInitiateRefundRequest,
	IInitiateRefundTableItem,
} from 'submodule/refunds';
import type { IEntityOrderItem } from 'module/orders';
import type { IEntityLicense } from 'module/licenses';
import { getFulfillmentValue } from 'module/licenses/utils/common';
import { RefundReasonEnum, RefundTypeEnum } from 'submodule/refunds/enums';
import { isNumber } from 'lodash';

export const createInitialRefundTableData = (
	items: IEntityOrderItem[],
	refundableAmount: IEntityRefundableAmount,
): IInitiateRefundTableItem[] => {
	const list: IInitiateRefundTableItem[] = [];

	for (const item of items) {
		// Find refundable item
		const refundableItem = refundableAmount?.lineItems.find((refundableItem) => refundableItem.lineItemId === item.id);
		if (refundableItem) {
			list.push({
				id: item.id,
				orderLineItemId: item.id,
				isSelected: false,
				productName: item.product.description,
				productSku: item.product.sku,
				quantity: item.computedValues.quantity,
				unit: item.computedValues.unit,
				totalPrice: item.price?.withoutTax || 0,
				totalTax: item.tax || 0,
				totalPriceWithTax: item.price?.withTax || 0,
				refundableAmount: refundableItem.amountRefundableWithTax,
				refundAmount: refundableItem.amountRefundableWithTax,
				refundAmountCustom: refundableItem.amountRefundableWithTax,
			});
		}
	}

	return list;
};

export const isInitialRefundFormInvalid = (values: IInitiateRefundForm) => {
	switch (values.refundType) {
		case RefundTypeEnum.LINE:
			return values.items.filter((item) => isNumber(item.refundAmountCustom) && item.isSelected).length === 0;
		case RefundTypeEnum.KEY:
			return values.licenses.filter((item) => isNumber(item.refundAmountCustom) && item.isSelected).length === 0;
		case RefundTypeEnum.ORDER:
			return !values.refundAmount;
		default:
			return false;
	}
};

export const createRefundRequest = (values: IInitiateRefundForm, canPartialRefund: boolean): IInitiateRefundRequest => {
	const request: IInitiateRefundRequest = {
		orderId: values.orderId,
		refundType: values.refundType,
		refundReason: values.refundReason,
		refundReasonNote: values.refundReasonNote,
	};

	switch (values.refundType) {
		case RefundTypeEnum.LINE:
			request.refundParamsItems = values.items
				.filter((item) => item.isSelected)
				.map<IInitiateRefundItem>(function mapRefundLineItem(item) {
					if (canPartialRefund) {
						return {
							orderLineItemId: item.orderLineItemId,
							refundAmount: item.refundAmountCustom,
						};
					}
					return {
						orderLineItemId: item.orderLineItemId,
					};
				});
			break;

		case RefundTypeEnum.KEY:
			request.refundParamsItems = values.licenses
				.filter((item) => item.isSelected)
				.map<IInitiateRefundItem>(function mapRefundKeyItem(item) {
					if (canPartialRefund) {
						return {
							entitlementId: item.entitlementId,
							licenseKey: item.licenseKey,
							refundAmount: item.refundAmountCustom,
						};
					}
					return {
						entitlementId: item.entitlementId,
						licenseKey: item.licenseKey,
					};
				});
			break;

		case RefundTypeEnum.ORDER:
			if (canPartialRefund) {
				request.refundAmount = values.refundAmount;
			}
			break;
		default:
			break;
	}
	return request;
};

export const getRefundAmount = (values: IInitiateRefundForm): number => {
	switch (values.refundType) {
		case RefundTypeEnum.LINE:
			return values.items.reduce((acc, item) => {
				if (item.isSelected) {
					return acc + item.refundAmountCustom;
				}
				return acc;
			}, 0);
		case RefundTypeEnum.KEY:
			return values.licenses.reduce((acc, item) => {
				if (item.isSelected) {
					return acc + item.refundAmountCustom;
				}
				return acc;
			}, 0);
		case RefundTypeEnum.TAX:
			return values.refundableAmount;
		case RefundTypeEnum.ORDER:
			return values.refundAmount;
		default:
			return 0;
	}
};

export const getInitialFormData = (
	chargeOrder: IEntityChargeOrder,
	refundableAmount: IEntityRefundableAmount,
): IInitiateRefundForm => {
	const items = createInitialRefundTableData(chargeOrder.lineItems, refundableAmount);

	return {
		orderId: chargeOrder.id,
		refundType: refundableAmount.refundTypes.find((type) => type.isEnabled)?.name || RefundTypeEnum.ORDER,
		items,
		licenses: [],
		refundAmount: refundableAmount.totalRefundableAmountWithTax,
		refundableAmount: refundableAmount.totalRefundableAmountWithTax,
		taxRefundableAmount: refundableAmount.totalTaxRefundableAmount,
		refundReason: RefundReasonEnum.CUSTOMER_SATISFACTION_ISSUE,
	};
};

export const createInitialFormLicenseItem = (
	license: IEntityLicense,
	refundableItem: IEntityRefundableItem,
): IInitiateRefundTableItem => ({
	id: String(license.id),
	entitlementId: license.id,
	licenseKey: getFulfillmentValue(license)!,
	isSelected: true,
	productName: license.product.productGroupName,
	productSku: license.computedValues.productSku,
	quantity: 1,
	unit: license.product.dimension,
	totalPrice: refundableItem.amountWithTax,
	totalTax: refundableItem.taxAmount,
	totalPriceWithTax: refundableItem.amountWithTax,
	refundableAmount: refundableItem.amountRefundableWithTax,
	refundAmount: refundableItem.amountRefundableWithTax,
	refundAmountCustom: refundableItem.amountRefundableWithTax,
});
