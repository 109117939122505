import { type Dispatch, type SetStateAction, useState } from 'react';
import { keepPreviousData, type UseQueryOptions, type UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IApiPaginatedListResponse, IApiSortBy } from 'types/api';
import type { AxiosError, AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { useApiPaginatedList } from 'js/hooks/api';
import type { TQueryConfig } from 'js/queries';
import type { TUseApiPaginatedListQueryKey } from 'js/hooks/api/useApiPaginatedList';
import type { TUseApiListProps } from 'js/hooks/api/useApiList';
import type { PaginationState } from '@tanstack/table-core/src/features/RowPagination';
import { useUiLocaleContext } from '@avast/react-ui-components';

/**
 * Paginated query config
 */
type TQueryPaginatedListConfig = TQueryConfig & {
	limit?: number;
};

/**
 * Response from paginated api call
 */
type TUseApiPaginatedListResponse<Data extends {}, Error = AxiosError<IApiPaginatedListResponse<Data>>> = {
	data: IApiPaginatedListResponse<Data> | null;
	pagination: PaginationState;
	setPagination: Dispatch<SetStateAction<PaginationState>>;
	query: UseQueryResult<AxiosResponse<IApiPaginatedListResponse<Data>>, Error>;
	sort?: IApiSortBy<Data>;
	setSort: Dispatch<SetStateAction<IApiSortBy<Data> | undefined>>;
};

/**
 * Props of module api query function
 */
type TUseApiPaginatedListQueryProps<
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	ApiFilter = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
> = {
	filter?: Filter;
	sortBy?: IApiSortBy<Data>;
	config?: TQueryPaginatedListConfig;
	queryConfig?: Omit<
		UseQueryOptions<
			AxiosResponse<IApiPaginatedListResponse<Data>>,
			Error,
			AxiosResponse<IApiPaginatedListResponse<Data>>,
			TUseApiPaginatedListQueryKey<Data, Filter>
		>,
		'queryFn' | 'queryKey'
	>;
} & Pick<TUseApiListProps<Filter, ApiFilter>, 'apiFilterNormalizer'>;

/**
 * Module api query function type
 */
export type TUseApiPaginatedListModule<
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	ApiFilter = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
> = (
	props?: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error>,
) => TUseApiPaginatedListResponse<Data, Error>;

/**
 * Query hook for get paginated data
 * @param {string} key
 * @param {TUseApiPaginatedListQueryProps} props
 * @param {TUseApiPaginatedListQueryProps} defaults
 * @param {string|undefined} extraQueryKey
 * @returns {TUseApiPaginatedListResponse}
 */
export const useApiPaginatedListQuery = <
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	ApiFilter extends {} = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
>(
	key: string,
	props: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error> = {},
	defaults?: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error>,
	extraQueryKey?: string,
): TUseApiPaginatedListResponse<Data, Error> => {
	const { filter, sortBy, config, queryConfig, apiFilterNormalizer } = merge(defaults, props);
	const { table: context } = useUiLocaleContext();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: config?.limit ?? context.pageSizeDefault,
	});
	const [sort, setSort] = useState<IApiSortBy<Data> | undefined>(sortBy);

	const queryFn = useApiPaginatedList<Data, Filter, ApiFilter>({
		config: {
			...config,
		},
		apiFilterNormalizer,
	});

	const query = useQuery({
		queryKey: [key, pagination.pageIndex, pagination.pageSize, filter as Filter, sort, extraQueryKey],
		queryFn,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
		...queryConfig,
	});

	return { query, setPagination, pagination, data: query.data?.data ?? null, sort, setSort };
};
