import { ButtonComposition, InlineButton } from '@avast/react-ui-components';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useTranslation } from 'react-i18next';
import type { TFlexibleBillingForm } from 'module/flexibleBilling';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

type TEditOverviewTableActionsProps = {
	className?: string;
	isLoading?: boolean;
};

export const EditOverviewTableActions = (props: TEditOverviewTableActionsProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { setValues } = useFormikContext<TFlexibleBillingForm>();

	const setAllServicesTo = (state: boolean) => {
		setValues((values) =>
			values.map((item) => ({
				...item,
				enabled: state,
			})),
		);
	};

	return (
		<ButtonComposition className={classNames('d-flex justify-content-end', props.className)}>
			<InlineButton type="button" variant="primary" onClick={() => setAllServicesTo(true)} disabled={props.isLoading}>
				{t('actions.enableAllProducts')}
			</InlineButton>
			<InlineButton type="button" variant="primary" onClick={() => setAllServicesTo(false)} disabled={props.isLoading}>
				{t('actions.disableAllProducts')}
			</InlineButton>
		</ButtonComposition>
	);
};
