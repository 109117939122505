import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import type { IStandardOrderInstanceCustomPrice } from 'module/purchase';
import { EditPricesModal } from 'module/purchase/components/editPrices/EditPricesModal';
import { useEditPricesSave } from 'module/purchase/components/editPrices/useEditPricesSave';
import {
	AsyncSuccessOrderModal,
	useAsyncSuccessOrderModalRef,
} from 'module/purchase/components/submitOrder/AsyncSuccessOrderModal';
import { useQueryClient } from '@tanstack/react-query';
import { STATUS_SUCCESS } from 'appConstants';
import { PaymentTypeActionEnum } from 'module/purchase/enums';
import { useNavigate } from 'react-router';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useOrderContext } from 'js/contexts';

export type TEditPricesForm = {
	items: IStandardOrderInstanceCustomPrice[];
};

export const AsyncEditPricesModal = (props: TAsyncModalContainerProps) => {
	const { forwardedRef } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const {
		orderState: { items },
		updateCustomPrices,
		resetOrder,
	} = useOrderContext();
	const save = useEditPricesSave();
	const successRef = useAsyncSuccessOrderModalRef();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return (
		<>
			<AsyncModal ref={forwardedRef} size="xl" testId="editPrices">
				<Modal.Header>{t('components.editPrices.title')}</Modal.Header>
				<Formik<TEditPricesForm>
					initialValues={{
						items: items.map((item) => ({ id: item.id })),
					}}
					onSubmit={async (values, { setSubmitting }) => {
						updateCustomPrices(values.items);
						await save(values.items, (order) => {
							successRef.current?.show({ order, action: PaymentTypeActionEnum.SAVE }).then((link) => {
								resetOrder();
								queryClient.invalidateQueries();
								if (link) {
									const navigateObject = navigateLinkToNavigateObject(link);
									navigate(navigateObject.to, navigateObject.options);
								}
								forwardedRef.current?.onSuccess(STATUS_SUCCESS);
							});
						});
						setSubmitting(false);
					}}
				>
					<FormikForm testId="editPrices">
						<EditPricesModal forwardedRef={forwardedRef} />
					</FormikForm>
				</Formik>
			</AsyncModal>
			<AsyncSuccessOrderModal forwardedRef={successRef} />
		</>
	);
};
