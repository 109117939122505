import type { IEntityPartnerDetail, IEntityPartnerProperties } from 'module/partners';
import type { IAuthGuards } from 'types';
import { get } from 'lodash';
import { useAuthContext } from 'js/contexts';
import type { PartnerRoleEnum } from 'module/partners/enums';

const arePartnerPropertiesEqual = (partner: IEntityPartnerDetail, properties: Partial<IEntityPartnerProperties>) => {
	return Object.keys(properties).reduce<boolean>((acc, key) => {
		const desiredValue = get(properties, key);
		const partnerValue = get(partner, key);
		return acc && desiredValue === partnerValue;
	}, true);
};

const useAuthAffiliatePropertiesGuard = (properties: Partial<IEntityPartnerProperties> | undefined): boolean => {
	const { isGroupPartner, authCompany } = useAuthContext();

	if (isGroupPartner || !authCompany || !properties) {
		return true;
	}

	return arePartnerPropertiesEqual(authCompany, properties);
};

const useAuthPartnerPropertiesGuard = (properties: Partial<IEntityPartnerProperties> | undefined): boolean => {
	const { isGroupPartner, authCompany } = useAuthContext();

	if (!isGroupPartner || !authCompany || !properties) {
		return true;
	}
	return arePartnerPropertiesEqual(authCompany, properties);
};

const useAuthRolesGuard = (roles: PartnerRoleEnum[] | undefined): boolean => {
	const { authRole } = useAuthContext();

	if (!roles || !authRole) {
		return true;
	}

	return roles.includes(authRole);
};

export const useAuthGuards = (guards?: IAuthGuards): boolean => {
	const isAffiliateAllowedByProperties = useAuthAffiliatePropertiesGuard(guards?.affiliateProperties);
	const isPartnerAllowedByProperties = useAuthPartnerPropertiesGuard(guards?.partnerProperties);
	const isAllowedByRoles = useAuthRolesGuard(guards?.roles);

	return isAffiliateAllowedByProperties && isPartnerAllowedByProperties && isAllowedByRoles;
};
