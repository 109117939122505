import type { TWidgetConfig, TWidgetCreditSettings, TWidgetSettingsValues, TWidgetState } from 'submodule/widgets';
import { useAuthGuards } from 'js/hooks/useSecurityGuards';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { useCan } from 'js/hooks/useCan';
import { WidgetTypeEnum } from 'submodule/widgets/enums';
import { useAuthContext } from 'js/contexts';

type Settings = TWidgetCreditSettings;

export const useCreditStatusWidget = (): TWidgetConfig<Settings> => {
	// Restrictions
	const { isGroupPartner } = useAuthContext();
	const isModuleAllowed = useCan({ do: creditStatusConfig.aclModule, readDetail: true });
	const isAllowed = useAuthGuards(creditStatusConfig.authGuards) && isGroupPartner && isModuleAllowed;

	return {
		type: WidgetTypeEnum.CREDIT_STATUS,
		isAllowed,
		getDefaultState(): TWidgetState<Settings> {
			return {
				type: this.type,
				settings: {},
			};
		},
		getSettingsFields(): TWidgetSettingsValues<Settings> {
			return {};
		},
	};
};
