import type { IEntityPromotion, IPromotionForm, IPromotionRequest } from 'module/promotions';
import { difference, isUndefined, omit, omitBy } from 'lodash';
import { promotionPartyTierEnumUtils, promotionPartyTypeEnumUtils, PromotionScopeEnum } from 'module/promotions/enums';
import { DateTime } from 'luxon';
import { dateTimeToFilterDate, dateToApiDate } from 'js/utils/dateTime';
import type { TEntityKey } from 'types';
import { MarketSegmentEnum } from 'js/enums';
import type { IEnumFunctions } from 'js/enums/generator';
import { logError } from 'js/utils/app';

type TScopeKeyBlacklist = Record<PromotionScopeEnum, TEntityKey<IPromotionForm>[]>;

const promotionFormNormalizerUtils = {
	normalizeKeysBlacklist: ['id', 'startDate', 'endDate'] as TEntityKey<IEntityPromotion>[],
	denormalizeKeysBlacklist: ['date'] as TEntityKey<IPromotionForm>[],
	denormalizeScopeKeysBlacklist: {
		[PromotionScopeEnum.PARTY_AND_PRODUCT]: [],
		[PromotionScopeEnum.PARTY_ONLY]: ['marketSegment', 'quantity', 'quantityMax', 'skuList', 'licenseTypeList'],
		[PromotionScopeEnum.PRODUCT_ONLY]: ['partnerId', 'partnerTypeList', 'partnerTierList', 'partnerCountryCodeList'],
	} as TScopeKeyBlacklist,

	/**
	 * Check if {values} contains all enum values
	 * @template T
	 * @param {IEnumFunctions<T>} enumUtils
	 * @param {T[]} values
	 * @return {boolean}
	 */
	hasEnumAllValues<T extends string>(enumUtils: IEnumFunctions<T>, values?: T[]): boolean {
		if (!values) {
			return false;
		}

		return difference(enumUtils.getValues(), values).length === 0;
	},

	denormalizePartnerTierList(form: IPromotionForm): IPromotionRequest {
		const { partnerTierList } = form;

		if (!partnerTierList || partnerTierList.length === 0) {
			return { partnerTierList: promotionPartyTierEnumUtils.getValues() };
		}

		return { partnerTierList };
	},

	denormalizePartnerTypeList(form: IPromotionForm): IPromotionRequest {
		const { partnerTypeList } = form;

		if (!partnerTypeList || partnerTypeList.length === 0) {
			return { partnerTypeList: promotionPartyTypeEnumUtils.getValues() };
		}

		return { partnerTypeList };
	},

	denormalizeQuantity(form: IPromotionForm): IPromotionRequest {
		const { quantity, quantityMax, marketSegment } = form;

		if (marketSegment === MarketSegmentEnum.CONSUMER) {
			if (!quantity) {
				return { quantity: 0, quantityMax: undefined };
			}
			return { quantity, quantityMax: undefined };
		}

		return { quantity, quantityMax };
	},

	denormalizePartyScopeFields(form: IPromotionForm): IPromotionRequest {
		if (!form.partnerId) {
			return {
				...this.denormalizePartnerTierList(form),
				...this.denormalizePartnerTypeList(form),
				...form.partnerCountryCodeList,
			};
		}

		return {
			partnerCountryCodeList: undefined,
			partnerTierList: undefined,
			partnerTypeList: undefined,
		};
	},

	denormalizeProductScopeFields(form: IPromotionForm): IPromotionRequest {
		return {
			...this.denormalizeQuantity(form),
			skuList: form.skuList?.length ? form.skuList : undefined,
		};
	},
};

export const promotionFormNormalizer = {
	normalize(promotion: IEntityPromotion): IPromotionForm {
		const { partnerTierList, partnerTypeList, startDate, endDate, partnerId } = promotion;
		const data = {
			...omit(promotion, promotionFormNormalizerUtils.normalizeKeysBlacklist),
			date: null,
		} as IPromotionForm;

		// Partner is selected, rest party criteria should have "all" in edit form
		if (partnerId) {
			data.partnerTierList = undefined;
			data.partnerTypeList = undefined;
			data.partnerCountryCodeList = undefined;
		} else {
			if (promotionFormNormalizerUtils.hasEnumAllValues(promotionPartyTierEnumUtils, partnerTierList)) {
				data.partnerTierList = undefined;
			}

			if (promotionFormNormalizerUtils.hasEnumAllValues(promotionPartyTypeEnumUtils, partnerTypeList)) {
				data.partnerTypeList = undefined;
			}
		}

		if (Boolean(startDate) && Boolean(endDate)) {
			data.date = [DateTime.fromISO(startDate).toJSDate(), DateTime.fromISO(endDate).toJSDate()];
		}

		return data;
	},

	denormalize(form: IPromotionForm): IPromotionRequest {
		const { date, scope } = form;
		let request: IPromotionRequest = {
			...omit(form, promotionFormNormalizerUtils.denormalizeKeysBlacklist),
		};

		// Dates
		if (date) {
			const [startDate, endDate] = date;
			if (startDate && endDate) {
				request.startDate = dateToApiDate(dateTimeToFilterDate(DateTime.fromJSDate(startDate)));
				request.endDate = dateToApiDate(dateTimeToFilterDate(DateTime.fromJSDate(endDate).endOf('day')));
			}
		}

		// Data by scope
		switch (scope) {
			case PromotionScopeEnum.PARTY_ONLY:
				request = { ...request, ...promotionFormNormalizerUtils.denormalizePartyScopeFields(form) };
				break;
			case PromotionScopeEnum.PRODUCT_ONLY:
				request = { ...request, ...promotionFormNormalizerUtils.denormalizeProductScopeFields(form) };
				break;
			case PromotionScopeEnum.PARTY_AND_PRODUCT:
				request = {
					...request,
					...promotionFormNormalizerUtils.denormalizePartyScopeFields(form),
					...promotionFormNormalizerUtils.denormalizeProductScopeFields(form),
				};
				break;
			default:
				logError(`Not supported promotion scope: ${scope}`);
		}

		return omitBy(omit(request, promotionFormNormalizerUtils.denormalizeScopeKeysBlacklist[scope]), isUndefined);
	},
};
