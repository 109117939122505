import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import type { IEntityCreditLimit } from 'module/creditLimit';
import type { TableColumnsDef } from 'types';

type TTableData = IEntityCreditLimit;

export const useCreditLimitListColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(creditLimitConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.ellipsis('partner.name', {
				header: t('common:entity.partner'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.ellipsis('createdByAffiliate.name', { header: t('entity.createdBy') }),
			columnHelper.dateTime('createdAt', { header: t('common:dates.created') }),
			columnHelper.money(
				'amount',
				{
					header: t('common:entity.amount'),
					meta: { formatters: [formatter.bold] },
				},
				{ currencyKey: 'currencyCode' },
			),
		];
	}, [t]);
};
