import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import {
	type PromotionScopeEnum,
	promotionScopeEnumUtils,
	type PromotionTypeEnum,
	promotionTypeEnumUtils,
} from 'module/promotions/enums';

export const PromotionFormMainBlock = ({ isUpdate }: { isUpdate: boolean }) => {
	const { t } = useTranslation(promotionsConfig.trNamespace);

	return (
		<>
			<Row size="md">
				<ColInput label={t('common:entity.name')} name="name" colProps={{ sm: isUpdate ? 8 : 12 }} required />
			</Row>
			<FormikControl label={t('common:entity.description')}>
				<FormikControl.Textarea name="description" required />
			</FormikControl>
			<Row size="md">
				<Col sm={6}>
					<FormikControl label={t('entity.scope')}>
						<FormikControl.SingleSelect<PromotionScopeEnum>
							name="scope"
							options={promotionScopeEnumUtils.getSelectOptions()}
							isClearable={false}
							required
						/>
					</FormikControl>
				</Col>
				<Col sm={6}>
					<FormikControl label={t('entity.type')}>
						<FormikControl.SingleSelect<PromotionTypeEnum>
							name="type"
							options={promotionTypeEnumUtils.getSelectOptions()}
							isClearable={false}
							required
						/>
					</FormikControl>
				</Col>
				<Col sm={12}>
					<FormikControl label={t('entity.date')}>
						<FormikControl.RangeDatepicker name="date" required minDate={new Date()} />
					</FormikControl>
				</Col>
				<Col sm={6}>
					<FormikControl label={t('entity.rate')}>
						<FormikControl.Number
							name="rate"
							min={0}
							max={1}
							step={0.01}
							mantissa={4}
							numberFormat={{
								output: 'percent',
								spaceSeparated: true,
								trimMantissa: true,
							}}
							required
						/>
					</FormikControl>
				</Col>
			</Row>
		</>
	);
};
