import { Tab, Tabs } from 'react-bootstrap';
import {
	CustomerTab,
	DiscretionaryDiscountTab,
	DistributionPartnerTab,
	ItemsInOrderTab as ItemsInQuoteTab,
	NotesTab,
	PartnerTab,
} from 'module/orders/pages/tabs';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { QuoteStatusTab } from 'module/orders/pages/tabs/QuoteStatusTab';
import { useTabsTestAttributes } from 'js/hooks/testAttributes/useTabsTestAttributes';
import { hasOrderAnyNote } from 'module/orders/utils/common';

export const PageQuoteDetailTabs = () => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { data } = usePageDetailContext<IEntityOrder>();
	const { isRoleReseller, isGroupInternal, isGroupPartner } = useAuthContext();
	const { customer, billableParty, discount, distributionPartner } = data;
	const testAttributes = useTabsTestAttributes('quote');

	const isDistributor = billableParty?.type === 'Distributor';
	const isPartner = billableParty?.type === 'Partner';

	const isCustomerTabDisabled = !customer?.id;
	const isPartnerTabDisabled = (!isDistributor && !isPartner) || !billableParty?.id;
	const isDistributionPartnerTabDisabled = !isDistributor || !distributionPartner;

	return (
		<Tabs className="px-4" defaultActiveKey="quoteStatusTab" {...testAttributes}>
			<Tab eventKey="quoteStatusTab" title={t('tab.quoteStatus')}>
				<QuoteStatusTab />
			</Tab>
			<Tab eventKey="itemsInQuoteTab" title={t('tab.itemsInQuote')}>
				<ItemsInQuoteTab />
			</Tab>
			{!isGroupPartner && (
				<Tab eventKey="partnerTab" disabled={isPartnerTabDisabled} title={t('tab.partner')}>
					<PartnerTab />
				</Tab>
			)}
			{!isRoleReseller && (
				<Tab
					disabled={isDistributionPartnerTabDisabled}
					eventKey="distributionPartnerTab"
					title={t('common:entity.distributionPartner')}
				>
					<DistributionPartnerTab />
				</Tab>
			)}
			<Tab disabled={isCustomerTabDisabled} eventKey="customerTab" title={t('tab.customer')}>
				<CustomerTab />
			</Tab>
			{isGroupInternal && (
				<Tab disabled={!discount?.applied} eventKey="discretionaryDiscountTab" title={t('tab.discretionaryDiscount')}>
					<DiscretionaryDiscountTab />
				</Tab>
			)}
			<Tab eventKey="notesTab" title={t('tab.notes')} disabled={!hasOrderAnyNote(data)}>
				<NotesTab />
			</Tab>
		</Tabs>
	);
};
