import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import type { IEntityPartner } from 'module/partners';
import type { TFormMessagesProps } from '@avast/react-ui-components';
import * as yup from 'yup';

interface ValidPartner extends Omit<IEntityPartner, 'id'> {
	id: number;
}

type TUseCustomerPartnerValidator =
	| { isValid: true; partner: ValidPartner }
	| { isValid: false; message: TFormMessagesProps['list'] };

export const useCustomerPartnerValidator = (partner?: IEntityPartner | null): TUseCustomerPartnerValidator => {
	const { t } = useTranslation(customersConfig.trNamespace);

	// Check that partner is not null and contains at least an id
	const partnerSchema = yup
		.object()
		.shape({
			id: yup.number().required('Partner ID is required'),
		})
		.required('Partner object is required');

	const isPartner = (partner?: unknown): partner is ValidPartner => {
		try {
			partnerSchema.validateSync(partner);
			return true;
		} catch (error) {
			return false;
		}
	};

	if (isPartner(partner)) {
		return { isValid: true, partner };
	}

	return {
		isValid: false,
		message: [
			[
				t('form:validator.mixed.required', {
					path: t('common:entity.partner'),
					interpolation: { prefix: '${', suffix: '}' },
				}),
				'danger',
			],
		],
	};
};
