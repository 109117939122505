import type { PropsWithChildren } from 'react';
import { Card, CardBody, H2 } from '@avast/react-ui-components';

type THelpCardProps = {
	title: string;
};

export const HelpCard = (props: PropsWithChildren<THelpCardProps>) => {
	const { title, children } = props;

	return (
		<Card className="h-100 border-0">
			<CardBody className="p-3 px-sm-6 py-sm-5 px-xl-7 py-xl-6">
				<H2 type="h3" bold className="mb-2">
					{title}
				</H2>
				{children}
			</CardBody>
		</Card>
	);
};
