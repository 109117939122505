import { memo } from 'react';
import type { TButtonProps } from '@avast/react-ui-components';
import classNames from 'classnames';

export type TButtonTextBadgeProps = {
	children: string;
	count?: number;
	className?: string;
	variant?: TButtonProps['variant'];
};

/**
 * Button text with number in badge
 * @param {TButtonTextBadgeProps} props
 * @returns {any}
 * @private
 */
const _ButtonTextBadge = (props: TButtonTextBadgeProps) => {
	const { variant = 'secondary', className, count, children } = props;

	if (!count) {
		return <>{children}</>;
	}

	return (
		<span className={classNames('d-flex align-items-center', className)}>
			<span>{children}</span>
			<span className={`ms-2 flex-shrink-0 badge bg-${variant}`}>{count}</span>
		</span>
	);
};

export const ButtonTextBadge = memo(_ButtonTextBadge);
