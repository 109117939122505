import { Button } from '@avast/react-ui-components';
import { useCancelAction } from 'module/orders/hooks/useCancelAction';
import { useCancelButtonVisibility } from 'module/orders/hooks';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';

type TCancelOrderButtonProps = {
	text?: string;
};

export const CancelOrderButton = (props: TCancelOrderButtonProps) => {
	const { text } = props;
	const {
		data: order,
		query: { isFetching },
	} = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { onCancel } = useCancelAction();
	const cancelVisibility = useCancelButtonVisibility()(order);

	if (!cancelVisibility.isAllowed) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				variant="outline-danger"
				disabled={!cancelVisibility.isEnabled}
				loading={isFetching}
				onClick={() => onCancel()}
				testId="cancel"
			>
				{text || t('actions.cancel')}
			</Button>
		</>
	);
};
