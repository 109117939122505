import { useCallback } from 'react';
import { useApiRetailGenerate } from 'module/retail/hooks/useApiRetail';
import type { IGenerateRetailCodesForm } from 'module/retail';
import { retailFormNormalizer } from 'module/retail/normalizer';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const useSubmitRetailGenerateRequest = () => {
	const { mutateAsync } = useApiRetailGenerate();
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { t } = useTranslation(retailConfig.trNamespace);

	return useCallback(
		(form: IGenerateRetailCodesForm, onSuccess: () => void) => {
			const data = retailFormNormalizer.denormalize(form);
			if (!data) {
				return;
			}

			loadingModalRef.current?.show({
				title: t('generate.submitLoading'),
			});
			mutateAsync(data)
				.then(() => {
					toast.success(<Toast>{t('generate.success')}</Toast>);
					onSuccess();
				})
				.catch((error) => {
					setError({ error });
				})
				.finally(() => {
					loadingModalRef.current?.hide();
				});
		},
		[mutateAsync, t, loadingModalRef, setError],
	);
};
