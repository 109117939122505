import { DistributorSpendingOverview, FlexibleBillingListMenu, PageListHeader, ReportListTable } from '../components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { Col, Row } from 'js/components/atoms/Row';
import { useApiFlexibleBillingReportList } from 'module/flexibleBilling/hooks';
import { useAuthContext } from 'js/contexts';

export const PageReports = () => {
	const { authCompanyId } = useAuthContext();

	return (
		<>
			<DefaultContainer>
				<PageListHeader />
			</DefaultContainer>
			<FlexibleBillingListMenu />
			<DefaultContainer>
				<Row justify="end" multi size="sm" className="mb-3">
					<Col xs="auto">
						<DistributorSpendingOverview />
					</Col>
				</Row>
				<ReportListTable filter={{ distributorId: authCompanyId }} query={useApiFlexibleBillingReportList} />
			</DefaultContainer>
		</>
	);
};
