import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Alert, Button } from '@avast/react-ui-components';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { ActionLink } from 'js/components/atoms/Button';
import { useCommonContext, useOrderContext } from 'js/contexts';

type TRequiredDistributionPartnerAlertProps = {
	className?: string;
};

export const RequiredDistributionPartnerAlert = (props: TRequiredDistributionPartnerAlertProps) => {
	const { className } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { distributionPartner } = useRequiredOrderParties();
	const { orderState, setDistributionPartner } = useOrderContext();
	const { selectDistributionPartnerRef } = useCommonContext();
	const partnerId = orderState?.partner?.id;
	const [show, toggle] = useState(false);

	const selectDistributionPartner = async () => {
		const distributionPartner = await selectDistributionPartnerRef.current?.show({
			distributorId: partnerId,
		});

		if (distributionPartner) {
			setDistributionPartner(distributionPartner);
		}
	};

	if (distributionPartner.valid) {
		return null;
	}

	return (
		<Alert variant="warning" caption={t('error.requiredDistributionPartner.title')} className={className}>
			<div className="d-flex">
				<div className="flex-grow-1 align-self-center">
					<p>{t('error.requiredDistributionPartner.text')}</p>
					<p className="fw-bold">
						<ActionLink onClick={() => toggle((state) => !state)} className="fw-bold">
							{t('error.requiredDistributionPartner.tooltip.question')}
						</ActionLink>
					</p>
					{show && <p className="font-italic">{t('error.requiredDistributionPartner.tooltip.answer')}</p>}
				</div>
				<div className="flex-shrink-0 ps-3">
					<Button size="xs" variant="primary" onClick={selectDistributionPartner}>
						{t('error.requiredDistributionPartner.action')}
					</Button>
				</div>
			</div>
		</Alert>
	);
};
