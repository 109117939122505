import type { InvalidateQueryFilters } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export type TUseInvalidateQueriesKeys = string[] | true;

export const useInvalidateQueries = (keys?: TUseInvalidateQueriesKeys) => {
	const queryClient = useQueryClient();
	return useCallback(() => {
		if (typeof keys === 'undefined') {
			return;
		}

		if (keys === true) {
			queryClient.invalidateQueries();
			return;
		}

		for (const key of keys) {
			queryClient.invalidateQueries({ queryKey: [key] });
		}
	}, [queryClient, keys]);
};

export const useAsyncInvalidateQueries = (keys?: TUseInvalidateQueriesKeys) => {
	const queryClient = useQueryClient();
	return useCallback(
		async (filters?: InvalidateQueryFilters) => {
			if (typeof keys === 'undefined') {
				return;
			}

			if (keys === true) {
				await queryClient.invalidateQueries(filters);
				return;
			}

			const promises: Promise<void>[] = [];

			for (const key of keys) {
				promises.push(
					queryClient.invalidateQueries({
						queryKey: [key],
						...filters,
					}),
				);
			}
			await Promise.all(promises);
		},
		[queryClient, keys],
	);
};
