import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { ITestId } from '@avast/react-ui-components';
import { SelectButton } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import type { TSelectEntityProps, TSelectEntityPropsWithChildren } from 'js/components/molecules/Modal';
import { FormLabel } from 'react-bootstrap';

type TSelectModalProps<Data extends {}, E extends {}, ValueType = string> = {
	trNamespace: string;
	disabled?: boolean;
	renderValue: (data: Data | null) => string | undefined;
	onChange(data: Data | null): void;
	component: (
		props: TSelectEntityPropsWithChildren<Data, TSelectEntityProps<Data, ValueType>, ValueType> & E,
	) => ReactElement;
} & Pick<TSelectEntityProps<Data, ValueType>, 'disabledLabel' | 'size'> &
	E &
	ITestId;

export const SelectModal = <Data extends {}, E extends {}, ValueType = string>(
	props: TSelectModalProps<Data, E, ValueType>,
): ReactElement => {
	const {
		disabled = false,
		trNamespace,
		component: Component,
		renderValue,
		onChange,
		disabledLabel,
		size,
		testId,
		...rest
	} = props;
	const { t } = useTranslation(trNamespace);
	const componentProps = rest as unknown as E;

	return (
		<Component {...componentProps} onChange={onChange}>
			{({ entity, resetValue, setModalShow, value, loading }) => (
				<>
					{!disabledLabel && <FormLabel>{t('select.button.label')}</FormLabel>}
					<SelectButton
						testId={testId}
						size={size}
						disabled={disabled}
						onClick={() => setModalShow(true)}
						placeholder={t('select.button.placeholder')}
						resetOnClick={resetValue}
						loading={loading}
						iconRight={<FontAwesomeIcon className="chevron" icon={faChevronDown} />}
					>
						{value && renderValue(entity)}
					</SelectButton>
				</>
			)}
		</Component>
	);
};
