import { Trans, useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';

export type TUsageLimitApiErrorItem = {
	name: string;
	limit: number;
};

type TUsageLimitApiErrorProps = {
	errorList: TUsageLimitApiErrorItem[];
};

export const UsageLimitApiError = (props: TUsageLimitApiErrorProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);

	return (
		<>
			<p>{t('error.usageLimit.message')}</p>
			<ul>
				{props.errorList.map((item) => (
					<li key={item.name}>
						{
							<Trans
								t={t}
								i18nKey="error.usageLimit.product"
								tOptions={{
									product: item.name,
									limit: item.limit === FLEXIBLE_BILLING_UNLIMITED_VALUE ? t('common.unlimited') : item.limit,
								}}
							/>
						}
					</li>
				))}
			</ul>
			<p className="mb-0">
				<Trans t={t} i18nKey="error.usageLimit.note" />
			</p>
		</>
	);
};
