import type { TPartnerId } from 'types';
import { useCallback } from 'react';
import { logError } from 'js/utils/app';
import type { IEntityPartnerDetail } from 'module/partners';
import type { TPartnerPriceLists } from 'js/priceList';
import { useAsyncApiPartnerDetail } from 'module/partners/api/useAsyncApiPartners';
import { useAsyncApiPartnerPriceLists } from 'js/priceList/useAsyncApiPartnerPriceLists';
import { normalizePartnerDetail } from 'module/partners/normalizer';

type TUseAsyncOrderInstancePartnerResponse = {
	partner: IEntityPartnerDetail;
	priceLists: TPartnerPriceLists;
};

export const useAsyncLoadOrderInstancePartner = () => {
	const asyncApiPartnerDetail = useAsyncApiPartnerDetail();
	const asyncApiPartnerPriceLists = useAsyncApiPartnerPriceLists();

	return useCallback(
		async (partnerId?: TPartnerId | null): Promise<TUseAsyncOrderInstancePartnerResponse | null> => {
			if (!partnerId) {
				return null;
			}

			// Get partner detail
			const partner = await asyncApiPartnerDetail(partnerId)
				.then((response) => normalizePartnerDetail(response.data))
				.catch(logError);

			// Get partner price lists
			const priceLists = await asyncApiPartnerPriceLists(partnerId).catch(logError);

			if (!partner || !priceLists) {
				return null;
			}

			return {
				partner,
				priceLists,
			};
		},
		[asyncApiPartnerPriceLists, asyncApiPartnerDetail],
	);
};
