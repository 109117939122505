import { createAxiosInstance } from 'api/setupInterceptors';
import type { IAuthTokenResponse } from 'js/reducers/authContextReducer';

export const getApiOmsSsoPromise = () => {
	const api = createAxiosInstance({
		withCredentials: true,
		authToken: null,
	});

	return api.get<IAuthTokenResponse>('/jwt/token');
};
