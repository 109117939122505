import React, { type PropsWithChildren, type ReactElement, useCallback } from 'react';
import type { IEntityCustomer } from 'module/customers';
import { type TAsyncModalRef, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncSelectCustomerModal,
	type TAsyncSelectCustomerModalProps,
} from 'module/customers/components/AsyncSelectCustomerModal';
import {
	AsyncSelectDistributionPartnerModal,
	type TAsyncSelectDistributionPartnerModalProps,
	useAsyncSelectDistributionPartnerRef,
} from 'module/distributionPartners/components/AsyncSelectDistributionPartnerModal';
import type { IEntityDistributionPartner } from 'module/distributionPartners';
import { AsyncCreateCustomerModal, useAsyncCreateCustomerRef } from 'module/customers/components';
import type { TAsyncCreateCustomerModalProps } from 'module/customers/components/AsyncCreateCustomerModal';
import { CustomEventEnum, useCustomEventListener } from 'js/events';
import invariant from 'invariant';

export interface ICommonContext {
	selectCustomerRef: TAsyncModalRef<TAsyncSelectCustomerModalProps, IEntityCustomer>;
	createCustomerRef: TAsyncModalRef<TAsyncCreateCustomerModalProps, IEntityCustomer>;
	selectDistributionPartnerRef: TAsyncModalRef<TAsyncSelectDistributionPartnerModalProps, IEntityDistributionPartner>;
}

const CommonContext = React.createContext<ICommonContext | null>(null);
CommonContext.displayName = 'CommonContext';

/**
 * Hook to get common context
 */
export const useCommonContext = () => {
	const context = React.useContext(CommonContext);

	invariant(
		context !== null,
		'Common context is undefined, please verify you are calling useCommonContext() as child of a <CommonContextProvider> component.',
	);

	return context;
};

export const CommonContextProvider = (props: PropsWithChildren): ReactElement => {
	const selectCustomerRef = useAsyncModalRef<TAsyncSelectCustomerModalProps, IEntityCustomer>();
	const createCustomerRef = useAsyncCreateCustomerRef();
	const selectDistributionPartnerRef = useAsyncSelectDistributionPartnerRef();

	const closeModalsHandler = useCallback(() => {
		selectCustomerRef.current?.hide();
		createCustomerRef.current?.hide();
		selectDistributionPartnerRef.current?.hide();
	}, [selectCustomerRef, createCustomerRef, selectDistributionPartnerRef]);

	useCustomEventListener(CustomEventEnum.CLOSE_MODALS, closeModalsHandler);

	return (
		<CommonContext.Provider value={{ selectCustomerRef, createCustomerRef, selectDistributionPartnerRef }}>
			{props.children}
			<AsyncSelectCustomerModal forwardedRef={selectCustomerRef} />
			<AsyncCreateCustomerModal forwardedRef={createCustomerRef} />
			<AsyncSelectDistributionPartnerModal forwardedRef={selectDistributionPartnerRef} />
		</CommonContext.Provider>
	);
};
