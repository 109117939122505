import { useApiPriceCalculator } from './useApiPriceCalculator';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { calculatorFormNormalizer } from 'module/priceCalculator/normalizer';
import { useCompare } from 'js/hooks/useCompare';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { IPricingPromotionDetail, IPricingRequest, IStandardOrderItemPricing } from 'module/purchase';
import { useFormikContext } from 'formik';
import { trim } from 'lodash';

export const useCalculatorPricing = () => {
	const api = useApiPriceCalculator();
	const { values, setFieldValue } = useFormikContext<IPriceCalculatorForm>();
	const request = calculatorFormNormalizer.denormalize(values);
	const requestDataChanged = useCompare(request, true);
	const reprice = useDebouncedCallback((request: IPricingRequest) => {
		api.mutate(request, {
			onSuccess({ data }) {
				if (request.request_header.goalSeekedPrice) {
					const item = data.lineitems.find(
						(item) => item.reference_lineitem_id === request.request_header.goalSeekedPrice?.lineItemReferenceId,
					);
					setFieldValue('discount.discretionary', (item?.discount?.discretionary?.percent || 0) / 100);
					setFieldValue('goalSeekedPrice', undefined);
				}
			},
		});
	}, 400);

	useEffect(() => {
		if (requestDataChanged && request) {
			reprice(request);
		}
	}, [reprice, requestDataChanged, request]);

	return {
		loading: api.isPending,
		data: api.data,
		findByReferenceId(id: string) {
			return api.data?.data.lineitems?.find((item) => item.reference_lineitem_id === id);
		},
		findItemPromotions(item?: IStandardOrderItemPricing): IPricingPromotionDetail[] {
			const list: IPricingPromotionDetail[] = [];
			const promotionsIds = trim(item?.pricingPromoApplied_Line, ',')
				.split(',')
				.map((n) => Number(n))
				.filter((n) => Boolean(n));

			if (api.data?.data.headers?.appliedPromotions) {
				const promotions = Object.values(api.data.data.headers.appliedPromotions);
				for (const promotionsId of promotionsIds) {
					const promotion = promotions.find((item) => item.id === promotionsId);
					if (promotion) {
						list.push(promotion);
					}
				}
			}

			return list;
		},
	};
};
