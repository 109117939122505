import { H3, Modal } from '@avast/react-ui-components';
import { Send } from 'assets/image/icon';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { PAYMENT_TYPES_CONFIRMATION_TEXT } from 'module/purchase/constants';
import { getPaymentTypeTranslationKey } from 'module/purchase/utils/common';
import type { TPaymentTypeAction } from 'module/purchase';
import { PaymentTypeTranslationKeyEnum } from 'module/purchase/enums';
import { isDefined } from 'js/utils/common';

type TOrderConfirmationModalProps = {
	onCancel: () => void;
	onSubmit: () => void;
	show: boolean;
	action: TPaymentTypeAction;
};

export const ConfirmationModal = (props: TOrderConfirmationModalProps) => {
	const { onCancel, onSubmit, show, action } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	// No order action => cancel modal
	if (!action) {
		onCancel();
		return null;
	}

	// Declare modal properties
	const hasConfirmationText = PAYMENT_TYPES_CONFIRMATION_TEXT.includes(action);
	const actionText = t(getPaymentTypeTranslationKey(action, PaymentTypeTranslationKeyEnum.ACTION));
	const confirmationCaption = t(
		getPaymentTypeTranslationKey(action, PaymentTypeTranslationKeyEnum.CONFIRMATION_CAPTION),
	);
	const confirmationText = hasConfirmationText
		? t(getPaymentTypeTranslationKey(action, PaymentTypeTranslationKeyEnum.CONFIRMATION_MESSAGE))
		: null;

	return (
		<Modal show={show} size="sm" backdrop="static" onHide={onCancel} testId="confirmationModal">
			<Modal.Header className="pb-0">
				{t(getPaymentTypeTranslationKey(action, PaymentTypeTranslationKeyEnum.CAPTION))}
			</Modal.Header>
			<Modal.Body className="py-md-9">
				<div className="text-center">
					<p>
						<Send className="text-primary" />
					</p>
					<H3 bold className="mt-3">
						{confirmationCaption}
					</H3>

					{isDefined(confirmationText) && (
						// biome-ignore lint/security/noDangerouslySetInnerHtml: Our own message is passed.
						<p className="mt-2" dangerouslySetInnerHTML={{ __html: confirmationText }} />
					)}
				</div>
			</Modal.Body>
			<Modal.Footer
				onSubmit={onSubmit}
				submitButton={{
					children: actionText,
				}}
			/>
		</Modal>
	);
};
