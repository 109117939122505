import type {
	IEntityCreditStatus,
	IEntityCreditTransaction,
	IEntityCreditTransactionFilter,
} from 'module/creditStatus';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useApiCreditTransactionList } from 'module/creditStatus/hooks/useApiCreditStatus';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { useCreditTransactionColumns } from 'module/creditStatus/hooks';

type TTableData = IEntityCreditTransaction;
type TTableFilter = IEntityCreditTransactionFilter;

export const CreditTransactionTable = () => {
	const { data } = usePageDetailContext<IEntityCreditStatus>();
	const columns = useCreditTransactionColumns();

	return (
		<ApiPaginatedListTable<TTableData, TTableFilter>
			columns={columns}
			query={useApiCreditTransactionList}
			filter={{ partnerId: data.partnerId }}
			table={{
				testId: 'creditTransactions',
				enableSorting: false,
			}}
		/>
	);
};
