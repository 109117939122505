import type { TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { useAuthContext } from 'js/contexts';
import type { AxiosResponse } from 'axios';
import type { IAuthTokenResponse } from 'js/reducers/authContextReducer';

export const useSsoLogin = (
	apiSsoPromise: () => Promise<AxiosResponse<IAuthTokenResponse>>,
): (() => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const authUserData = useAuthUserData();

	return useCallback(async () => {
		const errorResponse: TLoginResolve = {
			success: false,
			message: 'state.invalidSso',
		};

		try {
			const response = await apiSsoPromise();

			if (!response) {
				return errorResponse;
			}

			const data = await authUserData(response.data);

			if (!data) {
				return errorResponse;
			}

			setAuthData(data);
			return {
				success: true,
				redirectTo: getInvalidStateLocation(data),
			};
		} catch {
			return errorResponse;
		}
	}, [setAuthData, authUserData, apiSsoPromise]);
};
