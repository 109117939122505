import type { IEntityPromotion } from 'module/promotions';
import { promotionPartyTierEnumUtils, promotionPartyTypeEnumUtils } from 'module/promotions/enums';

const promotionEntityNormalizerUtils = {
	normalizePartnerTierList(tiers: IEntityPromotion['partnerTierList']): IEntityPromotion['partnerTierList'] {
		if (!tiers) {
			return tiers;
		}

		return tiers.filter(promotionPartyTierEnumUtils.validateValue);
	},

	normalizePartnerTypeList(types: IEntityPromotion['partnerTypeList']): IEntityPromotion['partnerTypeList'] {
		if (!types) {
			return types;
		}

		return types.filter(promotionPartyTypeEnumUtils.validateValue);
	},
};

export const promotionEntityNormalizer = {
	normalizeList(items: IEntityPromotion[]): IEntityPromotion[] {
		return items.map((item) => promotionEntityNormalizer.normalize(item));
	},

	normalize(promotion: IEntityPromotion): IEntityPromotion {
		return {
			...promotion,
			partnerTierList: promotionEntityNormalizerUtils.normalizePartnerTierList(promotion.partnerTierList),
			partnerTypeList: promotionEntityNormalizerUtils.normalizePartnerTypeList(promotion.partnerTypeList),
		};
	},
};
