import type { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IEntityOrder } from 'module/orders';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { noValuePlaceholder } from 'js/utils/app';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { orderStatusEnumUtils } from 'module/orders/enums';
import { ordersConfig } from 'module/orders/ordersConfig';
import type { TWidgetColumns } from 'submodule/widgets';
import { paymentStatusEnumUtils } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityOrder;

export const useRecentOrdersWidgetColumns = (): TWidgetColumns<TTableData> => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { isGroupInternal } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();
		const columns: TableColumnsDef<TTableData> = [
			columnHelper.link(
				'id',
				{
					header: t('entity.orderNumberShort'),
				},
				{
					to: (quote) => ordersConfig.detailLink(quote),
					copy: true,
				},
			),
		];

		if (isGroupInternal) {
			columns.push(
				columnHelper.ellipsis(
					(row) => getPartnerRenderName(row.partner),
					{
						id: 'partner',
						header: t('common:entity.partner'),
						meta: {
							defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
						},
					},
					{ width: 200 },
				),
			);
		}

		columns.push(
			columnHelper.ellipsis(
				(row) => getCustomerRenderName(row.customer),
				{
					id: 'customer',
					header: t('common:entity.customer'),
					meta: {
						defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					},
				},
				{ width: 200 },
			),
		);

		columns.push(columnHelper.date('createdAt', { header: t('entity.created') }));

		columns.push(
			columnHelper.text('status', {
				header: t('common:entity.orderStatus'),
				meta: { formatters: [orderStatusEnumUtils.getText, formatter.bold] },
			}),
			columnHelper.text('payment.status', {
				header: t('common:entity.paymentStatus'),
				id: 'payment_status',
				meta: { formatters: [paymentStatusEnumUtils.getText] },
			}),
		);

		columns.push(
			columnHelper.money(
				'totalAmountWithoutTax',
				{
					header: t('entity.totalAmount'),
					meta: { className: 'text-nowrap' },
				},
				{ currencyKey: 'currency' },
			),
		);

		return { columns, columnVisibility: { customer: false, status: false, payment_status: false } };
	}, [isGroupInternal, t]);
};
