import { LinkButton } from 'js/components/atoms/Button';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import type { IEntityClosingBalance } from 'module/closingBalance';
import { useOrderClosingBalance } from 'module/orders/hooks/useOrderClosingBalance';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';

export const ViewClosingBalanceButton = () => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const { isFetching, closingBalance, enabled } = useOrderClosingBalance(order);

	const emptyResult = !isFetching && !closingBalance;

	if (!enabled || emptyResult) {
		return null;
	}

	return (
		<LinkButton
			to={closingBalanceConfig.detailLink(closingBalance || ({} as IEntityClosingBalance))}
			size="sm"
			variant="outline-primary"
			loading={isFetching}
			testId="viewClosingBalance"
		>
			{t('actions.viewClosingBalance')}
		</LinkButton>
	);
};
