import type { ReactElement } from 'react';
import { PartnerCard } from 'module/partners/components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Alert } from '@avast/react-ui-components';
import { useOrderContext } from 'js/contexts';

/**
 * Order partner tab
 * @return {ReactElement}
 * @constructor
 */
export const PartnerTab = (): ReactElement => {
	const { orderState } = useOrderContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	if (orderState.partner) {
		return <PartnerCard partner={orderState.partner} />;
	}

	return <Alert variant="warning" caption={t('widget.selectPartnerCard.error.load')} />;
};
