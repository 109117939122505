import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import type { ILicenseOperationPartyResolution } from './selectPartyReducer';
import { SelectPartyModal } from './SelectPartyModal';

export const AsyncSelectPartyModal = (props: TAsyncModalContainerProps<object, ILicenseOperationPartyResolution>) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<object, ILicenseOperationPartyResolution> ref={forwardedRef} testId="selectParty">
			<SelectPartyModal forwardedRef={forwardedRef} />
		</AsyncModal>
	);
};
