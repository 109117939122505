import { CONFIG } from 'config';
import type { IUiLocaleContext } from '@avast/react-ui-components';
import { numberFormatter } from 'js/utils/number';
import type { TConfigLocaleDateTime, TConfigLocaleDateTimeValues, TLocale } from 'types/config';
import { CustomModalManager } from 'js/components/molecules/Modal/CustomModalManager';
import { localesConfig, localesConfigKeys } from 'i18n/locales';
import { userLocaleInstance } from 'i18n/userLocaleInstance';
import { reduce } from 'lodash';

type IFormatsItem = {
	DATETIME: TConfigLocaleDateTime;
};

type IFormats = Record<TLocale, IFormatsItem>;

/**
 * Define formats templates for each locale
 *
 * @type {IFormats}
 */
const locales: IFormats = reduce(
	localesConfigKeys,
	(data: Partial<IFormats>, locale: TLocale) => {
		data[locale] = {
			DATETIME: {
				...CONFIG.LOCALE.DATETIME,
				...(localesConfig[locale]?.format.datetimeConfig ?? {}),
			},
		};
		return data;
	},
	{},
) as IFormats;

/**
 * Get date time format mask
 * @param {TConfigLocaleDateTimeValues} key
 * @param {TLocale|undefined} locale
 * @returns {string}
 */
export const getDateTimeLocale = (key: TConfigLocaleDateTimeValues, locale?: TLocale): string =>
	locales[locale || userLocaleInstance.format].DATETIME[key];

export const getUiLocaleContext = (locale?: TLocale): IUiLocaleContext => {
	const localeCode = locale || userLocaleInstance.format;
	const { DATETIME } = locales[localeCode];

	return {
		localeCode,
		testAttributes: {
			enabled: true,
		},
		modalManager: new CustomModalManager(),
		table: CONFIG.TABLE,
		format: {
			datepicker: {
				default: DATETIME.DATE,
				month: DATETIME.MONTH,
				year: DATETIME.YEAR,
				input: DATETIME.INPUT,
				weekDay: DATETIME.WEEK_DAY,
				dayLabel: DATETIME.DAY_LABEL,
			},
			number: numberFormatter.format,
		},
	};
};
