import { LicenseOperationEnum } from 'module/licenses/enums';
import type {
	IEntityLicense,
	IEntityLicenseOperation,
	IIsDifferentLicenseAndOrderParty,
	TLicenseOperationBillableParty,
} from 'module/licenses';
import type {
	IEntityProduct,
	IEntityProductGroup,
	IOrderContextBillableParty,
	IStandardOrderInstance,
} from 'module/purchase';
import type { IEntityPartnerDetail } from 'module/partners';
import type { IEntityCustomer } from 'module/customers';
import type { ILicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import type { TPartnerPriceLists, TPriceListCode } from 'js/priceList';
import { isPriceListCodeInPriceLists } from 'js/priceList/priceListUtils';
import { licenseOperationKeyOrder } from 'module/licenses/contants';
import { BillablePartyTypeEnum } from 'module/orders/enums';
import { PartnerTypeEnum } from 'module/partners/enums';
import { isDefined } from 'js/utils/common';

export const normalizeLicenseOperations = (operations: IEntityLicenseOperation[]): IEntityLicenseOperation[] =>
	operations
		.sort((a, b) => (licenseOperationKeyOrder[a.type] < licenseOperationKeyOrder[b.type] ? -1 : 1))
		.filter((operation: IEntityLicenseOperation) => operation.productGroups.length !== 0);

export const hasLicenseOperations = (license: IEntityLicense): boolean =>
	license.computedValues.allowedEntitlementLicenseOperations.length !== 0;

export const getLicenseOperationEntities = (
	operations: IEntityLicenseOperation[],
	selectedLicenseOperation?: LicenseOperationEnum,
	productGroupCode?: IEntityProductGroup['code'] | null,
	productVariant?: IEntityProduct['sku'] | null,
) => {
	const operation = operations.find((item) => item.type === selectedLicenseOperation) || null;
	const productGroup = operation?.productGroups.find((group) => group.code === productGroupCode) || null;
	const product = productGroup?.products.find((product) => product.sku === productVariant) || null;

	return { operation, productGroup, product };
};

const isOperation = (operations: LicenseOperationEnum[], operation?: LicenseOperationEnum | null): boolean => {
	if (!operation) {
		return false;
	}
	return operations.includes(operation);
};

export const isIncreaseOperation = (operation?: LicenseOperationEnum | null): boolean =>
	isOperation(
		[LicenseOperationEnum.INCREASE, LicenseOperationEnum.RENEWAL_INCREASE, LicenseOperationEnum.INCREASE_UPGRADE],
		operation,
	);

export const isDecreaseOperation = (operation?: LicenseOperationEnum | null): boolean =>
	isOperation([LicenseOperationEnum.RENEWAL_DECREASE], operation);

export const isUpgradeOperation = (operation?: LicenseOperationEnum | null): boolean =>
	isOperation(
		[LicenseOperationEnum.UPGRADE, LicenseOperationEnum.RENEWAL_UPGRADE, LicenseOperationEnum.INCREASE_UPGRADE],
		operation,
	);

export const isRenewOperation = (operation?: LicenseOperationEnum | null): boolean =>
	isOperation(
		[
			LicenseOperationEnum.RENEWAL,
			LicenseOperationEnum.RENEWAL_UPGRADE,
			LicenseOperationEnum.RENEWAL_INCREASE,
			LicenseOperationEnum.RENEWAL_DECREASE,
			LicenseOperationEnum.RENEWAL_DOWNGRADE,
		],
		operation,
	);

export const normalizeLicenseOperationKey = (
	operationKey: LicenseOperationEnum,
	conditions: {
		isBusiness: boolean;
	},
): LicenseOperationEnum => {
	if (conditions.isBusiness) {
		return operationKey;
	}

	switch (operationKey) {
		case LicenseOperationEnum.INCREASE:
			return LicenseOperationEnum.UPGRADE;
		case LicenseOperationEnum.RENEWAL_INCREASE:
			return LicenseOperationEnum.RENEWAL_UPGRADE;
		default:
			return operationKey;
	}
};

export const isLicenseOperationAlreadyInOrder = (
	license: IEntityLicense,
	orderState: IStandardOrderInstance,
): boolean => orderState.items.some((item) => item.license?.id === license.id);

export const getLicenseOperationBillableParty = (
	partner: IEntityPartnerDetail | null,
	customer: IEntityCustomer | null,
	orderContextBillableParty: IOrderContextBillableParty | null,
): TLicenseOperationBillableParty => {
	if (isDefined(partner) && partner.currencyCode && partner.countryCode) {
		return {
			currencyCode: partner.currencyCode,
			countryCode: partner.countryCode,
			partnerId: partner.id,
			billablePartyType:
				partner.accountType === PartnerTypeEnum.DISTRIBUTOR
					? BillablePartyTypeEnum.DISTRIBUTOR
					: BillablePartyTypeEnum.PARTNER,
		};
	}

	if (isDefined(customer) && customer.billing.countryCode) {
		return {
			countryCode: customer.billing.countryCode,
			partnerId: null,
			billablePartyType: BillablePartyTypeEnum.END_CUSTOMER,
		};
	}

	if (isDefined(orderContextBillableParty)) {
		return orderContextBillableParty;
	}

	return null;
};

export const isDifferentLicenseAndOrderParty = (
	licenseOperationContext: ILicenseOperationContext,
	orderState: IStandardOrderInstance,
): IIsDifferentLicenseAndOrderParty => {
	const partner = licenseOperationContext.partner?.id !== orderState.partner?.id;
	const distributionPartner = licenseOperationContext.distributionPartner?.id !== orderState.distributionPartner?.id;
	const customer = licenseOperationContext.customer?.id !== orderState.customer?.id;

	return {
		partner,
		distributionPartner,
		customer,
		isDifferent: partner || distributionPartner || customer,
	};
};

export const resolveLicenseOperationPriceListCode = (
	license: IEntityLicense,
	priceLists: TPartnerPriceLists,
	licensePriceListCode: TPriceListCode,
): TPriceListCode | null => {
	if (isPriceListCodeInPriceLists(priceLists, licensePriceListCode)) {
		return licensePriceListCode;
	}
	// License price list is not in allowed -> reset price list code
	return null;
};
