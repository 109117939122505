import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash';
import { useApiMutation } from 'js/hooks/api';
import type { TQueryConfig } from 'js/queries';
import type { IAxiosApiError } from 'types/api';
import { type TUseInvalidateQueriesKeys, useInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import type { ApiMutationTypeEnum } from 'js/enums';

/**
 * Response from paginated api call
 */
type TUseApiMutationResponse<Data extends {}, Response = Data, TErr = unknown> = UseMutationResult<
	AxiosResponse<Response>,
	TErr,
	Data
>;

/**
 * Props of module api query function
 */
export type TUseApiMutationProps<
	Data extends {},
	Response = Data,
	Params = Record<string, unknown>,
	TErr = IAxiosApiError,
> = {
	config?: TQueryConfig<Params>;
	queryConfig?: UseMutationOptions<AxiosResponse<Response>, TErr, Data>;
	invalidateQueries?: TUseInvalidateQueriesKeys;
};

/**
 * Module api query function type
 */
export type TUseApiMutationModule<
	Data extends {},
	Response = Data,
	Params = Record<string, unknown>,
	TErr = IAxiosApiError,
> = (props?: TUseApiMutationProps<Data, Response, Params, TErr>) => TUseApiMutationResponse<Data, Response, TErr>;

/**
 * Mutation query for update/create entity
 * @param {string} key
 * @param {ApiMutationTypeEnum} type
 * @param {TUseApiMutationProps} props
 * @param {TUseApiMutationProps} defaults
 * @returns {TUseApiMutationResponse}
 */
export const useApiMutationQuery = <
	Data extends {},
	Response = Data,
	Params extends {} = Record<string, unknown>,
	TErr = IAxiosApiError,
>(
	key: string,
	type: ApiMutationTypeEnum,
	props?: TUseApiMutationProps<Data, Response, Params, TErr>,
	defaults?: TUseApiMutationProps<Data, Response, Params, TErr>,
): TUseApiMutationResponse<Data, Response, TErr> => {
	const { config, queryConfig, invalidateQueries } = merge({}, defaults, props);
	const invalidateQueryCache = useInvalidateQueries(invalidateQueries);

	const mutationFn = useApiMutation<Data, Response, Params>({
		key,
		type,
		config: {
			...config,
		},
	});

	return useMutation<AxiosResponse<Response>, TErr, Data>({
		mutationFn,
		...queryConfig,
		onSettled(data, error, variables, context) {
			invalidateQueryCache();
			queryConfig?.onSettled?.(data, error, variables, context);
		},
	});
};
