import type { IGenerateRetailCodesProduct } from 'module/retail';
import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { getProductValidityLabels, isQuantityChangeDisabled } from 'module/purchase/utils/common';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { FormikControl } from 'js/components/formik/FormikControl';
import { CONFIG } from 'config';
import { IconButton, Tooltip } from '@avast/react-ui-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import type { Row } from '@tanstack/react-table';
import { skuUtils } from 'js/utils/sku';
import { getMaxProductQuantity } from 'js/utils/common';
import { MarketSegmentEnum } from 'js/enums';

type TTableData = IGenerateRetailCodesProduct;

export const useGenerateRetailCodesColumns = (
	onRemove: (row: Row<TTableData>) => void,
): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(retailConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		return [
			columnHelper.text('product.sku', {
				header: t('common:entity.sku'),
				meta: {
					formatters: [
						(value, { row }) =>
							skuUtils.putUnitValueToSku(value, {
								isBusiness: row.original.product.isBusiness,
								unit: row.original.unit,
							}),
					],
				},
			}),
			columnHelper.text('product.group.name', {
				header: t('common:entity.product'),
				meta: {
					formatters: [
						(value, { row }) => (
							<>
								<span className="d-block">
									<strong>{value}</strong>
								</span>
								<span className="fs-sm">{getProductValidityLabels(row.original.product).label}</span>
							</>
						),
					],
				},
			}),
			columnHelper.text('quantity', {
				header: t('common:entity.quantity'),
				meta: {
					formatters: [
						(value, { row }) => {
							const isConsumerProduct = row.original.product.isConsumer;
							const groupCode = row.original.product.group.code;
							return (
								<FixedWidthWrapper width={160}>
									<FormikControl.Number
										name={`products.${row.index}.quantity`}
										size="sm"
										min={1}
										max={
											isConsumerProduct
												? getMaxProductQuantity({
														marketSegment: MarketSegmentEnum.CONSUMER,
														isFirstPurchase: true,
														groupCode,
														isRetailPartner: true,
													})
												: undefined
										}
									/>
								</FixedWidthWrapper>
							);
						},
					],
				},
			}),
			columnHelper.text('unit', {
				header: t('common:entity.unit', { count: 0 }),
				meta: {
					formatters: [
						(value, { row }) => (
							<FixedWidthWrapper width={160}>
								<FormikControl.Number
									name={`products.${row.index}.unit`}
									size="sm"
									min={1}
									max={row.original.product.isBusiness ? CONFIG.MAX_QUANTITY.BUSINESS : undefined}
									disabled={
										row.original.product.isConsumer || isQuantityChangeDisabled(row.original.product.group.code)
									}
								/>
							</FixedWidthWrapper>
						),
					],
				},
			}),
			columnHelper.display({
				id: 'remove',
				meta: {
					align: 'end',
					formatters: [
						(_, { row }) => (
							<Tooltip content={t('generate.cart.removeTooltip')} placement="bottom">
								<IconButton
									onClick={() => {
										onRemove(row);
									}}
									variant="light"
									iconFa={faTimes}
									size="sm"
									className="text-danger"
									testId={`remove_${row.index}`}
								/>
							</Tooltip>
						),
					],
				},
			}),
		];
	}, [t, onRemove]);
};
