import { createContext, type PropsWithChildren, useContext } from 'react';
import invariant from 'invariant';

export interface IDataTableContext {
	isLoading?: boolean;
}

const DataTableContext = createContext<IDataTableContext | null>(null);
DataTableContext.displayName = 'DataTableContext';

/**
 * Hook to get api error context
 */
export const useDataTableContext = () => {
	const context = useContext(DataTableContext);

	invariant(
		context !== null,
		'Data table context is undefined, please verify you are calling useDataTableContext() as child of a <DataTableContextProvider> component.',
	);

	return context;
};

export const DataTableContextProvider = (props: PropsWithChildren<IDataTableContext>) => {
	const value: IDataTableContext = {
		isLoading: props.isLoading,
	};

	return <DataTableContext.Provider value={value}>{props.children}</DataTableContext.Provider>;
};
