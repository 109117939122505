import { type FunctionComponent, memo } from 'react';
import { RegistrationStepper } from 'module/registration/components/RegistrationStepper';
import { Col, Row } from 'js/components/atoms/Row';
import { AuthPartnerDropdownCol, HeaderLogo } from 'js/layouts/components';
import { Outlet } from 'react-router';
import { RegistrationContextProvider } from 'module/registration/context/RegistrationContext';

const _RegistrationLayout: FunctionComponent = () => (
	<RegistrationContextProvider>
		<div className="header-app justify-content-end">
			<HeaderLogo collapsedLogo={false} />
			<AuthPartnerDropdownCol />
		</div>
		<RegistrationStepper />
		<div className="container pt-10 pb-3">
			<Row justify="center">
				<Col xl={6} lg={8} md={10}>
					<Outlet />
				</Col>
			</Row>
		</div>
	</RegistrationContextProvider>
);

export const RegistrationLayout = memo(_RegistrationLayout);
