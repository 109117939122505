import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl } from 'js/components/formik/FormikControl';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import type { TCurrency } from 'types';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';

type TGoalSeekedPriceFormProps = {
	initialPrice: number;
	onSubmit(values: IGoalSeekedPriceForm): void;
	onCancel(): void;
	currencyCode: TCurrency;
};

export interface IGoalSeekedPriceForm {
	price: number;
}

export const GoalSeekedPriceForm = (props: TGoalSeekedPriceFormProps) => {
	const { initialPrice, onCancel, currencyCode, onSubmit } = props;
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);

	return (
		<Formik<IGoalSeekedPriceForm>
			initialValues={{ price: initialPrice }}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				onSubmit(values);
			}}
		>
			<FormikForm testId="goalSeekedPrice">
				<FormikControl label={t('goalSeekedPrice.finalPrice')}>
					<FormikControl.Number
						name="price"
						min={0}
						required
						numberFormat={{
							output: 'currency',
							currencySymbol: currencyCode,
							currencyPosition: 'postfix',
							spaceSeparatedCurrency: true,
						}}
					/>
				</FormikControl>

				<FormikFooterControls cancel={{ onClick: onCancel }} />
			</FormikForm>
		</Formik>
	);
};
