import { Button, ButtonComposition } from '@avast/react-ui-components';
import { PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';
import { useFormikContext } from 'formik';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { getMarketSegments } from 'module/partners/utils/partnerSelectors';
import { marketSegmentEnumUtils } from 'js/enums';

export const MarketSegmentSwitch = () => {
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);
	const { setFieldValue, values } = useFormikContext<IPriceCalculatorForm>();
	const marketSegments = getMarketSegments({
		partner: values.partner,
		purchaseBillablePartyType: values.billablePartyType,
	});

	if (marketSegments.length <= 1) {
		return null;
	}

	return (
		<ButtonComposition size="sm" className="mb-3">
			{marketSegments.map((key) => (
				<Button
					key={key}
					variant="outline-primary"
					onClick={() => {
						if (values.marketSegment === key) {
							setFieldValue('marketSegment', undefined);
						} else {
							setFieldValue('marketSegment', key);
							if (values.productGroup?.marketSegment !== key) {
								setFieldValue('productGroupCode', undefined);
								setFieldValue('productGroup', null);
							}
						}
					}}
					size="sm"
					active={values.marketSegment === key}
					testId={key}
					disabled={values.billablePartyType === PurchaseBillablePartyTypeEnum.CUSTOMER}
				>
					{`${t('modulePurchase:table.filter.marketSegment', {
						type: marketSegmentEnumUtils.getText(key),
					})}`}
				</Button>
			))}
		</ButtonComposition>
	);
};
