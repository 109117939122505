import type { ReactElement } from 'react';
import { ReactComponent as Logo } from 'assets/image/logo.svg';
import { ReactComponent as LogoOnly } from 'assets/image/logo-only.svg';
import { useTranslation } from 'react-i18next';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router';

type THeaderLogoProps = {
	collapsedLogo?: boolean;
};

export const HeaderLogo = (props: THeaderLogoProps): ReactElement => {
	const { t } = useTranslation();
	const { collapsedLogo } = props;
	const LogoComponent = collapsedLogo ? LogoOnly : Logo;

	return (
		<div className="logo-container">
			<Link to={RouteName.HOMEPAGE.DEFAULT} className="d-block text-white">
				<LogoComponent title={t('app:appName')} className="logo" />
			</Link>
		</div>
	);
};
