import type { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { useExport } from 'js/components/exports/useExport';
import type { IAsyncExportProps } from 'js/components/exports';
import { useCallback, useEffect } from 'react';
import { H5, Modal } from '@avast/react-ui-components';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { Beforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { gtm } from 'js/analytics/gtm';
import { STATUS_SUCCESS } from 'appConstants';

export const ExportModalContent = <Request extends {}>(
	props: IAsyncExportProps<Request> & TAsyncModalContainerProps<IAsyncExportProps<Request>>,
) => {
	const { forwardedRef, caption } = props;
	const { t } = useTranslation('components');

	useEffect(() => {
		gtm.export();
	}, []);

	useExport({
		...props,
		onSettled: useCallback(() => {
			forwardedRef.current?.onSuccess(STATUS_SUCCESS);
		}, [forwardedRef]),
	});

	return (
		<>
			<Modal.Body className="text-center py-8">
				<LoadingPlaceholder minHeight={false} />
				<H5 bold className="mt-1">
					{caption || t('export.caption')}
				</H5>
				<p>{t('export.autoDownload')}</p>
			</Modal.Body>
			<Modal.Footer
				cancelButton={{
					size: 'xs',
					onClick() {
						forwardedRef.current?.onCancel();
					},
				}}
			/>
			<Beforeunload onBeforeunload={() => t('error:beforeUnload')} />
		</>
	);
};
