import type { TSelectOptions } from '@avast/react-ui-components';
import type { TSelectOptionCriteria } from 'js/enums/generator';
import { intersection } from 'lodash';
import { PartnerTypeEnum } from 'module/partners/enums';
import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PartnerSubTypeEnum {
	NONE = 'NONE',
	RETAIL = 'RETAIL',
	ISP = 'ISP',
	MSP = 'MSP',
}

const DISTRIBUTOR_SUB_TYPE_VALUES: PartnerSubTypeEnum[] = [PartnerSubTypeEnum.ISP, PartnerSubTypeEnum.MSP];

interface IPartnerSubTypeEnumExtend {
	getResellerSelectOptions(criteria?: TSelectOptionCriteria<PartnerSubTypeEnum>): TSelectOptions<PartnerSubTypeEnum>;

	getDistributorSelectOptions(criteria?: TSelectOptionCriteria<PartnerSubTypeEnum>): TSelectOptions<PartnerSubTypeEnum>;

	validateValuesByPartnerType(
		partnerType: PartnerTypeEnum,
		partnerSubTypes?: PartnerSubTypeEnum[],
	): PartnerSubTypeEnum[] | undefined;
}

export const partnerSubTypeEnumUtils = generateEnumUtils<PartnerSubTypeEnum, IPartnerSubTypeEnumExtend>(
	PartnerSubTypeEnum,
	{
		translatePath: 'enums:partner.subType',
		getResellerSelectOptions(criteria) {
			return this.getSelectOptions!(criteria);
		},
		getDistributorSelectOptions(criteria) {
			return this.getSelectOptions!({
				...criteria,
				picked: criteria?.picked?.length
					? intersection(DISTRIBUTOR_SUB_TYPE_VALUES, criteria.picked)
					: DISTRIBUTOR_SUB_TYPE_VALUES,
			});
		},
		validateValuesByPartnerType(partnerType, partnerSubTypes) {
			if (!partnerSubTypes || partnerSubTypes.length === 0) {
				return undefined;
			}

			if (partnerType === PartnerTypeEnum.DISTRIBUTOR) {
				return partnerSubTypes.filter((subType) => DISTRIBUTOR_SUB_TYPE_VALUES.includes(subType));
			}

			return partnerSubTypes;
		},
	},
);
