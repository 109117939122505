import type { IFormikContextValidator } from 'types/validator';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupContextValidator } from 'js/utils/validator';
import type { ICreditCardForm } from 'module/account/forms/CreditCardForm';
import { accountConfig } from 'module/account/accountConfig';
import { hasCountryStates } from 'js/entities/country/countryUtils';

export const useCreditCardFormValidator = (): IFormikContextValidator<ICreditCardForm> => {
	type Values = ICreditCardForm;
	const { t } = useTranslation(accountConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				type: Yup.string<ICreditCardForm['type']>().required(),

				owner: Yup.object()
					.required()
					.shape({
						firstName: Yup.string().label(t('common:contact.firstName')).required(),
						lastName: Yup.string().label(t('common:contact.lastName')).required(),
						email: Yup.string().label(t('common:contact.email')).email().required(),
						address: Yup.object()
							.required()
							.shape({
								line1: Yup.string().label(t('common:address.caption')).required(),
								city: Yup.string().label(t('common:address.city')).required(),
								postalCode: Yup.string().label(t('common:address.postalCode')).required(),
								country: Yup.string().ensure().label(t('common:address.country')).required(),
								state: Yup.string().ensure().label(t('common:address.state')).when('country', {
									is: hasCountryStates,
									then: Yup.string().required(),
								}),
							}),
					}),

				isPreferredPayAsYouGoPaymentMethod: Yup.boolean().required(),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) => yupContextValidator<Values>(validationSchema, values),
	};
};
