import { useLanguages } from 'submodule/localeSwitch/hooks/useLanguages';
import type { IEntityLanguage } from 'submodule/localeSwitch';
import { useAuthContext } from 'js/contexts';

export const useCurrentLanguage = (): IEntityLanguage => {
	const {
		authState: { locale },
	} = useAuthContext();
	const languages = useLanguages();
	return languages.find((language) => language.language === locale.language) || languages[0];
};
