import { useCallback, useEffect, useState } from 'react';
import { useApiList } from 'js/hooks/api';
import type { IEntityLicense, TEntityLicenseId } from 'module/licenses';
import type { IEntityRefundableItem } from 'submodule/refunds';

export const useAsyncApiLicenseRefundableItem = () => {
	const api = useApiList<IEntityRefundableItem, { entitlementIds: TEntityLicenseId[] }>({});
	const [abortController] = useState(new AbortController());

	useEffect(() => {
		return () => {
			abortController.abort();
		};
	}, [abortController.abort]);

	return useCallback(
		async (license: IEntityLicense): Promise<IEntityRefundableItem> => {
			const response = await api({
				queryKey: ['refund/refundableAmountForEntitlements', { entitlementIds: [license.id] }],
				meta: undefined,
				signal: abortController.signal,
			});

			const refundableAmount = response.data.find((item) => item.entitlementId === license.id);
			if (refundableAmount) {
				return refundableAmount;
			}

			throw new Error(`No refundable amount found for entitlement #${license.id}`);
		},
		[api, abortController.signal],
	);
};
