import { useOrderContext } from 'js/contexts';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useSelectPartyTranslation } from '../hooks';
import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import { Modal } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartyModalFooter } from '../common';
import type { TSelectPartyModalContentProps } from './types';
import type { IEntityCustomer } from 'module/customers';
import { SelectCustomerCard } from './SelectCustomerCard';

export const SelectCustomerModalContent = (props: TSelectPartyModalContentProps<IEntityCustomer>) => {
	const { onPrev, state } = props;
	const { orderState, isEndCustomerBillableParty } = useOrderContext();
	const licenseOperationContext = useLicenseOperationContext();
	const { tParty } = useSelectPartyTranslation(SelectPartyEnum.CUSTOMER);

	return (
		<>
			<Modal.Header>{tParty('title')}</Modal.Header>
			<Modal.Body className="pt-lg-3 pb-lg-4">
				<Row>
					<Col sm={6}>
						<SelectCustomerCard
							{...props}
							option={SelectPartyOptionEnum.LICENSE}
							customer={licenseOperationContext.customer}
							// Customer is not B2C
							isNotAllowed={!state.partner && licenseOperationContext.hasPartner}
						/>
					</Col>
					<Col sm={6}>
						<SelectCustomerCard
							{...props}
							option={SelectPartyOptionEnum.ORDER}
							customer={orderState.customer}
							// Customer is not B2C
							isNotAllowed={!isEndCustomerBillableParty && !state.partner}
						/>
					</Col>
				</Row>
			</Modal.Body>
			<SelectPartyModalFooter onClick={onPrev} />
		</>
	);
};
