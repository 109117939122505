import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityLicense } from 'module/licenses';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import {
	getDistributionPartnerFullName,
	getDistributionPartnerRenderName,
} from 'module/distributionPartners/utils/distributionPartnerSelector';
import { useLicenseDistributionPartner } from 'module/licenses/hooks';
import { Link } from 'react-router';

export const DistributionPartnerGroup = () => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { data: license } = usePageDetailContext<IEntityLicense>();
	const { distributionPartner, isFetching, hasDistributionPartner, distributionPartnerId, distributionPartnerLink } =
		useLicenseDistributionPartner(license);

	if (!hasDistributionPartner) {
		return null;
	}

	const renderName = getDistributionPartnerRenderName(distributionPartner);
	return (
		<>
			<DataTableTr name={t('entity.distributionPartner.name')} highlighted isLoading={isFetching}>
				{distributionPartnerLink ? <Link to={distributionPartnerLink}>{renderName}</Link> : renderName}
			</DataTableTr>
			<DataTableTr name={t('entity.distributionPartner.id')}>{distributionPartnerId}</DataTableTr>
			<DataTableTr name={t('entity.distributionPartner.contact')} isLoading={isFetching}>
				{getDistributionPartnerFullName(distributionPartner)}
			</DataTableTr>
		</>
	);
};
