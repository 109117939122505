import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum TierCertificationEnum {
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_3',
	TIER_4 = 'TIER_4',
	DISTRIBUTORS = 'DISTRIBUTORS',
}

export const tierCertificationEnumUtils = generateEnumUtils<TierCertificationEnum>(TierCertificationEnum, {
	translatePath: 'enums:tierCertification',
});
