import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl, FormikControlRadio } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { FormikHelpers } from 'formik/dist/types';
import { CardBody, CardFooter } from '@avast/react-ui-components';
import { SubmitButton } from 'js/components/atoms/Button';
import { PayAsYouGoPaymentMethodEnum, PayAsYouGoPaymentMethodEnumUtils } from 'module/partners/enums';

interface IFlexibleBillingForm {
	paymentMethod?: PayAsYouGoPaymentMethodEnum;
}

type TFlexibleBillingFormProps = {
	autoBillingDisabled?: boolean;
	initialValues: IFlexibleBillingForm;
	onSubmit(values: IFlexibleBillingForm, formikHelpers: FormikHelpers<IFlexibleBillingForm>): void;
};

export const FlexibleBillingForm = (props: TFlexibleBillingFormProps) => {
	const { onSubmit, autoBillingDisabled, initialValues } = props;
	const { t } = useTranslation(accountConfig.trNamespace);
	const paymentMethods = PayAsYouGoPaymentMethodEnumUtils.getSelectOptions();

	return (
		<Formik<IFlexibleBillingForm>
			initialValues={initialValues}
			onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
		>
			<FormikForm testId="creditCard">
				<CardBody>
					<FormikControlRadio name="paymentMethod" groupProps={{ className: 'mb-3' }} required>
						{paymentMethods.map((option) => (
							<FormikControl.Radio
								key={option.value}
								name="paymentMethod"
								value={option.value}
								label={option.label}
								description={option.description as string}
								disabled={option.value === PayAsYouGoPaymentMethodEnum.AUTO_BILLING && autoBillingDisabled}
							/>
						))}
					</FormikControlRadio>
				</CardBody>

				<CardFooter className="text-center">
					<SubmitButton
						size="sm"
						testId="submitForm"
						// Disable submitting until it is possible to use credit cards
						// All flexible billing partners are temporarily moved to LOC
						disabled
					>
						{t('flexibleBilling.paymentMethod.submitButton')}
					</SubmitButton>
				</CardFooter>
			</FormikForm>
		</Formik>
	);
};
