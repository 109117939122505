import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { Alert, ProgressSpinner } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { hasAvailableLicenses, isLicensesGeneratingInProcess } from 'module/orders/utils/common';
import { RetryWrapper } from 'js/components/RetryWrapper';
import { OrderLicenses } from 'module/orders/components/OrderLicenses';

type TOrderLicensesTabProps = {
	onCancelOrder?(): void;
};

export const OrderLicensesTab = (props: TOrderLicensesTabProps) => {
	const {
		data: order,
		query: { refetch },
	} = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);

	return (
		<DefaultContainer>
			{(() => {
				if (!hasAvailableLicenses(order)) {
					return null;
				}

				if (isLicensesGeneratingInProcess(order)) {
					return (
						<RetryWrapper
							retry={refetch}
							errorCaption={t('licenses.error.title')}
							errorDetail={t('licenses.error.detail')}
							growingSequence
							maxAttempts={12}
						>
							<Alert variant="warning" className="text-center" caption={t('licenses.inProcess')} icon={false}>
								<ProgressSpinner variant="warning" />
							</Alert>
						</RetryWrapper>
					);
				}

				return <OrderLicenses order={order} onCancelOrder={props.onCancelOrder} />;
			})()}
		</DefaultContainer>
	);
};
