import * as Yup from 'yup';
import { useMemo } from 'react';
import { yupContextValidator } from 'js/utils/validator';
import type { IFormikContextValidator } from 'types/validator';
import { useTranslation } from 'react-i18next';
import type { IPromotionForm } from 'module/promotions';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import {
	PromotionPartyTierEnum,
	PromotionPartyTypeEnum,
	PromotionScopeEnum,
	promotionScopeEnumUtils,
	type PromotionTypeEnum,
} from 'module/promotions/enums';
import { isEmpty, isNumber } from 'lodash';
import { MarketSegmentEnum } from 'js/enums';
import { CONFIG } from 'config';

type Values = IPromotionForm;

const MAX_PROMOTION_PARTY_TIER_COUNT = Object.values(PromotionPartyTierEnum).length - 1;
const MAX_PROMOTION_PARTY_TYPE_COUNT = Object.values(PromotionPartyTypeEnum).length - 1;

export const usePromotionFormValidator = (): IFormikContextValidator<Values> => {
	const { t } = useTranslation(promotionsConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				name: Yup.string().label(t('common:entity.name')).max(255).required(),

				description: Yup.string().label(t('common:entity.description')).max(800).required(),

				type: Yup.string<PromotionTypeEnum>().label(t('entity.type')).required(),

				scope: Yup.string<PromotionScopeEnum>().label(t('entity.scope')).required(),

				// @ts-ignore
				date: Yup.array<Date | null>()
					.of(Yup.date().nullable(false))
					.typeError(t('form:validator.mixed.required'))
					.label(t('entity.date'))
					.min(2)
					.max(2)
					.required(),

				rate: Yup.number().label(t('entity.rate')).moreThan(0).lessThan(1).required(),

				partnerId: Yup.number()
					.nullable()
					.when(['partnerTypeList', 'partnerTierList', 'partnerCountryCodeList', 'scope'], {
						is: (partnerTypeList, partnerTierList, partnerCountryCodeList, scope) =>
							promotionScopeEnumUtils.validateOneOf(scope, [
								PromotionScopeEnum.PARTY_AND_PRODUCT,
								PromotionScopeEnum.PARTY_ONLY,
							]) &&
							isEmpty(partnerTypeList) &&
							isEmpty(partnerTierList) &&
							isEmpty(partnerCountryCodeList),
						then: Yup.number().required().label(t('common:entity.partner')),
					}),

				// @ts-ignore
				partnerTypeList: Yup.array()
					.of(Yup.string().oneOf(Object.values(PromotionPartyTypeEnum)))
					.max(MAX_PROMOTION_PARTY_TYPE_COUNT, t('validation.cannotSelectAllItems')),

				// @ts-ignore
				partnerTierList: Yup.array()
					.of(Yup.string().oneOf(Object.values(PromotionPartyTierEnum)))
					.max(MAX_PROMOTION_PARTY_TIER_COUNT, t('validation.cannotSelectAllItems')),

				marketSegment: Yup.string<MarketSegmentEnum>()
					.label(t('entity.category'))
					.when('scope', {
						is: (scope: PromotionScopeEnum) =>
							promotionScopeEnumUtils.validateOneOf(scope, [
								PromotionScopeEnum.PARTY_AND_PRODUCT,
								PromotionScopeEnum.PRODUCT_ONLY,
							]),
						then: Yup.string<MarketSegmentEnum>().required(),
					}),

				quantity: Yup.number()
					.integer()
					.when('scope', {
						is: (scope: PromotionScopeEnum) =>
							promotionScopeEnumUtils.validateOneOf(scope, [
								PromotionScopeEnum.PARTY_AND_PRODUCT,
								PromotionScopeEnum.PRODUCT_ONLY,
							]),
						then: Yup.number()
							.required()
							.when(['marketSegment'], {
								is: (marketSegment: MarketSegmentEnum) => marketSegment === MarketSegmentEnum.BUSINESS,
								then: Yup.number().label(t('entity.unitsFrom')).min(1).max(CONFIG.MAX_QUANTITY.BUSINESS),
								otherwise: Yup.number().label(t('entity.quantityMin')).min(0),
							}),
					}),

				quantityMax: Yup.number()
					.integer()
					.label(t('entity.unitsTo'))
					.when('scope', {
						is: (scope: PromotionScopeEnum) =>
							promotionScopeEnumUtils.validateOneOf(scope, [
								PromotionScopeEnum.PARTY_AND_PRODUCT,
								PromotionScopeEnum.PRODUCT_ONLY,
							]),
						then: Yup.number().when('marketSegment', {
							is(marketSegment: MarketSegmentEnum) {
								return marketSegment === MarketSegmentEnum.BUSINESS;
							},
							then: Yup.number()
								.required()
								.min(1)
								.max(CONFIG.MAX_QUANTITY.BUSINESS)

								.when('quantity', {
									is(quantity?: number) {
										return isNumber(quantity) && quantity < CONFIG.MAX_QUANTITY.BUSINESS;
									},
									then: Yup.number().min(Yup.ref('quantity')),
								}),
						}),
					}),
			}),
		[t],
	);

	return {
		validationSchema,
		validate(values) {
			return yupContextValidator<Values>(validationSchema, values);
		},
	};
};
