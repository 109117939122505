import { type ReactElement, useState } from 'react';
import type { TSearchFilter } from 'types/filter';
import type { IApiCustomerListFilter, IEntityCustomer } from 'module/customers';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { useApiCustomerList } from 'module/customers/hooks/useApiCustomers';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { CustomersSearchKeyEnum, customersSearchKeyEnumUtils } from 'module/customers/enums';
import type { TableColumnsDef } from 'types';
import { trim } from 'lodash';
import { isValidEmail } from 'js/utils/validator';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import type { TSearchComboBoxValidationResult } from 'js/components/molecules/SearchBox/useSearchComboBox';

type TTableData = IEntityCustomer;
type TTableSearchKeys = CustomersSearchKeyEnum;

type TSelectCustomerInternalCompanyProps = {
	onSelect: (customer: IEntityCustomer) => void;
	columns: TableColumnsDef<IEntityCustomer>;
};

type TFilter = TSearchFilter<TTableSearchKeys>;

/**
 * Search customer for internal user
 * @param {TSelectCustomerInternalCompanyProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectCustomerInternalCompany = (props: TSelectCustomerInternalCompanyProps): ReactElement => {
	const { onSelect, columns } = props;
	const { t } = useTranslation(customersConfig.trNamespace);
	const [filter, setFilter] = useState<TFilter>({});

	function onSubmit(value: TFilter) {
		if (value.key) {
			setFilter({ [value.key]: value.value });
		}
	}

	function onCancel() {
		setFilter({});
	}

	const validationFunction = ({ value, key }: TFilter): TSearchComboBoxValidationResult => {
		let valid = false;
		if (trim(value).length !== 0) {
			if (key === CustomersSearchKeyEnum.EMAIL && isValidEmail(value)) {
				valid = true;
			}
			if (key === CustomersSearchKeyEnum.SALESFORCE_ID) {
				valid = true;
			}
		}

		return {
			valid,
			message: t('common:validator.email'),
		};
	};

	return (
		<>
			<div className="mb-3">
				<SearchComboBox<TTableSearchKeys>
					autoFocus
					controlledValue={filter}
					onSubmit={onSubmit}
					onCancel={onCancel}
					keys={customersSearchKeyEnumUtils.getSelectOptions()}
					validationFn={validationFunction}
				/>
			</div>

			<ApiListTable<TTableData, IApiCustomerListFilter>
				columns={columns}
				query={useApiCustomerList}
				filter={filter as IApiCustomerListFilter}
				filterRequired
				table={{
					testId: 'customers',
					meta: {
						onRowClick: onSelect,
						loadingType: 'BAR',
						nowrapCell: false,
					},
				}}
			/>
		</>
	);
};
