import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useFormikContext } from 'formik';
import type { TFlexibleBillingForm } from 'module/flexibleBilling';
import { getFlexibleBillingFormSum } from 'module/flexibleBilling/utils';
import { Col, Row } from 'js/components/atoms/Row';

export const FlexibleBillingFormInfo = () => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { values } = useFormikContext<TFlexibleBillingForm>();
	const valueAfter = getFlexibleBillingFormSum(values);

	if (valueAfter === FLEXIBLE_BILLING_UNLIMITED_VALUE) {
		return (
			<Row justify="end">
				<Col md={8} xl={6}>
					<Alert className="mt-3 mb-0" icon variant="warning">
						{t('common.unlimitedNote')}
					</Alert>
				</Col>
			</Row>
		);
	}

	return null;
};
