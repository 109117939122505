import { useOrderContext } from 'js/contexts';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useSelectPartyTranslation } from '../hooks';
import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import { Modal } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartyModalFooter } from '../common';
import type { TSelectPartnerModalContentResolution, TSelectPartyModalContentProps } from './types';
import { SelectPartnerCard } from './SelectPartnerCard';
import { isPriceListCodeInPriceLists } from 'js/priceList/priceListUtils';

export const SelectPartnerModalContent = (
	props: TSelectPartyModalContentProps<TSelectPartnerModalContentResolution>,
) => {
	const { onPrev } = props;
	const { orderState } = useOrderContext();
	const licenseOperationContext = useLicenseOperationContext();
	const { tParty } = useSelectPartyTranslation(SelectPartyEnum.PARTNER);
	const { partner } = orderState;

	const isNotAllowedPriceList =
		licenseOperationContext.priceListCode !== orderState.priceListCode &&
		!isPriceListCodeInPriceLists(orderState.partnerPriceLists || [], licenseOperationContext.priceListCode);
	const isInconsistentProductSegment =
		(partner?.isBusinessPartner &&
			!partner?.isConsumerPartner &&
			!licenseOperationContext.licenseProductGroupIsBusiness) ||
		(!partner?.isBusinessPartner &&
			partner?.isConsumerPartner &&
			licenseOperationContext.licenseProductGroupIsBusiness);

	return (
		<>
			<Modal.Header>{tParty('title')}</Modal.Header>
			<Modal.Body className="pt-lg-3 pb-lg-4">
				<Row>
					<Col sm={6}>
						<SelectPartnerCard
							{...props}
							option={SelectPartyOptionEnum.LICENSE}
							partner={licenseOperationContext.partner}
							partnerPriceLists={licenseOperationContext.partnerPriceLists}
						/>
					</Col>
					<Col sm={6}>
						<SelectPartnerCard
							{...props}
							option={SelectPartyOptionEnum.ORDER}
							partner={orderState.partner}
							partnerPriceLists={orderState.partnerPriceLists}
							isNotAllowed={isNotAllowedPriceList || isInconsistentProductSegment}
						/>
					</Col>
				</Row>
			</Modal.Body>
			<SelectPartyModalFooter onClick={onPrev} />
		</>
	);
};
