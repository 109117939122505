import qs from 'qs';
import type { IGetMaxProductQuantityProps, NavigateLink, TDetailId, TEntityKey } from 'types';
import { logError } from 'js/utils/app';
import { normalizeSku } from 'module/orders/utils/common';
import Skeleton from 'react-loading-skeleton';
import type React from 'react';
import type { IApiSortBy } from 'types/api';
import type { ColumnSort } from '@tanstack/react-table';
import type { SortingState } from '@tanstack/table-core/src/features/RowSorting';
import { MarketSegmentEnum, SortDirectionEnum } from 'js/enums';
import { isNil, isObject, isString, negate, pickBy, range, takeWhile, trim } from 'lodash';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { CONFIG } from 'config';
import { isQuantityChangeDisabled } from 'module/purchase/utils/common';
import { BUSINESS_BULK_QUANTITY } from 'appConstants';

/**
 * Convert object data into url params string
 */
export const object2UrlParams = <D extends object>(data: D, removeEmpty = true): string => {
	let _data: Partial<D> = { ...data };
	if (removeEmpty) {
		_data = pickBy(_data, negate(isNil)) as Partial<D>;
	}
	return qs.stringify(_data, { encodeValuesOnly: true });
};

/**
 * URL params convert to object
 */
export const urlParams2object = <T,>(data: string, detectTypes = true): T =>
	qs.parse(trim(data, '?'), {
		decoder(value: string): number | string | boolean | null | undefined {
			// Detect types is off
			if (!detectTypes) {
				return value;
			}

			// Parse number
			if (/^(\d+|\d*\.\d+)$/.test(value)) {
				return Number.parseFloat(value);
			}

			// Parse constant
			switch (value) {
				case 'true':
					return true;
				case 'false':
					return false;
				case 'null':
					return null;
				case 'undefined':
					return undefined;
				default:
					return decodeURIComponent(value);
			}
		},
	}) as T;

/**
 * Reorder props in object alphabetically
 */
export const orderObject = <T extends Record<PropertyKey, unknown>>(data: T): T | Record<PropertyKey, unknown> => {
	const ordered: Record<PropertyKey, unknown> = {};
	const sortedKeys = Object.keys(data).sort();

	for (const key of sortedKeys) {
		if (Array.isArray(data[key])) {
			ordered[key] = (data[key] as Array<unknown>).sort();
		} else if (isObject(data[key]) && data[key] !== null) {
			ordered[key] = orderObject(data[key] as T);
		} else {
			ordered[key] = data[key];
		}
	}

	return ordered;
};

/**
 * Filter to table filter structure
 * @param {{}} filter
 * @return {{filter: {}}}
 */
export const tableFilterCreator = <T extends {}>(filter: T): { filter: T } => ({ filter });

/**
 * Filter data items
 */
export const filterData = <D extends {}>(data: D[], filter?: Partial<D>): D[] => {
	const keys = Object.keys(filter || {});

	// No filter
	if (!filter || keys.length === 0) {
		return data;
	}

	// Filter data
	return data.filter((item) => {
		let valid = true;
		takeWhile(keys, (key: string) => {
			// @ts-ignore
			const filterValue = filter[key];
			// @ts-ignore
			const itemValue = item[key];

			switch (typeof filterValue) {
				case 'undefined':
					return true;
				case 'boolean':
					valid = filterValue === Boolean(itemValue);
					return valid;
				default:
					valid = filterValue === itemValue;
					return valid;
			}
		});
		return valid;
	});
};

/**
 * @example route=/partner/:id, params={id: 5, name: 'John'} => ['/partner/5', {name: 'John'}]
 *
 * @param {string} url
 * @param {object} params
 * @return {[string, object]}
 */
export const fillUrlParameters = <T,>(url: string, params: T): [string, Partial<T>] => {
	const _params = { ...params };

	// Replace all placeholders in the URL
	const _url = url.replace(/:(\w+)/g, (placeholder, key: TEntityKey<T>) => {
		if (_params && Object.hasOwn(_params, key)) {
			const value: string = String(_params[key]);
			delete _params[key];
			return value;
		}
		logError(`Missing parameter '${key}' for a url '${url}'`, params);
		return placeholder; // If missing, keep the placeholder as is
	});

	return [_url, _params];
};

/**
 * Creates router name based on parameters.
 *
 * @param {string} route - Base route.
 * @param {{}|string|number} params - Route parameters
 * @returns {string} - Route with added parameters and query.
 */
export const buildParametrizedRoute = <P extends object>(route: string, params: Partial<P> | TDetailId): string => {
	// Non-object parameter is `id`
	let queryParams: P & { id?: TDetailId };
	if (isObject(params)) {
		queryParams = params as typeof queryParams;
	} else {
		queryParams = { id: params } as typeof queryParams;
	}

	// Fill route parameters
	const result = fillUrlParameters<P>(route, queryParams);
	let filledRoute = result[0];
	const restQuery = result[1];

	// Add query
	if (restQuery && Object.keys(restQuery).length !== 0) {
		filledRoute += `?${object2UrlParams(restQuery)}`;
	}

	return filledRoute;
};

/**
 * Returns validity extracted from Sku or naturalSku
 * @param {string} sku xxx.x.xxx or xxx-x-xxx form
 * @example
 * extractValidityFromSku("bew-0-12m") => 12
 * extractValidityFromSku("ulw.2.36m") => 36
 * @return {number | null} Validity number in months
 */
export const extractMonthValidityFromSku = (sku?: string): number | null => {
	if (!sku) {
		return null;
	}
	const [, , validity] = normalizeSku(sku).split('.');
	const parsedValidity = Number.parseInt(validity, 10);
	return Number.isNaN(parsedValidity) ? null : parsedValidity;
};

/**
 * Parse the SKU to get the middle value.
 * Return 1 if is business sku - it contains "*".
 * @throws
 */
export const extractBulkQuantityFromSku = (sku: string): number => {
	const [, qty] = normalizeSku(sku).split('.');

	if (qty === BUSINESS_BULK_QUANTITY) {
		return 1;
	}

	const bulkQuantity = Number.parseInt(qty, 10);

	if (Number.isNaN(bulkQuantity)) {
		throw new Error(`Could not correctly extract the bulkQuantity from the SKU, ${sku}`);
	}

	return bulkQuantity;
};

/**
 * Returns validity in full years extracted from Sku or naturalSku
 * @param {string} sku xxx.x.xxx or xxx-x-xxx form
 * @example
 * extractValidityFromSku("bew-0-12m") => 1
 * extractValidityFromSku("ulw.2.40m") => 3
 * @returns {number | null} Validity number in full years
 */
export const getYearValidityFromSku = (sku?: string): number | null => {
	const validityInMonths = extractMonthValidityFromSku(sku);

	return validityInMonths && Math.floor(validityInMonths / 12);
};

export const joinValues = (values: unknown[], separator = ', '): string => values.filter(Boolean).join(separator);

export const buildUrlFromLocation = (location?: NavigateLink['to']): string | undefined => {
	if (isString(location)) {
		return location;
	}
	return location ? `${location.pathname}${location.search}` : undefined;
};

export const sortByToTableSortingState = <Entity,>(
	sortBy: IApiSortBy<Entity> | undefined,
): ColumnSort[] | undefined => {
	if (sortBy) {
		return [
			{
				id: sortBy.key,
				desc: sortBy.direction === SortDirectionEnum.DESC,
			},
		];
	}
};

export const tableSortingStateToSortBy = <Entity extends {}>(sorting: SortingState): IApiSortBy<Entity> | undefined => {
	if (sorting.length >= 1) {
		return {
			key: sorting[0].id as TEntityKey<Entity>,
			direction: sorting[0].desc ? SortDirectionEnum.DESC : SortDirectionEnum.ASC,
		};
	}
};

/**
 * Checks if a value is defined.
 * @param value - The value to check.
 * @returns `true` if the value is defined, `false` otherwise.
 */
export const isDefined = <T,>(value: T | null | undefined): value is T => {
	return value !== null && value !== undefined;
};

/**
 * Get table loading skeleton
 * @param rowCount
 * @param colCount
 */
export const getLoadingTableSkeleton = (rowCount: number, colCount: number): React.ReactNode => {
	return range(rowCount).map((row) => (
		<tr key={`skeleton-tr-${row}`}>
			{range(colCount).map((col) => (
				<CustomTable.Td key={`skeleton-td-${col}`}>
					<Skeleton />
				</CustomTable.Td>
			))}
		</tr>
	));
};

/**
 * Returns max value that can be set to product which a partner is buying or updating
 */
export const getMaxProductQuantity = (props: IGetMaxProductQuantityProps) => {
	const { groupCode, currentQuantity = 0, isFirstPurchase, marketSegment, isRetailPartner } = props;

	if (isQuantityChangeDisabled(groupCode)) {
		// Quantity change
		// for First purchase has to be limited to 1,
		// for License operations has to be limited to 0.
		return isFirstPurchase ? 1 : 0;
	}

	if (isRetailPartner) {
		return CONFIG.MAX_QUANTITY.RETAIL_PARTNERS;
	}

	const configMaxQuantity = getConfigMaxQuantity(marketSegment);

	if (currentQuantity > configMaxQuantity) {
		logError(
			`Max product quantity is lower than current value: (Max quantity:${configMaxQuantity}), (Current quantity: ${currentQuantity})`,
		);
		return 0;
	}

	return configMaxQuantity - currentQuantity;
};

/**
 * Helper function to get max value for market segment.
 * @param {MarketSegmentEnum} quantityType
 * @returns {number}
 */
const getConfigMaxQuantity = (quantityType: MarketSegmentEnum): number => {
	switch (quantityType) {
		case MarketSegmentEnum.CONSUMER:
			return CONFIG.MAX_QUANTITY.CONSUMER;
		default:
			return CONFIG.MAX_QUANTITY.BUSINESS;
	}
};
