import type { ITaxExemptionFormFields } from 'module/account';
import { dateTimeToFilterDate, dateToApiDate } from 'js/utils/dateTime';
import { DateTime } from 'luxon';

export const taxExemptionFormNormalizer = {
	denormalize(formValues: ITaxExemptionFormFields): FormData {
		const { file, taxAuthority, endDate, companyName, startDate } = formValues;
		const _startDate = startDate ? dateToApiDate(dateTimeToFilterDate(DateTime.fromJSDate(startDate))) : '';
		const _endDate = endDate ? dateToApiDate(dateTimeToFilterDate(DateTime.fromJSDate(endDate))) : '';

		const data = new FormData();
		data.append('companyName', companyName);
		data.append('startDate', _startDate || '');
		data.append('endDate', _endDate || '');
		data.append('file', file as File);
		data.append('taxAuthority', taxAuthority);

		return data;
	},
};
