import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { SelectCustomerContainer, type TSelectCustomerContainerProps } from './SelectCustomerContainer';
import type { IEntityCustomer } from 'module/customers';

export type TAsyncSelectCustomerModalProps = TSelectCustomerContainerProps;

export const AsyncSelectCustomerModal = (
	props: TAsyncModalContainerProps<TAsyncSelectCustomerModalProps, IEntityCustomer>,
) => {
	const { forwardedRef } = props;
	const { t } = useTranslation(customersConfig.trNamespace);

	return (
		<AsyncModal<TAsyncSelectCustomerModalProps, IEntityCustomer> ref={forwardedRef} size="lg" testId="selectCustomer">
			{(props) => (
				<>
					<Modal.Header className="pb-0">{t('select.title')}</Modal.Header>
					<Modal.Body className="mt-4">
						<SelectCustomerContainer {...props} onSelect={(customer) => forwardedRef.current?.onSuccess(customer)} />
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
