import { CustomTable } from 'js/components/molecules/CustomTable';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { numberFormatter } from 'js/utils/number';
import classNames from 'classnames';
import type { TFlexibleBillingForm } from 'module/flexibleBilling';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useFormikContext } from 'formik';
import { getLoadingTableSkeleton } from 'js/utils/common';
import { FLEXIBLE_BILLING_PRODUCT_COUNT } from 'module/flexibleBilling/constants';
import { isEqual } from 'lodash';
import { hasGroupDisabledUnitSetting } from 'module/flexibleBilling/utils';
import { DateTime } from 'luxon';
import { ProductNameCell } from 'module/flexibleBilling/components/table/ProductNameCell';

type TOverviewEditTableProps = { currency: string; isLoading: boolean };

export const OverviewEditTable = (props: TOverviewEditTableProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { values, initialValues, setFieldValue } = useFormikContext<TFlexibleBillingForm>();
	const daysInCurrentMonth = DateTime.fromJSDate(new Date()).daysInMonth;

	return (
		<div className="table-content scrollable nowrap-cell">
			<Table hover className="table-flexibleBilling" responsive>
				<thead>
					<tr>
						<CustomTable.Th>{t('common:entity.product')}</CustomTable.Th>
						<CustomTable.Th>{t('common:entity.status')}</CustomTable.Th>
						<CustomTable.Th>{t('entity.usageLimit')}</CustomTable.Th>
						<CustomTable.Th>{t('common:entity.unit_other')}</CustomTable.Th>
						<CustomTable.Th align="end">{t('entity.yourPrice')}</CustomTable.Th>
						<CustomTable.Th align="end">{t('common:entity.active')}</CustomTable.Th>
						<CustomTable.Th align="end">{t('common.currentSpend')}</CustomTable.Th>
						<CustomTable.Th align="end">{t('common.maxSpend')}</CustomTable.Th>
					</tr>
				</thead>
				<tbody>
					{props.isLoading
						? getLoadingTableSkeleton(FLEXIBLE_BILLING_PRODUCT_COUNT, 8)
						: values?.map((item, index) => {
								const isChanged = !isEqual(item, initialValues[index]);
								return (
									// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
									<tr key={item.productGroupCode + index} className={classNames({ 'table-primary': isChanged })}>
										<ProductNameCell>{item.productLabel}</ProductNameCell>
										<CustomTable.Td>
											<FixedWidthWrapper width={100}>
												<FormikControl.Switch
													name={`${index}.enabled`}
													id={`product-${item.productGroupCode}-enabled`}
													label={{
														enabled: t('common:entity.enabled'),
														disabled: t('common:entity.disabled'),
													}}
													onChange={(e) => {
														if (e.target.checked && hasGroupDisabledUnitSetting(item.productGroupCode)) {
															setFieldValue(`${index}.usageLimitEnabled`, false);
														}
													}}
												/>
											</FixedWidthWrapper>
										</CustomTable.Td>
										<CustomTable.Td>
											{!hasGroupDisabledUnitSetting(item.productGroupCode) && (
												<FixedWidthWrapper width={100}>
													<FormikControl.Switch
														name={`${index}.usageLimitEnabled`}
														id={`product-${item.productGroupCode}-usageLimitEnabled`}
														label={{
															enabled: t('common:entity.enabled'),
															disabled: t('common:entity.disabled'),
														}}
														disabled={!item.enabled}
													/>
												</FixedWidthWrapper>
											)}
										</CustomTable.Td>
										<CustomTable.Td>
											{!hasGroupDisabledUnitSetting(item.productGroupCode) && (
												<FixedWidthWrapper width={130}>
													<FormikControl.Number
														disabled={!item.usageLimitEnabled || !item.enabled}
														id={`usageLimit.${index}`}
														name={`${index}.usageLimit`}
														min={item.usageLimitEnabled && item.enabled ? 1 : 0}
														step={1}
														size="xs"
														className="py-0"
													/>
												</FixedWidthWrapper>
											)}
										</CustomTable.Td>
										<CustomTable.Td
											align="right"
											className={classNames({ 'text-muted': !item.enabled }, 'text-nowrap')}
										>
											{numberFormatter.currency(item.currentPrice, props.currency)}
										</CustomTable.Td>
										<CustomTable.Td className={classNames({ 'text-muted': !item.enabled })} align="right">
											{numberFormatter.format(item.currentUsage, { optionalMantissa: true })}
										</CustomTable.Td>
										<CustomTable.Td
											className={classNames({ 'text-muted': !item.enabled }, 'text-nowrap')}
											align="right"
										>
											{numberFormatter.currency(item.currentSpend, props.currency)}
										</CustomTable.Td>
										<CustomTable.Td
											className={classNames({ 'text-muted': !item.enabled }, 'text-nowrap')}
											align="right"
										>
											{(() => {
												if (!item.enabled) {
													return numberFormatter.currency(0, props.currency);
												}
												if (!item.usageLimitEnabled) {
													return t('common.unlimited');
												}
												return numberFormatter.currency(
													item.currentPrice * item.usageLimit * daysInCurrentMonth,
													props.currency,
												);
											})()}
										</CustomTable.Td>
									</tr>
								);
							})}
				</tbody>
			</Table>
		</div>
	);
};
