import { FormikControl } from 'js/components/formik/FormikControl';
import { PurchaseBillablePartyTypeEnum, purchaseBillablePartyTypeEnumUtils } from 'module/purchase/enums';
import { useFormikContext } from 'formik';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { MarketSegmentEnum } from 'js/enums';

export const BillablePartyTypeSelect = () => {
	const { setFieldValue, values } = useFormikContext<IPriceCalculatorForm>();

	return (
		<FormikControl>
			<FormikControl.SingleSelect<PurchaseBillablePartyTypeEnum>
				required
				name="billablePartyType"
				size="sm"
				isClearable={false}
				options={purchaseBillablePartyTypeEnumUtils.getSelectOptions()}
				onChange={(value: PurchaseBillablePartyTypeEnum | null) => {
					if (value === PurchaseBillablePartyTypeEnum.CUSTOMER) {
						setFieldValue('marketSegment', MarketSegmentEnum.BUSINESS);
						if (values.productGroup?.marketSegment === MarketSegmentEnum.CONSUMER) {
							setFieldValue('productGroupCode', undefined);
							setFieldValue('productGroup', null);
						}
					}
				}}
			/>
		</FormikControl>
	);
};
