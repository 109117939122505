import { PriceSummary } from 'js/components/molecules/PriceSummary';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useTranslation } from 'react-i18next';
import { SpendingOverviewHeader } from 'module/flexibleBilling/components/SpendingOverviewHeader';
import { useFormikContext } from 'formik';
import type { TFlexibleBillingForm } from 'module/flexibleBilling';
import { getFlexibleBillingFormSum } from 'module/flexibleBilling/utils';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';
import { UnlimitedValue } from 'module/flexibleBilling/components/UnlimitedValue';
import type { TCurrency } from 'types';
import { FlexibleBillingFormAfterValue } from 'module/flexibleBilling/components/FlexibleBillingFormAfterValue';

type TUpdateSpendingOverviewProps = {
	currency?: TCurrency;
	className?: string;
	isLoading?: boolean;
};

export const UpdateSpendingOverview = (props: TUpdateSpendingOverviewProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { initialValues, values } = useFormikContext<TFlexibleBillingForm>();
	const valueBefore = getFlexibleBillingFormSum(initialValues);
	const valueAfter = getFlexibleBillingFormSum(values);

	return (
		<PriceSummary
			className={props.className}
			isLoading={props.isLoading}
			currencyCode={props.currency}
			headers={[
				<SpendingOverviewHeader
					key="maxSpend"
					text={t('common.maxSpend')}
					tooltipText={t('tooltips.maxSpend.partner')}
					minWidth="200px"
				/>,
			]}
			rows={[
				{
					header: t('common.valueBefore'),
					values: [
						valueBefore === FLEXIBLE_BILLING_UNLIMITED_VALUE ? <UnlimitedValue key="unlimitedValue" /> : valueBefore,
					],
				},
				{
					header: t('common.valueAfter'),
					values: [
						<FlexibleBillingFormAfterValue
							key={0}
							valueBefore={valueBefore}
							valueAfter={valueAfter}
							currency={props.currency}
						/>,
					],
				},
			]}
		/>
	);
};
