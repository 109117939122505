import type { IEntityLicense } from 'module/licenses';
import { LicenseStatusEnum } from 'module/licenses/enums';
import type { TPeriodActivityUuid } from 'types';
import { getLicenseExternalIds } from 'module/licenses/utils/common';

export const refundLicenseListNormalizer = {
	allowedStatues: [LicenseStatusEnum.ACTIVE, LicenseStatusEnum.INACTIVE, LicenseStatusEnum.REPLACED],

	normalize(list: IEntityLicense[], periodActivityUuid: TPeriodActivityUuid): IEntityLicense[] {
		return list.filter((license) => {
			if (getLicenseExternalIds(license).cbPeriodId !== periodActivityUuid) {
				return false;
			}

			return this.allowedStatues.includes(license.status);
		});
	},
};
