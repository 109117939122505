import { type HTMLAttributes, memo, type PropsWithChildren, type ReactElement, type ReactNode } from 'react';
import classNames from 'classnames';

export type THeadlineProps = HTMLAttributes<HTMLDivElement> & {
	rightContent?: ReactNode;
};

const _Headline = (props: PropsWithChildren<THeadlineProps>): ReactElement => {
	const { children, rightContent, className, ...rest } = props;

	return (
		<div className={classNames('component--headline', 'row', 'justify-content-between', className)} {...rest}>
			<div className="col-auto">{children}</div>
			{rightContent && <div className="col-auto flex-shrink-0">{rightContent}</div>}
		</div>
	);
};

export const Headline = memo(_Headline);
