import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { Button } from '@avast/react-ui-components';
import type { IAsyncExportOrderRequest, IOrderListFilter } from 'module/orders';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import { useApiOrderListExport, useApiOrderListExportDetailed } from 'module/orders/hooks/useApiOrders';
import type { IAsyncExportProps } from 'js/components/exports';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IListMetaDataValues } from 'types/utils';
import { isEmpty } from 'lodash';
import { ordersFilterNormalizer, quotesFilterNormalizer } from 'module/orders/normalizer';

type TExportOrdersButtonProps = {
	detailed?: boolean;
	listMetaData: IListMetaDataValues<IOrderListFilter>;
	isQuote?: boolean;
};

export const ExportOrdersButton = (props: TExportOrdersButtonProps) => {
	const { detailed, listMetaData, isQuote } = props;
	const { filter, totalCount } = listMetaData;
	const { t } = useTranslation(ordersConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportOrderRequest>>();
	const isDisabled = totalCount === 0 || isEmpty(filter);
	const trKey = detailed ? 'detailed' : isQuote ? 'quotes' : 'default';

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				disabled={isDisabled}
				onClick={() =>
					exportRef.current?.show({
						request: {
							filter: isQuote ? quotesFilterNormalizer.denormalize(filter) : ordersFilterNormalizer.denormalize(filter),
						},
						useQuery: detailed ? useApiOrderListExportDetailed : useApiOrderListExport,
					})
				}
				testId={detailed ? 'exportOrdersDetailed' : 'exportOrders'}
			>
				{t(`actions.export.${trKey}`)}
			</Button>
			<AsyncExportModal forwardedRef={exportRef} testId="exportOrders" />
		</>
	);
};
