import { StepperVerticalContainer, StepperVerticalHeadline } from 'submodule/stepperVertical';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { useFormStepsGuard } from 'module/priceCalculator/guards';
import { StatusText } from '@avast/react-ui-components';
import { PriceCalculatorVariantTable } from 'module/priceCalculator/components/PriceCalculatorVariantTable';

export const PriceStep = () => {
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);
	const formStepsGuard = useFormStepsGuard();

	return (
		<StepperVerticalContainer variant="light" className="py-5 pb-10 flex-grow-1">
			<StepperVerticalHeadline
				step={4}
				title={t('steps.price.title')}
				className="mb-4"
				active={formStepsGuard.isPriceStepActive}
				highlighted
			/>

			{formStepsGuard.isPriceStepEnabled ? (
				<PriceCalculatorVariantTable />
			) : (
				<StatusText caption={t('message.requiredStepsNotFulfilled')} minHeight={false} className="py-3" />
			)}
		</StepperVerticalContainer>
	);
};
