import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { getSalesforceLoginLink } from 'module/security/utils/common';
import { SecurityFormLayout } from 'module/security/layouts';
import { useAutoLogin } from 'module/security/hooks/useAutoLogin';
import { CONFIG } from 'config';
import { IconButton } from '@avast/react-ui-components';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons/faRightToBracket';
import { trim } from 'lodash';
import { useAuthContext } from 'js/contexts';
import { useLocationState } from 'js/hooks/useLocationState';
import type { ILoginPageState } from 'module/security/index';

export const PageLogin = (): ReactElement => {
	const { t } = useTranslation(securityConfig.trNamespace);
	const { setAuthToken } = useAuthContext();
	const { authRedirectFrom } = useLocationState<ILoginPageState>();
	useAutoLogin(getSalesforceLoginLink(), authRedirectFrom);

	return (
		<SecurityFormLayout title={t('page.login.title')} columns={2}>
			<div className="text-center">
				<div>
					<a href={getSalesforceLoginLink()} className="btn btn-primary">
						<span>{t('common.partnerPortal')}</span>
					</a>
				</div>
			</div>
			{CONFIG.ENV === 'local' && (
				<IconButton
					iconFa={faRightToBracket}
					variant="danger"
					size="sm"
					style={{
						position: 'absolute',
						left: '1rem',
						top: '1rem',
					}}
					onClick={() => {
						const token = prompt('Put new JWT token');
						if (token) {
							setAuthToken({ token: trim(token, '"') });
							setTimeout(() => window.location.reload(), 100);
						}
					}}
				/>
			)}
		</SecurityFormLayout>
	);
};
