import { useMemo } from 'react';
import type { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import type { IEntityOrder } from 'module/orders';
import { noValuePlaceholder } from 'js/utils/app';
import { ordersConfig } from 'module/orders/ordersConfig';
import type { TWidgetColumns } from 'submodule/widgets';
import { DateTime } from 'luxon';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';

type TTableData = IEntityOrder;

export const useOverduePaymentWidgetColumns = (): TWidgetColumns<TTableData> => {
	const { t } = useTranslation(ordersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const columns: TableColumnsDef<TTableData> = [
			columnHelper.link(
				'id',
				{
					header: t('entity.orderNumberShort'),
				},
				{
					to: (quote) => ordersConfig.detailLink(quote),
					copy: true,
				},
			),
		];

		columns.push(
			columnHelper.ellipsis(
				(row) => getCustomerRenderName(row.customer),
				{
					id: 'customer',
					header: t('common:entity.customer'),
					meta: {
						defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					},
				},
				{ width: 200 },
			),
		);

		columns.push(
			columnHelper.text('payment.dueDate', {
				header: t('common:entity.daysOverdue'),
				id: 'days_overdue',
				meta: {
					formatters: [
						(date: string) => {
							const startOfPastDate = DateTime.fromISO(date).startOf('day');
							const startOfToday = DateTime.now().startOf('day');
							const diffDateObj = startOfToday.diff(startOfPastDate, ['days']).toObject();

							return <>{Math.ceil(diffDateObj.days || 0)}</>;
						},
					],
				},
			}),
		);

		columns.push(
			columnHelper.text('status', {
				header: t('common:entity.orderStatus'),
			}),
		);

		columns.push(
			columnHelper.text('payment.type', {
				id: 'paymentMethod',
				header: t('common:entity.paymentMethod'),
			}),
		);

		columns.push(columnHelper.date('createdAt', { header: t('entity.created') }));

		columns.push(columnHelper.date('payment.dueDate', { header: t('common:dates.paymentDue'), id: 'payment_dueDate' }));

		columns.push(
			columnHelper.ellipsis(
				(row) => getPartnerRenderName(row.partner),
				{
					id: 'partner',
					header: t('common:entity.partner'),
					meta: {
						defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					},
				},
				{ width: 200 },
			),
		);

		columns.push(
			columnHelper.money(
				'totalAmountWithoutTax',
				{
					header: t('common:entity.totalAmount'),
					meta: { className: 'text-nowrap' },
				},
				{ currencyKey: 'currency' },
			),
		);

		return {
			columns,
			columnVisibility: {
				paymentMethod: false,
				status: false,
				createdAt: false,
				payment_dueDate: false,
				customer: false,
			},
		};
	}, [t]);
};
