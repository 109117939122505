import TagManager from 'react-gtm-module';
import { getLocationPathname, logDebug, logWarn } from 'js/utils/app';
import type { TEntityLicenseId } from 'module/licenses';
import { useEffect } from 'react';
import type { PartnerRoleEnum } from 'module/partners/enums';
import type { TLocationChange } from 'js/hooks/useLocationChange';

interface IGtmEventExtraAttributes {
	[key: string]: unknown;
}

interface IGtmEvent extends IGtmEventExtraAttributes {
	event?: string;
	eventCategory?: string;
	eventAction: string;
	eventLabel: string;
}

interface IGtmEventUser {
	userRole: PartnerRoleEnum | null;
}

interface IGtmEventPageView {
	event: 'sendVirtualPageView';
	virtualUrl: string;
	virtualTitle?: string;
}

type TGtmEvent = IGtmEvent | IGtmEventUser | IGtmEventPageView;

type IGtm = {
	initialized: boolean;
	lastLocationPathname: string | null;

	init(): void;
	destructor(): void;
	onChangeLocation: TLocationChange;
	push(data: TGtmEvent): void;
	event(event: IGtmEvent): void;
	user(data: IGtmEventUser): void;
	pageView(url: string, title?: string): void;
	export(): void;
	registerLicense(licenseId: TEntityLicenseId): void;
	quickFilter(eventLabel: string): void;
	action(action: string, extra?: IGtmEventExtraAttributes): void;
};

export const gtm: IGtm = {
	initialized: false,
	lastLocationPathname: null,

	init() {
		const gtmId = process.env.REACT_APP_GTM_ID;
		if (!gtmId) {
			logDebug('GTM', 'Google Tag Manager id is not defined');
			return;
		}

		TagManager.initialize({ gtmId });
		this.initialized = true;
	},

	destructor() {
		this.initialized = false;
	},

	onChangeLocation(location) {
		const { pathname } = location;
		if (pathname !== gtm.lastLocationPathname) {
			gtm.lastLocationPathname = pathname;
			gtm.pageView(pathname);
		}
	},

	push(data) {
		if (!this.initialized) {
			return;
		}

		if (window.dataLayer?.push) {
			logDebug('GTM', 'push', data);
			window.dataLayer.push(data);
		} else {
			logWarn('GTM', 'dataLayer.push is not defined', window.dataLayer);
		}
	},

	event(event) {
		const pushEvent: IGtmEvent = {
			event: 'GAEvent',
			eventCategory: 'OMS',
			...event,
		};

		this.push(pushEvent);
	},

	user(data) {
		this.push(data);
	},

	pageView(url, title) {
		this.push({
			event: 'sendVirtualPageView',
			virtualUrl: url,
			virtualTitle: title,
		});
	},

	export() {
		this.event({
			eventAction: 'Export',
			eventLabel: getLocationPathname(),
		});
	},

	registerLicense(licenseId) {
		this.event({
			eventAction: 'Register license',
			eventLabel: getLocationPathname(),
			licenseId,
		});
	},

	quickFilter(eventLabel) {
		this.event({
			eventAction: 'Quick search',
			eventLabel,
		});
	},

	action(eventAction, extra) {
		this.event({
			eventAction,
			eventLabel: getLocationPathname(),
			...extra,
		});
	},
};

export const useGtmAction = (action: string) => {
	useEffect(() => {
		gtm.action(action);
	}, [action]);
};
