import type { TSelectOptions } from '@avast/react-ui-components';
import { generateId } from '@avast/react-ui-components';
import { RangeDatepickerTypeFilter } from 'js/components/molecules/Datepicker/RangeDatepickerTypeFilter';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import {
	FilterRangeDatepicker,
	type TFilterRangeDatepickerProps,
} from 'js/components/molecules/Datepicker/FilterRangeDatepicker';
import type { IDateRangeTypedFilter } from 'types/filter';
import { DataFilterContextProvider, useDataFilterContext } from 'js/components/molecules/DataFilter';
import { useCompare } from 'js/hooks/useCompare';

type TFilterTypedRangeDatepickerProps<T extends string> = TFilterRangeDatepickerProps & {
	defaultValue: T;
	typeValues: TSelectOptions<T>;
};

/**
 * Typed Datepicker component with predefined props.
 *
 * @param {TFilterTypedRangeDatepickerProps} props
 * @returns {React.ReactElement}
 */
export const FilterTypedRangeDatepicker = <T extends string>(
	props: TFilterTypedRangeDatepickerProps<T>,
): ReactElement => {
	const { defaultValue, typeValues, ...rest } = props;
	const { values, updateFilter, isEnabledField } = useDataFilterContext<IDateRangeTypedFilter<T>>();
	const filterValueChanged = useCompare<T | undefined>(values.dateRangeType);
	const [dateRangeType, setDateRangeType] = useState<T>(values.dateRangeType || defaultValue);
	const id = useMemo(() => generateId(), []);

	// Respect changes outside the component
	useEffect(() => {
		if (filterValueChanged && values.dateRangeType) {
			setDateRangeType(values.dateRangeType);
		}
	}, [filterValueChanged, values.dateRangeType]);

	return (
		<DataFilterContextProvider
			value={{
				values,
				isEnabledField,
				updateFilter(filter) {
					updateFilter({
						...filter,
						dateRangeType: filter.dateRange ? dateRangeType : undefined,
					});
				},
			}}
		>
			<FilterRangeDatepicker
				{...rest}
				additionalFilter={
					<RangeDatepickerTypeFilter<T>
						value={dateRangeType}
						onChange={setDateRangeType}
						values={typeValues}
						name={`filterTypedRangeDatepicker-${id}`}
					/>
				}
			/>
		</DataFilterContextProvider>
	);
};
