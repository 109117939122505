import type { TChargeOrderId, TEventDetailId } from 'types';
import { DocumentsModalContent } from 'js/components/documents/DocumentsModalContent';
import { useApiChargeOrderDocumentsList } from 'js/chargeOrder/useApiChargeOrder';

export type TChargeOrderDocumentsModalContentProps = {
	id: TEventDetailId;
	chargeOrderId: TChargeOrderId;
	title: string;
};

export const ChargeOrderDocumentsModalContent = (props: TChargeOrderDocumentsModalContentProps) => {
	const { id, chargeOrderId, title } = props;
	const { data: documents, query: documentsQuery } = useApiChargeOrderDocumentsList({
		filter: { chargeOrderId },
	});

	return (
		<DocumentsModalContent
			title={
				<>
					{title} <small>#{id}</small>
				</>
			}
			isLoading={documentsQuery.isFetching}
			documents={documents}
			documentFileNamePrefix={id}
		/>
	);
};
