import type { IDocument } from 'js/chargeOrder';
import type { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	getDocumentFileIcon,
	getDocumentFileIconColorVariant,
	getDocumentFileName,
} from 'js/components/documents/utils';
import classNames from 'classnames';
import { gtm } from 'js/analytics/gtm';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'js/hooks/useDownloadFile';
import { documentTypeEnumUtils, DocumentUrlType } from 'js/enums';
import { logError } from 'js/utils/app';
import { isString } from 'lodash';

type TTableData = IDocument;

export const useDocumentsColumns = (documentFileNamePrefix?: string): TableColumnsDef<TTableData> => {
	const { t } = useTranslation('components');
	const downloadFile = useDownloadFile();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		return [
			columnHelper.text('documentType', {
				header: t('documents.entity.documentType'),
				meta: {
					formatters: [
						documentTypeEnumUtils.getText,
						(value, { row }) => (
							<div className="d-flex align-items-center">
								<div
									style={{ width: '30px', fontSize: '24px' }}
									className={classNames('flex-grow-0 me-2', `text-${getDocumentFileIconColorVariant(row.original)}`)}
								>
									<FontAwesomeIcon icon={getDocumentFileIcon(row.original)} />
								</div>
								<div className="flex-grow-1">
									<div className="fw-bold">{value}</div>
									{isString(row.original.id) && <div className="fs-sm text-gray">#{row.original.id}</div>}
								</div>
							</div>
						),
					],
				},
			}),
			columnHelper.actions(
				{
					button: {
						children: t('common:actions.download'),
						onClick(document) {
							gtm.action('Download document', { documentType: document.documentType });
							switch (document.urlType) {
								case DocumentUrlType.FILE:
									downloadFile(document.url, getDocumentFileName(document, documentFileNamePrefix));
									break;
								default:
									logError(`Not supported url type: ${document.urlType}`);
							}
						},
					},
				},
				{ meta: { sticky: null } },
			),
		];
	}, [t, downloadFile, documentFileNamePrefix]);
};
