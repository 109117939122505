import type { ReactElement } from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import type { IFormDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { RouteName } from 'module/RouteName';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Row } from 'js/components/atoms/Row';
import { FormikCountrySelect } from 'js/components/formik/FormikCountrySelect';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import type { JoinDeepPaths } from 'types/utils';
import { useDistributionPartnerFormValidator } from 'module/distributionPartners/hooks/useDistributionPartnerFormValidator';
import { distributionPartnerFormNormalizer } from 'module/distributionPartners/normalizer';
import { useNavigate } from 'react-router';
import { isFunction } from 'lodash';
import { FlexibleBillingControl } from './FlexibleBillingControl';

type TDistributionPartnerFormFieldsKeys = JoinDeepPaths<IFormDistributionPartner>;
type TDistributionPartnerFormProps = {
	initialValues?: IFormDistributionPartner;
	onCancel?: () => void;
	onSubmit: FormikConfig<IFormDistributionPartner>['onSubmit'];
};

/**
 * Form for create and update Distribution Partner
 * @param {TDistributionPartnerFormProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const DistributionPartnerForm = (props: TDistributionPartnerFormProps): ReactElement => {
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const navigate = useNavigate();
	const { validate } = useDistributionPartnerFormValidator();

	const { initialValues = distributionPartnersConfig.defaultFormValues, onCancel } = props;
	const isUpdate = Boolean(props.initialValues);

	return (
		<Formik<IFormDistributionPartner>
			validate={validate}
			initialValues={initialValues}
			onSubmit={(values, helpers) => {
				props.onSubmit(distributionPartnerFormNormalizer.denormalize(values, isUpdate), helpers);
			}}
		>
			<FormikForm testId="distributionPartnerForm">
				{/* Base partner data */}
				<Row size="md">
					<ColInput name="name" label={t('common:entity.company')} colProps={{ sm: 8 }} required />
					<ColInput name="taxId" label={t('common:contact.taxId')} colProps={{ sm: 4 }} />
					<ColInput name="contact.firstName" label={t('common:contact.firstName')} colProps={{ sm: 6 }} required />
					<ColInput name="contact.lastName" label={t('common:contact.lastName')} colProps={{ sm: 6 }} required />
					<ColInput
						name="contact.email"
						label={t('common:contact.email')}
						colProps={{ sm: 6 }}
						required={!isUpdate}
						type="email"
						placeholder="@"
						disabled={isUpdate}
					/>
					<ColInput name="website" label={t('common:contact.website')} colProps={{ sm: 6 }} />
				</Row>
				<hr />

				{/* Address */}
				<Row size="md">
					<ColInput name="billing.street" label={t('common:address.street')} colProps={{ xs: 12 }} required />
					<ColInput name="billing.postalCode" label={t('common:address.postalCode')} colProps={{ sm: 4 }} required />
					<ColInput name="billing.city" label={t('common:address.city')} colProps={{ sm: 8 }} required />
				</Row>

				<FormikCountrySelect<IFormDistributionPartner, TDistributionPartnerFormFieldsKeys>
					countryField="billing.countryCode"
					countryStateField="billing.stateCode"
					required={!isUpdate}
					disabled={isUpdate}
				/>

				<FlexibleBillingControl isUpdate={isUpdate} />

				<FormikFooterControls
					submit={{ children: t(`actions.form.${isUpdate ? 'update' : 'create'}`) }}
					cancel={{
						onClick() {
							if (isFunction(onCancel)) {
								onCancel();
							} else {
								navigate(RouteName.DISTRIBUTION_PARTNERS.LIST);
							}
						},
					}}
				/>
			</FormikForm>
		</Formik>
	);
};
