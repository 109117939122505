import type { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { retailConfig } from 'module/retail/retailConfig';
import { RetailRoutes } from 'module/retail/RetailRoutes';
import { DISABLED_BREADCRUMB_LINK } from 'js/components/molecules/DynamicBreadcrumbs';

export const RetailContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={{ ...retailConfig, menuLink: DISABLED_BREADCRUMB_LINK }} />
		<RetailRoutes />
	</>
);
