import type { IEntityCustomer } from 'module/customers';
import type { TCustomerSalesforceId, TPartnerId } from 'types';
import { useApiCustomer, useApiPartnerCustomer } from 'module/customers/hooks/useApiCustomers';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'js/contexts';
import { CustomerSourceEnum } from 'module/customers/enums';
import type { TUseApiDetailQueryResponse } from 'js/queries/useApiDetailQuery';

type TUseCustomerDetailProps = {
	customerId?: TCustomerSalesforceId | null;
	partnerId?: TPartnerId | null;
	enabled?: boolean;
	onData?: (customer: IEntityCustomer | null) => void;
};

type TUseCustomerDetail = {
	response: TUseApiDetailQueryResponse<IEntityCustomer>;
	source: CustomerSourceEnum;
};

export const useCustomerDetail = (props: TUseCustomerDetailProps): TUseCustomerDetail => {
	const { customerId, partnerId, enabled, onData } = props;
	const { isGroupPartner } = useAuthContext();
	const [partnerCustomerFlag, setPartnerCustomerFlag] = useState(isGroupPartner);

	useEffect(() => {
		setPartnerCustomerFlag(Boolean(partnerId));
	}, [partnerId]);

	// Internal company request
	const internalCompanyQuery = useApiCustomer({
		// @ts-ignore The hook runs query only when customerId is not null
		filter: { salesforceId: customerId },
		queryConfig: {
			enabled: Boolean(customerId) && !partnerCustomerFlag && enabled,
		},
	});

	// Partner request
	const partnerQuery = useApiPartnerCustomer({
		// @ts-ignore The hook runs query only when customerId nor partnerId is null
		filter: { salesforceId: customerId, partnerId: partnerId },
		config: { catchError: isGroupPartner },
		queryConfig: {
			enabled: Boolean(customerId) && Boolean(partnerId) && partnerCustomerFlag && enabled,
			meta: {
				onError() {
					setPartnerCustomerFlag(false);
				},
			},
		},
	});

	const query = partnerCustomerFlag ? partnerQuery : internalCompanyQuery;
	const { data: customer } = query;

	// Pass returned value by callback
	useEffect(() => {
		if (enabled) {
			onData?.(customer);
		}
	}, [customer, onData, enabled]);

	return {
		response: query,
		source: partnerCustomerFlag ? CustomerSourceEnum.PARTNER : CustomerSourceEnum.GENERAL,
	};
};
