import { type IPageModuleConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import type { IAuthGuards } from 'types';

interface IAccountModuleConfig extends IPageModuleConfig {
	taxExemptFile: {
		mimeType: string;
		extensions: string[];
	};
	flexibleBilling: {
		authGuards: IAuthGuards;
	};
}

export const accountConfig = moduleConfigGenerator<IAccountModuleConfig>({
	trNamespace: 'moduleAccount',
	menuName: 'app:menu.account',
	menuLink: RouteName.ACCOUNT.DEFAULT,
	aclModule: 'account',
	authGuards: {
		partnerProperties: { isRegistered: true },
	},
	taxExemptFile: {
		mimeType: 'application/pdf, image/png, image/jpeg, image/bmp',
		extensions: ['PDF', 'JPG', 'PNG', 'BMP'],
	},
	flexibleBilling: {
		authGuards: { partnerProperties: { isPayAsYouGoEligible: true } },
	},
});
