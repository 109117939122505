import type { IEntityProductGroup } from 'module/purchase';
import { hasProductGroupProducts } from 'module/purchase/utils/common';

export const filterPosaProductGroups = (productGroups: IEntityProductGroup[]) =>
	productGroups.map(getGroupsWithPosaProducts).filter(hasProductGroupProducts);

const getGroupsWithPosaProducts = (group: IEntityProductGroup) => ({
	...group,
	products: group.products.filter((product) => Boolean(product.isPosaEnabled)),
});

export const exportedForTesting = {
	getGroupsWithPosaProducts,
};
