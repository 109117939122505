import { useCallback } from 'react';
import type { IStandardOrderInstance } from 'module/purchase';
import { useApiSaveOrder } from 'module/purchase/hooks/useApiPurchase';
import type { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { submitOrderApiError } from 'module/purchase/utils/apiError';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { useApiErrorContext, useAppContext, useOrderContext } from 'js/contexts';

export type TUsePlaceOrderCustomPricing = (
	customPrices: IStandardOrderInstance['customPrices'],
	onSuccess: (data: IEntityOrder) => void,
) => Promise<void>;

export const useEditPricesSave = (): TUsePlaceOrderCustomPricing => {
	const orderContext = useOrderContext();
	const { confirmationModalRef, loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const apiSaveOrder = useApiSaveOrder();

	return useCallback(
		async (customPrices, onSuccess) => {
			const order = orderInstanceNormalizer.denormalizeToSave(orderContext, customPrices);
			if (!order) {
				return;
			}

			const isConfirmed = await confirmationModalRef.current?.show({
				title: t('components.editPrices.title'),
				messages: [t('components.editPrices.confirmation.message')],
				submitButtonText: t('components.editPrices.confirmation.success'),
			});

			if (isConfirmed) {
				loadingModalRef.current?.show({
					title: t('common.savingOrder'),
					disableBeforeUnload: true,
				});

				apiSaveOrder.mutate(order, {
					onSuccess({ data }) {
						loadingModalRef.current?.hide();
						onSuccess(data);
					},
					onError(error) {
						loadingModalRef.current?.hide();
						if (error) {
							setError({
								error,
								resolve: submitOrderApiError,
							});
						}
					},
				});
			}
		},
		[apiSaveOrder, orderContext, t, confirmationModalRef, loadingModalRef, setError],
	);
};
