import { useTranslation } from 'react-i18next';
import { PriceCell } from './PriceCell';
import { useLicenseOperationComparativePricing } from 'submodule/comparativeFeatures/useLicenseOperationComparativePricing';
import { useLicenseOperationUnitTotal } from 'module/licenses/hooks/licenseOperation';
import type { TPricingRowProps } from 'submodule/comparativeFeatures/components/PricingRow';

type TLicenseOperationPricingRowProps = TPricingRowProps;

export const LicenseOperationPricingRow = (props: TLicenseOperationPricingRowProps) => {
	const { comparativeData, currencyCode } = props;
	const { t } = useTranslation('submodules');
	const { pricing, isPricingLoading } = useLicenseOperationComparativePricing(comparativeData);
	const { products } = comparativeData;
	const productUnits = useLicenseOperationUnitTotal();

	return (
		<tr>
			<td align="left">
				<small className="text-muted">*{t('comparativeFeatures.windowsOnly')}</small>
			</td>
			{products.map((list) =>
				list.map((productData) => {
					const price = pricing?.lineitems.find(
						(i) => i.reference_lineitem_id === productData.product.sku,
					)?.linePriceWOTax;
					return (
						<PriceCell
							key={`${productData.productGroup.code}_price`}
							isLoading={isPricingLoading}
							currencyCode={currencyCode}
							price={price}
							productData={productData}
							productUnits={productUnits}
							variant={comparativeData.variant}
						/>
					);
				}),
			)}
		</tr>
	);
};
