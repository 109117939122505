import type { ISelectOption } from '@avast/react-ui-components';
import type { TLocale } from 'types/config';
import { useLocales } from 'submodule/localeSwitch/hooks/useLocales';
import { LocaleFormatPreview } from 'submodule/localeSwitch/components';

export const useLocaleFormatSelectOptions = (): ISelectOption<TLocale>[] => {
	const locales = useLocales();

	return locales.map((item) => ({
		value: item.locale,
		label: item.name,
		description: <LocaleFormatPreview config={item} />,
	}));
};
