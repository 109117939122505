import { SelectPartner, type TSelectPartnerProps } from 'module/partners/components/SelectPartner';
import type { TFormFieldProps } from '@avast/react-ui-components';
import type { ReactElement } from 'react';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import { formik2Props, type TFormikFieldProps } from 'js/components/formik/FormikControl';
import { useFormikSetFieldValue } from 'js/hooks/useFormikSetFieldValue';
import { SubmittingIndicatorEnum } from 'js/enums';

type TFormikSelectPartnerProps = Partial<TSelectPartnerProps> & TFormFieldProps;

/**
 * @param {TFormikFieldProps<TFormikSelectPartnerProps>} componentProps
 * @returns {React.ReactElement}
 * @private
 */
export const FormikSelectPartner = (componentProps: TFormikFieldProps<TFormikSelectPartnerProps>): ReactElement => {
	const { name, onChange, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<SelectPartner
					disabledLabel
					{...fieldProps.field}
					{...formik2Props<TFormikSelectPartnerProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
					onChange={(partnerId, partner) => {
						formikSetFieldValue(partnerId);
						onChange?.(partnerId, partner);
					}}
				/>
			)}
		</Field>
	);
};
