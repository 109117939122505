import * as Yup from 'yup';
import { getWidgetValidator } from './getWidgetValidator';
import type { TWidgetConfig, TWidgetGridColumn, TWidgetGridItem } from 'submodule/widgets/index';

export const getWidgetConfigValidators = (allowedWidgets: TWidgetConfig[]) => {
	const widgetValidator = getWidgetValidator(allowedWidgets);

	const itemValidator = Yup.object()
		.shape<TWidgetGridItem>({
			id: Yup.number().required(),
			widget: widgetValidator.required(),
		})
		.required();

	const columnValidator = Yup.array<TWidgetGridItem>().required().min(0);

	const rowValidator = Yup.array<TWidgetGridColumn>().required().max(2);

	return { itemValidator, columnValidator, rowValidator };
};
