import { useFormikContext } from 'formik';
import type { IFormDistributionPartner } from 'module/distributionPartners/index';
import { FormikControl, FormikControlRadio } from 'js/components/formik/FormikControl';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useTranslation } from 'react-i18next';
import { Row } from 'js/components/atoms/Row';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import { YesNoEnum } from 'js/enums';
import { useCan } from 'js/hooks/useCan';

type TFlexibleBillingControlProps = {
	isUpdate?: boolean;
};

export const FlexibleBillingControl = (props: TFlexibleBillingControlProps) => {
	const { values } = useFormikContext<IFormDistributionPartner>();
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const isAllowed = useCan({
		do: flexibleBillingConfig.aclModule,
		create: true,
	});

	if (props.isUpdate || !isAllowed) {
		return null;
	}

	return (
		<>
			<hr />
			<FormikControl groupProps={{ className: 'mb-0' }}>
				<FormikControl.Checkbox
					name="flexibleBilling.create"
					label={t('invite.form.create.label')}
					description={[
						<p key="description">{t('invite.form.create.description')}</p>,
						<p key="note">{t('invite.note')}</p>,
					]}
				/>
			</FormikControl>
			{values.flexibleBilling?.create && (
				<>
					<FormikControlRadio name="flexibleBilling.differentEmail" groupProps={{ className: 'mb-0 mt-2' }}>
						<FormikControl.Radio
							name="flexibleBilling.differentEmail"
							label={t('invite.form.differentEmail.partner')}
							value={YesNoEnum.NO}
							description={values.contact.email}
						/>
						<FormikControl.Radio
							name="flexibleBilling.differentEmail"
							label={t('invite.form.differentEmail.other')}
							value={YesNoEnum.YES}
						/>
					</FormikControlRadio>
					{values.flexibleBilling?.differentEmail === YesNoEnum.YES && (
						<Row size="md" multi>
							<ColInput
								name="flexibleBilling.differentEmailValue"
								label={t('common:contact.email')}
								colProps={{ sm: 6 }}
								required
							/>
						</Row>
					)}
				</>
			)}
		</>
	);
};
