import type { IEntityCustomer } from 'module/customers';
import type { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { useMemo } from 'react';

type TTableData = IEntityCustomer;

export const useSelectCustomerColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(customersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('id'),
			columnHelper.ellipsis('companyName', {
				header: t('common:entity.company'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('firstName', { header: t('common:contact.firstName') }),
			columnHelper.text('lastName', { header: t('common:contact.lastName') }),
			columnHelper.text('email', { header: t('common:contact.email') }),
		];
	}, [t]);
};
