import { useCompare } from 'js/hooks/useCompare';
import { useEffect, useState } from 'react';
import { storageLoad, storageSave } from 'js/hooks/useStorage';
import { cacheInstance } from 'api/cache';

export type TUseStorageContextOnChange<State> = (action: 'LOAD' | 'CHANGE', state: State | null) => void;

type TUseStorageContextProps<State> = {
	key: string;
	state: State;
	onChange: TUseStorageContextOnChange<State>;
	allowSync?: boolean;
	allowLoadState?: boolean;
	allowUpdateState?: boolean;
};

interface Subscription {
	// Cancels the subscription
	unsubscribe(): void;
}

export const useStorageContext = <State extends {}>(props: TUseStorageContextProps<State>) => {
	const { key, onChange, state, allowLoadState = true, allowUpdateState = true, allowSync = true } = props;
	const isStateChanged = useCompare<State>(state, true);
	const [isStateLoaded, setStateLoaded] = useState<boolean>(false);

	useEffect(() => {
		let subscription: Subscription;

		const subscribe = async () => {
			await cacheInstance.ready();
			const observable = cacheInstance.newObservable({
				key,
				crossTabNotification: true,
				changeDetection: true,
			});
			subscription = observable.subscribe({
				next(obj) {
					if (obj.valueChange) {
						onChange('CHANGE', obj.newValue);
					}
				},
			});
		};
		if (allowSync) {
			subscribe();
		}

		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [key, onChange, allowSync]);

	// Load order from storage
	useEffect(() => {
		if (allowLoadState && !isStateLoaded) {
			storageLoad<State>(key, (payload) => {
				onChange('LOAD', payload);
				setStateLoaded(true);
			});
		}
	}, [key, onChange, allowLoadState, isStateLoaded]);

	// Save order step to storage
	useEffect(() => {
		if (allowUpdateState && isStateChanged) {
			storageSave(key, state);
		}
	}, [key, state, isStateChanged, allowUpdateState]);
};
