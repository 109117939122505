import { ordersConfig } from 'module/orders/ordersConfig';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { PromotionMessageParser } from 'module/orders/utils/promotionMessageParser';
import type { TCurrency } from 'types';
import { PromotionMessagePart } from 'module/orders/components/PromotionMessagePart';

type TPromotionMessageProps = {
	message?: string;
	className?: string;
	boxVariant?: 'white' | 'light';
	caption?: string | null;
	isLoading?: boolean;
	currency?: TCurrency;
};

export const PromotionMessage = (props: TPromotionMessageProps) => {
	const { message, className, boxVariant = 'light', caption = 'common.pricingMessage', isLoading, currency } = props;
	const { t } = useTranslation(ordersConfig.trNamespace);

	if (!message) {
		return isLoading ? <Skeleton height={60} /> : null;
	}

	const promotionMessageParser = new PromotionMessageParser(message, currency);
	const [percentageOff, quantityBased] = promotionMessageParser.format();

	return (
		<div className={className}>
			{caption && <p className="fw-bold fs-sm mb-1">{t(caption)}:</p>}
			<PromotionMessagePart
				promotionsArray={percentageOff}
				title={t('pricingMessage:percentageOffPromotions')}
				boxVariant={boxVariant}
			/>
			<PromotionMessagePart
				promotionsArray={quantityBased}
				title={t('pricingMessage:quantityBasedPromotions')}
				boxVariant={boxVariant}
			/>
		</div>
	);
};
