import { Alert } from '@avast/react-ui-components';
import type { IEntityPartnerDetail } from 'module/partners/index';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TPartnerTermsAlertProps = {
	partner: IEntityPartnerDetail;
};

export const PartnerTermsAlert = (props: TPartnerTermsAlertProps) => {
	const { t } = useTranslation(purchaseConfig.trNamespace);

	if (!props.partner.shouldAcceptNewTerms) {
		return null;
	}

	return <Alert variant="warning">{t('guards.partnerTermsGuard.message')}</Alert>;
};
