import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { Button, ButtonComposition } from '@avast/react-ui-components';
import { SubmitButton } from 'js/components/atoms/Button';
import { usePageDetailContext } from 'js/contexts';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import type { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

type TFlexibleBillingEditDetailActionsProps = {
	className?: string;
	isLoading?: boolean;
};

export const FlexibleBillingEditDetailActions = (props: TFlexibleBillingEditDetailActionsProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();
	const navigate = useNavigate();
	const { values, initialValues } = useFormikContext();
	const isFormChanged = !isEqual(values, initialValues);

	return (
		<ButtonComposition
			className={classNames('justify-content-end', props.className)}
			testId="flexibleBillingActions"
			size="sm"
		>
			<Button
				size="sm"
				testId="cancel"
				variant="outline-primary"
				onClick={() => navigate(flexibleBillingConfig.detailLinkById(distributionPartner.id))}
			>
				{t('common:actions.cancel')}
			</Button>
			<SubmitButton size="sm" loading={props.isLoading} disabled={!isFormChanged}>
				{t('common:actions.submitChanges')}
			</SubmitButton>
		</ButtonComposition>
	);
};
