import type { IEntityClosingBalance } from 'module/closingBalance';
import { isGcOrder } from 'module/orders/utils/common';

export const hasAvailableDocuments = (closingBalance: IEntityClosingBalance): boolean => {
	if (closingBalance.billing?.status === 'Complete') {
		return true;
	}

	if (!isGcOrder(closingBalance)) {
		if (closingBalance.billing?.status === 'Waiting Payment') {
			return true;
		}
	}

	return false;
};
