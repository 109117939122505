import type { ReactElement } from 'react';
import { UiTable } from '@avast/react-ui-components';
import type { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { useOrderDiscountsColumns } from 'module/orders/hooks';

type TTableData = IEntityOrderItem;
type TDiscountTableProps = {
	order: IEntityOrder;
};

export const DiscountTable = ({ order }: TDiscountTableProps): ReactElement => {
	const columns = useOrderDiscountsColumns(order);

	return (
		<UiTable<TTableData>
			data={order.lineItems}
			columns={columns}
			testId="discount"
			enablePagination
			meta={{
				isScrollable: true,
			}}
		/>
	);
};
