import { type ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { H1, Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import type { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { Headline } from 'js/components/molecules/Headline';
import { LinkButton } from 'js/components/atoms/Button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { customersConfig } from 'module/customers/customersConfig';
import type { IApiPartnerCustomerFilter, IEntityCustomer } from 'module/customers';
import { useApiPartnerCustomer, useApiUpdateCustomer } from 'module/customers/hooks/useApiCustomers';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { updateCustomerApiError } from 'module/customers/utils/apiError';
import { useApiErrorContext, useAuthContext, useOrderContext } from 'js/contexts';
import { useCustomerUrlParams, useGetCustomerLink } from 'module/customers/hooks';

type TData = IEntityCustomer;
type TQueryProps = TUseApiDetailProps<TData, IApiPartnerCustomerFilter>;

export const PageUpdate = (): ReactElement => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const { isGroupPartner, authCompanyId } = useAuthContext();
	const navigate = useNavigate();
	const { id, partnerId: urlPartnerId } = useCustomerUrlParams();
	const { orderState, setCustomer } = useOrderContext();
	const { setError } = useApiErrorContext();
	const getCustomerLink = useGetCustomerLink();
	const partnerId = isGroupPartner ? authCompanyId : urlPartnerId;

	const { mutateAsync: updateCustomer } = useApiUpdateCustomer();

	/**
	 * Setup query props/filter
	 * @type {TQueryProps}
	 */
	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: {
				salesforceId: id,
				partnerId: partnerId!,
			},
		}),
		[id, partnerId],
	);

	return (
		<PageDetailComponent<TData, IApiPartnerCustomerFilter>
			query={useApiPartnerCustomer}
			queryProps={queryProps}
			trNamespace={customersConfig.trNamespace}
			titleRender={getCustomerRenderName}
			detailLink={getCustomerLink.update(id, partnerId)}
		>
			{({ data }) => {
				const detailLink = getCustomerLink.detail(data.id, partnerId);
				return (
					<>
						<Headline
							className="align-items-center"
							rightContent={
								<LinkButton
									testId="back"
									size="sm"
									variant="outline-primary"
									to={detailLink}
									iconLeftFa={faChevronLeft}
								>
									{t('common:actions.back')}
								</LinkButton>
							}
						>
							<H1 type="h2">{getCustomerRenderName(data)}</H1>
						</Headline>

						<div className="section__content mt-3">
							<CustomerForm
								initialValues={data}
								isUpdate
								onCancel={() => {
									navigate(detailLink);
								}}
								partnerId={partnerId}
								onSubmit={async (request, { setSubmitting }) => {
									const response = await updateCustomer({
										data: request.customer,
										params: { partnerId: request.partnerId, salesforceCustomerId: id },
									}).catch((error) => {
										setError({ error, resolve: updateCustomerApiError });
									});

									setSubmitting(false);

									if (response?.data) {
										// Update in order instance
										if (orderState.customer?.id === response.data.id && orderState.partner?.id === request.partnerId) {
											setCustomer(response.data);
										}

										toast.success(<Toast>{t('page.update.success')}</Toast>);
										navigate(detailLink);
									}
								}}
							/>
						</div>
					</>
				);
			}}
		</PageDetailComponent>
	);
};
