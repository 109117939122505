import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_DETAIL, ACL_READ_LIST } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { PageDefault, PageDetail } from 'module/creditStatus/pages';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';

export const CreditStatusRoutes = () => {
	const { aclModule, authGuards } = creditStatusConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} authGuards={authGuards} />}>
				<Route index element={<PageDefault />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} authGuards={authGuards} />}>
				<Route path="detail/:id" element={<PageDetail />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
