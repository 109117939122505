import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TBillablePartyTextProps = {
	isBillable: boolean;
	className?: string;
};

export const BillablePartyText = (props: TBillablePartyTextProps) => {
	const { className, isBillable } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return (
		<div className={classNames('note-bottom', className, { 'note-muted': !isBillable })}>
			<p>{t(isBillable ? 'widget.common.billableParty' : 'widget.common.nonBillableParty')}</p>
		</div>
	);
};
