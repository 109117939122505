import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import type { IAsyncExportProps } from 'js/components/exports';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { TPartnerId } from 'types';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useApiDistributionPartnersExport } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import type { IAsyncExportDistributionPartnersRequest } from 'module/distributionPartners';
import { isNumber } from 'lodash';

type TExportAllDistributionPartnersButtonProps = {
	partnerId?: TPartnerId | null;
	isLoading?: boolean;
	isDisabled?: boolean;
};

export const ExportAllDistributionPartnersButton = (props: TExportAllDistributionPartnersButtonProps) => {
	const { partnerId, isLoading } = props;
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportDistributionPartnersRequest>>();
	const isDisabled = props.isDisabled || !partnerId;

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				disabled={isDisabled}
				loading={isLoading}
				onClick={() =>
					isNumber(partnerId) &&
					exportRef.current?.show({
						request: { partnerId },
						useQuery: useApiDistributionPartnersExport,
					})
				}
				testId="exportAllDistributionPartners"
			>
				{t('actions.exportAll')}
			</Button>
			<AsyncExportModal forwardedRef={exportRef} testId="exportAllDistributionPartners" />
		</>
	);
};
