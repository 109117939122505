import { memo, type PropsWithChildren, type ReactElement } from 'react';
import { FilterPlaceholder } from '../placeholder/FilterPlaceholder';

const _DefaultContainer = (props: PropsWithChildren<object>): ReactElement => (
	<>
		<FilterPlaceholder />
		<div className="container-fluid">{props.children}</div>
	</>
);

export const DefaultContainer = memo(_DefaultContainer);
