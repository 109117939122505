import { type ReactElement, useState } from 'react';
import type { IStandardOrderInstanceItem } from 'module/purchase';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Alert, Button } from '@avast/react-ui-components';
import { buildParametrizedRoute, isDefined } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { OrderTableRowDiscretionaryDiscount } from 'module/purchase/components/order/OrderTableRowDiscretionaryDiscount';
import { PricingMessage } from 'module/orders/components/PricingMessage';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import classNames from 'classnames';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { usePricingMetadata } from 'module/purchase/hooks/pricing/usePricingMetadata';
import { PricingBreakdownCols } from 'module/purchase/components/order/row/PricingBreakdownCols';
import { usePricingBreakdown } from 'module/purchase/hooks/usePricingBreakdown';
import { Link } from 'react-router';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useOrderContext } from 'js/contexts';
import { PromotionMessage } from 'module/orders/components/PromotionMessage';

type TProductsTableRowDetailProps = {
	row: IStandardOrderInstanceItem;
	index: number;
};

export const OrderTableRowDetail = (props: TProductsTableRowDetailProps): ReactElement => {
	const { row, index } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const [showPricingDetails, setShowPricingDetail] = useState(false);
	const {
		getItemPricing,
		isEndCustomerBillableParty,
		orderState: { isLocked },
		billableParty,
	} = useOrderContext();
	const pricingMetadata = usePricingMetadata();
	const { isGroupInternal } = useAuthContext();
	const pricing = getItemPricing(row.id);
	const isLoading = !pricing;
	const hasDiscountTab = isGroupInternal;
	const colSize = !isEndCustomerBillableParty && hasDiscountTab ? 4 : 6;
	const hasPricingMessage = isDefined(pricing?.pricingMessageLine);
	const pricingBreakdown = usePricingBreakdown(row);

	return (
		<>
			<Row multi>
				{isDefined(row.license) && row.license?.licenseKey && (
					<Col xs={12} className="pb-0">
						<Alert className="mb-0">
							<p className="fs-base">
								<strong>{t('common.previousLicense')}: </strong>
								<Link to={buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.license.id)}>
									{row.license.licenseKey}
								</Link>
							</p>
						</Alert>
					</Col>
				)}
				{hasDiscountTab && (
					<Col md={colSize} className="ps-4">
						<OrderTableRowDiscretionaryDiscount
							name={`items.${index}.discretionaryDiscount`}
							row={row}
							rowPricing={pricing}
							className="my-2"
						/>
					</Col>
				)}
				{pricingMetadata.hasPricingBreakdown && (
					<PricingBreakdownCols pricingBreakdown={pricingBreakdown} isLoading={isLoading} colSize={colSize} />
				)}
				{pricingMetadata.hasPricingBreakdown && hasPricingMessage && (
					<Col xs={12} className="pt-0">
						<div className="component__showMoreLine">
							<Button
								variant="link"
								iconRightFa={showPricingDetails ? faAngleUp : faAngleDown}
								size="sm"
								onClick={() => setShowPricingDetail((v) => !v)}
								testId={`pricingDetails_${index}`}
							>
								{t(`pricing.details.${showPricingDetails ? 'hide' : 'show'}`)}
							</Button>
						</div>
					</Col>
				)}
				{(showPricingDetails || !pricingMetadata.hasPricingBreakdown) && hasPricingMessage && (
					<Col xs={12} className={classNames('px-4 pb-4', { 'pt-0': !isLocked })}>
						<PricingMessage isLoading={isLoading} boxVariant="white" message={pricing?.pricingMessageLine} />
						<PromotionMessage
							caption="common.promoMessage"
							boxVariant="white"
							message={pricing?.pricingPromoMessage_Line}
							className="mt-3"
							currency={billableParty?.currencyCode}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};
