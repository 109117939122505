import { usePrevious } from './usePrevious';
import { isEqual } from 'lodash';

/**
 * Compares new value with previous value.
 */
export const useCompare = <P,>(val: P, deep = false, disabled = false) => {
	const prevVal = usePrevious<P | null>(disabled ? null : val);

	if (disabled) {
		return false;
	}

	return deep ? !isEqual(prevVal, val) : prevVal !== val;
};
