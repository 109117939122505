import { type ReactElement, useMemo, useState } from 'react';
import { ButtonComposition } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import type { IApiSortBy } from 'types/api';
import type { IEntityOrder, IOrderListApiFilter, IOrderListFilter } from 'module/orders';
import { ordersConfig } from 'module/orders/ordersConfig';
import { RouteName } from 'module/RouteName';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useApiQuoteList } from 'module/orders/hooks/useApiOrders';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { LinkButton } from 'js/components/atoms/Button';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { buildParametrizedRoute } from 'js/utils/common';
import { OrdersFilter } from 'module/orders/components/OrdersFilter';
import { ExportOrdersButton } from 'module/orders/components/buttons';
import type { IListMetaDataValues } from 'types/utils';
import { useQuoteListColumns } from 'module/orders/hooks';
import { useNavigate } from 'react-router';
import { SortDirectionEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityOrder;
type TTableDataFilter = IOrderListFilter;
type TTableDataApiFilter = IOrderListApiFilter;

export const PageQuoteList = (): ReactElement => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { isGroupPartner, authCompanyId, isAuthorizedBuyer } = useAuthContext();
	const navigate = useNavigate();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({});
	const columns = useQuoteListColumns();

	// Set manual (static) filter - not changeable
	const filterStatic = useMemo<TTableDataFilter>(
		() =>
			isGroupPartner
				? {
						partnerId: authCompanyId,
					}
				: {},
		[authCompanyId, isGroupPartner],
	);

	// Set default parameters
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	// Order
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'id', direction: SortDirectionEnum.DESC });

	return (
		<DefaultContainer>
			<FilterPlaceholderPortal>
				<OrdersFilter
					isQuote
					values={filter}
					onChange={(filter) => {
						setFilter(filter);
					}}
					controls={
						<ButtonComposition size="sm">
							<ExportOrdersButton isQuote listMetaData={listMetaData} />
							<ExportOrdersButton isQuote listMetaData={listMetaData} detailed />
							{isAuthorizedBuyer && (
								<LinkButton to={RouteName.PURCHASE.DEFAULT} size="sm" testId="createQuote">
									{t('actions.createQuote')}
								</LinkButton>
							)}
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>

			<ApiPaginatedListTable<TTableData, TTableDataFilter, TTableDataApiFilter>
				columns={columns}
				query={useApiQuoteList}
				sort={sort}
				useLocation
				filter={filter}
				filterStatic={filterStatic}
				table={{
					meta: {
						onRowClick: (row) => navigate(buildParametrizedRoute(RouteName.QUOTE.DETAIL, row.id)),
					},
					testId: 'quotes',
				}}
				onMetaDataChange={setListMetaData}
			/>
		</DefaultContainer>
	);
};
