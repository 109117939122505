import React, { type ReactElement, useMemo } from 'react';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import type { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import type { IApiCustomerFilter, IApiPartnerCustomerFilter, IEntityCustomer } from 'module/customers';
import { DetailContent, PartnerDetailContent } from 'module/customers/components';
import { customersConfig } from 'module/customers/customersConfig';
import { useApiCustomer, useApiPartnerCustomer } from 'module/customers/hooks/useApiCustomers';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { useCustomerUrlParams, useGetCustomerLink } from 'module/customers/hooks';

type TData = IEntityCustomer;
type TQueryProps = TUseApiDetailProps<TData, IApiCustomerFilter>;
type TPartnerQueryProps = TUseApiDetailProps<TData, IApiPartnerCustomerFilter>;

export const PagePartnerDetail = (): ReactElement => {
	const { id, partnerId } = useCustomerUrlParams();
	const getCustomerLink = useGetCustomerLink();

	const partnerQueryProps: TPartnerQueryProps = useMemo(
		() => ({
			filter: {
				partnerId: partnerId!,
				salesforceId: id,
			},
		}),
		[id, partnerId],
	);

	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: { salesforceId: id },
		}),
		[id],
	);

	return (
		<PageDetailComponent<TData, IApiPartnerCustomerFilter>
			query={useApiPartnerCustomer}
			queryProps={partnerQueryProps}
			trNamespace={customersConfig.trNamespace}
			titleRender={getCustomerRenderName}
			detailLink={getCustomerLink.detail(id, partnerId)}
			getErrorContent={() => (
				<PageDetailComponent<TData, IApiCustomerFilter>
					query={useApiCustomer}
					queryProps={queryProps}
					trNamespace={customersConfig.trNamespace}
					titleRender={getCustomerRenderName}
					detailLink={getCustomerLink.detail(id)}
				>
					<PartnerDetailContent partnerId={partnerId} />
				</PageDetailComponent>
			)}
		>
			<DetailContent partnerId={partnerId} />
		</PageDetailComponent>
	);
};
