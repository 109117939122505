import type {
	ILicenseListApiFilter,
	ILicenseListApiFilterDateRange,
	ILicenseListFilter,
	ILicenseListFilterBase,
} from 'module/licenses';
import { isEmpty, pick, trim } from 'lodash';
import { LicenseDateRangeTypeEnum, LicenseListApiChannelEnum, LicenseSearchKeyEnum } from 'module/licenses/enums';
import { dateToApiDate } from 'js/utils/dateTime';

interface ILicensesFilterNormalizer {
	preservedKeys: (keyof ILicenseListFilterBase)[];
	dateRangeRules: Record<
		LicenseDateRangeTypeEnum,
		[keyof ILicenseListApiFilterDateRange, keyof ILicenseListApiFilterDateRange]
	>;

	denormalize(filter: ILicenseListFilter): ILicenseListApiFilter;

	denormalizeDateRange(filter: ILicenseListFilter): ILicenseListApiFilterDateRange | undefined;

	denormalizeSearchTerm(filter: ILicenseListFilter): Partial<ILicenseListApiFilter> | undefined;

	excludeTestLicenses(apiFilter: ILicenseListApiFilter): boolean;
}

export const licensesFilterNormalizer: ILicensesFilterNormalizer = {
	preservedKeys: [
		'customerId',
		'partnerId',
		'distributionPartnerId',
		'fulfillment',
		'statuses',
		'origin',
		'standardOrderLineItemId',
	],

	dateRangeRules: {
		[LicenseDateRangeTypeEnum.CREATED_AT]: ['createdAtFrom', 'createdAtTo'],
		[LicenseDateRangeTypeEnum.EXPIRATION]: ['expirationFrom', 'expirationTo'],
		[LicenseDateRangeTypeEnum.REGISTERED_AT]: ['registeredAtFrom', 'registeredAtTo'],
	},

	denormalize(filter): ILicenseListApiFilter {
		const apiFilter: ILicenseListApiFilter = {
			detailed: true,
			channels: [],
			...pick(filter, this.preservedKeys),
			...this.denormalizeDateRange(filter),
			...this.denormalizeSearchTerm(filter),
		};

		if (!apiFilter.standardOrderId && !apiFilter.fulfillment) {
			apiFilter.channels.push(LicenseListApiChannelEnum.B2B);
		}

		if (this.excludeTestLicenses(apiFilter)) {
			apiFilter.test = false;
		}

		return apiFilter;
	},

	denormalizeDateRange(filter) {
		if (filter.dateRange && filter.dateRangeType && Object.hasOwn(this.dateRangeRules, filter.dateRangeType)) {
			const [from, to] = this.dateRangeRules[filter.dateRangeType];
			return {
				[`${from}`]: dateToApiDate(filter.dateRange[0]),
				[`${to}`]: dateToApiDate(filter.dateRange[1], true),
			};
		}

		return undefined;
	},

	denormalizeSearchTerm(filter) {
		const value = trim(filter.search?.value);

		if (!isEmpty(value)) {
			if (filter.search?.key === LicenseSearchKeyEnum.ORDER_NUMBER) {
				return { standardOrderId: value };
			}
		}

		return undefined;
	},

	excludeTestLicenses(apiFilter) {
		return (
			!apiFilter.partnerId &&
			!apiFilter.customerId &&
			!apiFilter.standardOrderId &&
			!apiFilter.standardOrderLineItemId &&
			!apiFilter.fulfillment
		);
	},
};
