import * as R from 'ramda';
import type {
	TEditWidgetPayload,
	TNormalizePositionKey,
	TPositionValueToNumber,
	TToPositionWithValuePair,
	TTypeOfWidgetSettingValue,
	TWidget,
	TWidgetActionPayload,
	TWidgetConfig,
	TWidgetCoords,
} from 'submodule/widgets';
import invariant from 'invariant';

/**
 * @param {string} name - Value, we want to check if is on the first array position.
 * @returns {boolean}
 * @example
 * 	isFirstValue( 'row' )(['row', 1]) => true;
 * 	isFirstValue( 'col' )(['row', 3]) => false;
 */
const isFirstValue: (name: string) => (list: [string, number]) => boolean = (name) => R.compose(R.equals(name), R.head);

/**
 * @example
 * 	isCorrectCoord(['rowIndex', 1]) => true;
 * 	isCorrectCoord(['satIndex', 4]) => false;
 */
const isCorrectCoord = R.either(isFirstValue('rowIndex'), isFirstValue('colIndex'));

/**
 * @example
 * 	positionValueToNumber( ['row', '1'] ) => [ 'row', 1 ];
 */
const positionValueToNumber: TPositionValueToNumber = ([key, value]) => [key, Number(value)];

/**
 * @example
 * 	normalizePositionKey( ['row', 1] ) => [ 'rowIndex', 1 ];
 */
const normalizePositionKey: TNormalizePositionKey = ([key, value]) => {
	const normalizedKey = `${key}Index` as 'rowIndex' | 'colIndex';
	return [normalizedKey, value];
};

/**
 * @example
 * 	positionAsKeyValuePair( 'row.1' ) => [ 'rowIndex', 1 ];
 * 	positionAsKeyValuePair( 'col.2' ) => [ 'colIndex', 2 ];
 */
const positionAsKeyValuePair: TToPositionWithValuePair = R.pipe(
	R.split('.'),
	R.take(2),
	positionValueToNumber,
	normalizePositionKey,
);

/**
 * Parses the ID which contains column and row position in widget grid.
 * The returned value is widget position as an object.
 * @example
 *	getPositionCoords( 'row.1' ) => { rowIndex: 1 };
 *	getPositionCoords( 'col.1' ) => { colIndex: 1 };
 *	getPositionCoords( 'row.1-col.1' ) => { rowIndex: 1, colIndex: 1 };
 */
export const getPositionCoords = R.pipe(
	R.split('-'),
	R.map(positionAsKeyValuePair),
	R.takeWhile(isCorrectCoord),
	R.fromPairs,
) as (input: string) => TWidgetCoords;

/**
 * Gets the ID for newly created widget.
 * @param {TWidgetGrid} rows
 * @returns {number} - New ID
 */
// @ts-ignore
export const getNextId = R.compose(R.inc, R.apply(Math.max), R.pluck('id'), R.flatten);

export const preSubmitWidget = (widgets: TWidgetConfig[], values: TWidget): TWidget => {
	const widget = widgets.find((widget) => widget.type === values.type);

	invariant(typeof widget !== 'undefined', `Widget with type ${values.type} is not defined`);

	const settingsKeys = R.keys(widget.getSettingsFields()) as Array<keyof typeof values.settings>;

	return {
		...values,
		settings: R.pick(settingsKeys, values.settings), // Eliminate settings for different widget type
	};
};

export const getWidgetSettingsValues = (item: TWidget, widgetConfig?: TWidgetConfig): string | null => {
	const { type, settings } = item;

	if (!widgetConfig) {
		console.warn(`Unknown widget type "${type}"`);
		return null;
	}

	const transformSettings: (settings: Record<string, unknown>) => string = R.pipe(
		// Convert the settings object to pairs [key, value]
		R.toPairs,

		// Map over each pair
		R.map(([key, value]: [string, unknown]) => {
			// Get the widget fields
			const fields = widgetConfig.getSettingsFields();
			// @ts-ignore
			const field = R.prop<TTypeOfWidgetSettingValue>(key, fields);

			if (R.isNil(field) || R.isNil(value)) {
				return null;
			}

			// @ts-ignore
			return `${R.prop('label', field)}: ${String(value)}`;
		}),

		// Filter-out the null values
		R.filter(Boolean),

		// Join the strings with ", "
		R.join(', '),
	);

	return transformSettings(settings);
};

/**
 * Type guard to check if payload is TEditWidgetPayload.
 * @param {TWidgetActionPayload} action
 * @returns {TEditWidgetPayload}
 */
export const isEditWidgetAction = (action: TWidgetActionPayload): action is TEditWidgetPayload =>
	Object.hasOwn(action, 'index');

export const exportedForTesting = {
	isFirstValue,
	isCorrectCoord,
	positionValueToNumber,
	normalizePositionKey,
	positionAsKeyValuePair,
};
