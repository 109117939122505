import { AffiliateConfigEnum } from 'module/partners/enums';
import { getApiAffiliateConfigProperty, postApiAffiliateConfigProperty } from 'module/partners/api/apiPartners';
import type { AxiosRequestConfig } from 'axios';
import type { IEntityPartner, TAffiliateConfigApiValue } from 'module/partners/index';
import { logDebug, logError } from 'js/utils/app';
import type { TLocale, TLocaleApi } from 'types/config';
import { localeCodeNormalizer } from 'i18n/normalizer';

type TGetAffiliateConfigPropOptions<UiType> = {
	affiliateId: IEntityPartner['id'];
	defaultValue?: UiType | null;
	apiConfig?: AxiosRequestConfig;
};

type TGetAffiliateConfigOptions<UiType> = TGetAffiliateConfigPropOptions<UiType> & {
	name: AffiliateConfigEnum;
};

const affiliateConfigValueType: Record<AffiliateConfigEnum, 'json' | 'string'> = {
	[AffiliateConfigEnum.HOME_WIDGET]: 'json',
	[AffiliateConfigEnum.LOCALE_FORMAT]: 'string',
};

const getAffiliateConfig = async <ApiType extends TAffiliateConfigApiValue, UiType = ApiType>(
	options: TGetAffiliateConfigOptions<UiType>,
): Promise<UiType | null> => {
	const { affiliateId, name, defaultValue, apiConfig } = options;
	const value: UiType | null = defaultValue ?? null;

	// Get from API
	const response = await getApiAffiliateConfigProperty<ApiType>(affiliateId, name, apiConfig).catch((error) => {
		if (error.response?.status === 404 && defaultValue) {
			const apiValue = affiliateConfigUiValueToApi<UiType>(name, defaultValue);
			if (apiValue) {
				logDebug('Save UI value to API', name, apiValue);
				postApiAffiliateConfigProperty(affiliateId, name, apiValue, apiConfig).catch((error) => {
					logError(error);
				});
			}
		}
	});

	if (!response) {
		return value;
	}

	return affiliateConfigApiValueToUi<UiType>(name, response.data.value);
};

export const getAffiliateConfig_LocaleFormat = async (
	options: TGetAffiliateConfigPropOptions<TLocale>,
): Promise<TLocale | null> => {
	const { defaultValue, ...rest } = options;
	const apiValue = await getAffiliateConfig<string, TLocaleApi>({
		name: AffiliateConfigEnum.LOCALE_FORMAT,
		defaultValue: localeCodeNormalizer.denormalize(defaultValue),
		...rest,
	});

	return localeCodeNormalizer.normalize(apiValue, true) || null;
};

export const affiliateConfigUiValueToApi = <UiType = TAffiliateConfigApiValue>(
	name: AffiliateConfigEnum,
	value: UiType,
): TAffiliateConfigApiValue => {
	const type = affiliateConfigValueType[name];
	switch (type) {
		case 'json':
			return JSON.stringify(value);
		case 'string':
			return String(value);
		default:
			logError(`Not supported affiliateConfigValue type: ${type}`);
			return JSON.stringify(value);
	}
};

export const affiliateConfigApiValueToUi = <UiType = TAffiliateConfigApiValue>(
	name: AffiliateConfigEnum,
	value: TAffiliateConfigApiValue,
): UiType => {
	const type = affiliateConfigValueType[name];
	switch (type) {
		case 'json':
			return JSON.parse(value);
		case 'string':
			return String(value) as UiType;
		default:
			logError(`Not supported UI type: ${type}`);
			return JSON.parse(value);
	}
};
