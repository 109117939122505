import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_CREATE, ACL_READ_DETAIL, ACL_UPDATE } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { PageCreate, PagePartnerDetail, PageUpdate } from 'module/customers/pages';
import { customersConfig } from 'module/customers/customersConfig';
import { customersRouteName } from 'module/customers/RouteName';
import type { IAuthGuards } from 'types';
import { PartnerRoleEnum } from 'module/partners/enums';

export const PartnerCustomerRoutes = () => {
	const { aclModule } = customersConfig;
	const routes = customersRouteName({ withoutPrefix: true });
	const authGuards: IAuthGuards = {
		roles: [PartnerRoleEnum.SALES_MANAGER, PartnerRoleEnum.SALES_OPERATIONS],
	};

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} authGuards={authGuards} />}>
				<Route path={routes.PARTNER_DETAIL} element={<PagePartnerDetail />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_UPDATE]} authGuards={authGuards} />}>
				<Route path={routes.PARTNER_UPDATE} element={<PageUpdate />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_CREATE]} authGuards={authGuards} />}>
				<Route path={routes.PARTNER_CREATE} element={<PageCreate />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
