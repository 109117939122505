import type {
	IApiError,
	IApiErrorResult,
	IAxiosApiError,
	TApiErrorList,
	TApiErrorResolve,
	TApiErrorResultMessages,
	TApiErrorWrapper,
} from 'types/api';
import { get, isUndefined } from 'lodash';
import i18n from 'i18next';
import { ApiErrorLevelEnum } from 'js/enums';

const extractServerError = (err: IAxiosApiError): IApiError | null => {
	const status = err.response?.status;

	// @see https://github.com/axios/axios/issues/383
	if (isUndefined(status)) {
		return {
			statusMessage: i18n.t('error:status.networkError'),
		};
	}

	return null;
};

export const extractApiError = (err: IAxiosApiError): IApiError | null => {
	if (err.response?.data) {
		return err.response?.data;
	}

	return extractServerError(err);
};

const apiErrorResolveViolations = (
	error: IApiError,
	errorList: TApiErrorList,
	removeDuplicates = false,
): TApiErrorResultMessages => {
	const messages: TApiErrorResultMessages = [];
	const errorCodes: string[] = [];

	if (!error.violations) {
		return messages;
	}

	for (const violation of error.violations) {
		if (violation.code) {
			const errorMessage = get(errorList, violation.code, null);
			if (errorMessage && !(removeDuplicates && errorCodes.includes(violation.code))) {
				messages.push(i18n.t(errorMessage));
				errorCodes.push(violation.code);
			}
		}
	}

	return messages;
};

export const apiErrorResolveError = (error: IApiError, errorList: TApiErrorList): TApiErrorResultMessages => {
	const messages: TApiErrorResultMessages = [];

	if (error.errorCode) {
		const errorCode = get(errorList, error.errorCode);
		if (errorCode) {
			messages.push(i18n.t(errorCode));
		}
	}

	messages.push(...apiErrorResolveViolations(error, errorList, true));
	return messages;
};

export const apiErrorResolve: TApiErrorResolve = () => ({
	level: ApiErrorLevelEnum.INFO,
	title: i18n.t('error:unspecifiedError'),
});

export const apiCriticalErrorResolve: TApiErrorResolve = () => ({
	level: ApiErrorLevelEnum.CRITICAL,
});

/**
 * Wrap error resolution - add request and error id to UI error
 * @param {IAxiosApiError} err
 * @param {TApiErrorResolve} resolver
 * @returns {TApiErrorResult}
 */
export const apiErrorWrapper: TApiErrorWrapper = (err, resolver) => {
	const response = extractApiError(err);
	if (!response) {
		return null;
	}

	const error = resolver(response);
	const messages: IApiErrorResult['messages'] = [];
	if (response.statusMessage) {
		messages.push(response.statusMessage);
	}
	if (error?.messages) {
		messages.push(...error.messages);
	} else {
		messages.push(i18n.t('error:common.repeatOrContactSales'));
	}

	return error
		? {
				errorCode: response.errorCode,
				requestId: response.requestId,
				...error,
				messages: messages.filter(Boolean),
			}
		: null;
};
