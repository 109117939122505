import type { IEntityChargeOrder } from 'js/chargeOrder';
import { orderEntityNormalizer } from 'module/orders/normalizer';
import { LineItemStatusEnum } from 'module/orders/enums';

export const chargeOrderEntityNormalizer = {
	normalize(entity: IEntityChargeOrder): IEntityChargeOrder {
		return {
			...entity,
			lineItems: (entity.lineItems || [])
				.filter((item) => item.status !== LineItemStatusEnum.INVALID)
				.map((item) => orderEntityNormalizer.normalizeItem(item, false)),
		};
	},
};
