import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash';
import { type TUseApiDynamicParamsMutationData, useApiDynamicParamsMutation } from 'js/hooks/api';
import type { TQueryConfig } from 'js/queries';
import type { IAxiosApiError } from 'types/api';
import { type TUseInvalidateQueriesKeys, useInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import type { ApiMutationTypeEnum } from 'js/enums';

type TUseApiDynamicParamsMutationResponse<Data extends {}, Response = Data, TErr = unknown> = UseMutationResult<
	AxiosResponse<Response>,
	TErr,
	Data
>;

type TUseApiDynamicParamsMutationProps<
	Data extends {},
	Response = Data,
	Params = Record<string, unknown>,
	TErr = IAxiosApiError,
> = {
	config?: TQueryConfig<Params>;
	queryConfig?: UseMutationOptions<AxiosResponse<Response>, TErr, Data>;
	invalidateQueries?: TUseInvalidateQueriesKeys;
};

export type TUseApiDynamicParamsMutationModule<
	Data extends {},
	DynamicParams extends {},
	Response = Data,
	StaticParams = object,
	TErr = IAxiosApiError,
> = (
	props?: TUseApiDynamicParamsMutationProps<
		TUseApiDynamicParamsMutationData<Data, DynamicParams>,
		Response,
		StaticParams,
		TErr
	>,
) => TUseApiDynamicParamsMutationResponse<TUseApiDynamicParamsMutationData<Data, DynamicParams>, Response, TErr>;

export const useApiDynamicParamsMutationQuery = <
	Data extends {},
	DynamicParams extends {},
	Response = Data,
	StaticParams extends {} = Record<string, unknown>,
	TErr = IAxiosApiError,
>(
	key: string,
	type: ApiMutationTypeEnum,
	props?: TUseApiDynamicParamsMutationProps<
		TUseApiDynamicParamsMutationData<Data, DynamicParams>,
		Response,
		StaticParams,
		TErr
	>,
	defaults?: TUseApiDynamicParamsMutationProps<
		TUseApiDynamicParamsMutationData<Data, DynamicParams>,
		Response,
		StaticParams,
		TErr
	>,
): TUseApiDynamicParamsMutationResponse<TUseApiDynamicParamsMutationData<Data, DynamicParams>, Response, TErr> => {
	const { config, queryConfig, invalidateQueries } = merge({}, defaults, props);
	const invalidateQueryCache = useInvalidateQueries(invalidateQueries);

	const mutationFn = useApiDynamicParamsMutation<Data, DynamicParams, Response, StaticParams>({
		key,
		type,
		config: {
			...config,
		},
	});

	return useMutation<AxiosResponse<Response>, TErr, TUseApiDynamicParamsMutationData<Data, DynamicParams>>({
		mutationFn,
		...queryConfig,
		onSettled(data, error, variables, context) {
			invalidateQueryCache();
			queryConfig?.onSettled?.(data, error, variables, context);
		},
	});
};
