import type { ReactElement } from 'react';
import { ButtonComposition } from '@avast/react-ui-components';
import { QuickFilterButton } from './QuickFilterButton';
import { useTranslation } from 'react-i18next';

type TQuickFilterAction<T extends object> = {
	caption: string;
	filter: T;
};

export type TQuickFiltersProps<T extends object> = {
	activeFilter: T;
	setFilter: (values: T) => void;
	caption?: string | null;
	actions: TQuickFilterAction<T>[];
};

export const QuickFilters = <Filter extends object>(props: TQuickFiltersProps<Filter>): ReactElement => {
	const { caption = 'common:filter.quickFilters' } = props;
	const { t } = useTranslation();

	return (
		<div className="component__quick-actions">
			{caption && (
				<div className="caption">
					<span className="form-label my-0 text-uppercase">{t(caption)}:</span>
				</div>
			)}
			<div className="actions">
				<ButtonComposition size="xs" marginY wrap>
					{props.actions.map((action) => (
						<QuickFilterButton<Filter>
							filter={props.activeFilter}
							key={action.caption}
							values={action.filter}
							onClick={props.setFilter}
						>
							{action.caption}
						</QuickFilterButton>
					))}
				</ButtonComposition>
			</div>
		</div>
	);
};
