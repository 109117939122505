import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import type {
	IAsyncExportClosingBalanceRequest,
	IClosingBalanceListApiFilter,
	IClosingBalanceListFilter,
	IEntityClosingBalance,
	IEntityClosingBalanceLicenses,
	IEntityClosingBalanceLicensesFilter,
	IEntityOmsClosingBalance,
} from 'module/closingBalance';
import type { TDetailArguments } from 'types';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { closingBalanceEntityNormalizer, closingBalanceFilterNormalizer } from 'module/closingBalance/normalizer';
import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import type { IApiPaginatedListResponse } from 'types/api';
import { ApiMutationTypeEnum } from 'js/enums';
import type { IAsyncJob } from 'js/asyncJobs';

const API_CLOSING_BALANCE_KEYS = {
	LIST: 'closing-balance',
	DETAIL: 'closing-balance/:id',
	DETAIL_LICENSES: 'closing-balance/:id/licenses',
	EXPORT: 'export/closingBalances',
};

export const useApiClosingBalanceList: TUseApiPaginatedListModule<
	IEntityClosingBalance,
	IClosingBalanceListFilter,
	IClosingBalanceListApiFilter
> = (props) =>
	useApiPaginatedListQuery(API_CLOSING_BALANCE_KEYS.LIST, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IApiPaginatedListResponse<IEntityOmsClosingBalance>>) => ({
					...response,
					data: {
						...response.data,
						items: closingBalanceEntityNormalizer.normalizeList(response.data.items),
					},
				}),
				[],
			),
		},
		apiFilterNormalizer: (filter) => closingBalanceFilterNormalizer.denormalize(filter),
	});

export const useApiClosingBalance: TUseApiDetailModule<
	IEntityClosingBalance,
	TDetailArguments,
	IEntityOmsClosingBalance
> = (props) =>
	useApiDetailQuery(API_CLOSING_BALANCE_KEYS.DETAIL, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IEntityOmsClosingBalance>) => ({
					...response,
					data: closingBalanceEntityNormalizer.normalize(response.data),
				}),
				[],
			),
		},
	});

export const useApiClosingBalanceLicenses: TUseApiPaginatedListModule<
	IEntityClosingBalanceLicenses,
	IEntityClosingBalanceLicensesFilter
> = (props) => useApiPaginatedListQuery(API_CLOSING_BALANCE_KEYS.DETAIL_LICENSES, props);

export const useApiClosingBalanceExport: TUseApiMutationModule<IAsyncExportClosingBalanceRequest, IAsyncJob> = (
	props,
) => useApiMutationQuery(API_CLOSING_BALANCE_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
