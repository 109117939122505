import { Route, Routes } from 'react-router';
import { PageLogin, PageLogout } from 'module/security/pages';
import { DefaultLayout } from 'module/security/layouts/DefaultLayout';
import { Error404 } from 'module/error/page/Error404';

export const SecurityRoutes = () => (
	<Routes>
		<Route path="login" element={<PageLogin />} />
		<Route path="logout" element={<PageLogout />} />
		{/*
		Not used by OMS, but it will be in Empower instance
		<Route element={<NoAuthRoute />}>
			<Route path="password-reset" element={<PagePasswordReset />} />
			<Route path="password-reset/:token" element={<PageNewPassword />} />
		</Route>
		*/}
		<Route element={<DefaultLayout />}>
			<Route path="*" element={<Error404 />} />
		</Route>
	</Routes>
);
