import type { TWidget } from 'submodule/widgets';
import type { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';
import { buildColumnVisibility } from 'submodule/widgets/utils';
import { useAllowedWidgets } from 'submodule/widgets/hooks/useAllowedWidgets';

export const useWidgetColumnVisibility = (item: TWidget): VisibilityState | undefined => {
	const widgets = useAllowedWidgets();
	return buildColumnVisibility(
		widgets.find((widget) => widget.type === item.type),
		item.columns,
	);
};
