import type React from 'react';
import type { IApiFlexibleBillingDetailFilter, IEntityFlexibleBillingDetail } from 'module/flexibleBilling';
import { useApiFlexibleBillingDetail, useFlexibleBillingOverviewColumns } from 'module/flexibleBilling/hooks';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { FLEXIBLE_BILLING_PRODUCT_COUNT } from 'module/flexibleBilling/constants';

type TTableData = IEntityFlexibleBillingDetail;
type TTableFilter = IApiFlexibleBillingDetailFilter;

type TOverviewTableProps = {
	currency: string;
	filter: TTableFilter;
};

export const OverviewTable: React.FC<TOverviewTableProps> = (props) => {
	const columns = useFlexibleBillingOverviewColumns(props.currency);

	return (
		<ApiListTable<TTableData, TTableFilter>
			query={useApiFlexibleBillingDetail}
			columns={columns}
			filter={props.filter}
			table={{
				enablePagination: false,
				enableSorting: false,
				meta: {
					rowCount: FLEXIBLE_BILLING_PRODUCT_COUNT,
				},
			}}
		/>
	);
};
