import React, { type PropsWithChildren, type ReactElement, useCallback, useState } from 'react';
import { HelpStepEnum, type HelpVideoEnum } from 'js/enums';
import invariant from 'invariant';

export interface IHelpContext {
	step: HelpStepEnum;
	setStep: (value: HelpStepEnum) => void;
	activeVideoKey?: HelpVideoEnum;
	setVideo: (value: HelpVideoEnum) => void;
}

/**
 * Create context
 */
const HelpContext = React.createContext<IHelpContext | null>(null);
HelpContext.displayName = 'HelpContext';

/**
 * Hook to get order context
 */
export const useHelpContext = () => {
	const context = React.useContext(HelpContext);

	invariant(
		context !== null,
		'Help context is undefined, please verify you are calling useHelpContext() as child of a <HelpContextProvider> component.',
	);

	return context;
};

export const HelpContextProvider = (props: PropsWithChildren): ReactElement => {
	const [step, setStep] = useState<HelpStepEnum>(HelpStepEnum.HOME);
	const [activeVideoKey, setActiveVideoKey] = useState<HelpVideoEnum | undefined>(undefined);

	const setVideo = useCallback((key: HelpVideoEnum) => {
		setStep(HelpStepEnum.VIDEOS);
		setActiveVideoKey(key);
	}, []);

	const value: IHelpContext = {
		step,
		setStep,
		activeVideoKey,
		setVideo,
	};

	return (
		<HelpContext.Provider value={value}>
			<div className="component__help component__help-bg" data-step={step}>
				{props.children}
			</div>
		</HelpContext.Provider>
	);
};
