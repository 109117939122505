import { FormikControl } from 'js/components/formik/FormikControl';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useFormikContext } from 'formik';
import { FormControl, InputNumber } from '@avast/react-ui-components';
import { useLicenseOperationUnitTotal } from 'module/licenses/hooks/licenseOperation';
import {
	getLicenseOperationEntities,
	isDecreaseOperation,
	isIncreaseOperation,
} from 'module/licenses/utils/licenseOperation';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import type { ILicenseOperationForm } from 'module/licenses';
import { MarketSegmentEnum } from 'js/enums';
import { getMaxProductQuantity } from 'js/utils/common';

export const IncreaseAndDecrease = () => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { licenseProductGroupIsBusiness: isBusinessProduct, licenseProductOperations } = useLicenseOperationContext();
	const { values } = useFormikContext<ILicenseOperationForm>();
	const { licenseOperation, productGroupCode, unit } = values;
	const { productGroup } = getLicenseOperationEntities(licenseProductOperations, licenseOperation, productGroupCode);
	const unitTotal = useLicenseOperationUnitTotal();

	// Component won't work without selected product group
	if (!productGroup) {
		return null;
	}

	// Common values
	const isIncrease = isIncreaseOperation(licenseOperation);
	const isDecrease = isDecreaseOperation(licenseOperation);
	const productGroupUnitType = t(`common:entity.unitType.${productGroup.unitType}`, { count: 0 });

	// Only for business increase and decrease operation
	if (!isBusinessProduct || !(isIncrease || isDecrease)) {
		return null;
	}

	const maxQuantity = getMaxProductQuantity({
		marketSegment: MarketSegmentEnum.BUSINESS,
		groupCode: productGroup.code,
		isFirstPurchase: false,
		currentQuantity: unit,
	});

	return (
		<Row className="mt-4" size="sm">
			<Col md={2} sm={3}>
				<FormikControl label={t('unit.current', { type: productGroupUnitType })}>
					<FormikControl.Number name="unit" readOnly size="sm" hideControls />
				</FormikControl>
			</Col>
			<Col lg={3} sm={4}>
				{isIncrease && (
					<FormikControl label={t('unit.toIncrease', { type: productGroupUnitType })}>
						<FormikControl.Number size="sm" name="unitToIncrease" min={1} max={maxQuantity} required />
					</FormikControl>
				)}
				{isDecrease && (
					<FormikControl label={t('unit.toDecrease', { type: productGroupUnitType })}>
						<FormikControl.Number size="sm" name="unitToDecrease" min={1} max={unit - 1} required />
					</FormikControl>
				)}
			</Col>
			<Col md={2} sm={3}>
				<FormControl label={t('unit.total', { type: productGroupUnitType })}>
					<InputNumber name="unitTotal" size="sm" readOnly value={unitTotal} hideControls />
				</FormControl>
			</Col>
		</Row>
	);
};
