import { isEmpty, isNumber, pick, trim } from 'lodash';
import { dateToApiDate } from 'js/utils/dateTime';
import type {
	IClosingBalanceListApiFilter,
	IClosingBalanceListApiFilterDateRange,
	IClosingBalanceListBaseFilter,
	IClosingBalanceListFilter,
} from 'module/closingBalance';
import { ClosingBalanceSearchKeyEnum } from 'module/closingBalance/enums';

interface IClosingBalanceFilterNormalizer {
	preservedKeys: (keyof IClosingBalanceListBaseFilter)[];

	denormalize(filter?: IClosingBalanceListFilter): IClosingBalanceListApiFilter;

	denormalizeDateRange(filter: IClosingBalanceListFilter): IClosingBalanceListApiFilterDateRange | undefined;

	denormalizeSearchTerm(filter: IClosingBalanceListFilter): Partial<IClosingBalanceListApiFilter> | undefined;
}

export const closingBalanceFilterNormalizer: IClosingBalanceFilterNormalizer = {
	preservedKeys: ['billingStatus', 'paymentStatus', 'periodActivityId', 'chargeOrderId'],

	denormalize(filter): IClosingBalanceListApiFilter {
		if (!filter) {
			return {};
		}

		return {
			...pick(filter, this.preservedKeys),
			...this.denormalizeDateRange(filter),
			...this.denormalizeSearchTerm(filter),
			partnerIds: isNumber(filter.partnerId) ? [filter.partnerId] : null,
		};
	},

	denormalizeDateRange(filter) {
		if (filter.dateRange) {
			return {
				periodEndDateFrom: dateToApiDate(filter.dateRange[0]),
				periodEndDateTo: dateToApiDate(filter.dateRange[1], true),
			};
		}

		return undefined;
	},

	denormalizeSearchTerm(filter) {
		const value = trim(filter.search?.value);
		if (!isEmpty(value)) {
			if (filter.search?.key === ClosingBalanceSearchKeyEnum.ORDER_NUMBER) {
				return { id: value };
			}
		}

		return undefined;
	},
};
