import type { TOrderTablePagination } from 'module/purchase/hooks/order/useOrderTablePagination';
import { Trans, useTranslation } from 'react-i18next';
import { Pagination, useUiLocaleContext } from '@avast/react-ui-components';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TOrderTablePaginationProps = {
	tablePagination: TOrderTablePagination;
};

export const OrderTablePagination = (props: TOrderTablePaginationProps) => {
	const { tablePagination } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { format } = useUiLocaleContext();

	if (!tablePagination.isPaginated) {
		return null;
	}

	return (
		<div className="d-flex justify-content-between align-items-center p-2">
			<div className="mr-3">
				<Trans
					t={t}
					i18nKey={'common.paginationText'}
					values={{
						total: format.number(tablePagination.total, { thousandSeparated: true }),
						from: format.number(tablePagination.range[0], { thousandSeparated: true }),
						to: format.number(tablePagination.range[1], { thousandSeparated: true }),
					}}
				/>
			</div>
			<Pagination
				variant="full"
				total={tablePagination.pageCount}
				active={tablePagination.page}
				onChange={tablePagination.setPage}
			/>
		</div>
	);
};
