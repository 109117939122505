import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { creditTransactionSourceEnumUtils } from 'module/creditStatus/enums';
import { numberFormatter } from 'js/utils/number';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import type { IEntityCreditTransaction } from 'module/creditStatus';
import type { TableColumnsDef } from 'types';

type TTableData = IEntityCreditTransaction;

export const useCreditTransactionColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(creditStatusConfig.trNamespace);
	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.date('createdAt', { header: t('entityTransaction.createdAt') }),
			columnHelper.text('source', {
				header: t('entityTransaction.source'),
				meta: { formatters: [creditTransactionSourceEnumUtils.getText] },
			}),
			columnHelper.text('orderId', {
				header: t('entityTransaction.orderId'),
				meta: { defaultValue: t('common:_.NA') },
			}),
			columnHelper.number('amount', {
				header: t('entityTransaction.amount'),
				meta: {
					formatters: [
						(_, { row }) => {
							const value = row.original.amount;
							const formattedValue = numberFormatter.format(Math.abs(value));

							if (value > 0) {
								return <span className="text-success">+ {formattedValue}</span>;
							}
							if (value < 0) {
								return <span className="text-danger">- {formattedValue}</span>;
							}
							return formattedValue;
						},
						formatter.bold,
					],
				},
			}),
			columnHelper.text('currency', { header: t('common:entity.currency') }),
		];
	}, [t]);
};
