import { type ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { useApiRegistrationStart } from 'module/registration/hooks/useApiRegistration';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { RouteName } from 'module/RouteName';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Formik } from 'formik';
import { SubmitButton } from 'js/components/atoms/Button';
import { useInitRegistration } from 'module/registration/hooks/useInitRegistration';
import { useNavigate } from 'react-router';
import { RegistrationStepEnum } from 'module/registration/enums';

const welcomeTrPrefix = 'page.welcome';

export const InitRegistration = (): ReactElement => {
	const { t } = useTranslation(registrationConfig.trNamespace);
	const { partnerId, setStep } = useRegistrationContext();
	const navigate = useNavigate();
	const { handler: initRegistrationProcess, isLoading } = useInitRegistration();
	const { mutate: startRegistrationProcess } = useApiRegistrationStart({ config: { params: { partnerId } } });
	const conditionList = t(`${welcomeTrPrefix}.conditionList`, { returnObjects: true });

	useEffect(() => {
		initRegistrationProcess();
	}, [initRegistrationProcess]);

	return (
		<Formik<object>
			initialValues={{}}
			onSubmit={() => {
				startRegistrationProcess(
					{},
					{
						onSuccess() {
							setStep(RegistrationStepEnum.TERMS);
						},
						onError() {
							toast.error(<Toast>{t('error.unableToStart')}</Toast>);
							navigate(RouteName.SECURITY.LOGOUT);
						},
					},
				);
			}}
		>
			<FormikForm testId="initRegistrationForm">
				<h1 className="mb-3">{t(`${welcomeTrPrefix}.title`)}</h1>
				<div>
					<p>{t(`${welcomeTrPrefix}.content`)}</p>
					{Array.isArray(conditionList) && (
						<ul>
							{conditionList.map((text) => (
								<li key={text}>{text}</li>
							))}
						</ul>
					)}
				</div>
				<hr className="mb-4 mt-8" />
				<p>
					<SubmitButton loading={isLoading}>{t('common.continue')}</SubmitButton>
				</p>
			</FormikForm>
		</Formik>
	);
};
