import { useOrderContext } from 'js/contexts';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useSelectPartyTranslation } from '../hooks';
import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import { Modal } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartyModalFooter } from '../common';
import type { TSelectPartyModalContentProps } from './types';
import type { IEntityDistributionPartner } from 'module/distributionPartners';
import { SelectDistributionPartnerCard } from './SelectDistributionPartnerCard';

export const SelectDistributionPartnerModalContent = (
	props: TSelectPartyModalContentProps<IEntityDistributionPartner>,
) => {
	const { onPrev } = props;
	const { orderState } = useOrderContext();
	const licenseOperationContext = useLicenseOperationContext();
	const { tParty } = useSelectPartyTranslation(SelectPartyEnum.DISTRIBUTION_PARTNER);

	return (
		<>
			<Modal.Header>{tParty('title')}</Modal.Header>
			<Modal.Body className="pt-lg-3 pb-lg-4">
				<Row>
					<Col sm={6}>
						<SelectDistributionPartnerCard
							{...props}
							option={SelectPartyOptionEnum.LICENSE}
							distributionPartner={licenseOperationContext.distributionPartner}
						/>
					</Col>
					<Col sm={6}>
						<SelectDistributionPartnerCard
							{...props}
							option={SelectPartyOptionEnum.ORDER}
							distributionPartner={orderState.distributionPartner}
						/>
					</Col>
				</Row>
			</Modal.Body>
			<SelectPartyModalFooter onClick={onPrev} />
		</>
	);
};
