import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useApiAffiliateConfigUpdate } from 'module/partners/hooks/useApiPartners';
import { logError } from 'js/utils/app';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { localeCodeNormalizer } from 'i18n/normalizer';
import { AffiliateConfigEnum } from 'module/partners/enums';
import type { ILocaleConfig } from 'types/config';
import { LOCALE } from 'config/locale';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

export const useChangeLocaleFormat = () => {
	const { t } = useTranslation('submodules');
	const { authPartner, setLocaleFormat } = useAuthContext();
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();

	const { mutateAsync: updateConfig } = useApiAffiliateConfigUpdate({
		config: { params: { id: authPartner?.id, name: AffiliateConfigEnum.LOCALE_FORMAT } },
	});

	return useCallback(
		async (config: ILocaleConfig): Promise<boolean> => {
			loadingModalRef.current?.show({
				title: t('locale.format.saving'),
				ellipsis: true,
			});

			// Save to OMS
			const response = await updateConfig({
				value: localeCodeNormalizer.denormalize(config.locale) || LOCALE.DEFAULT_LOCALE,
			}).catch((error) => {
				logError('Unable to change locale format', error);
				setError({ error, resolve: apiCriticalErrorResolve });
			});

			// Break on an error
			if (!response) {
				loadingModalRef.current?.hide();
				return false;
			}

			// Update Auth partner
			setLocaleFormat(config.locale);

			loadingModalRef.current?.hide();
			toast.success(<Toast>{t('locale.format.success')}</Toast>);
			return true;
		},
		[t, updateConfig, loadingModalRef, setError, setLocaleFormat],
	);
};
