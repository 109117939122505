import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IEntityClosingBalance } from 'module/closingBalance';
import { noValuePlaceholder } from 'js/utils/app';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { paymentStatusEnumUtils } from 'js/enums';
import type { TableColumnsDef } from 'types';
import type { TWidgetColumns } from 'submodule/widgets';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityClosingBalance;

export const useClosingBalanceWidgetColumns = (): TWidgetColumns<TTableData> => {
	const { t } = useTranslation(closingBalanceConfig.trNamespace);
	const { isGroupInternal } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		const columns: TableColumnsDef<TTableData> = [];

		if (isGroupInternal) {
			columns.push(
				columnHelper.ellipsis(
					'billableParty.name',
					{
						header: t('common:entity.company'),
						id: 'billableParty_name',
					},
					{ width: 200 },
				),
			);
		}

		columns.push(
			columnHelper.link(
				'periodEndDate',
				{
					header: t('common:entity.period'),
					meta: {
						formatters: [formatter.date('MONTH_YEAR'), formatter.bold],
					},
				},
				{
					to: (row) => buildParametrizedRoute(RouteName.CLOSING_BALANCE.DETAIL, row.id),
				},
			),
			columnHelper.money('totalAmountWithoutTax', { header: t('entity.amountWOTax') }, { currencyKey: 'currency' }),
			columnHelper.money(
				'totalAmountWithTax',
				{
					header: t('entity.amountWTax'),
					meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
				},
				{ currencyKey: 'currency' },
			),
			columnHelper.date('payment.dueDate', {
				header: t('common:dates.paymentDue'),
				id: 'payment_dueDate',
				meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
			}),
			columnHelper.text('payment.status', {
				header: t('common:entity.paymentStatus'),
				id: 'payment_status',
				meta: { formatters: [paymentStatusEnumUtils.getText] },
			}),
		);

		return {
			columns,
			columnVisibility: { payment_dueDate: false, payment_status: false },
		};
	}, [isGroupInternal, t]);
};
