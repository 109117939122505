import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_CREATE, ACL_READ_LIST } from 'config/acl';
import { RetailListLayout } from 'module/retail/layouts/RetailListLayout';
import { PageGenerate, PageList, PageRetailLicenses } from 'module/retail/pages';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { retailConfig } from 'module/retail/retailConfig';

export const RetailRoutes = () => {
	const { aclModule, generateAuthGuards } = retailConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} />}>
				<Route element={<RetailListLayout />}>
					<Route index element={<PageList />} />
					<Route path="licenses" element={<PageRetailLicenses />} />
				</Route>
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_CREATE]} authGuards={generateAuthGuards} />}>
				<Route path="generate" element={<PageGenerate />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
