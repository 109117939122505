import type { ColumnDef } from '@tanstack/react-table';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	type IUiTableProps,
	type TCardProps,
	UiTable,
} from '@avast/react-ui-components';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type TCardTableProps<T extends object> = TCardProps & {
	columns: ColumnDef<T>[];
	data: T[];
	caption: ReactNode;
	onViewMore: () => void;
	tableProps?: Partial<IUiTableProps<T>>;
};

const CardTable = <T extends object>(props: TCardTableProps<T>) => {
	const { columns, data, caption, tableProps, onViewMore, ...cardProps } = props;
	const { t } = useTranslation();

	return (
		<Card {...cardProps}>
			<CardHeader bottomBorder={false}>
				<div className="row justify-content-between align-items-center">
					<div className="col-auto">{caption}</div>
					<div className="col-auto ms-auto">
						<Button variant="outline-primary" size="xs" onClick={onViewMore} name="viewMore">
							{t('components:common.more')}
						</Button>
					</div>
				</div>
			</CardHeader>
			<CardBody noSpacing className="card-body--table">
				<UiTable<T> data={data} columns={columns} {...tableProps} />
			</CardBody>
		</Card>
	);
};

export { CardTable };
