import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { retailConfig } from 'module/retail/retailConfig';
import { API_RETAIL_KEYS, useApiRetailList } from 'module/retail/hooks/useApiRetail';
import { RetailFilter } from 'module/retail/components/RetailFilter';
import { Can } from 'js/components/molecules/Can';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import type {
	IAsyncRetailRequestLicensesExport,
	IEntityRetailRequest,
	IRetailRequestListApiFilter,
	IRetailRequestListFilter,
} from 'module/retail';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IAsyncExportProps } from 'js/components/exports';
import { Button, ButtonComposition } from '@avast/react-ui-components';
import { useRefetchQueries } from 'js/hooks/useRefetchQueries';
import { useRetailListColumns } from 'module/retail/hooks';
import { SortDirectionEnum } from 'js/enums';

type TTableData = IEntityRetailRequest;
type TTableDataFilter = IRetailRequestListFilter;
type TTableDataApiFilter = IRetailRequestListApiFilter;

export const PageList = (): ReactElement => {
	const { t } = useTranslation(retailConfig.trNamespace);
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncRetailRequestLicensesExport>>();
	const refetchQueries = useRefetchQueries();

	// Define columns
	const columns = useRetailListColumns(exportRef);

	return (
		<>
			<DynamicPageTitleItem text={t('page.list')} />
			<DynamicBreadcrumbsItem href={RouteName.RETAIL.LIST}>{t('page.list')}</DynamicBreadcrumbsItem>

			<FilterPlaceholderPortal>
				<RetailFilter
					values={filter}
					onChange={setFilter}
					controls={
						<ButtonComposition size="sm" justify="end">
							<Button variant="outline-primary" size="sm" onClick={() => refetchQueries([API_RETAIL_KEYS.LIST])}>
								{t('actions.refreshListItems')}
							</Button>
							<Can do={retailConfig.aclModule} create authGuards={retailConfig.generateAuthGuards}>
								<LinkButton to={RouteName.RETAIL.GENERATE} size="sm">
									{t('actions.generate')}
								</LinkButton>
							</Can>
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>
			<ApiPaginatedListTable<TTableData, TTableDataFilter, TTableDataApiFilter>
				columns={columns}
				query={useApiRetailList}
				filter={filter}
				sort={{ key: 'transactionDate', direction: SortDirectionEnum.DESC }}
				useLocation
			/>

			<AsyncExportModal forwardedRef={exportRef} testId="exportRetailRequests" />
		</>
	);
};
