import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { cancelStandardOrderApiError } from 'module/orders/utils/apiError';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { API_ORDERS_KEYS, useApiOrderCancel } from 'module/orders/hooks/useApiOrders';
import type { IEntityOrder } from 'module/orders';
import { useApiErrorContext, useAppContext, usePageDetailContext } from 'js/contexts';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';

export const useCancelAction = () => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { confirmationModalRef, loadingModalRef } = useAppContext();
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const invalidateQueries = useAsyncInvalidateQueries([API_ORDERS_KEYS.DETAIL, API_ORDERS_KEYS.LIST]);

	const { mutate: cancelOrder } = useApiOrderCancel({
		config: {
			params: { id: order.id },
		},
	});

	const onCancel = useCallback(async () => {
		if (
			await confirmationModalRef.current?.show({
				title: t('cancelOrder.title'),
				messages: [t('cancelOrder.message')],
				submitButtonText: t('common:actions.confirm'),
			})
		) {
			loadingModalRef.current?.show({
				title: t('cancelOrder.loading', { orderNumber: order.id }),
			});

			cancelOrder(
				{},
				{
					async onSuccess(response) {
						if (!response?.data) {
							toast.error(<Toast>{t('cancelOrder.error')}</Toast>);
							loadingModalRef.current?.hide();
							return;
						}

						await invalidateQueries();
						toast.success(<Toast>{t('cancelOrder.success')}</Toast>);
						loadingModalRef.current?.hide();
					},
					onError(error) {
						setError({ error, resolve: cancelStandardOrderApiError });
						loadingModalRef.current?.hide();
					},
				},
			);
		}
	}, [cancelOrder, confirmationModalRef, loadingModalRef, order.id, setError, t, invalidateQueries]);

	return { onCancel };
};
