import { Component, type ReactNode, type RefObject, useRef } from 'react';
import type { ITestId, TModalProps } from '@avast/react-ui-components';
import { Modal } from '@avast/react-ui-components';
import { isFunction } from 'lodash';

type TAsyncModalResolve<ResolveState = number> = (state: ResolveState | null) => void;
type TAsyncModalReject = (reason?: unknown) => void;
type TModalPropsForAsync = Omit<TModalProps, 'show'>;

// Helper types based on whether ChildrenProps is provided
type TChildrenType<ChildrenProps> = ChildrenProps extends undefined
	? ReactNode
	: ReactNode | ((props: ChildrenProps) => ReactNode);

type TPropsWithChildren = {
	show: boolean;
	modalProps?: TModalPropsForAsync;
};

type TPropsWithChildrenFunction<ChildrenProps> = {
	show: boolean;
	childrenProps: ChildrenProps;
	modalProps?: TModalPropsForAsync;
};

type TAsyncModalProps<ChildrenProps> = TModalPropsForAsync & {
	children: TChildrenType<ChildrenProps>;
};

type TAsyncModalState<ChildrenProps> = ChildrenProps extends undefined
	? TPropsWithChildren
	: TPropsWithChildrenFunction<ChildrenProps>;

export type TAsyncModalRef<ChildrenProps = undefined, ResolveState = number> = RefObject<
	AsyncModal<ChildrenProps, ResolveState>
>;

export type TAsyncModalContainerProps<ChildrenProps = undefined, ResolveState = number> = {
	forwardedRef: TAsyncModalRef<ChildrenProps, ResolveState>;
} & ITestId;

export const useAsyncModalRef = <ChildrenProps = undefined, ResolveState = number>() => {
	return useRef<AsyncModal<ChildrenProps, ResolveState>>(null);
};

function hasChildrenProps<ChildrenProps>(
	state: TPropsWithChildren | TPropsWithChildrenFunction<ChildrenProps>,
): state is TPropsWithChildrenFunction<ChildrenProps> {
	return 'childrenProps' in state;
}

// TODO: Throws an error
/**
 * Constructor for Async Modal component.
 * -	Set generic type only when you want to provide it to a children function
 *
 * @throws
 * @example
 * // without generic type:
 * <AsyncModal ref={forwardedRef}>
 * 	</ChildrenElement>
 * </AsyncModal>
 *
 * // with generic type
 * <AsyncModal<{length: number}> ref={forwardedRef}>
 * 	{({length}}) => {
 * 		</ChildrenElement count={length}>
 * 	}}
 * </AsyncModal>
 */
export class AsyncModal<ChildrenProps = undefined, ResolveState = number> extends Component<
	TAsyncModalProps<ChildrenProps>,
	TAsyncModalState<ChildrenProps>
> {
	state = {
		show: false,
		modalProps: {},
	} as TAsyncModalState<ChildrenProps>;

	promiseInfo: {
		resolve?: TAsyncModalResolve<ResolveState>;
		reject?: TAsyncModalReject;
	} = {};

	updateModalProps(modalProps: TModalPropsForAsync) {
		this.setState({ modalProps });
	}

	async show(childrenProps?: ChildrenProps, modalProps?: TModalPropsForAsync) {
		return new Promise<ResolveState | null>((resolve, reject) => {
			this.promiseInfo = {
				resolve,
				reject,
			};

			// Use type assertion to handle both state types
			const newState = {
				show: true,
				modalProps,
				...(childrenProps !== undefined ? { childrenProps } : {}),
			} as TAsyncModalState<ChildrenProps>;

			this.setState(newState);
		});
	}

	hide() {
		this.setState({
			show: false,
		});
	}

	isOpen(): boolean {
		return this.state.show;
	}

	onSuccess(state: ResolveState): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(state);
	}

	onCancel(): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(null);
	}

	getResolve(): TAsyncModalResolve<ResolveState> {
		const { resolve } = this.promiseInfo;
		return (result) => {
			resolve?.(result);
			this.hide();
		};
	}

	getReject(): TAsyncModalReject {
		const { reject } = this.promiseInfo;
		return (err) => {
			reject?.(err);
			this.hide();
		};
	}

	render() {
		const { children, onHide, ...props } = this.props;
		const { show, modalProps } = this.state;

		const renderChildren = () => {
			if (!show) return null;

			if (isFunction(children)) {
				if (hasChildrenProps(this.state)) {
					return children(this.state.childrenProps);
				}
				throw new Error('Children props are required when using function children');
			}

			return children;
		};

		return (
			<Modal
				{...props}
				{...modalProps}
				show={show}
				onHide={() => {
					this.onCancel();
					if (modalProps?.onHide) {
						modalProps.onHide();
					} else {
						onHide?.();
					}
				}}
			>
				{renderChildren()}
			</Modal>
		);
	}
}
