import type { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardHeader, type TCardProps } from '@avast/react-ui-components';
import { DataTable, type TDataTableProps } from 'js/components/molecules/DataTable/DataTable';

export type TDataTableCardProps = TDataTableProps & {
	caption?: string;
	lastTrBorder?: boolean;
	cardConfig?: TCardProps;
};

export const DataTableCard = (props: PropsWithChildren<TDataTableCardProps>) => {
	const { caption, children, lastTrBorder = false, className, cardConfig, ...rest } = props;

	return (
		<Card {...cardConfig}>
			{caption && <CardHeader>{caption}</CardHeader>}
			<CardBody className={classNames({ 'p-0': rest.variant !== 'simple' })}>
				<DataTable className={classNames(className, 'table-fixed', { 'table-noLastBorder': !lastTrBorder })} {...rest}>
					{props.children}
				</DataTable>
			</CardBody>
		</Card>
	);
};
