import type { ReactElement } from 'react';
import { Button, ButtonComposition, H3, Modal } from '@avast/react-ui-components';
import { CheckCircleAnimated } from 'assets/image/icon';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import { useTranslation } from 'react-i18next';

type TAsyncSuccessRefundModalProps = TAsyncModalContainerProps & { showOrderRefunds?: () => void };

export const AsyncSuccessRefundModal = (props: TAsyncSuccessRefundModalProps): ReactElement => {
	const { forwardedRef, showOrderRefunds } = props;
	const { t } = useTranslation('submodules');

	function handleClose() {
		forwardedRef.current?.onCancel();
	}

	function handleShowOrderRefunds() {
		forwardedRef.current?.onSuccess(STATUS_SUCCESS);
		showOrderRefunds?.();
	}

	return (
		<AsyncModal ref={forwardedRef} backdrop="static" testId="successRefund" size="sm">
			<>
				<Modal.Body className="text-center py-4 py-md-8">
					<p>
						<CheckCircleAnimated />
					</p>
					<H3 bold variant="success" className="mb-3">
						{t('common:_.success')}
					</H3>
					<p>{t('refunds.partner.successMessage')}</p>
				</Modal.Body>
				<Modal.Footer cancelButton={false} className="text-center d-block">
					<ButtonComposition className="justify-content-between">
						<Button size="sm" variant="outline-primary" onClick={handleClose}>
							{t('common:actions.close')}
						</Button>
						<Button size="sm" onClick={handleShowOrderRefunds}>
							{t('refunds.actions.viewRefunds')}
						</Button>
					</ButtonComposition>
				</Modal.Footer>
			</>
		</AsyncModal>
	);
};
