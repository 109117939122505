import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import type { IEntityCustomer } from 'module/customers';
import type { PropsWithChildren } from 'react';
import { useSelectPartyTranslation } from '../hooks';
import { useAuthContext, useCommonContext } from 'js/contexts';
import { useApiPartnerCustomer } from 'module/customers/hooks';
import { SelectPartyCard, SelectPartyCardBody, SelectPartyCardFooter } from '../common';
import { Alert } from '@avast/react-ui-components';
import { CustomerCard } from 'module/customers/components';
import type { TSelectPartyModalContentProps } from './types';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { customersConfig } from 'module/customers/customersConfig';
import { useCan } from 'js/hooks/useCan';

type TSelectCustomerCardProps = TSelectPartyModalContentProps<IEntityCustomer> & {
	option: SelectPartyOptionEnum;
	customer?: IEntityCustomer | null;
	isNotAllowed?: boolean;
};

export const SelectCustomerCard = (props: PropsWithChildren<TSelectCustomerCardProps>) => {
	const { customer, state, onResolve, isNotAllowed } = props;
	const { tParty, tPartyOption } = useSelectPartyTranslation(SelectPartyEnum.CUSTOMER);
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { createCustomerRef } = useCommonContext();
	const { isGroupPartner } = useAuthContext();
	const canCreate = useCan({ do: customersConfig.aclModule, create: true });

	const isBillableParty = !state.partner;
	const isBtnDisabled = isNotAllowed || (isBillableParty && !customer);

	const { data: partnerCustomerData, query } = useApiPartnerCustomer({
		filter: { salesforceId: customer?.id!, partnerId: state.partner?.id! },
		config: {
			catchError: (error) => error.response?.status !== 404,
		},
		queryConfig: {
			enabled: Boolean(customer?.id) && Boolean(state.partner?.id) && !isBillableParty,
		},
	});

	return (
		<SelectPartyCard type={SelectPartyEnum.CUSTOMER} option={props.option}>
			<SelectPartyCardBody loading={query.isFetching}>
				{(() => {
					if (!customer) {
						return (
							<>
								<Alert variant="warning">{tPartyOption(props.option, 'empty')}</Alert>
								{isBillableParty && <Alert variant="danger">{tParty('requiredBillableParty')}</Alert>}
							</>
						);
					}

					return (
						<>
							{!partnerCustomerData && !isBillableParty && (
								<Alert variant="danger">
									{t(
										isGroupPartner
											? 'error.operation.customer.partnerIsNotBound.partner'
											: 'error.operation.customer.partnerIsNotBound.internal',
									)}
								</Alert>
							)}
							<CustomerCard customer={partnerCustomerData || customer} />
							{!isNotAllowed && isBillableParty && <Alert className="mt-3 mb-0">{tParty('billableParty')}</Alert>}
						</>
					);
				})()}
			</SelectPartyCardBody>

			{(() => {
				if (!isBillableParty && customer && !partnerCustomerData) {
					return (
						<SelectPartyCardFooter
							loading={query.isFetching}
							option={props.option}
							disabled={!canCreate}
							onSelect={async () => {
								const response = await createCustomerRef.current?.show({
									initialValues: customer,
									partnerId: state.partner?.id,
								});
								if (response) {
									onResolve(response);
								}
							}}
						>
							{t(customersConfig.trPrefix('actions.add'))}
						</SelectPartyCardFooter>
					);
				}

				return (
					<SelectPartyCardFooter
						loading={query.isFetching}
						option={props.option}
						disabled={isBtnDisabled}
						onSelect={() => onResolve(partnerCustomerData || customer)}
					>
						{tPartyOption(
							props.option,
							customer || props.option === SelectPartyOptionEnum.ORDER ? 'button' : 'buttonEmpty',
						)}
					</SelectPartyCardFooter>
				);
			})()}
		</SelectPartyCard>
	);
};
