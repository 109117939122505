import { FormikControl } from 'js/components/formik/FormikControl';
import type { TCurrency } from 'types';
import { useFormikContext } from 'formik';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { useTranslation } from 'react-i18next';
import { useCountrySupportedCurrenciesOptions } from 'js/entities/country/useCountrySupportedCurrenciesOptions';
import type { TFormMessagesProps } from '@avast/react-ui-components';
import { useBillablePartyGuard } from 'module/priceCalculator/guards';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';

export const CurrencySelect = () => {
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);
	const { values, touched } = useFormikContext<IPriceCalculatorForm>();
	const hasCountryCode = Boolean(values.countryCode);
	const {
		options,
		query: { isFetching },
	} = useCountrySupportedCurrenciesOptions(values.countryCode);
	const messages: TFormMessagesProps['list'] = [];
	const billablePartyGuard = useBillablePartyGuard();

	if (!hasCountryCode && touched.countryCode) {
		messages.push([t('message.countryCodeNotSelected'), 'warning']);
	}

	return (
		<FormikControl label={t('common:entity.currency')} messages={messages}>
			<FormikControl.SingleSelect<TCurrency>
				name="currencyCode"
				size="sm"
				disabled={!hasCountryCode || billablePartyGuard.isExistingPartner}
				isLoading={isFetching}
				options={options}
				required={!billablePartyGuard.isExistingPartner}
			/>
		</FormikControl>
	);
};
