import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import type {
	IApiAnnouncementListFilter,
	IApiAnnouncementUpdateFilter,
	IApiAnnouncementUpdateRequest,
	IEntityAnnouncement,
} from 'submodule/Announcements/types';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { ApiMutationTypeEnum } from 'js/enums';

const API_ANNOUNCEMENT_KEYS = {
	LIST: 'announcement/affiliate/:partnerId',
	UPDATE: 'announcement/affiliate/:partnerId/:announcementId',
};

export const useApiAnnouncementList: TUseApiListModule<IEntityAnnouncement, IApiAnnouncementListFilter> = (props) => {
	return useApiListQuery(API_ANNOUNCEMENT_KEYS.LIST, props);
};

export const useApiAnnouncementUpdate: TUseApiMutationModule<
	IApiAnnouncementUpdateRequest,
	IEntityAnnouncement,
	IApiAnnouncementUpdateFilter
> = (props) => {
	return useApiMutationQuery(API_ANNOUNCEMENT_KEYS.UPDATE, ApiMutationTypeEnum.POST, props, {
		invalidateQueries: [API_ANNOUNCEMENT_KEYS.LIST],
	});
};
