import { useCallback, useMemo, useReducer } from 'react';
import type { TSearchFilter } from 'types/filter';
import { trim } from 'lodash';

export enum ActionType {
	RESET_VALUE = 'RESET_VALUE',
	SET_STATE = 'SET_STATE',
	SET_STATE_AND_RESET_VALIDATION = 'SET_STATE_AND_RESET_VALIDATION',
}

export enum ValidationState {
	INIT = 'INIT',
	DONE = 'DONE',
}

export type TSearchComboBoxValidationResult = { valid: true } | { valid: false; message?: string };

export type TUseSearchComboBoxProps<KeyType extends string> = {
	validationFn?: (value: TSearchFilter<KeyType>) => TSearchComboBoxValidationResult;
	initialKey?: KeyType;
	onSubmit: (value: TSearchFilter<KeyType>) => unknown;
	onCancel: (value: TSearchFilter<KeyType>) => unknown;
};

interface TState<KeyType extends string> {
	searchKey: KeyType | undefined;
	searchValue: string;
	validateState: TValidationStatus;
}

type TAction<KeyType extends string> =
	| { type: typeof ActionType.RESET_VALUE }
	| { type: typeof ActionType.SET_STATE; payload: Partial<TState<KeyType>> }
	| { type: typeof ActionType.SET_STATE_AND_RESET_VALIDATION; payload: Partial<TState<KeyType>> };

type TValidationStatus =
	| { state: ValidationState.INIT }
	| { state: ValidationState.DONE; valid: true }
	| { state: ValidationState.DONE; valid: false; message?: string };

export const useSearchComboBox = <KeyType extends string>({
	initialKey,
	validationFn,
	onSubmit,
	onCancel,
}: TUseSearchComboBoxProps<KeyType>) => {
	const initialState: TState<KeyType> = useMemo(
		() => ({
			validateState: { state: ValidationState.INIT },
			searchValue: '',
			searchKey: initialKey,
		}),
		[initialKey],
	);

	const reducer = useCallback(
		(state: typeof initialState, action: TAction<KeyType>): TState<KeyType> => {
			switch (action.type) {
				case ActionType.SET_STATE:
					return { ...state, ...action.payload };
				case ActionType.SET_STATE_AND_RESET_VALIDATION:
					return { ...state, ...action.payload, validateState: { state: ValidationState.INIT } };
				case ActionType.RESET_VALUE:
					return { ...initialState, searchKey: state.searchKey };
				default:
					throw new Error(`Unknown action type for SearchBox reducer. Action passed: ${JSON.stringify(action)}`);
			}
		},
		[initialState],
	);

	const [state, dispatch] = useReducer(reducer, initialState);

	const resetComponentState = () => {
		onCancel?.({ key: state.searchKey, value: state.searchValue });
		dispatch({ type: ActionType.RESET_VALUE });
	};

	const setSearchKey = (searchKey: KeyType | null) => {
		if (searchKey) {
			dispatch({ type: ActionType.SET_STATE_AND_RESET_VALIDATION, payload: { searchKey } });
		}
	};

	const setSearchValue = (searchValue: string) => {
		dispatch({ type: ActionType.SET_STATE_AND_RESET_VALIDATION, payload: { searchValue } });
	};

	const setSearchObject = (payload: { searchKey: KeyType; searchValue: string }) => {
		dispatch({ type: ActionType.SET_STATE_AND_RESET_VALIDATION, payload });
	};

	const validate = () => {
		if (validationFn) {
			const validateState = validationFn({ value: state.searchValue, key: state.searchKey });
			dispatch({
				type: ActionType.SET_STATE,
				payload: { validateState: { ...validateState, state: ValidationState.DONE } },
			});

			return validateState.valid;
		}

		return false;
	};

	const submit = () => {
		const trimmedValue = trim(state.searchValue);
		const result = { key: state.searchKey, value: trimmedValue };

		// Use custom submit handler if is passed
		if (onSubmit) {
			onSubmit(result);
		}

		return result;
	};

	const validateAndSubmit = () => {
		if (validate()) {
			return submit();
		}
	};

	const validationFailed = state.validateState.state === ValidationState.DONE && !state.validateState.valid;

	return {
		state,
		resetComponentState,
		setSearchKey,
		setSearchValue,
		setSearchObject,
		validationFailed,
		submit: validationFn ? validateAndSubmit : submit,
	};
};
