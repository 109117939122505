import { type ReactElement, useCallback } from 'react';
import { Button, StatusText } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import {
	AsyncSelectPartnerModal,
	type TAsyncSelectPartnerModalProps,
} from 'module/partners/components/AsyncSelectPartnerModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IEntityPartner } from 'module/partners';
import { useFormikContext } from 'formik';
import type { IGenerateRetailCodesForm } from 'module/retail';
import { retailConfig } from 'module/retail/retailConfig';
import { GenerateRetailCodesPartnerDetail } from 'module/retail/components/generate/GenerateRetailCodesPartnerDetail';
import { StepperVerticalHeadline } from 'submodule/stepperVertical';
import { PartnerSubTypeEnum, PartnerTypeEnum } from 'module/partners/enums';
import { isDefined } from 'js/utils/common';

export const GenerateRetailCodesPartner = (): ReactElement => {
	const { t } = useTranslation(retailConfig.trNamespace);
	const {
		values: { partner },
		setFieldValue,
	} = useFormikContext<IGenerateRetailCodesForm>();
	const selectPartnerRef = useAsyncModalRef<TAsyncSelectPartnerModalProps, IEntityPartner>();

	const selectPartner = useCallback(async () => {
		const selectedPartner = await selectPartnerRef.current?.show({
			selectedPartnerId: partner?.id,
			accountTypesWhiteList: [PartnerTypeEnum.RESELLER],
			accountSubTypesWhiteList: [PartnerSubTypeEnum.RETAIL],
			detailed: true,
		});

		if (isDefined(selectedPartner) && selectedPartner.id !== partner?.id) {
			setFieldValue('partner', selectedPartner);
			setFieldValue('products', []);
		}
	}, [selectPartnerRef, partner, setFieldValue]);

	return (
		<>
			<StepperVerticalHeadline
				step={1}
				title={t('generate.partner.headline')}
				className="mb-3"
				active={!partner}
				endComponent={
					<Button onClick={selectPartner} size="sm">
						{t(partner ? 'generate.partner.change' : 'generate.partner.select')}
					</Button>
				}
			/>

			{isDefined(partner) ? (
				<GenerateRetailCodesPartnerDetail partner={partner} />
			) : (
				<StatusText caption={t('generate.partner.placeholder')} minHeight={100} />
			)}

			<AsyncSelectPartnerModal forwardedRef={selectPartnerRef} />
		</>
	);
};
