import type { IAsyncRetailRequestLicensesExport, IEntityRetailRequest } from 'module/retail';
import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { PartnerName } from 'module/partners/components';
import { RetailStatusEnum, retailStatusEnumUtils } from 'module/retail/enums';
import { useApiRetailRequestLicensesExport } from 'module/retail/hooks/useApiRetail';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import type { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IAsyncExportProps } from 'js/components/exports';

type TTableData = IEntityRetailRequest;

export const useRetailListColumns = (
	exportRef: TAsyncModalRef<IAsyncExportProps<IAsyncRetailRequestLicensesExport>>,
): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(retailConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.ellipsis(
				'partnerId',
				{
					header: t('common:entity.partner'),
					enableSorting: false,
					meta: { formatters: [(partnerId) => <PartnerName partnerId={partnerId} />] },
				},
				{ width: 200, tooltip: (cell) => <PartnerName partnerId={cell.row.original.partnerId} /> },
			),
			columnHelper.ellipsis('productName', {
				header: t('common:entity.productName'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('productSku', { header: t('common:entity.sku') }),
			columnHelper.integer('requestedQuantity', { header: t('common:entity.quantity') }),
			columnHelper.copy2clipboard('bulkRequestId', { header: t('entity.bulkId') }),
			columnHelper.date('transactionDate', { header: t('entity.transactionDate') }),
			columnHelper.text('status', {
				header: t('common:entity.status'),
				meta: { formatters: [retailStatusEnumUtils.getText, formatter.bold] },
			}),
			columnHelper.actions({
				button: {
					onClick(row) {
						exportRef.current?.show({
							request: { retailCodeRequestId: row.id },
							useQuery: useApiRetailRequestLicensesExport,
						});
					},
					iconLeftFa: faDownload,
					title: t('actions.downloadLicenses'),
					conditionalProps(row) {
						return {
							disabled: row.status !== RetailStatusEnum.COMPLETE,
						};
					},
				},
			}),
		];
	}, [t, exportRef]);
};
