import { StepperVerticalContainer, StepperVerticalHeadline } from 'submodule/stepperVertical';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { Button, H3 } from '@avast/react-ui-components';
import {
	BillablePartyTypeSelect,
	CountrySelect,
	CurrencySelect,
	PartnerSelect,
} from 'module/priceCalculator/forms/fields';
import { useBillablePartyGuard, useFormStepsGuard } from 'module/priceCalculator/guards';
import { useFormikContext } from 'formik';

export const BillablePartyStep = () => {
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);
	const formStepsGuard = useFormStepsGuard();
	const billablePartyGuard = useBillablePartyGuard();
	const { resetForm } = useFormikContext();

	return (
		<StepperVerticalContainer border className="pt-5 pb-3">
			<StepperVerticalHeadline
				step={1}
				title={t('steps.billableParty.title')}
				className="mb-4"
				active={formStepsGuard.isBillablePartyStepActive}
				endComponent={
					<Button variant="outline-danger" size="sm" onClick={() => resetForm()}>
						{t('form:actions.reset')}
					</Button>
				}
			/>

			<Row size="sm">
				<Col md={4} xl={3}>
					<BillablePartyTypeSelect />
				</Col>
				{billablePartyGuard.isPartner && (
					<Col md={4}>
						<PartnerSelect />
					</Col>
				)}
			</Row>

			{billablePartyGuard.isPartner && !billablePartyGuard.isExistingPartner && (
				<H3 bold type="h5" className="text-uppercase mt-2 mb-2">
					{t('steps.billableParty.virtualPartnerTitle')}
				</H3>
			)}

			<Row size="sm">
				<Col lg={4} sm={6}>
					<CountrySelect />
				</Col>
				<Col lg={4} sm={6}>
					<CurrencySelect />
				</Col>
			</Row>
		</StepperVerticalContainer>
	);
};
