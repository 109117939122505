import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityLicense } from 'module/licenses';
import { useLicenseCustomer } from 'module/licenses/hooks';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { getCustomerFullName, getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { Link } from 'react-router';
import { EmailDisplay } from 'js/components/atoms/EmailDisplay';

export const CustomerGroup = () => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { data: license, query } = usePageDetailContext<IEntityLicense>();
	const {
		customer,
		isFetching: isCustomerFetching,
		hasCustomer,
		customerId,
		customerLink,
	} = useLicenseCustomer(license);
	const isFetching = isCustomerFetching || query.isFetching;

	if (!hasCustomer) {
		return null;
	}

	const renderName = getCustomerRenderName(customer);
	return (
		<>
			<DataTableTr name={t('entity.customer.name')} highlighted isLoading={isFetching}>
				{customerLink ? <Link to={customerLink}>{renderName}</Link> : renderName}
			</DataTableTr>
			<DataTableTr name={t('entity.customer.id')}>{customerId}</DataTableTr>
			<DataTableTr name={t('entity.customer.email')} isLoading={isFetching}>
				<Copy2Clipboard value={customer?.email}>
					<EmailDisplay email={customer?.email} />
				</Copy2Clipboard>
			</DataTableTr>
			<DataTableTr name={t('entity.customer.contact')} isLoading={isFetching}>
				{getCustomerFullName(customer)}
			</DataTableTr>
		</>
	);
};
