import { useCallback } from 'react';
import type { TStandardOrderInstanceAddItem } from 'module/purchase';
import type { TPriceListCode } from 'js/priceList';
import { usePriceListGuard } from 'module/purchase/hooks/guards';
import { useMaxCartItemsGuard } from 'module/purchase/hooks/guards/useMaxCartItemsGuard';
import { useOrderContext } from 'js/contexts';

export const useAddItemsToCart = () => {
	const priceListGuard = usePriceListGuard();
	const maxCartItemsGuard = useMaxCartItemsGuard();
	const { addItems } = useOrderContext();

	return useCallback(
		async (priceListCode: TPriceListCode, items: TStandardOrderInstanceAddItem[]): Promise<boolean> => {
			// Check max items in a cart
			if (!(await maxCartItemsGuard(items))) {
				return false;
			}

			// Check price lists
			const guard = await priceListGuard(priceListCode);
			if (!guard) {
				return false;
			}

			addItems(items, priceListCode);
			return true;
		},
		[priceListGuard, maxCartItemsGuard, addItems],
	);
};
