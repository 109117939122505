import { LinkButton } from 'js/components/atoms/Button';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { useIsEditable } from 'module/orders/hooks';
import { useTranslation } from 'react-i18next';
import { isOrderInQuoteStatus } from 'module/orders/utils/common';

export const EditOrderButton = () => {
	const {
		data: order,
		query: { isFetching },
	} = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { isGroupPartner } = useAuthContext();
	const isEditable = useIsEditable();
	const isQuote = isOrderInQuoteStatus(order);
	let text = t('actions.edit');
	const link = isQuote ? ordersConfig.quoteUpdateLink(order) : ordersConfig.updateLink(order);

	if (isQuote) {
		text = t('actions.payEditQuote');

		if (isGroupPartner) {
			text = t('actions.payQuote');
		}
	}

	if (isEditable(order)) {
		return (
			<LinkButton size="sm" variant="outline-primary" to={link} loading={isFetching} testId="edit">
				{text}
			</LinkButton>
		);
	}

	return null;
};
