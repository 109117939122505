import type { IPromotionListFilter, IPromotionListFilterExtra } from 'module/promotions';
import { apiDateToDate, dateTimeToFilterDate } from 'js/utils/dateTime';
import { filter, pick } from 'lodash';
import type { TEntityKey } from 'types';
import { DateTime } from 'luxon';

const EXTRA_FILTER_KEYS: TEntityKey<IPromotionListFilterExtra>[] = [
	'state',
	'partnerId',
	'effectiveDate',
	'partnerCountryCode',
	'partnerType',
	'productSku',
	'licenseType',
	'type',
];

export const promotionExtraFilterNormalizer = {
	denormalize(filter: IPromotionListFilterExtra): IPromotionListFilter {
		const _filter: IPromotionListFilter = pick(filter, EXTRA_FILTER_KEYS) as IPromotionListFilter;

		if (filter.effectiveDate) {
			_filter.effectiveDate = dateTimeToFilterDate(DateTime.fromJSDate(filter.effectiveDate));
		}

		return _filter;
	},

	normalize(filter: IPromotionListFilter): IPromotionListFilterExtra {
		const _filter: IPromotionListFilterExtra = pick(filter, EXTRA_FILTER_KEYS) as IPromotionListFilterExtra;

		if (filter.effectiveDate) {
			_filter.effectiveDate = apiDateToDate(filter.effectiveDate);
		}

		return _filter;
	},

	count(values: IPromotionListFilter): number {
		return filter(this.normalize(values)).length;
	},
};
