import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_DETAIL, ACL_READ_LIST } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { PageDetail, PageList } from 'module/closingBalance/pages';

export const ClosingBalanceRoutes = () => {
	const { aclModule, authGuards } = closingBalanceConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} authGuards={authGuards} />}>
				<Route index element={<PageList />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} authGuards={authGuards} />}>
				<Route path="detail/:id" element={<PageDetail />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
