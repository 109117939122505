import * as Yup from 'yup';
import { useMemo } from 'react';
import { yupContextValidator } from 'js/utils/validator';
import type { IFormikContextValidator } from 'types/validator';
import { useTranslation } from 'react-i18next';
import type { ICreditLimitForm } from 'module/creditLimit';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';

type Values = ICreditLimitForm;

export const useCreditLimitFormValidator = (): IFormikContextValidator<Values> => {
	const { t } = useTranslation(creditLimitConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				partnerId: Yup.number().nullable().required().label(t('common:entity.partner')),

				amount: Yup.number().required().label(t('common:entity.amount')).moreThan(0),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) => yupContextValidator<Values>(validationSchema, values),
	};
};
