import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/en-ZA');

const locale: TLocale = 'en-ZA';

export const en_ZA: ILocaleConfig = {
	name: 'English (South Africa)',
	locale,
	language: 'en',
	format: {
		numbro,
		datetime: locale,
	},
};
