import type React from 'react';
import { useEffect } from 'react';
import { breadcrumbsStore } from 'js/components/molecules/DynamicBreadcrumbs';
import type { TBreadcrumbsItemProps } from '@avast/react-ui-components';
import type { LinkProps } from 'react-router';
import { useTranslation } from 'react-i18next';

type TDynamicBreadcrumbLoadingItem = {
	type: 'loading';
};

export type TDynamicBreadcrumbsItem = TBreadcrumbsItemProps<LinkProps> & {
	href: string;
	children: React.ReactNode;
	type?: 'link';
};

export type TDynamicBreadcrumbsItemProps = TDynamicBreadcrumbsItem | TDynamicBreadcrumbLoadingItem;

/**
 * Component that creates a Breadcrumb navigation part.
 */
const DynamicBreadcrumbsItem = (props: TDynamicBreadcrumbsItemProps) => {
	const { setBreadcrumbsPart, removeBreadcrumbsPart } = breadcrumbsStore();
	const {
		i18n: { language },
	} = useTranslation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		// Add the breadcrumbs item
		setBreadcrumbsPart(props);

		return () => {
			// Remove the breadcrumbs item when unmounting
			removeBreadcrumbsPart();
		};
	}, [language]);

	return <></>;
};

function isLoadingItem(item: TDynamicBreadcrumbsItemProps): item is TDynamicBreadcrumbLoadingItem {
	return item?.type === 'loading';
}

export { DynamicBreadcrumbsItem, isLoadingItem };
