import { useCallback } from 'react';
import { authContextResetState, type IAuthContextState, type TAuthToken } from 'js/reducers/authContextReducer';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';

type TUseRefreshAuthState = (
	state: IAuthContextState | null,
	onNonAuthorized: (token?: TAuthToken | null) => void,
) => Promise<IAuthContextState | null>;

export const useRefreshAuthState = (): TUseRefreshAuthState => {
	const authUserData = useAuthUserData();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		async (state, onNonAuthorized) => {
			if (!state?.token) {
				return authContextResetState(state);
			}

			if (isRefreshRestricted) {
				return state;
			}

			const data = await authUserData({ token: state.token }, onNonAuthorized);
			if (!data) {
				return null;
			}

			return {
				...state,
				...data,
			};
		},
		[authUserData, isRefreshRestricted],
	);
};
