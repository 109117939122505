import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import type { IEntityLicense, ILicenseListApiFilter, ILicenseListFilter } from 'module/licenses';
import { useApiLicenseList } from 'module/licenses/hooks';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { ButtonComposition } from '@avast/react-ui-components';
import { ExportLicensesButton } from 'module/licenses/components/buttons';
import { RetailLicensesFilter } from 'module/retail/components/RetailLicensesFilter';
import type { IListMetaDataValues } from 'types/utils';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { DateTime } from 'luxon';
import { Can } from 'js/components/molecules/Can';
import { LinkButton } from 'js/components/atoms/Button';
import { LicenseDateRangeTypeEnum } from 'module/licenses/enums';
import { useRetailLicensesListColumns } from 'module/retail/hooks';
import { useNavigate } from 'react-router';
import { PartnerSubTypeEnum } from 'module/partners/enums';
import { SortDirectionEnum } from 'js/enums';

type TTableData = IEntityLicense;
type TTableDataFilter = ILicenseListFilter;

export const PageRetailLicenses = () => {
	const { t } = useTranslation(retailConfig.trNamespace);
	const navigate = useNavigate();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({});

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);
	const defaultFilter = useMemo<TTableDataFilter>(
		() => ({
			dateRangeType: LicenseDateRangeTypeEnum.CREATED_AT,
			dateRange: [dateTimeToFilterDate(DateTime.now()), dateTimeToFilterDate(DateTime.now())],
		}),
		[],
	);

	// Set manual (static) filter - not change able
	const filterStatic = useMemo<TTableDataFilter>(() => ({ origin: PartnerSubTypeEnum.RETAIL }), []);

	// Define columns
	const columns = useRetailLicensesListColumns();

	return (
		<>
			<DynamicPageTitleItem text={t('page.licenses')} />
			<DynamicBreadcrumbsItem href={RouteName.RETAIL.LICENSES}>{t('page.licenses')}</DynamicBreadcrumbsItem>

			<FilterPlaceholderPortal>
				<RetailLicensesFilter
					defaultValues={defaultFilter}
					values={filter}
					onChange={setFilter}
					controls={
						<ButtonComposition size="sm" justify="end" marginY wrap>
							<ExportLicensesButton listMetaData={listMetaData} />
							<ExportLicensesButton listMetaData={listMetaData} detailed />
							<Can do={retailConfig.aclModule} create authGuards={retailConfig.generateAuthGuards}>
								<LinkButton to={RouteName.RETAIL.GENERATE} size="sm">
									{t('actions.generate')}
								</LinkButton>
							</Can>
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>

			<ApiPaginatedListTable<TTableData, TTableDataFilter, ILicenseListApiFilter>
				columns={columns}
				query={useApiLicenseList}
				filter={filter}
				filterStatic={filterStatic}
				sort={{ key: 'expiration', direction: SortDirectionEnum.DESC }}
				table={{
					testId: 'licenses',
					meta: {
						onRowClick: (license) => navigate(buildParametrizedRoute(RouteName.LICENSE.DETAIL, license.id)),
					},
				}}
				onMetaDataChange={setListMetaData}
				useLocation
			/>
		</>
	);
};
