import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { logError } from 'js/utils/app';
import type { IEntityPartnerDetail } from 'module/partners';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useAsyncApiPartnerDetail } from 'module/partners/api/useAsyncApiPartners';
import { useAppContext } from 'js/contexts';

export const useAsyncPartnerDetail = () => {
	const { t } = useTranslation(partnersConfig.trNamespace);
	const { loadingModalRef } = useAppContext();
	const asyncApiPartnerDetail = useAsyncApiPartnerDetail();

	return useCallback(
		async (partnerId: IEntityPartnerDetail['id']): Promise<IEntityPartnerDetail | null> => {
			loadingModalRef.current?.show({
				title: t('common.loadingDetail'),
				ellipsis: true,
			});

			const response = await asyncApiPartnerDetail(partnerId).catch(logError);

			loadingModalRef.current?.hide();

			if (response) {
				return response.data;
			}

			toast.error(<Toast>{t('error.unableToLoadPartnerDetail')}</Toast>);
			return null;
		},
		[t, loadingModalRef, asyncApiPartnerDetail],
	);
};
