import type { ReactElement } from 'react';
import { UiTable } from '@avast/react-ui-components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { Col, Row } from 'js/components/atoms/Row';
import type { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { usePageDetailContext } from 'js/contexts';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncPriceDetailModal, type TPriceDetailModalProps } from 'module/orders/components/AsyncPriceDetailModal';
import { AsyncLicensesModal, type TLicenseModalProps } from 'module/orders/components/AsyncLicensesModal';
import { PriceSummary } from 'module/orders/components/PriceSummary';
import { useItemsInOrderColumns } from 'module/orders/hooks';
import { isOrderItemCancelled, isOrderType } from 'module/orders/utils/common';
import { OrderTypeEnum } from 'module/orders/enums';

type TTableData = IEntityOrderItem;

export const ItemsInOrderTab = (): ReactElement => {
	const { data } = usePageDetailContext<IEntityOrder>();
	const priceDetailRef = useAsyncModalRef<TPriceDetailModalProps>();
	const licensesRef = useAsyncModalRef<TLicenseModalProps>();
	const columns = useItemsInOrderColumns({ priceDetailRef, licensesRef });

	return (
		<DefaultContainer>
			<Row multi size="sm" justify="end" align="start" className="mb-4">
				<Col md="auto">
					<PriceSummary />
				</Col>
			</Row>
			<UiTable<TTableData>
				data={data.lineItems}
				columns={columns}
				testId="itemsInOrder"
				enablePagination
				enableSorting
				meta={{
					rowCount: data.lineItems.length,
					isScrollable: true,
					scrollTopOnPageChange: true,
					paginationComponent: 'Full',
					onRowClick: (orderItem) => priceDetailRef.current?.show({ order: data, orderItem }),
					rowClassName: (item) => (isOrderItemCancelled(item) ? 'disabled' : ''),
					isClickableRow: (item) => !isOrderItemCancelled(item) && !isOrderType(data, OrderTypeEnum.PAYG),
				}}
			/>
			<AsyncPriceDetailModal forwardedRef={priceDetailRef} />
			<AsyncLicensesModal forwardedRef={licensesRef} />
		</DefaultContainer>
	);
};
