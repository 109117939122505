import type { IPricingRequestItem } from 'module/purchase';
import type { IComparativeData } from 'submodule/comparativeFeatures';
import { useLicenseOperationPricing, useLicenseOperationPricingItem } from 'module/licenses/hooks/licenseOperation';
import type { TUsePricingResponse } from 'module/licenses/hooks/licenseOperation/useLicenseOperationPricing';

export const useLicenseOperationComparativePricing = (
	comparativeData: IComparativeData | null,
): TUsePricingResponse => {
	const licenseOperationPricingItem = useLicenseOperationPricingItem();

	// Prepare pricing fragments
	const lineItems: IPricingRequestItem[] = [];

	for (const list of comparativeData?.products ?? []) {
		for (const item of list) {
			lineItems.push(licenseOperationPricingItem(item.productGroup, item.product));
		}
	}

	return useLicenseOperationPricing({ lineItems });
};
