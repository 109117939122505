import { LOCALE } from './locale';
import { ERROR } from './error';
import { PRODUCT_GROUP } from './productGroup';
import type { IConfig, TEnv } from 'types/config';

export const CONFIG: IConfig = {
	VERSION: process.env.REACT_APP_VERSION ?? 'UNKNOWN',
	ENV: process.env.REACT_APP_ENV_NAME as TEnv,
	API_VERSION: 1,
	DEBUG: process.env.REACT_APP_DEBUG === '1',
	REQUIRED_STATE_COUNTRIES: ['US', 'CA'],
	LOCALE,
	ERROR,
	TABLE: {
		pageSizeList: [10, 20, 50, 100],
		pageSizeDefault: 10,
		loadingType: 'SKELETON',
	},
	APP_FILE_PATH: '/ui/app.json',
	APP: {
		VERSION_CHECK_TIMEOUT: 10 * 60 * 1000, // 10 min
		REFRESH_JWT_TIMEOUT: 20 * 60 * 1000, // 20 min
		MAX_FILE_SIZE: 8,
		ORDER_HOLD_DAYS: 15,
		CUSTOMERS_API_LIMIT: 2000,
		CUSTOMERS_API_FILTER_MIN_CHAR: 3,
		GC_URL: process.env.REACT_APP_API_URL_DR ?? 'UNKNOWN',
		SALES_FORCE_URL: process.env.REACT_APP_SF_URL ?? 'UNKNOWN',
		SALES_FORCE_APP_ID: process.env.REACT_APP_SF_APP_ID ?? 'UNKNOWN',
		GEN_SAML_LOGIN_URL: process.env.REACT_APP_GEN_SAML_LOGIN_URL ?? 'UNKNOWN',
		FEEDBACK_URL: 'https://docs.google.com/forms/d/1A0m7DqMThfUDd3u1LdAzZsYP4zH8fbETrj70BGIPvWQ',
	},
	PURCHASE: {
		MAX_ITEMS: 150,
	},
	MAX_QUANTITY: {
		BUSINESS: 25000,
		RETAIL_PARTNERS: 50000,
		CONSUMER: 10000,
	},
	PRODUCT_GROUP,
};
