import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { IEntityCreditStatus } from 'module/creditStatus';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { numberFormatter } from 'js/utils/number';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import Skeleton from 'react-loading-skeleton';
import { CreditExceedAlert } from 'module/purchase/components/CreditExceedAlert';
import classNames from 'classnames';
import { isNumber } from 'lodash';

type TCreditStatusCardProps = {
	creditStatus: IEntityCreditStatus;
	className?: string;
	orderValue?: number;
};

export const CreditStatusCard = (props: TCreditStatusCardProps): ReactElement => {
	const { creditStatus, orderValue } = props;
	const { t } = useTranslation(creditStatusConfig.trNamespace);

	const remaining = isNumber(orderValue) ? creditStatus.creditAvailable - orderValue : null;

	return (
		<div className={props.className}>
			<p className="fw-bold">
				{t('entity.creditAvailable')}: {numberFormatter.currency(creditStatus.creditAvailable, creditStatus.currency)}
			</p>
			<ul className="mt-3 fs-sm reset">
				<li>
					{t('entity.creditLimit')}: {numberFormatter.currency(creditStatus.creditLimit, creditStatus.currency)}
				</li>
				<li>
					{t('entityShort.unbilledBalance')}:{' '}
					{numberFormatter.currency(creditStatus.unbilledBalance, creditStatus.currency)}
				</li>
				<li>
					{t('entityShort.unpaidBalance')}:{' '}
					{numberFormatter.currency(creditStatus.unpaidBalance, creditStatus.currency)}
				</li>
			</ul>

			{remaining === null ? (
				<ul className="mt-3 fs-sm reset">
					<li>
						{t(purchaseConfig.trPrefix('common.orderValue'))}: <Skeleton width={80} />
					</li>
					<li className="fw-bold">
						{t(purchaseConfig.trPrefix('common.remaining'))}: <Skeleton width={80} />
					</li>
				</ul>
			) : (
				<ul className="mt-3 fs-sm reset">
					<li>
						{t(purchaseConfig.trPrefix('common.orderValue'))}:{' '}
						{numberFormatter.currency(orderValue, creditStatus.currency)}
					</li>
					<li className="fw-bold">
						{t(purchaseConfig.trPrefix('common.remaining'))}:{' '}
						<span className={classNames({ 'text-danger': remaining < 0 })}>
							{numberFormatter.currency(remaining, creditStatus.currency)}
						</span>
					</li>
				</ul>
			)}
			{remaining !== null && remaining < 0 && <CreditExceedAlert className="mt-3" />}
		</div>
	);
};
