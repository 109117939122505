import './css/main.scss';
import { DynamicPageTitle, DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import i18n from 'i18n';
import { setLocale as yupSetLocale } from 'js/yup';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUILocaleContextGuard } from 'js/hooks/useUILocaleContextGuard';
import { AppRoutes } from 'AppRoutes';

i18n.on('languageChanged', () => {
	yupSetLocale(); // Yup - update locale errors
});

export const App = () => {
	const { t } = useTranslation();

	// Watch changes what affects UI Locale Context
	useUILocaleContextGuard();

	return (
		<>
			<DynamicPageTitleItem text={t('app:appName')} />
			<DynamicPageTitle />
			<ToastContainer closeButton icon={false} />
			<AppRoutes />
		</>
	);
};
