import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { FormMessage } from '@avast/react-ui-components';
import { isDefined } from 'js/utils/common';

type TFormikErrorsProps<Keys extends string> = {
	fields: Keys[];
};

/**
 * Render error messages for specified fields
 * @constructor
 */
export const FormikErrors = <Keys extends string = string>(props: TFormikErrorsProps<Keys>) => {
	const { errors, touched } = useFormikContext();
	const errorList: string[] = [];

	for (const key of props.fields) {
		const error = get(errors, key, null);
		const hasError = isDefined(error);
		const touch = get(touched, key, null);
		const isTouched = isDefined(touch);

		if (isTouched && hasError) {
			errorList.push(error);
		}
	}

	if (errorList.length === 0) {
		return null;
	}

	return (
		<>
			{errorList.map((error, key) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
				<FormMessage type="danger" key={key}>
					{error}
				</FormMessage>
			))}
		</>
	);
};
