import { Stepper, StepperItem } from 'js/components/molecules/Stepper';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { RegistrationStepEnum } from 'module/registration/enums';

export const RegistrationStepper = () => {
	const { t } = useTranslation(registrationConfig.trNamespace);
	const { step, setStep, isTaxExemptEligible } = useRegistrationContext();
	const steps: RegistrationStepEnum[] = [
		RegistrationStepEnum.INIT,
		RegistrationStepEnum.TERMS,
		RegistrationStepEnum.REGISTRATION_INFO,
	];
	if (isTaxExemptEligible) {
		steps.push(RegistrationStepEnum.TAX_EXEMPTION);
	}

	return (
		<Stepper<RegistrationStepEnum>
			className="py-3 px-3"
			value={step}
			onClick={setStep}
			readonlyBeforeActive
			readonlyFromActive
		>
			{steps.map((step) => (
				<StepperItem<RegistrationStepEnum> key={step} value={step}>
					{t(`steps.${step}`)}
				</StepperItem>
			))}
		</Stepper>
	);
};
