import { type IPageModuleConfig, type IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import type { IEntityClosingBalance } from 'module/closingBalance';

export const closingBalanceConfig = moduleConfigGenerator<
	IPageModuleConfig & IPageModuleLinksConfig<IEntityClosingBalance>
>({
	trNamespace: 'moduleClosingBalance',
	menuName: 'app:menu.closingBalance',
	menuLink: RouteName.CLOSING_BALANCE.LIST,
	aclModule: 'closingBalance',
	authGuards: { partnerProperties: { isClosingBalanceEligible: true } },
	detailLink: (row) => buildParametrizedRoute(RouteName.CLOSING_BALANCE.DETAIL, row.id),
});
