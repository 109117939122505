import { ordersConfig } from 'module/orders/ordersConfig';
import { useTranslation } from 'react-i18next';
import { PricingMessageParser } from 'module/orders/utils/pricingMessageParser';
import Skeleton from 'react-loading-skeleton';

type TPricingMessageProps = {
	message?: string;
	className?: string;
	boxVariant?: 'white' | 'light';
	caption?: string | null;
	isLoading?: boolean;
};

export const PricingMessage = (props: TPricingMessageProps) => {
	const { message, className, boxVariant = 'light', caption = 'common.pricingMessage', isLoading } = props;
	const { t } = useTranslation(ordersConfig.trNamespace);

	if (!message) {
		return isLoading ? <Skeleton height={60} /> : null;
	}

	const pricingMessageParser = new PricingMessageParser(message, t);
	return (
		<div className={className}>
			{caption && <p className="fw-bold fs-sm mb-1">{t(caption)}:</p>}
			<div className={`bg-${boxVariant} fs-sm border p-3 component__pricing-message-container`}>
				{pricingMessageParser.getLines().map((line) => (
					<div key={line}>{line}</div>
				))}
			</div>
		</div>
	);
};
