import { type ReactElement, useMemo } from 'react';
import { H1 } from '@avast/react-ui-components';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import type { TDetailArguments } from 'types';
import { RouteName } from 'module/RouteName';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useApiDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import type { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import type { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { DistributionPartnerDetail } from 'module/distributionPartners/components/DistributionPartnerDetail';
import { Headline } from 'js/components/molecules/Headline';
import { LinkButton } from 'js/components/atoms/Button';
import { buildParametrizedRoute, urlParams2object } from 'js/utils/common';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { Can } from 'js/components/molecules/Can';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { useAuthContext } from 'js/contexts';

type TData = IEntityDistributionPartner;
type TArguments = TDetailArguments;
export type TQueryDetailFilter = IDistributionPartnerSearchFilter;
type TQueryProps = TUseApiDetailProps<TData, TQueryDetailFilter>;

export const PageDetail = (): ReactElement => {
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const params = useRouteParams<TArguments>();
	const { search } = useLocation();

	/**
	 * Setup query props/filter
	 * @type {TQueryProps}
	 */
	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: {
				distributorId: authCompanyId,
				...urlParams2object(search),
				...params,
			},
		}),
		[params, authCompanyId, search],
	);

	return (
		<PageDetailComponent<TData, TQueryDetailFilter>
			query={useApiDistributionPartner}
			queryProps={queryProps}
			trNamespace={distributionPartnersConfig.trNamespace}
			titleRender={getDistributionPartnerRenderName}
			detailLink={buildParametrizedRoute(RouteName.DISTRIBUTION_PARTNERS.DETAIL, params.id)}
		>
			{({ data, query: { isFetching } }) => (
				<>
					<Headline
						className="align-items-center"
						rightContent={
							<Can do={distributionPartnersConfig.aclModule} update>
								<LinkButton
									testId="update"
									size="sm"
									loading={isFetching}
									variant="primary"
									to={buildParametrizedRoute(RouteName.DISTRIBUTION_PARTNERS.UPDATE, params.id)}
								>
									{t('actions.edit')}
								</LinkButton>
							</Can>
						}
					>
						<H1 type="h2">{data.name}</H1>
					</Headline>

					<DistributionPartnerDetail distributionPartner={data} isLoading={isFetching} />
				</>
			)}
		</PageDetailComponent>
	);
};
