import { PRODUCT_GROUP } from 'config/productGroup';
import { difference } from 'lodash';
import type { TProductGroupCode } from 'types';
import type {
	IConfigProductGroupComparativeProduct,
	IConfigProductGroupComparativeRule,
} from 'types/config/productGroup';
import type { IEntityProductGroup } from 'module/purchase';
import type { IComparativeData, IComparativeProductData } from 'submodule/comparativeFeatures';
import { type ComparativeFeatureEnum, ComparativeTableVariantEnum } from 'submodule/comparativeFeatures/enums';

const getComparativeProduct = (group: TProductGroupCode): IConfigProductGroupComparativeProduct | undefined =>
	PRODUCT_GROUP.COMPARATIVE.LIST.find((item) => item.groups.includes(group));

const getComparativeRule = (group: TProductGroupCode): IConfigProductGroupComparativeRule | undefined =>
	PRODUCT_GROUP.COMPARATIVE.RULES.find((rule) => rule.from.includes(group));

export const isFeatureAvailableAsAddon = (feature: ComparativeFeatureEnum): boolean =>
	PRODUCT_GROUP.COMPARATIVE.AVAILABLE_ADDON.includes(feature);

export const isFeatureWindowsOnly = (feature: ComparativeFeatureEnum): boolean =>
	PRODUCT_GROUP.COMPARATIVE.WINDOWS_ONLY.includes(feature);

const hasComparativeProductsEqualFeatures = (
	a: IConfigProductGroupComparativeProduct,
	b: IConfigProductGroupComparativeProduct,
): boolean => a.features.length === b.features.length && difference(a.features, b.features).length === 0;

export const getComparativeData = (
	groups: IEntityProductGroup[],
	groupCode: TProductGroupCode,
): IComparativeData | null => {
	// Get FROM product data
	const comparativeProduct = getComparativeProduct(groupCode);
	const productGroup = groups.find((group) => group.code === groupCode);
	const product = productGroup?.products[0];
	if (!productGroup || !comparativeProduct || !product) {
		return null;
	}

	// Get a Rule
	const comparativeRule = getComparativeRule(groupCode);
	if (!comparativeRule) {
		return null;
	}

	// Get to products
	const items: IComparativeProductData[] = [];

	for (const group of comparativeRule.to) {
		const groupComparativeProduct = getComparativeProduct(group);
		const productGroup = groups.find((group) => group.code === groupComparativeProduct?.groups[0]);
		const product = productGroup?.products[0];

		if (productGroup && groupComparativeProduct && product) {
			items.push({
				product,
				productGroup,
				comparativeProduct: groupComparativeProduct,
				buttonVariant: 'primary',
				isEquivalent:
					group === comparativeRule.toEquivalent &&
					!hasComparativeProductsEqualFeatures(comparativeProduct, groupComparativeProduct),
			});
		}
	}

	if (items.length === 0) {
		return null;
	}

	return {
		message: comparativeRule?.message,
		products: [[{ comparativeProduct, product, productGroup, buttonVariant: 'outline-primary' }, ...items]],
		count: 1 + items.length,
		variant: ComparativeTableVariantEnum.PURCHASE,
	};
};

export const getLicenseOperationComparativeData = (
	groups: IEntityProductGroup[],
	groupCode: TProductGroupCode,
): IComparativeData | null => {
	const data = getComparativeData(groups, groupCode);

	if (data) {
		return { ...data, variant: ComparativeTableVariantEnum.LICENSE_OPERATION };
	}
	return null;
};
