import { useDataTableContext } from 'js/components/molecules/DataTable/DataTableContext';
import classNames from 'classnames';
import { noValuePlaceholder } from 'js/utils/app';
import { DEFAULT_ENTITY_VALUE } from 'appConstants';
import Skeleton from 'react-loading-skeleton';
import type { ReactNode } from 'react';

type TTrProps = {
	name: string;
	children: ReactNode;
	highlighted?: boolean;
	bold?: boolean;
	valueAlign?: 'start' | 'end' | 'center';
	nameAlign?: 'start' | 'end' | 'center';
	noRenderEmpty?: boolean;
	isLoading?: boolean;
	defaultValue?: boolean | ReactNode;
	className?: string;
	nameClassName?: string;
	isEmpty?: boolean;
};

export const DataTableTr = (props: TTrProps) => {
	const dataTableContext = useDataTableContext();
	const isLoading = props.isLoading || dataTableContext.isLoading;
	const {
		valueAlign,
		bold,
		nameAlign,
		highlighted,
		noRenderEmpty,
		children,
		defaultValue = true,
		className,
		isEmpty = true,
	} = props;

	const nameClassName = classNames({ [`text-${nameAlign}`]: Boolean(nameAlign) }, props.nameClassName);
	const valueClassName = classNames({ 'fw-bold': bold, [`text-${valueAlign}`]: Boolean(valueAlign) });

	let value = children;
	if (Boolean(defaultValue) && !children && isEmpty) {
		value = noValuePlaceholder(defaultValue === true ? DEFAULT_ENTITY_VALUE : defaultValue);
	}

	return noRenderEmpty === true && !children ? null : (
		<tr className={classNames(className, { highlighted })}>
			<th className={nameClassName}>{props.name}</th>
			<td className={valueClassName}>{isLoading ? <Skeleton /> : value}</td>
		</tr>
	);
};
