import { DetailList } from 'module/promotions/components/DetailList';
import { PromotionPartyTierEnum, promotionPartyTierEnumUtils } from 'module/promotions/enums';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityPromotion } from 'module/promotions';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';

export const PartyTierRow = ({ maxShownItems = 4 }: { maxShownItems?: number }) => {
	const { data: promotion } = usePageDetailContext<IEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const values = promotion.partnerTierList?.map(promotionPartyTierEnumUtils.getText) || [];

	return (
		<DataTableTr name={t('entity.partyTier', { count: values.length })}>
			<DetailList
				title={t('entity.partyTier', { count: values.length })}
				defaultValue={t('placeholder.partyTiers')}
				values={values}
				maxShownItems={maxShownItems}
				allValues={Object.values(PromotionPartyTierEnum)}
			/>
		</DataTableTr>
	);
};
