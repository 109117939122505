import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { accountConfig } from 'module/account/accountConfig';
import type { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { useAuthContext } from 'js/contexts';

export const AccountInformationCard = (props: Partial<TDataTableCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const { t } = useTranslation(partnersConfig.trNamespace);
	const [tAccount] = useTranslation(accountConfig.trNamespace);

	if (!company) {
		return null;
	}

	return (
		<DataTableCard caption={tAccount('cards.accountInformation')} variant="simple" {...props}>
			<DataTableTr name={t('common:entity.company')}>{getPartnerRenderName(company)}</DataTableTr>
			<DataTableTr name={t('entity.salesforceId')}>{company.salesforceId}</DataTableTr>
			<DataTableTr name={t('common:contact.taxId')}>{company.identifiers?.partyTaxId}</DataTableTr>
			<DataTableTr name={t('common:contact.secondaryTaxId')}>{company.identifiers?.partyTaxId2}</DataTableTr>
			<DataTableTr name={t('common:entity.currency')} noRenderEmpty>
				{company.currencyCode}
			</DataTableTr>
		</DataTableCard>
	);
};
