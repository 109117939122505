import { memo, type PropsWithChildren, type ReactElement } from 'react';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { Outlet } from 'react-router';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { useTranslation } from 'react-i18next';
import type { IModuleMenuConfig } from 'module/index';

export type TModuleContainerProps = {
	moduleMenuConfig: IModuleMenuConfig;
};

const _ModuleContainer = (props: PropsWithChildren<TModuleContainerProps>): ReactElement => {
	const {
		moduleMenuConfig: { menuName, menuLink },
	} = props;
	const { t } = useTranslation();

	return (
		<>
			<DynamicPageTitleItem text={t(menuName)} />
			<DynamicBreadcrumbsItem href={menuLink}>{t(menuName)}</DynamicBreadcrumbsItem>
			<Outlet />
		</>
	);
};

export const ModuleContainer = memo(_ModuleContainer);
