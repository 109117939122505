import type { IEntityPartnerDetail } from 'module/partners';
import { useApiPartnerBySalesforceId } from 'module/partners/hooks/useApiPartners';
import { useCustomerDetail } from 'module/customers/hooks/useCustomerDetail';
import type { IEntityCustomer } from 'module/customers';
import { buildParametrizedRoute, isDefined } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import type { IEntityLicense } from 'module/licenses';
import type { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { useApiDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { getLicenseExternalIds } from 'module/licenses/utils/common';
import { useAuthContext } from 'js/contexts';
import { useCallback } from 'react';
import { useAsyncApiPartnerBySalesforceId } from 'module/partners/hooks';
import { logError } from 'js/utils/app';
import { CustomerSourceEnum } from 'module/customers/enums';
import { useGetCustomerLink } from 'module/customers/hooks';

export type TUseAsyncLicensePartner = {
	partner: IEntityPartnerDetail | null;
	hasPartner: boolean;
};

export type TUseLicensePartner = TUseAsyncLicensePartner & {
	isLoading: boolean;
	isFetching: boolean;
};

type TUseLicenseCustomer = {
	isLoading?: boolean;
	isFetching?: boolean;
	customer: IEntityCustomer | null;
	customerId?: IEntityCustomer['id'] | null;
	customerLink: string | null;
	customerSource: CustomerSourceEnum;
	hasCustomer: boolean;
};

type TUseLicenseDistributionPartner = {
	isLoading?: boolean;
	isFetching?: boolean;
	distributionPartner: IEntityDistributionPartner | null;
	distributionPartnerId?: IEntityDistributionPartner['id'] | null;
	distributionPartnerLink: string | null;
	hasDistributionPartner: boolean;
};

export const useAsyncLicensePartner = (): ((license: IEntityLicense) => Promise<TUseAsyncLicensePartner>) => {
	const { isGroupPartner, authCompanySalesforceId, authCompany } = useAuthContext();
	const apiPartnerBySalesforceId = useAsyncApiPartnerBySalesforceId();

	return useCallback(
		async (license) => {
			const { partnerSalesforceId } = getLicenseExternalIds(license);

			// No partner on the license
			if (!partnerSalesforceId) {
				return {
					partner: null,
					hasPartner: false,
				};
			}

			// Use AuthPartner data if
			//  | the logged partner is from Partner group - is not allowed to load details of other partners
			//  | license SF id is same with the logged partner
			const isAuthPartner =
				isGroupPartner || (Boolean(partnerSalesforceId) && authCompanySalesforceId === partnerSalesforceId);

			if (isAuthPartner) {
				return {
					partner: authCompany,
					hasPartner: true,
				};
			}

			const response = await apiPartnerBySalesforceId(partnerSalesforceId).catch((error) => {
				logError(error);
			});

			return {
				partner: response ? response.data : null,
				hasPartner: true,
			};
		},
		[apiPartnerBySalesforceId, authCompany, authCompanySalesforceId, isGroupPartner],
	);
};

/**
 * @param {IEntityLicense} license
 * @returns {TUseLicensePartner}
 */
export const useLicensePartner = (license: IEntityLicense): TUseLicensePartner => {
	const { partnerSalesforceId } = getLicenseExternalIds(license);
	const { isGroupPartner, authCompanySalesforceId, authCompany } = useAuthContext();

	// Use AuthPartner data if
	//  | the logged partner is from Partner group - is not allowed to load details of other partners
	//  | license SF id is same with the logged partner
	const isAuthPartner =
		isGroupPartner || (Boolean(partnerSalesforceId) && authCompanySalesforceId === partnerSalesforceId);

	const {
		data: partner,
		query: { isLoading, isFetching },
	} = useApiPartnerBySalesforceId({
		id: partnerSalesforceId,
		queryConfig: { enabled: Boolean(partnerSalesforceId) && !isAuthPartner },
	});

	return isAuthPartner
		? {
				partner: authCompany,
				isLoading: false,
				isFetching: false,
				hasPartner: true,
			}
		: {
				partner,
				isLoading,
				isFetching,
				hasPartner: Boolean(partnerSalesforceId),
			};
};

export const useLicenseCustomer = (license: IEntityLicense): TUseLicenseCustomer => {
	const { isGroupPartner } = useAuthContext();
	const partnerQuery = useLicensePartner(license);
	const { customerId } = getLicenseExternalIds(license);
	const getCustomerLink = useGetCustomerLink();
	const {
		response: { data: customer, query },
		source: customerSource,
	} = useCustomerDetail({
		customerId,
		partnerId: partnerQuery.partner?.id,
		enabled: !partnerQuery.isLoading,
	});

	let hasLink = Boolean(customer);
	if (isGroupPartner && customerSource !== CustomerSourceEnum.PARTNER) {
		hasLink = false;
	}

	return {
		customer,
		customerSource,
		isLoading: query.isLoading || partnerQuery.isLoading,
		isFetching: query.isFetching || partnerQuery.isFetching,
		hasCustomer: Boolean(customerId),
		customerId,
		customerLink: hasLink ? getCustomerLink.detail(customer?.id, partnerQuery.partner?.id) : null,
	};
};

export const useLicenseDistributionPartner = (license: IEntityLicense): TUseLicenseDistributionPartner => {
	const { isRoleDistributor, isGroupSales, authCompanyId } = useAuthContext();
	const partnerQuery = useLicensePartner(license);
	const { distributionPartnerId } = getLicenseExternalIds(license);
	const { data: distributionPartner, query } = useApiDistributionPartner({
		filter: {
			id: distributionPartnerId,
			distributorId: partnerQuery.partner?.id,
		},
		queryConfig: { enabled: Boolean(distributionPartnerId) && Boolean(partnerQuery.partner?.id) },
	});

	let link: TUseLicenseDistributionPartner['distributionPartnerLink'] = null;
	if (isDefined(distributionPartner)) {
		let linkPartnerId: IDistributionPartnerSearchFilter['distributorId'];
		if (isRoleDistributor) {
			linkPartnerId = authCompanyId;
		} else if (isGroupSales && isDefined(partnerQuery.partner?.id)) {
			linkPartnerId = partnerQuery.partner?.id;
		}

		if (linkPartnerId) {
			link = buildParametrizedRoute<IDistributionPartnerSearchFilter>(RouteName.DISTRIBUTION_PARTNERS.DETAIL, {
				id: distributionPartner.id!,
				distributorId: linkPartnerId,
			});
		}
	}

	return {
		distributionPartner,
		isLoading: query.isLoading || partnerQuery.isLoading,
		isFetching: query.isFetching || partnerQuery.isFetching,
		hasDistributionPartner: Boolean(distributionPartnerId),
		distributionPartnerId,
		distributionPartnerLink: link,
	};
};
