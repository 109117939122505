import type { ILocaleConfig } from 'types/config';

const numbro = require('numbro/languages/de-DE');

export const de_DE: ILocaleConfig = {
	name: 'German (Germany)',
	locale: 'de-DE',
	language: 'de',
	default: true,
	format: {
		numbro,
		datetime: 'de-DE',
	},
};
