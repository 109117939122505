import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/es-CL');

const locale: TLocale = 'es-CL';

export const es_CL: ILocaleConfig = {
	name: 'Spanish (Chile)',
	locale,
	language: 'es',
	format: {
		numbro,
		datetime: locale,
	},
};
