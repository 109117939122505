import { type ReactElement, useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Row } from 'js/components/atoms/Row';
import { FormikCountrySelect } from 'js/components/formik/FormikCountrySelect';
import { Trans, useTranslation } from 'react-i18next';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import type { ICustomerForm, ICustomerRequest, TEntityCustomerDeepKeys } from 'module/customers/index';
import { customersConfig } from 'module/customers/customersConfig';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useCustomerFormValidator } from 'module/customers/hooks/useCustomerFormValidator';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { isRequired } from 'module/customers/utils/form';
import { FormikDiscountTypeControl } from 'module/customers/forms/FormikDiscountTypeControl';
import { customerTypeEnumUtils } from 'module/customers/enums';
import { FormikCustomerIndustrySelect } from 'js/components/formik/FormikCustomerIndustrySelect';
import { isCustomerBusiness, isCustomerConsumer } from 'module/customers/utils/customerSelectors';
import { customerFormNormalizer } from 'module/customers/normalizer';
import { useNavigate } from 'react-router';
import { isFunction } from 'lodash';
import type { IEntityPartner } from 'module/partners';
import { Alert, FormControl } from '@avast/react-ui-components';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { useAuthContext } from 'js/contexts';
import { SelectPartner } from 'module/partners/components';
import type { TPartnerId } from 'types';
import type { FormikHelpers } from 'formik/dist/types';
import { useCustomerPartnerValidator, useGetCustomerLink } from 'module/customers/hooks';

type TCustomerFormProps = {
	initialValues?: ICustomerForm;
	partnerId?: TPartnerId | null;
	isUpdate?: boolean;
	onCancel?: () => void;
	onSubmit: (values: ICustomerRequest, formikHelpers: FormikHelpers<ICustomerForm>) => void;
};

/**
 * Form for create and update Customer
 * @param {TCustomerFormProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
const CustomerForm = (props: TCustomerFormProps): ReactElement => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const { validate } = useCustomerFormValidator();
	const navigate = useNavigate();
	const { isGroupPartner, authCompanyId, authCompany } = useAuthContext();
	const getCustomerLink = useGetCustomerLink();

	const { initialValues = customersConfig.defaultFormValues, onCancel, onSubmit, isUpdate } = props;
	const [partnerId, setPartnerId] = useState<TPartnerId | undefined | null>(
		isGroupPartner ? authCompanyId : props.partnerId,
	);
	const [partner, setPartner] = useState<IEntityPartner | null>(isGroupPartner ? authCompany : null);
	const partnerValidator = useCustomerPartnerValidator(partner);
	const isPartnerSelectorDisabled = isGroupPartner ? true : Boolean(props.partnerId) && Boolean(partner);

	const onSubmitHandler: FormikConfig<ICustomerForm>['onSubmit'] = (values, formikHelpers) => {
		if (!partnerValidator.isValid) {
			formikHelpers.setSubmitting(false);
			return;
		}

		const request: ICustomerRequest = {
			customer: customerFormNormalizer.denormalize(values),
			partnerId: partnerValidator.partner.id,
		};
		onSubmit(request, formikHelpers);
	};

	return (
		<Formik<ICustomerForm> validate={validate} initialValues={initialValues} onSubmit={onSubmitHandler}>
			{({ values, submitCount }) => (
				<FormikForm testId="customerForm">
					{!isGroupPartner && (
						<div>
							<FormControl
								label={t('common:entity.partner')}
								required
								isWrapper
								messages={submitCount === 0 || partnerValidator.isValid ? undefined : partnerValidator.message}
							>
								<SelectPartner
									value={partnerId}
									disabledLabel
									disabled={isUpdate || isPartnerSelectorDisabled}
									onChange={(partnerId, partner) => {
										setPartnerId(partnerId);
										setPartner(partner);
									}}
								/>
							</FormControl>
							{partner && !isUpdate && (
								<Alert variant="info" className="mt-3">
									<Trans t={t} i18nKey="common.createBehalfPartner" values={{ name: getPartnerRenderName(partner) }} />
								</Alert>
							)}
							<hr />
						</div>
					)}

					<FormikControl label={t('entity.customerType')}>
						<FormikControl.SingleSelect
							name="contactType"
							options={customerTypeEnumUtils.getSelectOptions()}
							disabled={isUpdate && Boolean(initialValues.contactType)}
							required={isRequired(values, 'contactType')}
							isClearable={false}
						/>
					</FormikControl>

					<FormikDiscountTypeControl />

					<hr />

					{/* Base partner data */}
					<Row size="md">
						<ColInput
							name="companyName"
							label={t('common:entity.company')}
							colProps={{ sm: 12 }}
							hidden={isCustomerConsumer(values)}
							required={isRequired(values, 'companyName')}
						/>
						<ColInput
							name="firstName"
							label={t('common:contact.firstName')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'firstName')}
						/>
						<ColInput
							name="lastName"
							label={t('common:contact.lastName')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'lastName')}
						/>
						<ColInput
							name="email"
							label={t('common:contact.email')}
							colProps={{ sm: 12 }}
							required={isRequired(values, 'email')}
							type="email"
							placeholder="@"
							disabled={isUpdate}
							messages={isUpdate ? [] : [[t('page.create.form.email'), 'info']]}
						/>
						<ColInput
							name="phone"
							label={t('common:contact.phone')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'phone')}
						/>
						<ColInput
							name="website"
							label={t('common:contact.website')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'website')}
						/>
					</Row>

					<hr />

					{/* Address */}
					<Row size="md">
						<ColInput
							name="billing.street"
							label={t('common:address.street')}
							colProps={{ sm: 12 }}
							required={isRequired(values, 'billing.street')}
						/>
						<ColInput
							name="billing.postalCode"
							label={t('common:address.postalCode')}
							colProps={{ sm: 4 }}
							required={isRequired(values, 'billing.postalCode')}
						/>
						<ColInput
							name="billing.city"
							label={t('common:address.city')}
							colProps={{ sm: 8 }}
							required={isRequired(values, 'billing.city')}
						/>
					</Row>

					<FormikCountrySelect<ICustomerForm, TEntityCustomerDeepKeys>
						countryField="billing.countryCode"
						countryStateField="billing.stateCode"
						required={isRequired(values, 'billing.countryCode')}
					/>

					{isCustomerBusiness(values) && (
						<>
							<hr />

							<FormikCustomerIndustrySelect name="industry" required={isRequired(values, 'industry')} />
						</>
					)}

					<FormikFooterControls
						submit={{ children: t(`actions.form.${isUpdate ? 'update' : 'create'}`) }}
						cancel={{
							onClick() {
								if (isFunction(onCancel)) {
									onCancel();
								} else {
									navigate(getCustomerLink.list());
								}
							},
						}}
					/>
				</FormikForm>
			)}
		</Formik>
	);
};

export { CustomerForm };
