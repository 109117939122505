import type { TLoginAsAffiliateFormData, TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { useApiLoginAsAffiliate } from 'module/security/hooks/useApiSecurity';
import { useLogout } from 'module/security/hooks/useLogout';
import { useAuthContext } from 'js/contexts';

export const useLoginAsAffiliate = (): ((formData: TLoginAsAffiliateFormData) => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const logout = useLogout();
	const getAuthUserData = useAuthUserData();
	const { mutateAsync: logAsAffiliate } = useApiLoginAsAffiliate();

	return useCallback(
		async (formData) => {
			const errorResponse: TLoginResolve = {
				success: false,
				message: 'state.invalidAuthorizationCode',
			};

			try {
				await logout();

				const response = await logAsAffiliate(formData);
				if (!response) {
					return errorResponse;
				}

				const data = await getAuthUserData(response.data);
				if (!data) {
					return errorResponse;
				}

				setAuthData(data);
				return {
					success: true,
					redirectTo: getInvalidStateLocation(data),
				};
			} catch {
				return errorResponse;
			}
		},
		[logout, logAsAffiliate, getAuthUserData, setAuthData],
	);
};
