import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import type { IStatementDetailItem } from 'module/closingBalance';
import { StatementDetailModalContent } from 'module/closingBalance/components/modal/StatementDetailModalContent';

export type TAsyncStatementDetailModalProps = {
	statementDetail: IStatementDetailItem;
};

export const AsyncStatementDetailModal = (props: TAsyncModalContainerProps<TAsyncStatementDetailModalProps>) => {
	const { forwardedRef } = props;

	const { t } = useTranslation(closingBalanceConfig.trNamespace);

	return (
		<AsyncModal ref={forwardedRef} size="lg" testId="statementDetail">
			{({ statementDetail }) => (
				<>
					<Modal.Header className="pb-0">{t('tab.statement')}</Modal.Header>
					<Modal.Body className="mt-4">
						<StatementDetailModalContent statementDetail={statementDetail} />
					</Modal.Body>
					<Modal.Footer cancelButton={{ children: t('common:actions.close') }} />
				</>
			)}
		</AsyncModal>
	);
};
