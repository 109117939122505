import { useApiDetail } from 'js/hooks/api';
import type { IEntityPartnerDetail } from 'module/partners/index';
import { useCallback, useEffect, useState } from 'react';
import { API_PARTNERS_KEYS } from './useApiPartners';
import type { TSalesforceId } from 'types';

export const useAsyncApiPartnerBySalesforceId = () => {
	const api = useApiDetail<IEntityPartnerDetail, object>({});
	const [abortController] = useState(new AbortController());

	useEffect(() => {
		return () => {
			abortController.abort();
		};
	}, [abortController.abort]);

	return useCallback(
		(id: TSalesforceId) =>
			api({
				queryKey: [API_PARTNERS_KEYS.SF_DETAIL, id, {}],
				meta: undefined,
				signal: abortController.signal,
			}),
		[api, abortController.signal],
	);
};
