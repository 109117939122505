import type { ReactElement } from 'react';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { ComparativeFeaturesTable, type TComparativeFeaturesTableBaseProps } from './ComparativeFeaturesTable';
import { Modal } from '@avast/react-ui-components';
import type { IEntityProductGroup } from 'module/purchase';
import { useTranslation } from 'react-i18next';

export type TAsyncComparativeTableModalProps = TComparativeFeaturesTableBaseProps;

export const AsyncComparativeTableModal = (
	props: TAsyncModalContainerProps<TAsyncComparativeTableModalProps, IEntityProductGroup>,
): ReactElement | null => {
	const { forwardedRef } = props;
	const { t } = useTranslation('submodules');

	return (
		<AsyncModal size="lg" ref={forwardedRef} testId="comparativeFeatures">
			{({ comparativeData, currencyCode }) => (
				<>
					<Modal.Header>{t('comparativeFeatures.title')}</Modal.Header>
					<Modal.Body>
						<ComparativeFeaturesTable
							comparativeData={comparativeData}
							currencyCode={currencyCode}
							onSelect={(group) => {
								if (group) {
									forwardedRef.current?.onSuccess(group);
								} else {
									forwardedRef.current?.onCancel();
								}
							}}
						/>
					</Modal.Body>
				</>
			)}
		</AsyncModal>
	);
};
