import type { ReactElement } from 'react';
import type { TButtonProps } from '@avast/react-ui-components';
import { Button } from '@avast/react-ui-components';
import { isEqual } from 'lodash';
import { gtm } from 'js/analytics/gtm';

export type TQuickFilterButtonProps<T extends object> = Omit<TButtonProps, 'onClick'> & {
	onClick: (data: T) => void;
	values: T;
	filter: T;
};

/**
 * Filter button, marked as active when his filter is used
 * @param {TQuickFilterButtonProps<Filter>} props
 * @returns {ReactElement}
 * @constructor
 */
export const QuickFilterButton = <Filter extends object>(props: TQuickFilterButtonProps<Filter>): ReactElement => {
	const { filter, values, onClick, variant, ...rest } = props;

	// Variant
	let _variant = variant;
	const active = isEqual(filter, values);

	if (active) {
		_variant = 'secondary';
	} else if (!_variant) {
		_variant = 'outline-secondary';
	}

	return (
		<Button
			type="button"
			variant={_variant}
			size="xs"
			onClick={() => {
				if (active) {
					onClick({} as Filter);
				} else {
					onClick(values);
					gtm.quickFilter(String(props.children));
				}
			}}
			{...rest}
		/>
	);
};
