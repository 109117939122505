import type { PropsWithChildren } from 'react';
import type { DroppableProvided } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import type { TWidgetCoords, TWidgetGridColumn } from 'submodule/widgets';
import classNames from 'classnames';
import { useWidgetEditContext } from 'submodule/widgets/context';
import { Col } from 'js/components/atoms/Row';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@avast/react-ui-components';
import { WidgetColumn } from './WidgetColumn';
import { getColKey } from '../utils';
import { WidgetColumnWidthEnum } from 'submodule/widgets/enums/widgetColumnWidthEnum';
import { useTranslation } from 'react-i18next';

type TWidgetPlaceholderColumnProps = {
	column: TWidgetGridColumn;
	coords: TWidgetCoords;
	width: WidgetColumnWidthEnum;
};

type TWidgetContainer = PropsWithChildren<{
	provided: DroppableProvided;
	isActive: boolean;
}>;

const ColumnContainer = ({ provided, isActive, children }: TWidgetContainer) => (
	<div
		ref={provided.innerRef}
		className={classNames('column-container', { 'is-active': isActive })}
		{...provided.droppableProps}
	>
		{children}
	</div>
);

export const WidgetColumnContainer = ({ column, coords, width }: TWidgetPlaceholderColumnProps) => {
	const { t } = useTranslation('submodules');
	const { widgetModal } = useWidgetEditContext();

	const colKey = getColKey(coords);
	const droppableKey = `${colKey}-droppable`;

	const onAddWidgetClick = () => {
		widgetModal.current?.show({
			action: 'ADD_WIDGET',
			title: t('widgets.action.addWidget'),
			index: coords.colIndex,
			coords,
		});
	};

	return (
		<Col key={`widget-column-${coords.colIndex}`} md={width === WidgetColumnWidthEnum.FULL ? 12 : 6}>
			<Droppable type="COLUMN" droppableId={droppableKey} key={droppableKey}>
				{(provided, snapshot) => (
					<ColumnContainer provided={provided} isActive={snapshot.isDraggingOver}>
						<div key={colKey}>
							<WidgetColumn column={column} coords={coords} />
							{provided.placeholder}
						</div>
						<div key={`${colKey}-adding`} className="text-center">
							<Button size="xs" iconLeftFa={faPlus} variant="outline-primary" onClick={onAddWidgetClick}>
								{t('widgets.action.addWidget')}
							</Button>
						</div>
					</ColumnContainer>
				)}
			</Droppable>
		</Col>
	);
};
