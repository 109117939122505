import type { ReactElement } from 'react';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import type { IEntityProductGroup } from 'module/purchase/index';
import { normalizeVariants } from 'js/utils/normalize';
import { selectVariantItemsToInstanceAddItems } from 'module/purchase/utils/common';
import { toast } from 'react-toastify';
import { ToastSuccess } from 'module/purchase/components/selectProducts/ToastSuccess';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';
import { useAddItemsToCart } from 'module/purchase/hooks/order';
import {
	SelectProductVariantModal,
	type TSelectProductVariantModalProps,
} from 'js/components/molecules/Modal/SelectProductVariantModal';
import type { TSelectVariantItem } from 'module/purchase/components/selectProducts/index';
import { isGroupConsumer } from 'module/purchase/utils/selectors';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';

export type TAsyncSelectVariantModalProps = Pick<TSelectProductVariantModalProps, 'productGroup'>;

export const AsyncSelectVariantModal = (
	props: TAsyncModalContainerProps<TAsyncSelectVariantModalProps>,
): ReactElement | null => {
	const { forwardedRef } = props;
	const { updateMode } = useOrderContext();
	const { priceListCode } = usePriceListContext();
	const addItemsToCart = useAddItemsToCart();

	const submitHandler = (productGroup: IEntityProductGroup): TSelectProductVariantModalProps['onSubmit'] => {
		return async (values, options = {}) => {
			const items = normalizeVariants(values);
			const { setSubmitting } = options.formikHelpers ?? {};

			if (items.length === 0) {
				return;
			}

			setSubmitting?.(true);

			if (!(await addItemsToCart(priceListCode, selectVariantItemsToInstanceAddItems(items)))) {
				return;
			}

			toast.success(<ToastSuccess productGroup={productGroup} items={items} isUpdateOrderMode={updateMode} />);
			setSubmitting?.(false);
			forwardedRef.current?.onSuccess(STATUS_SUCCESS);
		};
	};

	const getInitialState = (productGroup: IEntityProductGroup): TSelectVariantItem[] => {
		return productGroup.products.map((product, i) => ({
			id: i,
			checked: false,
			quantity: isGroupConsumer(productGroup) ? 0 : 1,
			unit: isGroupConsumer(productGroup) ? product.bulkQuantity : 0,
			product: orderInstanceNormalizer.normalizeProduct(productGroup, product),
		}));
	};

	return (
		<AsyncModal size="lg" backdrop="static" ref={forwardedRef} testId="selectVariant">
			{({ productGroup }) => (
				<SelectProductVariantModal
					productGroup={productGroup}
					onSubmit={submitHandler(productGroup)}
					initialState={getInitialState(productGroup)}
					module="purchase"
				/>
			)}
		</AsyncModal>
	);
};
