import type { ReactElement } from 'react';
import { partnersConfig } from 'module/partners/partnersConfig';
import { SelectPartnerModal } from 'module/partners/components';
import type { TSelectPartnerModalProps } from 'module/partners/components/SelectPartnerModal';
import type { IEntityPartner } from 'module/partners';
import { SelectModal } from 'js/components/molecules/Modal/SelectModal';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';

export type TSelectPartnerProps = Omit<TSelectPartnerModalProps, 'onChange'> & {
	disabled?: boolean;
	onChange: (value: IEntityPartner['id'], partner: IEntityPartner | null) => void;
};

/**
 * Select partner filter field
 * @param {TSelectPartnerProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectPartner = (props: TSelectPartnerProps): ReactElement => {
	const { onChange, ...rest } = props;

	return (
		<SelectModal<IEntityPartner, object, number>
			testId="selectPartner"
			component={SelectPartnerModal}
			trNamespace={partnersConfig.trNamespace}
			renderValue={(partner) => getPartnerRenderName(partner)}
			onChange={(value) => {
				onChange(value?.id || null, value);
			}}
			{...rest}
		/>
	);
};
