import type { PropsWithChildren, ReactElement } from 'react';
import type { TAlertProps, TModalButtonProps, TModalFooterProps } from '@avast/react-ui-components';
import { Alert, Modal } from '@avast/react-ui-components';
import { ExclamationCircle } from 'assets/image/icon';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import { isString } from 'lodash';

type TMessage = string | Pick<PropsWithChildren<TAlertProps>, 'caption' | 'children'>;

export type TAsyncConfirmationModalProps = {
	messages: TMessage[];
	title: string;
	content?: ReactElement;
	submitButtonText?: string;
	hideSubmitButton?: boolean;
	cancelButtonProps?: TModalButtonProps | boolean;
} & Pick<TModalFooterProps, 'additionalButtons'>;

export const AsyncConfirmationModal = (props: TAsyncModalContainerProps<TAsyncConfirmationModalProps>) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncConfirmationModalProps> ref={forwardedRef} backdrop="static" testId="confirmation" size="sm">
			{({ messages, title, submitButtonText, additionalButtons, content, hideSubmitButton, cancelButtonProps }) => (
				<>
					<Modal.Header className="pb-0">{title}</Modal.Header>
					<Modal.Body className="py-4 py-md-8">
						<p className="text-center mb-4 text-warning">
							<ExclamationCircle />
						</p>
						{messages?.map((message, index) => {
							const props = isString(message) ? { caption: message } : message;
							// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
							return <Alert variant="warning" key={index} {...props} />;
						})}
						{content}
					</Modal.Body>
					<Modal.Footer
						cancelButton={cancelButtonProps}
						additionalButtons={additionalButtons}
						submitButton={
							hideSubmitButton
								? undefined
								: {
										children: submitButtonText,
										onClick() {
											forwardedRef.current?.onSuccess(STATUS_SUCCESS);
										},
									}
						}
					/>
				</>
			)}
		</AsyncModal>
	);
};
