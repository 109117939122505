import { useTranslation } from 'react-i18next';
import { type TPartyType, usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import {
	PriceSummary,
	type TPriceSummary,
	type TPriceSummaryProps,
} from 'js/components/molecules/PriceSummary/PriceSummary';

export type TOrderPriceSummaryProps = {
	customerTotal?: number;
	quotedCustomerTotal?: number;
	partnerTotal?: number | false;
	taxTotal?: number;
	totalWithTax?: number;
	customerPrefixType?: TPartyType;
} & TPriceSummaryProps;

export const OrderPriceSummary = (props: TOrderPriceSummaryProps) => {
	const {
		customerTotal,
		quotedCustomerTotal,
		partnerTotal,
		taxTotal,
		totalWithTax,
		customerPrefixType = 'customer',
		...rest
	} = props;
	const { t } = useTranslation('common');
	const { tPartyPrice } = usePartyPriceTranslation();

	const hasPartnerTotal = partnerTotal !== false;
	const hasCustomerTotal = Boolean(customerTotal);
	const hasQuotedCustomerTotal = Boolean(quotedCustomerTotal);

	// Total&Tax rows
	const taxRows: TPriceSummary<3>['rows'] = [];
	if (totalWithTax) {
		taxRows.push({
			header: t('entity.totalTax'),
			values: [hasPartnerTotal && hasCustomerTotal ? null : false, hasQuotedCustomerTotal ? null : false, taxTotal],
			size: 'sm',
		});
		taxRows.push({
			header: t('entity.totalWTax'),
			values: [hasPartnerTotal && hasCustomerTotal ? null : false, hasQuotedCustomerTotal ? null : false, totalWithTax],
		});
	}

	return (
		<PriceSummary<3>
			{...rest}
			headers={[
				hasCustomerTotal ? tPartyPrice('price', customerPrefixType) : false,
				hasQuotedCustomerTotal ? tPartyPrice('price', 'quotedCustomer') : false,
				hasPartnerTotal ? tPartyPrice('price') : false,
			]}
			rows={[
				{
					header: t('entity.subTotalWOTax'),
					values: [
						hasCustomerTotal ? customerTotal : false,
						hasQuotedCustomerTotal ? quotedCustomerTotal : false,
						hasPartnerTotal ? partnerTotal : false,
					],
					size: totalWithTax ? 'sm' : undefined,
				},
				...taxRows,
			]}
		/>
	);
};
