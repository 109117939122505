import type { IStandardOrderItemPricing } from 'module/purchase';
import type { ReactNode } from 'react';
import { numberFormatter } from 'js/utils/number';
import type { TUsePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import type { IEntityOrderItemComputedValues } from 'module/orders';
import type { TFunction } from 'i18next';

export type TPricingBreakdownLine = [string, ReactNode];

interface IGetPricingBreakdownProps {
	pricing: IStandardOrderItemPricing | null | undefined;
	t: TFunction;
	currencyCode: string | undefined;
	tPartyPrice: TUsePartyPrefixTranslation['tPartyPrice'];
}

interface IGetCustomerPricingBreakdownProps extends IGetPricingBreakdownProps {
	quotedCustomerPricing?: IEntityOrderItemComputedValues['price']['quotedCustomer'];
}

export const getPartnerPricingBreakdown = (props: IGetPricingBreakdownProps): TPricingBreakdownLine[] => {
	const { pricing, t, tPartyPrice, currencyCode } = props;
	const data: TPricingBreakdownLine[] = [];

	// Partner discount
	if (pricing?.discount?.partner?.percent) {
		data.push([
			t('pricing.discount.partner'),
			numberFormatter.percentage(pricing.discount.partner.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Discretionary discount
	if (pricing?.discount?.discretionary?.percent) {
		data.push([
			t('pricing.discount.discretionary'),
			numberFormatter.percentage(pricing.discount.discretionary.percent, {
				optionalMantissa: true,
				isNumber: false,
			}),
		]);
	}

	// Promo discount
	if (pricing?.discount?.promo?.percent) {
		data.push([
			t('pricing.discount.promo'),
			numberFormatter.percentage(pricing.discount.promo.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Add unit price after all discounts
	data.push([tPartyPrice('unitPrice'), numberFormatter.currency(pricing?.unitPrice, currencyCode)]);

	// Total customer price
	data.push([tPartyPrice('totalPrice'), numberFormatter.currency(pricing?.linePriceWOTax, currencyCode)]);

	return data;
};

export const getCustomerPricingBreakdown = (props: IGetCustomerPricingBreakdownProps): TPricingBreakdownLine[] => {
	const { pricing, t, tPartyPrice, currencyCode, quotedCustomerPricing } = props;
	const data: TPricingBreakdownLine[] = [];

	// Add SRP / base unit price
	data.push([tPartyPrice('unitPrice', 'srp'), numberFormatter.currency(pricing?.srpPrice?.unit, currencyCode)]);

	// Add segment discount
	if (pricing?.discount?.segment?.percent) {
		data.push([
			t('pricing.discount.segment'),
			<div key="pricingDiscountSegment">
				{numberFormatter.percentage(pricing.discount.segment.percent, {
					optionalMantissa: true,
					isNumber: false,
				})}{' '}
				({pricing?.customerSegment})
			</div>,
		]);
	}

	// Renewal discount
	if (pricing?.discount?.renewal?.percent) {
		data.push([
			t('pricing.discount.renewal'),
			numberFormatter.percentage(pricing.discount.renewal.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Add unit price after all discounts
	data.push([
		tPartyPrice('unitPrice', 'customer'),
		numberFormatter.currency(pricing?.customerPrice?.unit, currencyCode),
	]);

	// Total customer price
	data.push([
		tPartyPrice('totalPrice', 'customer'),
		numberFormatter.currency(pricing?.customerPrice?.total, currencyCode),
	]);

	// Quoted / Custom price
	if (quotedCustomerPricing) {
		data.push([
			tPartyPrice('unitPrice', 'quotedCustomer'),
			numberFormatter.currency(quotedCustomerPricing.unit, currencyCode),
		]);
		data.push([
			tPartyPrice('totalPrice', 'quotedCustomer'),
			numberFormatter.currency(quotedCustomerPricing.total, currencyCode),
		]);
	}

	return data;
};
