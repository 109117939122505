import { type ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IApiPartnerCustomerListFilter, IEntityCustomer } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useApiPartnerCustomerCount, useApiPartnerCustomerList } from 'module/customers/hooks/useApiCustomers';
import { ApiListTable } from 'js/components/molecules/DataTable';
import type { IApiSortBy } from 'types/api';
import { ButtonComposition } from '@avast/react-ui-components';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { CustomersFilter } from 'module/customers/components';
import { LinkButton } from 'js/components/atoms/Button';
import { Can } from 'js/components/molecules/Can';
import {
	getFilterCharsMessage,
	hasFilterEnoughChars,
	isCustomersApiLimitExceeded,
} from 'module/customers/utils/common';
import { ExportAllCustomersButton } from 'module/customers/components/buttons';
import { useCustomerListColumns, useGetCustomerLink } from 'module/customers/hooks';
import { isFunction, omit } from 'lodash';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useNavigate } from 'react-router';
import { SortDirectionEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityCustomer;
type TTableFilter = IApiPartnerCustomerListFilter;

export const PageList = (): ReactElement => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const getCustomerLink = useGetCustomerLink();
	const { isGroupInternal, authCompanyId } = useAuthContext();
	const [filter, setFilter] = useState<TTableFilter | null>(null);
	const filterStatic: TTableFilter = isGroupInternal ? {} : { partnerId: authCompanyId };
	const sort = useMemo<IApiSortBy<TTableData>>(
		() => ({
			key: 'companyName',
			direction: SortDirectionEnum.ASC,
		}),
		[],
	);
	const columns = useCustomerListColumns(filter?.partnerId);
	const navigate = useNavigate();

	// Get count of customers
	const filterPartnerId = isGroupInternal ? filter?.partnerId : filterStatic.partnerId;
	const {
		data: customersCount,
		query: { isFetching },
	} = useApiPartnerCustomerCount({
		filter: { partnerId: filterPartnerId ?? null },
		queryConfig: { enabled: Boolean(filterPartnerId) },
	});

	return (
		<>
			<FilterPlaceholderPortal>
				<CustomersFilter
					onChange={setFilter}
					values={filter}
					controls={
						<ButtonComposition size="sm">
							{!isCustomersApiLimitExceeded(customersCount) && (
								<ExportAllCustomersButton
									partnerId={filterPartnerId}
									isLoading={isFetching}
									isDisabled={(customersCount?.count || 0) === 0}
								/>
							)}
							<Can do={customersConfig.aclModule} create>
								<LinkButton
									size="sm"
									variant="primary"
									to={getCustomerLink.create(filter?.partnerId)}
									testId="newCustomer"
								>
									{t('actions.newCustomer')}
								</LinkButton>
							</Can>
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>
			{(() => {
				if (isFetching) {
					return <LoadingPlaceholder type="BAR" />;
				}

				if (isCustomersApiLimitExceeded(customersCount)) {
					const hasPartner = Boolean(filterPartnerId);
					const hasEnoughChars = hasFilterEnoughChars(filter?.filter);
					let requiredMessage: string | boolean = false;
					if (!hasPartner) {
						requiredMessage = t('common:filter.partnerIsRequired');
					}
					if (!hasEnoughChars) {
						requiredMessage = getFilterCharsMessage(t);
					}

					return (
						<ApiListTable<TTableData, TTableFilter>
							useLocation
							columns={columns}
							query={useApiPartnerCustomerList}
							filter={filter}
							filterRequired={requiredMessage}
							filterIsEmpty={() => !hasPartner || !hasEnoughChars}
							filterStatic={filterStatic}
							sort={sort}
							table={{
								testId: 'customers',
								meta: {
									paginationComponent: 'Full',
									onRowClick(row) {
										navigate(getCustomerLink.detail(row.id, filter?.partnerId));
									},
								},
							}}
						/>
					);
				}

				return (
					<ApiListTable<TTableData, TTableFilter>
						useLocation
						columns={columns}
						query={useApiPartnerCustomerList}
						filter={filter === null ? filter : omit(filter, 'filter')}
						filterStatic={filterStatic}
						filterRequired={t('common:filter.partnerIsRequired') as string}
						filterIsEmpty={() => !filterPartnerId}
						globalFilterUrlKey="filter"
						sort={sort}
						table={{
							onGlobalFilterChange(value) {
								setFilter((values) => ({
									...values,
									filter: isFunction(value) ? value(values?.filter) : value,
								}));
							},
							state: { globalFilter: filter?.filter },
							meta: {
								paginationComponent: 'Full',
								onRowClick(row) {
									navigate(getCustomerLink.detail(row.id, filter?.partnerId));
								},
							},
						}}
					/>
				);
			})()}
		</>
	);
};
