import { useApi } from 'js/hooks/api';
import { useCallback } from 'react';
import { fillUrlParameters } from 'js/utils/common';
import type { AxiosRequestConfig } from 'axios';
import { API_CUSTOMERS_KEYS } from './useApiCustomers';
import type { IApiCustomerFilter, IApiPartnerCustomerFilter, IEntityCustomer } from 'module/customers';

export const useAsyncApiPartnerCustomer = () => {
	const api = useApi();

	return useCallback(
		(filter: IApiPartnerCustomerFilter, config?: AxiosRequestConfig) => {
			const [url] = fillUrlParameters(API_CUSTOMERS_KEYS.PARTNER_DETAIL, filter);
			return api.get<IEntityCustomer>(`/${url}`, config);
		},
		[api],
	);
};

export const useAsyncApiCustomer = () => {
	const api = useApi();

	return useCallback(
		(filter: IApiCustomerFilter, config?: AxiosRequestConfig) => {
			const [url] = fillUrlParameters(API_CUSTOMERS_KEYS.DETAIL, filter);
			return api.get<IEntityCustomer>(`/${url}`, config);
		},
		[api],
	);
};
