import type { TFormFieldProps, TSingleDatepickerProps } from '@avast/react-ui-components';
import { SingleDatepicker } from '@avast/react-ui-components';
import type { ReactElement } from 'react';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import { formik2Props, type TFormikFieldProps } from 'js/components/formik/FormikControl';
import { useFormikSetFieldValue } from 'js/hooks/useFormikSetFieldValue';
import { useFormikOnBlur } from 'js/hooks/useFormikOnBlur';
import { SubmittingIndicatorEnum } from 'js/enums';

type TFormikSingleDatepickerProps = Partial<TSingleDatepickerProps> & TFormFieldProps;

/**
 * @param {TFormikFieldProps} componentProps
 * @returns {ReactElement}
 * @private
 */
export const FormikSingleDatepicker = (
	componentProps: TFormikFieldProps<TFormikSingleDatepickerProps>,
): ReactElement => {
	const { name, onChange, onBlur, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	const formikOnBlur = useFormikOnBlur(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<SingleDatepicker
					{...fieldProps.field}
					{...formik2Props<TFormikSingleDatepickerProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
					onChange={(value) => {
						formikSetFieldValue(value);
						onChange?.(value);
					}}
					onBlur={() => {
						formikOnBlur();
						onBlur?.();
					}}
				/>
			)}
		</Field>
	);
};
