import type { AxiosResponse } from 'axios';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { merge } from 'lodash';
import { useApiDetail } from 'js/hooks/api';
import type { TQueryConfig } from 'js/queries';
import type { TUseApiDetailQueryKey } from 'js/hooks/api/useApiDetail';
import type { IAxiosApiError } from 'types/api';

/**
 * Response from paginated api call
 */
export type TUseApiDetailQueryResponse<Data extends {}, TErr = IAxiosApiError> = {
	data: Data | null;
	query: UseQueryResult<AxiosResponse<Data>, TErr>;
};

/**
 * Props of module api query function
 */
export type TUseApiDetailProps<
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	QueryData = Data,
	TErr = IAxiosApiError,
> = {
	id?: string | number | null;
	filter?: Filter;
	config?: TQueryConfig;
	queryConfig?: Omit<
		UseQueryOptions<AxiosResponse<QueryData>, TErr, AxiosResponse<Data>, TUseApiDetailQueryKey<Filter>>,
		'queryFn' | 'queryKey'
	>;
};

/**
 * Module api query function type
 */
export type TUseApiDetailModule<
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	QueryData = Data,
	TErr = IAxiosApiError,
> = (props?: TUseApiDetailProps<Data, Filter, QueryData, TErr>) => TUseApiDetailQueryResponse<Data, TErr>;

/**
 * Query hook for get paginated data
 * @param {string} key
 * @param {TUseApiDetailProps} props
 * @param {TUseApiDetailProps} defaults
 * @returns {TUseApiDetailQueryResponse}
 */
export const useApiDetailQuery = <
	Data extends {},
	Filter extends {} = Record<string, unknown>,
	QueryData = Data,
	Error = IAxiosApiError,
>(
	key: string,
	props: TUseApiDetailProps<Data, Filter, QueryData, Error> = {},
	defaults?: TUseApiDetailProps<Data, Partial<Filter>, QueryData, Error>,
): TUseApiDetailQueryResponse<Data, Error> => {
	const { id, filter, config, queryConfig } = merge(defaults, props);

	const queryFn = useApiDetail<QueryData, Filter>({
		config: {
			...config,
		},
	});

	const response = useQuery({
		queryKey: [key, id, filter as Filter],
		queryFn,
		refetchOnWindowFocus: false,
		...queryConfig,
	});

	return { query: response, data: response.data?.data ?? null };
};
