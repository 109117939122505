export enum CustomEventEnum {
	CLOSE_MODALS = 'CLOSE_MODALS',
}

export const customEvent = {
	subscribe(name: CustomEventEnum, listener: EventListener) {
		document.addEventListener(name.toString(), listener);
	},
	unsubscribe(name: CustomEventEnum, listener: EventListener) {
		document.removeEventListener(name.toString(), listener);
	},
	trigger(name: CustomEventEnum, data?: unknown) {
		const event = new CustomEvent(name.toString(), { detail: data });
		document.dispatchEvent(event);
	},
};
