import { useApiCountry } from 'js/entities/country/useApiCountry';

export const useCountryName = (countryCode?: string | null, countryName?: string): string => {
	const { data: country } = useApiCountry({
		// @ts-ignore The hook runs query only when countryCode is not null
		filter: { countryCode },
		queryConfig: { enabled: Boolean(countryCode) && !countryName },
	});

	if (!countryCode && !countryName) {
		console.debug('Values countryCode and countryName are missing. At least one of them has to be present.');
	}

	return countryName || country?.name || '';
};
