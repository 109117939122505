import { useFormikContext } from 'formik';
import type { IPriceCalculatorForm } from 'module/priceCalculator';
import { useBillablePartyGuard } from './useBillablePartyGuard';

export const useFormStepsGuard = () => {
	const { values } = useFormikContext<IPriceCalculatorForm>();
	const billablePartyGuard = useBillablePartyGuard();

	// Billable party
	const isBillablePartyStepFulfilled =
		billablePartyGuard.isExistingPartner || (Boolean(values.countryCode) && Boolean(values.currencyCode));

	// Product
	const isProductStepFulfilled = Boolean(values.productGroup) && Boolean(values.units);

	return {
		isPriceStepEnabled: isBillablePartyStepFulfilled && isProductStepFulfilled,
		// Active step
		isBillablePartyStepActive: !isBillablePartyStepFulfilled,
		isProductStepActive: isBillablePartyStepFulfilled && !isProductStepFulfilled,
		isDiscountStepActive: isBillablePartyStepFulfilled && isProductStepFulfilled,
		isPriceStepActive: isBillablePartyStepFulfilled && isProductStepFulfilled,
	};
};
