import { DateTime } from 'luxon';
import { CONFIG } from 'config';
import type { IEntityPromotion } from 'module/promotions';
import type { ReactElement } from 'react';
import i18n from 'i18n';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { PromotionStateEnum } from 'module/promotions/enums';

/**
 * Finds out whether the promotion can be edited.
 * @param {IEntityPromotion} promotion - Promotion has to contain startDate in string form
 * @returns {boolean}
 */
export const isPromotionEditable = (promotion: IEntityPromotion): boolean => {
	const startDateISOForm = DateTime.fromISO(promotion.startDate, { zone: CONFIG.LOCALE.TIMEZONE });

	return startDateISOForm.diffNow().toMillis() >= 0 && promotion.state === PromotionStateEnum.ENABLED;
};

/**
 * Formats the list of products. <br/>
 * Returns string form of SKUs or shortened version **5 products** or **All Products**.
 *
 * @param {IEntityPromotion.skuList} skuList
 * @returns {JSX.Element | string}
 *
 * @example
 * skuListFormatter(undefined) => "All Products"
 * skuListFormatter([]) => "All Products"
 * skuListFormatter(["stl.*.36m"]) => "stl.*.36m"
 * skuListFormatter(["bmp.*.12m", "bmp.*.24m", "bmp.*.36m", "pmg.*.12m"]) => "4 Products"
 */
export const skuListFormatter = (skuList: IEntityPromotion['skuList']): ReactElement | string => {
	if (!skuList || skuList.length === 0) {
		return <em className="text-gray">{i18n.t(promotionsConfig.trPrefix('placeholder.products'))}</em>;
	}

	const count = skuList.length;

	if (count === 1) {
		return skuList[0];
	}

	return (
		<em className="text-gray">
			{count} {i18n.t(promotionsConfig.trPrefix('entity.product'), { count })}
		</em>
	);
};
