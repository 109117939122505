import type { ReactElement } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { AdditionalData, OrderTable } from 'module/purchase/components/order';
import { OrderPriceSummary } from 'js/components/molecules/PriceSummary';
import { usePricingMetadata } from 'module/purchase/hooks/pricing/usePricingMetadata';
import { RequiredCustomerAlert } from 'module/purchase/components/RequiredCustomerAlert';
import { RequiredDistributionPartnerAlert } from 'module/purchase/components/RequiredDistributionPartnerAlert';
import { FormikErrors } from 'js/components/formik/FormikErrors';
import { getOrderInstanceSavedCustomerPrice } from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';

export const OrderSummary = (): ReactElement => {
	const { billableParty, orderState, isEndCustomerBillableParty } = useOrderContext();
	const { pricing } = orderState;
	const pricingMetadata = usePricingMetadata();

	return (
		<div className="container-fluid component__orderReview-table">
			<div className="container-orderReview">
				<RequiredDistributionPartnerAlert />
				<RequiredCustomerAlert />

				<OrderTable />
				<Row multi justify="between" align="center" className="my-3">
					<Col lg={5} className="py-lg-0">
						<AdditionalData />
						<FormikErrors fields={['additionalInfo.externalPurchaseNumber']} />
					</Col>
					<Col lg={7} className="py-lg-0">
						<OrderPriceSummary
							isLoading={!pricing}
							customerTotal={
								isEndCustomerBillableParty
									? pricing?.headers.totalAmountWithoutTax
									: pricing?.headers.customerTotalPrice
							}
							quotedCustomerTotal={getOrderInstanceSavedCustomerPrice(orderState)}
							partnerTotal={isEndCustomerBillableParty ? false : pricing?.headers.totalAmountWithoutTax}
							currencyCode={billableParty?.currencyCode}
							customerPrefixType={pricingMetadata.customerPricePrefixType}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};
