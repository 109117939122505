import { Button } from '@avast/react-ui-components';
import { useCallback } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import { useTranslation } from 'react-i18next';

type TGuideNextButtonProps = {
	slider?: EmblaCarouselType;
	onFinish(): void;
};

export const GuideNextButton = (props: TGuideNextButtonProps) => {
	const { slider, onFinish } = props;
	const { t } = useTranslation('common');
	const isLast = !slider?.canScrollNext();

	const clickHandler = useCallback(
		() => (isLast ? onFinish() : slider?.scrollNext()),
		[isLast, onFinish, slider?.scrollNext],
	);

	return (
		<Button variant="primary" onClick={clickHandler} className="px-7">
			{t(`actions.${isLast ? 'close' : 'next'}`)}
		</Button>
	);
};
