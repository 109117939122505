import type { IEntityLicense } from 'module/licenses';
import { useCallback } from 'react';
import { refundLicenseListNormalizer } from 'submodule/refunds/normalizer';
import { logError } from 'js/utils/app';
import { useAsyncApiLicenseRefundableItem } from 'submodule/refunds/hooks/useAsyncApiLicenseRefundableItem';
import type { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { TAsyncSelectRefundLicenseModalProps } from 'submodule/refunds/initiate/search/AsyncSelectRefundLicenseModal';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import { createInitialFormLicenseItem } from 'submodule/refunds/refundUtils';
import type { IInitiateRefundTableItem } from 'submodule/refunds';
import { useAsyncApiLicenseListByLicenseString } from 'module/licenses/hooks/useAsyncApiLicenses';
import { useAppContext, usePageDetailContext } from 'js/contexts';
import type { IEntityClosingBalance } from 'module/closingBalance';

type TUseApiRefundLicenseListProps = {
	selectLicenseRef: TAsyncModalRef<TAsyncSelectRefundLicenseModalProps, IEntityLicense>;
	onSuccess(license: IInitiateRefundTableItem): void;
	onError(error: string): void;
};

export const useRefundLicenseByLicenseString = (props: TUseApiRefundLicenseListProps) => {
	const { onError, onSuccess, selectLicenseRef } = props;
	const { data: closingBalance } = usePageDetailContext<IEntityClosingBalance>();
	const { t } = useInitiateRefundContext();
	const { loadingModalRef } = useAppContext();

	// Api's
	const asyncApiLicenseListByLicenseString = useAsyncApiLicenseListByLicenseString();
	const asyncApiLicenseRefundableItem = useAsyncApiLicenseRefundableItem();

	return useCallback(
		async (licenseKey?: string) => {
			if (!licenseKey || !closingBalance.periodActivityUuid) {
				return;
			}

			// Find licenses
			loadingModalRef.current?.show({ title: t('refunds.license.search.loadingLicenses') });
			const response = await asyncApiLicenseListByLicenseString(licenseKey).catch(logError);
			loadingModalRef.current?.hide();

			if (!response?.data) {
				onError(t('refunds.license.search.searchFailure', { licenseKey }));
				return;
			}

			// Normalize licenses
			const licenses = refundLicenseListNormalizer.normalize(response.data, closingBalance.periodActivityUuid);
			if (licenses.length === 0) {
				onError(t('refunds.license.search.searchFailure', { licenseKey }));
				return;
			}

			// Select license
			let license: IEntityLicense = licenses[0];
			if (licenses.length > 1) {
				const licenseResponse = await selectLicenseRef.current?.show({ licenses });
				if (!licenseResponse) {
					return; /* Cancelled */
				}
				license = licenseResponse;
			}

			// Load refundable amount for the license
			loadingModalRef.current?.show({ title: t('refunds.license.search.loadingRefundableAmount') });
			const refundableItem = await asyncApiLicenseRefundableItem(license).catch(logError);
			loadingModalRef.current?.hide();

			if (!refundableItem) {
				onError(t('refunds.license.search.refundableAmountFailure', { licenseKey }));
				return;
			}

			onSuccess(createInitialFormLicenseItem(license, refundableItem));
		},
		[
			t,
			closingBalance.periodActivityUuid,
			asyncApiLicenseListByLicenseString,
			asyncApiLicenseRefundableItem,
			loadingModalRef,
			onSuccess,
			onError,
			selectLicenseRef,
		],
	);
};
