import { useCallback } from 'react';
import { logDebug, logError } from 'js/utils/app';
import { useAutoBillingContext } from 'module/account/context/AutoBillingContext';
import type { IDigitalRiverElement } from 'types/digitalRiver';
import type { ICreditCardForm } from 'module/account/forms/CreditCardForm';
import { API_AUTO_BILLING_KEYS, useApiAutoBillingSetPaymentSourceId } from 'module/account/hooks/useApiAutoBilling';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { API_PARTNERS_KEYS } from 'module/partners/hooks/useApiPartners';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { useApiErrorContext, useAppContext } from 'js/contexts';

type TAutoBillingCreateSourceProps = {
	values: ICreditCardForm;
	cardNumber: IDigitalRiverElement;
	paymentSessionId: string;
};

export const useAutoBillingCreateSource = () => {
	const { loadingModalRef, apiErrorModalRef } = useAppContext();
	const { digitalRiver } = useAutoBillingContext();
	const { setError } = useApiErrorContext();
	const { mutateAsync } = useApiAutoBillingSetPaymentSourceId();
	const invalidateQueries = useAsyncInvalidateQueries([API_AUTO_BILLING_KEYS.SOURCE, API_PARTNERS_KEYS.DETAIL]);
	const { t } = useTranslation(accountConfig.trNamespace);

	return useCallback(
		(props: TAutoBillingCreateSourceProps) => {
			const {
				values: { isPreferredPayAsYouGoPaymentMethod, ...values },
				cardNumber,
				paymentSessionId,
			} = props;
			return new Promise<boolean>((resolve, reject) => {
				loadingModalRef.current?.show({
					title: t('creditCard.message.saving'),
					ellipsis: true,
				});
				digitalRiver
					.createSource(cardNumber, { ...values, sessionId: paymentSessionId })
					.then(async (response) => {
						if (response.source) {
							await mutateAsync({
								paymentSourceId: response.source.id,
								paymentSessionId: response.source.sessionId,
								isPreferredPayAsYouGoPaymentMethod,
							})
								.then(async ({ data }) => {
									logDebug('Save source data success', data);
									await invalidateQueries({ refetchType: 'none' });
									resolve(true);
								})
								.catch((error) => {
									logError('Save source data failed', error);
									setError({
										error,
										onClose: () => resolve(false),
										resolve: apiCriticalErrorResolve,
									});
								});
						} else if (response.error) {
							// @see https://docs.digitalriver.com/commerce-api/error-codes#credit-card-error-and-declined-message
							if (response.error.type === 'validation_error') {
								resolve(false);
							} else if (response.error.type === 'bad_request') {
								logError('DR create source failed.', response.error);
								await apiErrorModalRef.current?.show({
									error: {
										messages: [t(`creditCard.error.${response.error.type}`)],
										errorData: response.error.errors,
									},
								});
								resolve(false);
							} else {
								logError('DR create source failed.', response.error);
								await apiErrorModalRef.current?.show({
									error: {
										messages: [t('error:common.contactSales')],
										errorData: response.error,
									},
								});
								reject(new Error('DR create source failed.'));
							}
						}
						loadingModalRef.current?.hide();
					})
					.catch((error) => {
						logError('DR create source failed.', error);
						loadingModalRef.current?.hide();
						reject(error);
					});
			});
		},
		[t, digitalRiver, loadingModalRef, mutateAsync, invalidateQueries, apiErrorModalRef, setError],
	);
};
