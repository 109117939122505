import type { IEntityClosingBalance, IEntityOmsClosingBalance } from 'module/closingBalance';
import { billingSystemEnumUtils } from 'js/enums';

export const closingBalanceEntityNormalizer = {
	normalizeList(items: IEntityOmsClosingBalance[]): IEntityClosingBalance[] {
		return items.map((item) => this.normalize(item));
	},

	normalize(closingBalance: IEntityOmsClosingBalance): IEntityClosingBalance {
		return {
			...closingBalance,
			billingSystem: billingSystemEnumUtils.fromOrderAction(closingBalance.userOrderAction),
		};
	},
};
