import { H3, H4, Modal } from '@avast/react-ui-components';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { GoalSeekedPriceForm, type IGoalSeekedPriceForm } from 'module/priceCalculator/forms/GoalSeekedPriceForm';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import type { IEntityProduct, IEntityProductGroup } from 'module/purchase';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { numberFormatter } from 'js/utils/number';
import type { TCurrency } from 'types';

export type TAsyncGoalSeekedPriceModalProps = {
	productGroup: IEntityProductGroup;
	product: IEntityProduct;
	initialPrice: number;
	currencyCode?: TCurrency | null;
};

export const AsyncGoalSeekedPriceModal = (
	props: TAsyncModalContainerProps<TAsyncGoalSeekedPriceModalProps, IGoalSeekedPriceForm>,
) => {
	const { forwardedRef } = props;
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);

	return (
		<AsyncModal<TAsyncGoalSeekedPriceModalProps, IGoalSeekedPriceForm> ref={forwardedRef} size="sm">
			{({ initialPrice, currencyCode, productGroup, product }) => (
				<>
					<Modal.Header>{t('goalSeekedPrice.title')}</Modal.Header>
					<Modal.Body>
						<H3 bold>
							{productGroup.name} ({productGroup.code})
						</H3>
						<H4>{getProductValidityLabels(product).label}</H4>

						<p className="mt-2 mb-4">
							{t('goalSeekedPrice.originalPrice')}: {numberFormatter.currency(initialPrice, currencyCode)}
						</p>
						<GoalSeekedPriceForm
							initialPrice={initialPrice}
							onCancel={() => forwardedRef.current?.onCancel()}
							onSubmit={(values) => forwardedRef.current?.onSuccess(values)}
							currencyCode={currencyCode!}
						/>
					</Modal.Body>
				</>
			)}
		</AsyncModal>
	);
};
