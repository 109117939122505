import type { PropsWithChildren } from 'react';

export const StartComponent = ({ children }: PropsWithChildren<object>) => {
	if (children) {
		return (
			<>
				{children}
				<div className="component__line flex-grow-1" />
			</>
		);
	}

	return <div className="component__line flex-grow-1" />;
};
