import { Alert, InlineButton } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useState } from 'react';
import { useInviteToFlexibleBilling } from 'module/flexibleBilling/hooks';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { InviteToFlexibleBillingModal } from './InviteToFlexibleBillingModal';
import type { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';
import { useApiNotificationList } from 'js/notifications/useApiNotifications';
import { DomainEventEnum, NotificationTypeEnum } from 'js/notifications/enums';
import { SortDirectionEnum } from 'js/enums';
import Skeleton from 'react-loading-skeleton';

export const InviteToFlexibleBillingInfo = () => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const {
		data: { distributionPartner, mspId },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();
	const {
		data: notifications,
		query: { isFetching },
	} = useApiNotificationList({
		filter: {
			partnerId: authCompanyId!,
			salesforceId: distributionPartner.id!,
			domainEvent: DomainEventEnum.MSP_ONBOARDING_INVITATION,
			type: NotificationTypeEnum.EMAIL,
		},
		sortBy: {
			key: 'createdAt',
			direction: SortDirectionEnum.DESC,
		},
	});

	const modalRef = useAsyncModalRef();
	const invite = useInviteToFlexibleBilling();
	const invitationLinkSent = Boolean(notifications?.items?.length);
	const [invitationLinkSentFlag, setInvitationLinkSentFlag] = useState(false);

	if (mspId) {
		return null;
	}

	if (isFetching) {
		return (
			<Alert variant="info" icon>
				<p>
					<Skeleton />
				</p>
				<Skeleton width={120} />
			</Alert>
		);
	}

	return (
		<>
			<Alert variant="info" icon>
				<p>{t('invite.info')}</p>
				<InlineButton variant="primary" type="button" onClick={() => modalRef.current?.show()}>
					{invitationLinkSent || invitationLinkSentFlag ? t('invite.action.resend') : t('invite.action.send')}
				</InlineButton>
			</Alert>

			<AsyncModal ref={modalRef} testId="InviteToFlexibleBilling" size="sm">
				<InviteToFlexibleBillingModal
					distributionPartner={distributionPartner}
					notifications={notifications?.items}
					onCancel={() => modalRef.current?.hide()}
					onSubmit={async (values) => {
						const isSuccess = await invite(distributionPartner, { email: values.differentEmailValue });
						if (isSuccess) {
							modalRef.current?.hide();
							setInvitationLinkSentFlag(true);
						}
						return isSuccess;
					}}
				/>
			</AsyncModal>
		</>
	);
};
