import { ButtonComposition } from '@avast/react-ui-components';
import { LinkButton } from 'js/components/atoms/Button';
import { buildParametrizedRoute, tableFilterCreator } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import type { ILicenseListFilter } from 'module/licenses';
import type { IOrderListFilter } from 'module/orders';
import { Can } from 'js/components/molecules/Can';
import { customersConfig } from 'module/customers/customersConfig';
import React from 'react';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import type { IEntityCustomer } from 'module/customers/index';
import { useTranslation } from 'react-i18next';
import { useGetCustomerLink } from 'module/customers/hooks';
import type { TPartnerId } from 'types';

type TDetailViewActionsProps = {
	partnerId?: TPartnerId;
	disabled?: boolean;
};

export const DetailViewActions = (props: TDetailViewActionsProps) => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const {
		data: customer,
		query: { isFetching },
	} = usePageDetailContext<IEntityCustomer>();
	const { isGroupPartner } = useAuthContext();
	const getCustomerLink = useGetCustomerLink();

	return (
		<ButtonComposition size="sm" testId="customerActions">
			<LinkButton
				testId="licenses"
				variant="outline-primary"
				size="sm"
				loading={isFetching}
				disabled={props.disabled}
				to={buildParametrizedRoute(
					RouteName.LICENSE.LIST,
					tableFilterCreator<ILicenseListFilter>({ customerId: customer.id }),
				)}
			>
				{t('actions.viewLicenses')}
			</LinkButton>
			<LinkButton
				testId="orders"
				variant="outline-primary"
				size="sm"
				loading={isFetching}
				disabled={props.disabled}
				to={buildParametrizedRoute(
					RouteName.ORDER.LIST,
					tableFilterCreator<IOrderListFilter>({ customerId: customer.id }),
				)}
			>
				{t('actions.viewOrders')}
			</LinkButton>
			{!isGroupPartner && (
				<LinkButton
					testId="quotes"
					variant="outline-primary"
					size="sm"
					loading={isFetching}
					disabled={props.disabled}
					to={buildParametrizedRoute(
						RouteName.QUOTE.LIST,
						tableFilterCreator<IOrderListFilter>({ customerId: customer.id }),
					)}
				>
					{t('actions.viewQuotes')}
				</LinkButton>
			)}
			<Can do={customersConfig.aclModule} update isAllowed={isGroupPartner || Boolean(props.partnerId)}>
				{(allowed) => (
					<LinkButton
						testId="update"
						size="sm"
						variant="primary"
						loading={isFetching}
						disabled={props.disabled || !allowed}
						to={getCustomerLink.update(customer.id, props.partnerId)}
					>
						{t('actions.edit')}
					</LinkButton>
				)}
			</Can>
		</ButtonComposition>
	);
};
