import { useCallback, useState } from 'react';
import { useApiRegistrationInit } from 'module/registration/hooks/useApiRegistration';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { RouteName } from 'module/RouteName';
import { logError } from 'js/utils/app';
import { apiErrorWrapper } from 'js/utils/apiError';
import { useNavigate } from 'react-router';
import { useAppContext } from 'js/contexts';
import { initRegistrationApiError } from 'module/registration/utils/apiError';

export const useInitRegistration = () => {
	const { apiErrorModalRef } = useAppContext();
	const { partnerId } = useRegistrationContext();
	const navigate = useNavigate();
	const { mutateAsync: initRegistration } = useApiRegistrationInit({
		config: {
			params: { partnerId },
		},
	});
	const [isReady, setIsReady] = useState(false);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	const handler = useCallback(async () => {
		const response = await initRegistration(
			{},
			{
				async onError(e) {
					logError('Unable to init registration', e);
					const error = apiErrorWrapper(e, initRegistrationApiError);
					if (error) {
						await apiErrorModalRef.current?.show({ error });
					}
					navigate(RouteName.SECURITY.LOGOUT);
				},
			},
		);

		setIsReady(Boolean(response));
	}, []);

	return {
		isLoading: !isReady,
		handler,
	};
};
