import type { TWidgetCoords, TWidgetGridColumn, TWidgetGridItem } from 'submodule/widgets/index';
import { WidgetItemContainer } from 'submodule/widgets/components/WidgetItemContainer';
import { getColKey } from '../utils';
import { useTranslation } from 'react-i18next';

type TWidgetColumn = {
	column: TWidgetGridColumn;
	coords: TWidgetCoords;
};

export const WidgetColumn = ({ column, coords }: TWidgetColumn) => {
	const { t } = useTranslation('submodules');

	if (column.length === 0) {
		return <div className="pt-1 pb-2 text-center fs-md text-muted">{t('widgets.common.columnPlaceholder')}</div>;
	}

	return (
		<>
			{column.map((item: TWidgetGridItem, itemIndex: number) => (
				<WidgetItemContainer
					key={`${getColKey(coords)}-${itemIndex}`}
					item={item}
					coords={coords}
					itemIndex={itemIndex}
				/>
			))}
		</>
	);
};
