import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import { FormikControl } from 'js/components/formik/FormikControl';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { useApiAuthorityListByCountry } from 'module/account/hooks/useApiAccount';
import { FormikFileUpload } from 'js/components/fileUpload';

type TTaxExemptionFormProps = {
	countryCode?: string | null;
};

export const TaxExemptionForm = (props: TTaxExemptionFormProps) => {
	const { countryCode } = props;
	const { t } = useTranslation(accountConfig.trNamespace);
	const {
		data,
		query: { isLoading },
	} = useApiAuthorityListByCountry({ filter: { countryCode } });
	const options = data?.map((country: string) => ({ label: country, value: country }));

	return (
		<>
			<Row size="md">
				<ColInput required label={t('common:entity.companyName')} name="companyName" colProps={{ sm: 12 }} />

				<Col sm={6}>
					<FormikControl label={t('taxExemption.startDate')}>
						<FormikControl.SingleDatepicker maxDate={new Date()} name="startDate" required />
					</FormikControl>
				</Col>

				<Col sm={6}>
					<FormikControl label={t('taxExemption.endDate')}>
						<FormikControl.SingleDatepicker minDate={new Date()} name="endDate" required />
					</FormikControl>
				</Col>

				<Col sm={12}>
					<FormikFileUpload
						label={t('form.taxExemption.file')}
						name="file"
						required
						accept={accountConfig.taxExemptFile.mimeType}
						acceptExtensions={accountConfig.taxExemptFile.extensions}
					/>
				</Col>

				<Col sm={12}>
					<FormikControl label={t('taxExemption.taxAuthority')}>
						<FormikControl.SingleSelect
							isLoading={isLoading}
							name="taxAuthority"
							placeholder={t('form.taxExemption.taxAuthorityPlaceholder')}
							options={options}
							required
						/>
					</FormikControl>
				</Col>
			</Row>
			<p className="fs-md">{t('form.taxExemption.declaration')}</p>
		</>
	);
};
