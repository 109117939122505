import type { TStandardOrderInstanceAddItem } from 'module/purchase';
import { useCallback } from 'react';
import { isGroupBusiness } from 'module/purchase/utils/selectors';
import {
	getLicenseOperationEntities,
	isDecreaseOperation,
	isIncreaseOperation,
	normalizeLicenseOperationKey,
} from 'module/licenses/utils/licenseOperation';
import { getFulfillmentValue } from 'module/licenses/utils/common';
import type { ILicenseOperationForm } from 'module/licenses';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';

export const useLicenseOperationToOrderItem = () => {
	const { license, licenseProductOperations } = useLicenseOperationContext();

	return useCallback(
		(values: ILicenseOperationForm) => {
			const { productGroupCode, variant, licenseOperation, quantity, unitToIncrease, unitToDecrease } = values;
			const { product, productGroup } = getLicenseOperationEntities(
				licenseProductOperations,
				licenseOperation,
				productGroupCode,
				variant,
			);

			if (!licenseOperation || !product || !productGroup) {
				return null;
			}

			const isBusiness = isGroupBusiness(productGroup);
			const unit = isBusiness ? values.unit : product.bulkQuantity;
			const item: TStandardOrderInstanceAddItem = {
				licenseOperation: normalizeLicenseOperationKey(licenseOperation, { isBusiness }),
				product: orderInstanceNormalizer.normalizeProduct(productGroup, product),
				license: {
					id: license.id,
					expireDate: license.expiration,
					createDate: license.createdAt,
					licenseKey: getFulfillmentValue(license),
				},
				quantity,
				unit,
				unitToIncrease: 0,
				licenseUnit: unit,
				licenseSku: license.computedValues.productSku,
			};

			if (isBusiness) {
				if (isIncreaseOperation(item.licenseOperation)) {
					item.unitToIncrease = unitToIncrease;
					item.unit! += unitToIncrease;
				}

				if (isDecreaseOperation(item.licenseOperation)) {
					item.unitToIncrease = unitToDecrease;
					item.unit! -= unitToDecrease;
				}
			}

			return item;
		},
		[license, licenseProductOperations],
	);
};
