import { Draggable } from 'react-beautiful-dnd';
import type { TWidgetCoords, TWidgetGridItem } from 'submodule/widgets';
import { ButtonComposition, Card, CardHeader, IconButton } from '@avast/react-ui-components';
import { useWidgetHeader } from 'submodule/widgets/hooks';
import { isString } from 'lodash';
import { useWidgetItem } from 'submodule/widgets/hooks/useWidgetItem';

export type TWidgetPlaceholderItemProps = {
	item: TWidgetGridItem;
	itemIndex: number;
	coords: TWidgetCoords;
};

export const WidgetItemContainer = ({ item, itemIndex, coords }: TWidgetPlaceholderItemProps) => {
	const { title, description, meta } = useWidgetHeader(item.widget);
	const { editWidgetProps, removeWidgetProps, draggableContainerProps, getDraggableContentProps, draggableKey } =
		useWidgetItem({
			item,
			itemIndex,
			coords,
		});

	return (
		<Draggable key={draggableKey} {...draggableContainerProps}>
			{(provided, snapshot) => (
				<div {...getDraggableContentProps(provided, snapshot)}>
					<Card>
						<CardHeader bottomBorder={false} className="d-flex justify-content-between">
							<div>
								<div>{title}</div>
								{isString(meta) && <div className="fs-md">{meta}</div>}
								{isString(description) && <div className="fs-md text-gray">{description}</div>}
							</div>
							<ButtonComposition size="xs" marginY={false} className="ms-3">
								<IconButton {...editWidgetProps} />
								<IconButton {...removeWidgetProps} />
							</ButtonComposition>
						</CardHeader>
					</Card>
				</div>
			)}
		</Draggable>
	);
};
