import { useMemo } from 'react';
import type { TSelectOptions } from '@avast/react-ui-components';
import { useApiIndustryOptions } from 'module/customers/hooks/useApiCustomers';

type TUseIndustrySelectOptions = {
	isIndustrySelectOptionsLoading: boolean;
	industrySelectOptions: TSelectOptions;
};

export const useIndustrySelectOptions = (): TUseIndustrySelectOptions => {
	const {
		data,
		query: { isFetching },
	} = useApiIndustryOptions();

	const industrySelectOptions: TUseIndustrySelectOptions['industrySelectOptions'] = useMemo(
		() =>
			data?.map((industry) => ({
				value: industry,
				label: industry,
			})) || [],
		[data],
	);

	return {
		industrySelectOptions,
		isIndustrySelectOptionsLoading: isFetching,
	};
};
