import { StepperVerticalContainer, StepperVerticalHeadline } from 'submodule/stepperVertical';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { useBillablePartyGuard, useFormStepsGuard } from 'module/priceCalculator/guards';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { type DiscountTypeEnum, discountTypeEnumUtils } from 'module/customers/enums';
import { useExistingPartnerDiscountsGuard } from 'module/priceCalculator/guards/useExistingPartnerDiscountsGuard';
import { tierCertificationEnumUtils } from 'js/enums';

export const DiscountStep = () => {
	const { t } = useTranslation(priceCalculatorConfig.trNamespace);
	const billablePartyGuard = useBillablePartyGuard();
	const existingPartnerDiscountsGuard = useExistingPartnerDiscountsGuard();
	const formStepsGuard = useFormStepsGuard();

	return (
		<StepperVerticalContainer border className="py-5">
			<StepperVerticalHeadline
				step={3}
				title={t('steps.discounts.title')}
				className="mb-3"
				active={formStepsGuard.isDiscountStepActive}
			/>

			<Row size="sm">
				{billablePartyGuard.isPartner && (
					<Col md={3} sm={6}>
						<FormikControl label={t('steps.discounts.tier')}>
							<FormikControl.SingleSelect
								name="discount.tierCertification"
								size="sm"
								disabled={existingPartnerDiscountsGuard.isSelected}
								options={tierCertificationEnumUtils.getSelectOptions()}
							/>
						</FormikControl>
					</Col>
				)}
				{billablePartyGuard.isPartner && (
					<Col md={3} sm={6}>
						<FormikControl label={t('steps.discounts.special')}>
							<FormikControl.Number
								name="discount.special"
								size="sm"
								min={0}
								max={0.99}
								step={0.01}
								mantissa={2}
								numberFormat={{
									output: 'percent',
									spaceSeparated: true,
									trimMantissa: true,
								}}
								disabled={existingPartnerDiscountsGuard.isSelected}
							/>
						</FormikControl>
					</Col>
				)}
				<Col md={3} sm={6}>
					<FormikControl label={t('steps.discounts.segment')}>
						<FormikControl.SingleSelect<DiscountTypeEnum>
							name="discount.segment"
							size="sm"
							options={discountTypeEnumUtils.getSelectOptions()}
						/>
					</FormikControl>
				</Col>
				<Col md={3} sm={6}>
					<FormikControl label={t('steps.discounts.discretionary')}>
						<FormikControl.Number
							name="discount.discretionary"
							size="sm"
							min={0}
							max={0.99}
							step={0.01}
							mantissa={4}
							numberFormat={{
								output: 'percent',
								spaceSeparated: true,
								trimMantissa: true,
							}}
						/>
					</FormikControl>
				</Col>
			</Row>
		</StepperVerticalContainer>
	);
};
