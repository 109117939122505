import type { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { create } from 'zustand';
import { tail } from 'lodash';
import type { TDynamicPageTitleItemProps } from 'js/components/molecules/DynamicPageTitle';

interface IDynamicTitleStore {
	titleParts: TDynamicPageTitleItemProps[];
	setTitlePart: (titleParts: TDynamicPageTitleItemProps) => void;
	removeTitlePart: () => void;
}

export const titleStore = create<IDynamicTitleStore>()((set) => ({
	titleParts: [],
	setTitlePart: (newTitlePart) => set((state) => ({ titleParts: [newTitlePart, ...state.titleParts] })),
	removeTitlePart: () => set((state) => ({ titleParts: tail(state.titleParts) })),
}));

/**
 * Render page title by Helmet
 */
export const DynamicPageTitle = (): ReactElement => {
	const titleParts = titleStore((state) => state.titleParts);

	return <Helmet title={titleParts.join(' | ')} />;
};
