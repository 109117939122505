import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { useCountryName } from 'js/entities/country/useCountryName';
import { useCountryStateName } from 'js/entities/country/useCountryStateName';
import { useAuthContext } from 'js/contexts';

export const AddressInformationCard = (props: Partial<TDataTableCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const countryName = useCountryName(company?.countryCode, company?.countryName);
	const stateName = useCountryStateName(company?.countryCode, company?.stateCode, company?.stateName);

	if (!company) {
		return null;
	}

	return (
		<DataTableCard caption={t('cards.addressInformation')} variant="simple" {...props}>
			<DataTableTr name={t('common:address.street')}>{company.address1}</DataTableTr>
			<DataTableTr name={t('common:address.city')}>{company.city}</DataTableTr>
			<DataTableTr name={t('common:address.postalCode')}>{company.postalCode}</DataTableTr>
			<DataTableTr name={t('common:address.state')} noRenderEmpty>
				{stateName}
			</DataTableTr>
			<DataTableTr name={t('common:address.country')}>{countryName}</DataTableTr>
		</DataTableCard>
	);
};
