import type {
	IEntityAffiliateConfig,
	IEntityPartner,
	IEntityPartnerDetail,
	TAffiliateConfigApiValue,
} from 'module/partners';
import { createAxiosInstance } from 'api/setupInterceptors';
import { fillUrlParameters } from 'js/utils/common';
import { API_PARTNERS_KEYS } from 'module/partners/hooks/useApiPartners';
import type { AxiosRequestConfig } from 'axios';
import type { AffiliateConfigEnum } from 'module/partners/enums';

export const getApiPartnerDetailPromise = (id: IEntityPartner['id'], config: AxiosRequestConfig) => {
	const api = createAxiosInstance();
	const [url] = fillUrlParameters(API_PARTNERS_KEYS.DETAIL, { id });

	return api.get<IEntityPartnerDetail>(`/${url}`, config);
};

export const getApiAffiliateConfigProperty = <ApiType extends TAffiliateConfigApiValue>(
	id: IEntityPartner['id'],
	name: AffiliateConfigEnum,
	config?: AxiosRequestConfig,
) => {
	const api = createAxiosInstance();
	const [url] = fillUrlParameters(API_PARTNERS_KEYS.CONFIG, { id, name });

	return api.get<IEntityAffiliateConfig<ApiType>>(`/${url}`, config);
};

export const postApiAffiliateConfigProperty = <ApiType extends TAffiliateConfigApiValue>(
	id: IEntityPartner['id'],
	name: AffiliateConfigEnum,
	value: ApiType,
	config?: AxiosRequestConfig,
) => {
	const api = createAxiosInstance();
	const [url] = fillUrlParameters(API_PARTNERS_KEYS.CONFIG, { id, name });

	return api.post<IEntityAffiliateConfig<ApiType>>(
		`/${url}`,
		{ value },
		{
			...config,
			headers: {
				...config?.headers,
				'content-type': 'application/x-www-form-urlencoded',
			},
		},
	);
};
