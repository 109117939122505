import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_DETAIL, ACL_READ_LIST, ACL_UPDATE } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { ordersConfig } from 'module/orders/ordersConfig';
import { PageDetail, PageList, PageUpdate } from 'module/orders/pages';

export const OrderRoutes = () => {
	const { aclModule } = ordersConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} />}>
				<Route index element={<PageList />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} />}>
				<Route path="detail/:id" element={<PageDetail />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_UPDATE]} />}>
				<Route path="detail/:id/update" element={<PageUpdate />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
