import type { ReactNode } from 'react';
import { PriceSummary } from 'js/components/molecules/PriceSummary';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useTranslation } from 'react-i18next';
import { SpendingOverviewHeader } from 'module/flexibleBilling/components/SpendingOverviewHeader';
import type { FixedLengthArray } from 'type-fest';
import type { TPriceSummaryRows } from 'js/components/molecules/PriceSummary/PriceSummary';
import type { IFlexibleBillingSpendingOverviewValues } from 'module/flexibleBilling';
import { UnlimitedValue } from 'module/flexibleBilling/components/UnlimitedValue';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';

type TSpendingOverviewProps = {
	currency?: string;
	className?: string;
	values?: IFlexibleBillingSpendingOverviewValues | null;
	isLoading?: boolean;
	type: 'partner' | 'distributor';
};

export const SpendingOverview = (props: TSpendingOverviewProps) => {
	type N = 3;
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const { values } = props;

	const headers: FixedLengthArray<ReactNode, N> = [
		<SpendingOverviewHeader
			key="forecastedSpend"
			text={t('common.forecastedSpend')}
			tooltipText={t(`tooltips.forecastedSpend.${props.type}`)}
		/>,
		<SpendingOverviewHeader
			key="currentSpend"
			text={t('common.currentSpend')}
			tooltipText={t(`tooltips.currentSpend.${props.type}`)}
		/>,
		<SpendingOverviewHeader
			key="maxSpend"
			text={t('common.maxSpend')}
			tooltipText={t(`tooltips.maxSpend.${props.type}`)}
		/>,
	];

	const rows: TPriceSummaryRows<N>[] = [
		{
			header: t('common:entity.subTotalWOTax'),
			values: [
				values?.forecast,
				values?.actual,
				values?.max === FLEXIBLE_BILLING_UNLIMITED_VALUE ? <UnlimitedValue key="max" tooltip /> : values?.max,
			],
		},
	];

	return (
		<PriceSummary<N>
			className={props.className}
			headers={headers}
			currencyCode={props.currency}
			rows={rows}
			isLoading={props.isLoading}
		/>
	);
};
