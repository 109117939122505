import { type ReactElement, useMemo, useState } from 'react';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import type { IClosingBalanceListFilter, IEntityClosingBalance } from 'module/closingBalance';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { ClosingBalanceFilter } from 'module/closingBalance/components';
import type { IApiSortBy } from 'types/api';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { DateTime } from 'luxon';
import { ExportClosingBalanceButton } from 'module/closingBalance/components/buttons';
import type { IListMetaDataValues } from 'types/utils';
import { useClosingBalanceList } from 'module/closingBalance/hooks/useClosingBalanceList';
import { useClosingBalanceListColumns } from 'module/closingBalance/hooks';
import { useNavigate } from 'react-router';
import { SortDirectionEnum } from 'js/enums';

type TTableData = IEntityClosingBalance;
type TTableDataFilter = IClosingBalanceListFilter;

export const PageList = (): ReactElement => {
	const navigate = useNavigate();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<IClosingBalanceListFilter>>({});

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);
	const defaultFilter = useMemo<TTableDataFilter>(
		() => ({
			dateRange: [
				dateTimeToFilterDate(DateTime.now().minus({ months: 1 }).startOf('month')),
				dateTimeToFilterDate(DateTime.now().minus({ months: 1 }).endOf('month')),
			],
		}),
		[],
	);

	// Order
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'id', direction: SortDirectionEnum.DESC });

	// Define columns
	const columns = useClosingBalanceListColumns();

	return (
		<DefaultContainer>
			<FilterPlaceholderPortal>
				<ClosingBalanceFilter
					defaultValues={defaultFilter}
					values={filter}
					onChange={setFilter}
					controls={<ExportClosingBalanceButton format="list" listMetaData={listMetaData} />}
				/>
			</FilterPlaceholderPortal>
			<ApiPaginatedListTable<TTableData, TTableDataFilter>
				columns={columns}
				query={useClosingBalanceList}
				useLocation
				sort={sort}
				filter={filter}
				table={{
					enableSorting: false,
					meta: {
						onRowClick: (row) => navigate(closingBalanceConfig.detailLink(row)),
					},
				}}
				onMetaDataChange={setListMetaData}
			/>
		</DefaultContainer>
	);
};
