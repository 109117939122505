import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IEntityLicense } from 'module/licenses';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { extractLicenseSku, getFulfillmentValue, getLicenseExternalIds } from 'module/licenses/utils/common';
import { LicenseCustomerName } from 'module/customers/components';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { licenseStatusEnumUtils } from 'module/licenses/enums';
import type { TWidgetColumns } from 'submodule/widgets';

type TTableData = IEntityLicense;

export const useLicenseWidgetColumns = (): TWidgetColumns<TTableData> => {
	const { t } = useTranslation(licensesConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		const columns = [
			columnHelper.ellipsis(
				'product.productGroupName',
				{
					header: t('common:entity.productName'),
					id: 'product_productGroupName',
				},
				{ width: 200 },
			),
			columnHelper.text(extractLicenseSku, {
				header: t('common:entity.sku'),
				id: 'sku',
			}),
			columnHelper.ellipsis(
				(row) => getLicenseExternalIds(row).customerId,
				{
					header: t('common:entity.customer'),
					id: 'customer',
					enableSorting: false,
					meta: { formatters: [(_, { row }) => <LicenseCustomerName license={row.original} />] },
				},
				{ width: 200, tooltip: (cell) => <LicenseCustomerName license={cell.row.original} /> },
			),
			columnHelper.link(
				getFulfillmentValue,
				{
					header: t('common:entity.licenseKey'),
					id: 'licenseKey',
				},
				{
					to: (license) => buildParametrizedRoute(RouteName.LICENSE.DETAIL, license.id),
					copy: true,
				},
			),
			columnHelper.text('status', {
				header: t('common:entity.status'),
				meta: {
					formatters: [licenseStatusEnumUtils.getText],
				},
			}),
			columnHelper.date('expiration', {
				header: t('common:dates.expire'),
				meta: {
					align: 'end',
				},
			}),
		];

		return {
			columns,
			columnVisibility: { sku: false, status: false },
		};
	}, [t]);
};
