import { ButtonComposition, H1 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { Headline } from 'js/components/molecules/Headline';
import { SelectPartnerButton, ViewOrdersButton } from './buttons';

export const PageListHeader = () => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);

	return (
		<Headline
			className="align-items-center"
			rightContent={
				<ButtonComposition size="sm">
					<ViewOrdersButton />
					<SelectPartnerButton />
				</ButtonComposition>
			}
		>
			<H1 type="h2">{t('page.title')}</H1>
		</Headline>
	);
};
