import type { ReactNode } from 'react';
import { Modal, UiTable } from '@avast/react-ui-components';
import type { IDocument } from 'js/chargeOrder';
import { useDocumentsColumns } from 'js/components/documents/hooks';

export type TDocumentsModalContentProps = {
	title: ReactNode;
	documentFileNamePrefix?: string;
	documents: IDocument[];
	isLoading?: boolean;
};

export const DocumentsModalContent = (props: TDocumentsModalContentProps) => {
	const columns = useDocumentsColumns(props.documentFileNamePrefix);

	return (
		<>
			<Modal.Header>{props.title}</Modal.Header>
			<Modal.Body>
				<UiTable<IDocument>
					columns={columns}
					data={props.documents}
					meta={{
						loading: props.isLoading,
						isScrollable: true,
						rowCount: 3, // Init loading rows count
						tableProps: { className: 'table-head-autoHeight' },
					}}
				/>
			</Modal.Body>
			<Modal.Footer />
		</>
	);
};
