import type { FunctionComponent } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { ExclamationCircle } from 'assets/image/icon';
import { AuthPartnerDropdownCol, HeaderLogo } from 'js/layouts/components';
import { Outlet } from 'react-router';

export const WarningLayout: FunctionComponent = () => (
	<div className="fullHeight-layout">
		<div className="header-app justify-content-end">
			<HeaderLogo collapsedLogo={false} />
			<AuthPartnerDropdownCol />
		</div>

		<div className="container">
			<Row justify="center">
				<Col xl={6} lg={9} className="text-center">
					<p className="mb-4 text-danger">
						<ExclamationCircle />
					</p>
					<Outlet />
				</Col>
			</Row>
		</div>
	</div>
);
