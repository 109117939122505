import type React from 'react';
import { PureComponent } from 'react';
import type { TableProps } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import { _CustomTd } from 'js/components/molecules/CustomTable/Td';
import { _CustomTh } from 'js/components/molecules/CustomTable/Th';

export class CustomTable extends PureComponent<TableProps & React.TableHTMLAttributes<HTMLTableElement>> {
	static Td = _CustomTd;
	static Th = _CustomTh;

	render(): React.ReactNode {
		const { children, className, ...props } = this.props;

		return (
			<Table {...props} className={classNames('table-custom', className)}>
				{children}
			</Table>
		);
	}
}
