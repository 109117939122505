import { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TSelectEntityProps, TSelectEntityPropsWithChildren } from 'js/components/molecules/Modal';
import type { IEntityPartnerDetail } from 'module/partners';
import { partnersConfig } from 'module/partners/partnersConfig';
import { ModalWrapper } from 'js/components/molecules/Modal/ModalWrapper';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { useCompare } from 'js/hooks/useCompare';
import {
	SelectPartnerContainer,
	type TSelectPartnerContainerProps,
} from 'module/partners/components/SelectPartnerContainer';

type TTableData = IEntityPartnerDetail;
export type TSelectPartnerModalProps = TSelectEntityProps<TTableData, number> &
	Omit<TSelectPartnerContainerProps, 'selectedPartnerId'> & {
		partner?: IEntityPartnerDetail | null;
	};

/**
 * Select partner filter field
 * @param {TSelectPartnerModalProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectPartnerModal = (
	props: TSelectEntityPropsWithChildren<TTableData, TSelectPartnerModalProps, number>,
): ReactElement => {
	// Constants
	const {
		value: partnerId,
		onChange,
		accountTypesWhiteList,
		accountSubTypesWhiteList,
		staticFilter,
		partner = null,
		detailed,
	} = props;

	// Component hooks
	const { t } = useTranslation(partnersConfig.trNamespace);
	const [entity, setEntity] = useState<TTableData | null>(partner);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const entityIsLoaded = Boolean(entity);

	// Reset value - clear
	const resetValue = useCallback(() => {
		onChange(null);
	}, [onChange]);

	// Function for set entity - after select from table
	const setValue = useCallback(
		(row: TTableData) => {
			setEntity(row);
			onChange(row);
			setModalShow(false);
		},
		[onChange],
	);

	// Load data on init -> get selected row
	const {
		data,
		query: { isFetching, isSuccess },
	} = useApiPartner({
		id: partnerId,
		queryConfig: {
			enabled: Boolean(partnerId) && (!entityIsLoaded || partnerId !== entity?.id),
		},
	});
	const dataChanged = useCompare(data);

	useEffect(() => {
		if (isSuccess && dataChanged) {
			onChange(data);
			setEntity(data);
		}
	}, [data, dataChanged, onChange, isSuccess]);

	return (
		<>
			{props.children({
				setModalShow,
				resetValue,
				value: props.value,
				entity: data || entity,
				loading: isFetching,
			})}
			{modalShow && (
				<ModalWrapper title={t('select.title')} toggle={setModalShow} show={modalShow} size="lg" testId="selectPartner">
					<SelectPartnerContainer
						selectedPartnerId={props?.value}
						accountTypesWhiteList={accountTypesWhiteList}
						accountSubTypesWhiteList={accountSubTypesWhiteList}
						onSelect={setValue}
						detailed={detailed}
						staticFilter={staticFilter}
					/>
				</ModalWrapper>
			)}
		</>
	);
};
