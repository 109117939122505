import { type Dispatch, useCallback } from 'react';
import type { IStandardOrderInstance } from 'module/purchase';
import { isOrderInstanceObsolete, logDebug } from 'js/utils/app';
import type { IAuthData } from 'js/reducers/authContextReducer';
import type { TOrderStateAction } from 'js/reducers/orderReducer';
import { useRefreshOrderPartnerState } from 'js/hooks/useRefreshOrderPartnerState';
import { useAsyncApiCustomer, useAsyncApiPartnerCustomer } from 'module/customers/hooks/useAsyncApiCustomers';
import { isEqual } from 'lodash';
import { useAsyncApiDistributionPartner } from 'module/distributionPartners/hooks/useAsyncApiDistributionPartners';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';
import invariant from 'invariant';
import { isDefined } from 'js/utils/common';

export interface IRefreshOrderStateProps {
	state: IStandardOrderInstance | null;
	authData?: IAuthData;
	dispatch: Dispatch<TOrderStateAction>;
}

// TODO: Hook throw errors
export const useRefreshOrderState = () => {
	const refreshOrderPartnerState = useRefreshOrderPartnerState();
	const asyncApiCustomer = useAsyncApiCustomer();
	const asyncApiPartnerCustomer = useAsyncApiPartnerCustomer();
	const asyncApiDistributionPartner = useAsyncApiDistributionPartner();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		async (props: IRefreshOrderStateProps): Promise<void> => {
			const { state, dispatch, authData } = props;

			// Do not run when is restricted
			if (isRefreshRestricted) {
				return;
			}

			// Logged partner has different ID then state owner
			if (isOrderInstanceObsolete(state, authData?.partner?.id)) {
				return;
			}

			// Partner Order
			if (state?.partner) {
				logDebug('useRefreshOrderState', 'PARTNER');
				const partnerPayload = await refreshOrderPartnerState(props);

				invariant(partnerPayload !== null, 'Could not load partner data for the order.');

				// Partner customer
				if (state.customer) {
					logDebug('useRefreshOrderState', 'PARTNER CUSTOMER');
					const partnerId = partnerPayload.partner.id;
					const customerId = state.customer.id;

					invariant(partnerId !== null, 'Get Order partner failed. Partner on the order has no id number');
					invariant(isDefined(customerId), 'Get Order customer failed. Customer on the order has no id number');

					const customerResponse = await asyncApiPartnerCustomer(
						{
							partnerId: partnerId,
							salesforceId: customerId,
						},
						{ authToken: authData?.token },
					).catch((error) => {
						logDebug('Get Order customer failed.', customerId, error);
						throw new Error(
							`Get Order customer failed.
							customerId: ${customerId},
							error: ${JSON.stringify(error)}`,
						);
					});

					if (!isEqual(customerResponse.data, state.customer)) {
						dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
					}
				}

				// Partner distribution partner
				if (state.distributionPartner) {
					logDebug('useRefreshOrderState', 'DISTRIBUTION PARTNER');
					const distributorId = partnerPayload.partner.id; // Partner is distributor
					const distributionPartnerId = state.distributionPartner.id;

					const distributionPartnerResponse = await asyncApiDistributionPartner(
						{
							distributorId: distributorId,
							id: distributionPartnerId,
						},
						{ authToken: authData?.token },
					).catch((error) => {
						logDebug('Get Order distribution partner failed.', distributionPartnerId, error);
						throw new Error(
							`Get Order distribution partner failed.
							distributionPartnerId: ${distributionPartnerId},
							error: ${JSON.stringify(error)}`,
						);
					});

					if (!isEqual(distributionPartnerResponse.data, state.customer)) {
						dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: distributionPartnerResponse.data });
					}
				}
			}

			// Customer Order
			else if (state?.customer) {
				logDebug('useRefreshOrderState', 'CUSTOMER');
				const customerId = state.customer.id;

				invariant(isDefined(customerId), 'Get Order customer failed. Customer on the order has no id number');

				const customerResponse = await asyncApiCustomer(
					{ salesforceId: customerId },
					{ authToken: authData?.token },
				).catch((error) => {
					logDebug('Get Order customer failed.', state.customer?.id, error);
					throw new Error(
						`Get Order customer failed.
							customerId: ${customerId},
							error: ${JSON.stringify(error)}`,
					);
				});

				if (!isEqual(customerResponse.data, state.customer)) {
					dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
				}
			}
		},
		[
			refreshOrderPartnerState,
			asyncApiPartnerCustomer,
			asyncApiCustomer,
			asyncApiDistributionPartner,
			isRefreshRestricted,
		],
	);
};
