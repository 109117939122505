import { useApi } from 'js/hooks/api';
import type { AxiosInstance, AxiosResponse } from 'axios';
import type { TUseApiProps } from 'js/hooks/api/useApi';
import { fillUrlParameters } from 'js/utils/common';
import { ApiMutationTypeEnum } from 'js/enums';

type TUseApiMutationProps = TUseApiProps & {
	key: string;
	type?: ApiMutationTypeEnum;
};

type TUseApiMutation<Data extends {}, Response> = (data: Data) => Promise<AxiosResponse<Response>>;

export const useApiMutation = <Data extends {}, Response = Data, Params extends {} = Record<string, unknown>>(
	props: TUseApiMutationProps,
): TUseApiMutation<Data, Response> => {
	const { key, type = ApiMutationTypeEnum.POST, config } = props;
	const { params = {} } = config || {};
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (data) => {
		const [route, _params] = fillUrlParameters<Partial<Params>>(key, params);
		let promise: Promise<AxiosResponse<Response>>;

		// DELETE action do not need data
		if (type === ApiMutationTypeEnum.DELETE) {
			promise = api.delete<Data, AxiosResponse<Response>>(`/${route}`, {
				...config,
				params: _params,
			});
		} else {
			let instance: AxiosInstance['post'] | AxiosInstance['put'] | AxiosInstance['patch'];
			switch (type) {
				case ApiMutationTypeEnum.PUT:
					instance = api.put;
					break;
				case ApiMutationTypeEnum.PATCH:
					instance = api.patch;
					break;
				case ApiMutationTypeEnum.POST:
					instance = api.post;
					break;
				default:
					throw new TypeError(`Not supported api mutation type: ${type}`);
			}

			promise = instance<Data, AxiosResponse<Response>>(`/${route}`, data, {
				...config,
				params: _params,
			});
		}

		return promise;
	};
};
