import type { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useApiCreditStatus } from 'module/creditStatus/hooks/useApiCreditStatus';
import { CreditStatusCard } from 'module/creditStatus/components';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

/**
 * Order credit status tab
 * @return {ReactElement}
 * @constructor
 */
export const CreditStatusTab = (): ReactElement => {
	const { t } = useTranslation(creditStatusConfig.trNamespace);
	const {
		orderState: { partner, pricing },
	} = useOrderContext();

	// Load data on init -> get selected row
	const {
		data: creditStatus,
		query: { isLoading },
	} = useApiCreditStatus({
		filter: { id: partner?.id },
		config: { catchError: false },
		queryConfig: {
			enabled: isDefined(partner?.id),
			retry: false,
		},
	});

	if (isLoading) {
		return <LoadingPlaceholder />;
	}
	if (creditStatus) {
		return <CreditStatusCard creditStatus={creditStatus} orderValue={pricing?.headers.totalAmountWithoutTax} />;
	}

	return <Alert variant="danger" caption={t('error.creditStatusNotFound')} />;
};
