import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type {
	IApiLocReviewRequestNotificationRequest,
	INotification,
	INotificationListApiFilter,
} from 'js/notifications';
import { ApiMutationTypeEnum } from 'js/enums';
import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';

export const API_NOTIFICATION_KEYS = {
	LIST: 'notification',
	REQUEST: 'notification/loc-review-request',
};

export const useApiLocReviewRequestNotification: TUseApiMutationModule<
	IApiLocReviewRequestNotificationRequest,
	Record<string, unknown>
> = (props) => useApiMutationQuery(API_NOTIFICATION_KEYS.REQUEST, ApiMutationTypeEnum.POST, props);

export const useApiNotificationList: TUseApiPaginatedListModule<INotification, INotificationListApiFilter> = (
	props,
) => {
	return useApiPaginatedListQuery(API_NOTIFICATION_KEYS.LIST, props);
};
