import { useApiAsyncJob } from 'js/asyncJobs/useApiAsyncJobs';
import { logError } from 'js/utils/app';
import { isAsyncJobDone, isAsyncJobInError } from 'js/asyncJobs/asyncJobsUtils';
import type { IAsyncJob } from 'js/asyncJobs';
import { isNumber } from 'lodash';
import { useEffect } from 'react';

export const useFetchAsyncJobRecursively = (
	asyncJob: IAsyncJob | null,
	onSuccess: (asyncJob: IAsyncJob) => void,
	onError: () => void,
	delay = 500,
) => {
	const { data } = useApiAsyncJob({
		id: asyncJob?.id,
		queryConfig: {
			enabled: isNumber(asyncJob?.id),
			meta: {
				onError() {
					logError(asyncJob);
					onError();
				},
			},
			refetchInterval(query) {
				const data = query.state.data;
				console.log('fetchAsync', { data, delay });
				if (!data) {
					return false;
				}

				const asyncJob = data.data;
				console.log('fetchAsync result', { done: isAsyncJobDone(asyncJob), error: isAsyncJobInError(asyncJob) });
				if (isAsyncJobDone(asyncJob)) {
					return false;
				}

				if (isAsyncJobInError(asyncJob)) {
					logError(asyncJob);
					onError();
					return false;
				}

				return delay;
			},
		},
	});

	useEffect(() => {
		if (data && isAsyncJobDone(data)) {
			onSuccess(data);
		}
	}, [data, onSuccess]);
};
