import { type IPageModuleConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import type { ICustomerForm } from 'module/customers';
import { CustomerTypeEnum } from 'module/customers/enums';

interface ICustomersConfig extends IPageModuleConfig {
	defaultFormValues: ICustomerForm;
}

export const customersConfig = moduleConfigGenerator<ICustomersConfig>({
	trNamespace: 'moduleCustomers',
	defaultFormValues: {
		email: '',
		companyName: '',
		firstName: '',
		lastName: '',
		phone: '',
		website: '',
		contactType: CustomerTypeEnum.CONSUMER,
		billing: {
			city: '',
			countryCode: '',
		},
	},
	menuName: 'app:menu.customers',
	menuLink: RouteName.CUSTOMERS.LIST,
	aclModule: 'customers',
});
