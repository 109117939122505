import type { TableColumnsDef } from 'types';
import type { IEntityLicense } from 'module/licenses';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { getFulfillmentValue, getLicenseId, getWalletKey, licenseStatusFormatter } from 'module/licenses/utils/common';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { noValuePlaceholder } from 'js/utils/app';
import { licenseStatusEnumUtils } from 'module/licenses/enums';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';

type TTableData = IEntityLicense;

export const useOrderItemLicensesColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(licensesConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text(getLicenseId, { header: t('common:entity.id') }),
			columnHelper.copy2clipboard(getFulfillmentValue, { header: t('common:entity.licenseKey') }),
			columnHelper.copy2clipboard(getWalletKey, { header: t('entity.walletKey') }),
			columnHelper.timeZoneDateTime('expiration', {
				header: t('common:dates.expire'),
				meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
			}),
			columnHelper.text('status', {
				header: t('entity.statusShort'),
				meta: {
					formatters: [(value) => licenseStatusFormatter(value, licenseStatusEnumUtils.getText(value)), formatter.bold],
				},
			}),
			columnHelper.actions({
				iconLink: {
					icon: faArrowRight,
					to: (row) => buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.id),
					title: t('common:actions.detail'),
				},
			}),
		];
	}, [t]);
};
