import { Alert } from '@avast/react-ui-components';
import type { IEntityPartnerDetail } from 'module/partners/index';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';

type TPartnerCurrencyAlertProps = {
	partner: IEntityPartnerDetail;
};

export const PartnerCurrencyAlert = (props: TPartnerCurrencyAlertProps) => {
	const { t } = useTranslation(partnersConfig.trNamespace);

	if (!props.partner.currencyCode) {
		return null;
	}

	return (
		<Alert variant="info" icon={false} className="mt-3 mb-0 py-3 py-2">
			<p className="text-primary">
				<strong>{t('common:entity.currency')}:</strong> {props.partner.currencyCode}
			</p>
		</Alert>
	);
};
