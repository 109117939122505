import { type ReactElement, useCallback, useState } from 'react';
import { Button, ButtonComposition, Card, CardBody, CardHeader, IconButton, Tooltip } from '@avast/react-ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { SelectCardState } from 'module/purchase/components/widget';
import { DistributionPartnerCard } from 'module/distributionPartners/components';
import { IconChange, IconClose } from 'assets/image/icon';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncCreateDistributionPartnerModal } from 'module/distributionPartners/components/AsyncCreateDistributionPartnerModal';
import { AsyncUpdateDistributionPartnerModal } from 'module/distributionPartners/components/AsyncUpdateDistributionPartnerModal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Can } from 'js/components/molecules/Can';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useCommonContext, useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

const widgetTrPrefix = 'widget.selectDistributionPartnerCard';

export const SelectDistributionPartnerCard = (): ReactElement => {
	const createRef = useAsyncModalRef();
	const updateRef = useAsyncModalRef();

	// Hooks
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { isRoleDistributor, authCompanyId } = useAuthContext();
	const { setDistributionPartner, orderState } = useOrderContext();
	const { selectDistributionPartnerRef } = useCommonContext();
	const [loading, setLoading] = useState(false);

	// Data
	const distributionPartner = orderState?.distributionPartner;
	const distributionPartnerId = distributionPartner?.id;
	const partnerId = isRoleDistributor ? authCompanyId : orderState?.partner?.id;

	// Select
	const selectDistributionPartner = useCallback(async () => {
		setLoading(true);
		const selectedDistributionPartner = await selectDistributionPartnerRef.current?.show({
			distributorId: partnerId,
			selectedDistributionPartnerId: distributionPartnerId,
		});

		// Add dist. partner to the order
		if (isDefined(selectedDistributionPartner) && isDefined(selectedDistributionPartner.id)) {
			setDistributionPartner(selectedDistributionPartner);
		}
		setLoading(false);
	}, [setDistributionPartner, selectDistributionPartnerRef, partnerId, distributionPartnerId]);

	return (
		<Card>
			<CardHeader>{t(`${widgetTrPrefix}.title`)}</CardHeader>
			<CardBody>
				{(() => {
					if (loading) {
						return <LoadingPlaceholder />;
					}

					if (distributionPartner) {
						return (
							<div className="state-result">
								<ButtonComposition size="xs" className="justify-content-end">
									<Tooltip content={t(`${widgetTrPrefix}.tooltips.change`)} placement="bottom">
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconChange />}
											onClick={selectDistributionPartner}
											testId="changeDistributionPartner"
										/>
									</Tooltip>
									<Can do={distributionPartnersConfig.aclModule} update>
										<Tooltip content={t(`${widgetTrPrefix}.tooltips.update`)} placement="bottom">
											<IconButton
												variant="outline-primary"
												size="xs"
												iconFa={faPencilAlt}
												onClick={() => updateRef.current?.show()}
												testId="updateDistributionPartner"
											/>
										</Tooltip>
									</Can>
									<Tooltip content={t(`${widgetTrPrefix}.tooltips.cancel`)} placement="bottom">
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconClose />}
											onClick={() => setDistributionPartner(null)}
											testId="unsetDistributionPartner"
										/>
									</Tooltip>
								</ButtonComposition>

								<DistributionPartnerCard distributionPartner={distributionPartner} className="mt-3 content" />
							</div>
						);
					}

					// No value
					return (
						<SelectCardState note={<Trans t={t}>{`${widgetTrPrefix}.note`}</Trans>}>
							<p>
								<Button
									variant="outline-primary"
									size="sm"
									onClick={selectDistributionPartner}
									testId="selectDistributionPartner"
								>
									{t(`${widgetTrPrefix}.select`)}
								</Button>
							</p>
							{isRoleDistributor && (
								<p>
									<Button
										variant="light"
										size="sm"
										onClick={() => createRef.current?.show()}
										testId="createDistributionPartner"
									>
										{t(`${widgetTrPrefix}.createNew`)}
									</Button>
								</p>
							)}
						</SelectCardState>
					);
				})()}

				<AsyncModal ref={createRef} testId="createDistributionPartner" backdrop="static">
					<AsyncCreateDistributionPartnerModal
						forwardedRef={createRef}
						onCreate={(distributionPartner) => {
							setDistributionPartner(distributionPartner);
							createRef.current?.hide();
						}}
					/>
				</AsyncModal>

				<AsyncModal ref={updateRef} testId="updateDistributionPartner" backdrop="static">
					<AsyncUpdateDistributionPartnerModal
						forwardedRef={updateRef}
						distributionPartner={orderState.distributionPartner}
						onUpdate={(distributionPartner) => {
							setDistributionPartner(distributionPartner);
							updateRef.current?.hide();
						}}
					/>
				</AsyncModal>
			</CardBody>
		</Card>
	);
};
