import type { IEntityOrder } from 'module/orders';
import type { TEntityLicenseId } from 'module/licenses';
import { logError } from 'js/utils/app';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { API_ORDERS_KEYS, useApiOrderCancel, useApiOrderLicensesCancel } from 'module/orders/hooks/useApiOrders';
import { useCancelButtonVisibility } from 'module/orders/hooks/useCancelButtonVisibility';
import { apiCriticalErrorResolve, extractApiError } from 'js/utils/apiError';
import { API_ERR_CODE } from 'constant';
import { cancelStandardOrderApiError } from 'module/orders/utils/apiError';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { API_LICENSES_KEYS } from 'module/licenses/hooks';
import { STATUS_CANCEL } from 'appConstants';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export type TCancelOrderLicensesResult = 0 | 1 | 2;
export const RESULT_CANCEL_LICENSES: TCancelOrderLicensesResult = 1;
export const RESULT_CANCEL_ORDER: TCancelOrderLicensesResult = 2;

export const useCancelOrderLicenses = (order: IEntityOrder | null) => {
	const { confirmationModalRef, loadingModalRef } = useAppContext();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const invalidateQueries = useAsyncInvalidateQueries([
		API_LICENSES_KEYS.LIST,
		API_LICENSES_KEYS.DETAIL,
		API_ORDERS_KEYS.LIST,
		API_ORDERS_KEYS.DETAIL,
	]);

	// Cancel licences call
	const { mutateAsync: cancelOrderLicenses } = useApiOrderLicensesCancel({ config: { params: { id: order?.id } } });

	// Cancel order data
	const orderCancelVisibility = useCancelButtonVisibility()(order);
	const isOrderCancelable = orderCancelVisibility.isAllowed && orderCancelVisibility.isEnabled;
	const { mutateAsync: cancelOrder } = useApiOrderCancel({ config: { params: { id: order?.id } } });

	return useCallback(
		async (entitlementIds: TEntityLicenseId[]): Promise<TCancelOrderLicensesResult> => {
			const count = entitlementIds.length;

			const confirm = await confirmationModalRef.current?.show({
				title: t('cancelLicenses.confirm.title', { count }),
				messages: [
					{
						caption: t('cancelLicenses.confirm.message', { count }),
						children: t('cancelLicenses.confirm.message_note', { count }),
					},
				],
				submitButtonText: t('common:actions.confirm'),
			});

			if (!confirm) {
				return STATUS_CANCEL;
			}

			loadingModalRef.current?.show({ title: t('cancelLicenses.loading', { count }), ellipsis: true });

			const response = await new Promise<TCancelOrderLicensesResult>((resolve) => {
				// Cancel selected licenses
				cancelOrderLicenses({ entitlementIds })
					.then((cancelLicensesResponse) => {
						if (cancelLicensesResponse) {
							resolve(RESULT_CANCEL_LICENSES);
						}
					})
					.catch((error) => {
						const apiError = extractApiError(error);

						// If error has too many entitlements, try to cancel an order
						if (
							apiError?.errorCode === API_ERR_CODE.TOO_MANY_ENTITLEMENTS_IN_CANCELLATION_REQUEST &&
							isOrderCancelable
						) {
							cancelOrder({})
								.then((response) => {
									if (response) {
										resolve(RESULT_CANCEL_ORDER);
									}
								})
								.catch(async (error) => {
									logError('Cancel order failed', error);
									await setError({ error, resolve: cancelStandardOrderApiError });
									resolve(STATUS_CANCEL);
								});
						} else {
							logError('Cancel licenses failed', entitlementIds, error);
							setError({ error, resolve: apiCriticalErrorResolve }).then(() => resolve(STATUS_CANCEL));
						}
					});
			});

			loadingModalRef.current?.hide();
			if (response !== STATUS_CANCEL) {
				await invalidateQueries();
			}
			return response;
		},
		[
			loadingModalRef,
			confirmationModalRef,
			t,
			cancelOrderLicenses,
			isOrderCancelable,
			cancelOrder,
			setError,
			invalidateQueries,
		],
	);
};
