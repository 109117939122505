import { ExportModalContent } from 'js/components/exports/ExportModalContent';
import type { IAsyncExportProps } from 'js/components/exports';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';

export const AsyncExportModal = <Request extends {}>(props: TAsyncModalContainerProps<IAsyncExportProps<Request>>) => {
	const { forwardedRef, testId } = props;

	return (
		<AsyncModal<IAsyncExportProps<Request>>
			ref={forwardedRef}
			testId={testId}
			size="sm"
			backdrop="static"
			keyboard={false}
		>
			{(props) => <ExportModalContent<Request> forwardedRef={forwardedRef} {...props} />}
		</AsyncModal>
	);
};
