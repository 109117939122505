import { useApi } from 'js/hooks/api';
import type { AxiosResponse } from 'axios';
import type { TUseApiProps } from 'js/hooks/api/useApi';
import type { QueryFunctionContext } from '@tanstack/react-query';
import type { IApiSortBy, TApiListResponse } from 'types/api';
import { fillUrlParameters } from 'js/utils/common';

export type TUseApiListFilterDto<Filter, ApiFilter = Filter> = (filter: Filter) => ApiFilter;

export type TUseApiListProps<Filter, ApiFilter = Filter> = TUseApiProps & {
	apiFilterNormalizer?: TUseApiListFilterDto<Filter, ApiFilter>;
};

export type TUseApiListQueryKey<Data extends {}, Filter> =
	| [string, Filter, IApiSortBy<Data> | undefined]
	| [string, Filter];
type TUseApiListContextQuery<Data extends {}, Filter> = QueryFunctionContext<TUseApiListQueryKey<Data, Filter>>;

type TUseApiList<Data extends {}, Filter> = (
	context: TUseApiListContextQuery<Data, Filter>,
) => Promise<AxiosResponse<TApiListResponse<Data>>>;

export const useApiList = <Data extends {}, Filter, ApiFilter = Filter>(
	props: TUseApiListProps<Filter, ApiFilter>,
): TUseApiList<Data, Filter> => {
	const { config, apiFilterNormalizer } = props;
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (context) => {
		const [key, filter, sortBy] = context.queryKey;
		let apiFilter = { ...config?.params, ...filter };
		if (apiFilterNormalizer) {
			apiFilter = apiFilterNormalizer(apiFilter);
		}
		const [route, _filter] = fillUrlParameters<ApiFilter>(key, apiFilter);
		const abortController = new AbortController();

		const promise = api.get<TApiListResponse<Data>>(`/${route}`, {
			signal: abortController.signal,
			...config,
			params: {
				..._filter,
				sort: sortBy?.key,
				direction: sortBy?.direction,
			},
		});

		// Cancel the request if TanStack Query signals to abort
		context.signal?.addEventListener('abort', () => {
			abortController.abort();
		});

		return promise;
	};
};
