import { useApi } from 'js/hooks/api';
import { useCallback } from 'react';
import type { AxiosRequestConfig } from 'axios';
import { fillUrlParameters } from 'js/utils/common';
import type { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { API_DISTRIBUTION_PARTNERS_KEYS } from './useApiDistributionPartners';

export const useAsyncApiDistributionPartner = () => {
	const api = useApi();

	return useCallback(
		(filter: IDistributionPartnerSearchFilter, config?: AxiosRequestConfig) => {
			const [url] = fillUrlParameters(API_DISTRIBUTION_PARTNERS_KEYS.DETAIL, filter);
			return api.get<IEntityDistributionPartner>(`/${url}`, config);
		},
		[api],
	);
};
