import type { PropsWithChildren, ReactElement, ReactNode, ThHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as iconTooltip } from '@fortawesome/free-solid-svg-icons';

type TCustomThProps = Omit<ThHTMLAttributes<HTMLTableCellElement>, 'align'> & {
	description?: ReactNode;
	align?: 'start' | 'center' | 'end' | 'justify' | 'char' | undefined;
};

export const _CustomTh = (props: PropsWithChildren<TCustomThProps>): ReactElement => {
	const { children, description, className, align, ...rest } = props;
	const containerClassName = classNames({
		[`text-${align}`]: Boolean(align),
		[`justify-content-${align}`]: align === 'center',
		'justify-content-start': align === 'start',
		'justify-content-end': align === 'end',
		'th-tooltip-description': Boolean(description),
	});
	return (
		<th className={classNames('cell', 'cell-head', className)} {...rest}>
			<div className={containerClassName}>
				<span>{children}</span>
				{description && (
					<Tooltip content={description}>
						<FontAwesomeIcon className="tooltip-icon" icon={iconTooltip} />
					</Tooltip>
				)}
			</div>
		</th>
	);
};
