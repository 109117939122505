import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/pt-BR');
const locale: TLocale = 'pt-BR';

export const pt_BR: ILocaleConfig = {
	name: 'Portuguese (Brazil)',
	locale,
	language: 'pt',
	format: {
		numbro,
		datetime: locale,
	},
};
