import type { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { PartnerDetail } from 'module/partners/components';

export const PartnerTab = (): ReactElement => {
	const {
		data: { partner },
	} = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			<PartnerDetail partner={partner} />
		</DefaultContainer>
	);
};
