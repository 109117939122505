import type { TWidgetGrid } from 'submodule/widgets';
import { useCallback } from 'react';
import { AffiliateConfigEnum } from 'module/partners/enums';
import { API_PARTNERS_KEYS, useApiAffiliateConfigUpdate } from 'module/partners/hooks/useApiPartners';
import { affiliateConfigUiValueToApi } from 'module/partners/utils/affiliateConfig';
import { logDebug, logError } from 'js/utils/app';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

type TUseLoadUserWidgetConfig = (config: TWidgetGrid) => Promise<void>;

export const useUserWidgetConfigUpdate = (): TUseLoadUserWidgetConfig => {
	const { authPartnerId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { loadingModalRef } = useAppContext();
	const { t } = useTranslation('submodules');
	const invalidateQueries = useAsyncInvalidateQueries([API_PARTNERS_KEYS.CONFIG]);

	// Prepare update config call
	const { mutateAsync: affiliateConfigUpdate } = useApiAffiliateConfigUpdate({
		config: { params: { id: authPartnerId, name: AffiliateConfigEnum.HOME_WIDGET } },
	});

	return useCallback(
		async (config: TWidgetGrid) => {
			loadingModalRef.current?.show({
				title: t('widgets.common.savingLoader'),
			});

			const apiValue = affiliateConfigUiValueToApi<TWidgetGrid>(AffiliateConfigEnum.HOME_WIDGET, config);
			if (!apiValue) {
				logError('Unable to save widget config', { config, authPartnerId });
				return;
			}

			logDebug('Save UI value to API', AffiliateConfigEnum.HOME_WIDGET, apiValue);
			await affiliateConfigUpdate({ value: apiValue })
				.then(async () => {
					await invalidateQueries();
					toast.success(<Toast caption={t('widgets.common.changesSaved')} />);
				})
				.catch((error) => {
					logError(error);
					setError({ error, resolve: apiCriticalErrorResolve });
				});

			loadingModalRef.current?.hide();
		},
		[affiliateConfigUpdate, authPartnerId, invalidateQueries, loadingModalRef, setError, t],
	);
};
