import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useFormikContext } from 'formik';
import { FormikControl } from 'js/components/formik/FormikControl';
import type { TCustomerQuoteForm } from 'module/purchase/components/customerQuote/AsyncCustomerQuoteModal';
import { useCustomerQuotePrices } from 'module/purchase/components/customerQuote/useCustomerQuotePrices';
import { Col, Row } from 'js/components/atoms/Row';
import { Alert, FormControl } from '@avast/react-ui-components';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import type { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { CustomerQuoteTable } from 'module/purchase/components/customerQuote/CustomerQuoteTable';
import { useOrderContext } from 'js/contexts';

export const CustomerQuoteForm = (props: TAsyncModalContainerProps): ReactElement | null => {
	const { forwardedRef } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { orderState } = useOrderContext();
	const { hasInvalidItems } = useCustomerQuotePrices();
	const { values } = useFormikContext<TCustomerQuoteForm>();
	const isDisabled = hasInvalidItems(values);

	return (
		<>
			{isDisabled && <Alert variant="danger" caption={t('components.customerQuote.error.invalidItems')} />}
			<CustomerQuoteTable />
			<Row className="mt-4">
				<Col md={6} lg={4}>
					<FormControl label={t('components.customerQuote.fields.customerEmail')}>
						<FormControl.Input name="customerEmail" size="sm" readOnly value={orderState.customer?.email} />
					</FormControl>
					<FormControl label={t('components.customerQuote.fields.partnerEmail')}>
						<FormControl.Input name="partnerEmail" size="sm" readOnly value={orderState.partner?.email} />
					</FormControl>
				</Col>
				<Col md={6} lg={8}>
					<FormikControl label={t('components.customerQuote.fields.message')}>
						<FormikControl.Textarea name="customerQuoteMessage" size="sm" rows={4} />
					</FormikControl>
				</Col>
			</Row>
			<FormikFooterControls
				cancel={{
					onClick() {
						forwardedRef.current?.onCancel();
					},
				}}
				submit={{
					children: t('components.customerQuote.submit'),
					disabled: isDisabled,
				}}
			/>
		</>
	);
};
