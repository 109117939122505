import type { IEntityOrder } from 'module/orders';
import { isClosingBalanceOrder, isOrderInStatus, isOrderPaymentInStatus } from 'module/orders/utils/common';
import { PaymentStatusEnum } from 'js/enums';
import { OrderStatusEnum } from 'module/orders/enums';
import { useAuthContext } from 'js/contexts';

export const useOrderLicensesAreCancellable = (order?: IEntityOrder | null) => {
	const { isRoleSalesOperations } = useAuthContext();

	if (!order || order.cbConsolidatedComplete || !isRoleSalesOperations) {
		return false;
	}

	return (
		isClosingBalanceOrder(order) &&
		isOrderInStatus(order, OrderStatusEnum.POSTED_TO_STATEMENT) &&
		isOrderPaymentInStatus(order, PaymentStatusEnum.UNBILLED)
	);
};
