import type { ReactElement } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { OrderActions, OrderSummary, PartiesInfo } from 'module/purchase/components/order';
import { isEmptySelector } from 'js/selectors/order';
import { FormikForm } from 'js/components/formik/FormikForm';
import { AutoSave } from 'js/components/formik/AutoSave';
import type { TProductsTableForm } from 'module/purchase/pages/PageOrder';
import { StatusText } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useReviewOrderPricing } from 'module/purchase/hooks/pricing/useReviewOrderPricing';
import { useOrderContext } from 'js/contexts';

type TReviewOrderProps = {
	linkToDetail: string;
};

export const ReviewOrder = (props: TReviewOrderProps): ReactElement => {
	const { linkToDetail } = props;
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { orderState, updateAdditionalData, updateDiscretionaryDiscount, updateItems } = useOrderContext();
	useReviewOrderPricing();

	if (isEmptySelector(orderState)) {
		return (
			<StatusText variant="danger" caption={t('error.noProductsInOrder.caption')}>
				<p>{t('error.noProductsInOrder.text')}</p>
			</StatusText>
		);
	}

	return (
		<Formik<TProductsTableForm>
			initialValues={orderState}
			enableReinitialize
			onSubmit={(values, { setSubmitting }) => {
				if (!orderState.isLocked) {
					updateItems(values.items);
					updateDiscretionaryDiscount(values.discretionaryDiscount);
				}
				updateAdditionalData(values.additionalInfo);
				setSubmitting(false);
			}}
			validationSchema={Yup.object().shape({
				additionalInfo: Yup.object().shape({
					externalPurchaseNumber: Yup.string().label(t('common:entity.poNumber')).max(45),
				}),
			})}
		>
			<FormikForm testId="reviewOrderForm">
				<AutoSave />
				<OrderSummary />
				<div className="container-fluid pb-8">
					<div className="container-orderReview">
						<PartiesInfo />
						<OrderActions backLink={linkToDetail} />
					</div>
				</div>
			</FormikForm>
		</Formik>
	);
};
