import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useApiOrder } from 'module/orders/hooks/useApiOrders';
import { useIsEditable, useOrderToInstance } from 'module/orders/hooks';
import { useState } from 'react';
import { isOrderInQuoteStatus } from 'module/orders/utils/common';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { ReviewHeadline, ReviewOrder } from 'module/orders/components/UpdateOrder';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { toast } from 'react-toastify';
import { Button, ButtonComposition, Modal, Toast } from '@avast/react-ui-components';
import { OrderContextProvider } from 'js/contexts';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { SelectProducts } from 'module/purchase/components/selectProducts/SelectProducts';
import { Navigate } from 'react-router';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';

type TPageUpdateContent = {
	id: string;
	isQuote: boolean;
};

export const PageUpdateContent = (props: TPageUpdateContent) => {
	const { id, isQuote } = props;
	const { data: order, query } = useApiOrder({ id });
	const { t } = useTranslation(ordersConfig.trNamespace);
	const instance = useOrderToInstance(order);
	const isLoading = query.isFetching || instance.isFetching;
	const [productsModal, setProductsModal] = useState(false);
	const isEditable = useIsEditable();
	const linkToDetail = buildParametrizedRoute(isQuote ? RouteName.QUOTE.DETAIL : RouteName.ORDER.DETAIL, id);
	const linkToUpdate = buildParametrizedRoute(isQuote ? RouteName.QUOTE.UPDATE : RouteName.ORDER.UPDATE, id);
	const breadcrumbs = (
		<>
			<DynamicPageTitleItem text={t('common:_.pageDetailTitle', { id })} />
			<DynamicPageTitleItem text={t(`actions.${isQuote ? 'editQuote' : 'edit'}`)} />
			<DynamicBreadcrumbsItem href={linkToDetail}>{t('common:_.pageDetailTitle', { id })}</DynamicBreadcrumbsItem>
			<DynamicBreadcrumbsItem href={linkToUpdate}>
				{t(`actions.${isQuote ? 'editQuote' : 'edit'}`)}
			</DynamicBreadcrumbsItem>
		</>
	);

	if (isLoading) {
		return (
			<div className="section__orderUpdate">
				{breadcrumbs}
				<ReviewHeadline id={id} linkToDetail={linkToDetail} isQuote={isQuote} />
				<LoadingPlaceholder type="BAR" />
			</div>
		);
	}

	if (!order) {
		toast.error(<Toast>{t('error.orderNotFound', { id })}</Toast>);
		return <Navigate to={isQuote ? RouteName.QUOTE.LIST : RouteName.ORDER.LIST} replace />;
	}

	if (isOrderInQuoteStatus(order)) {
		if (!isQuote) {
			return <Navigate to={ordersConfig.quoteUpdateLink(order)} />;
		}
	} else if (isQuote) {
		return <Navigate to={ordersConfig.updateLink(order)} />;
	}

	if (!isEditable(order) || !instance.orderInstance) {
		toast.error(<Toast>{t('error.orderNotEditable', { id })}</Toast>);
		return <Navigate to={linkToDetail} />;
	}

	return (
		<OrderContextProvider
			updateMode
			initialState={instance.orderInstance}
			discountApprovalFlag={order.discount?.approvalFlag}
		>
			{breadcrumbs}
			<div className="section__orderUpdate">
				<ReviewHeadline
					id={id}
					linkToDetail={linkToDetail}
					isQuote={isQuote}
					buttons={
						!isQuote && (
							<ButtonComposition marginY>
								<Button
									size="sm"
									onClick={() => setProductsModal(true)}
									variant="success"
									iconLeftFa={faPlus}
									disabled={instance.orderInstance.isLocked}
									testId="addProducts"
								>
									{t('actions.addProductsToOrder')}
								</Button>
							</ButtonComposition>
						)
					}
				/>
				<ReviewOrder linkToDetail={linkToDetail} />
			</div>

			<Modal onHide={() => setProductsModal(false)} show={productsModal} size="xl" testId="addProducts">
				<Modal.Header>{t('actions.addProductsToOrder')}</Modal.Header>
				<Modal.Body>
					<SelectProducts onAddItem={() => setProductsModal(false)} />
				</Modal.Body>
				<Modal.Footer cancelButton />
			</Modal>
		</OrderContextProvider>
	);
};
