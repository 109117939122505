import { Col } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import Skeleton from 'react-loading-skeleton';
import type { TPricingBreakdownLine } from 'module/purchase/utils/pricingBreakdown';

type TPricingBreakdownColsProps = {
	pricingBreakdown: TPricingBreakdownLine[][];
	colSize: number;
	isLoading?: boolean;
};

export const PricingBreakdownCols = (props: TPricingBreakdownColsProps) => {
	const { pricingBreakdown, colSize, isLoading } = props;

	return (
		<>
			{pricingBreakdown.map((list, listId) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
				<Col md={colSize} key={listId}>
					<DataTable className="table-transparent table-noLastBorder" contentWidth>
						{list.map(([name, value], itemIndex) => {
							return (
								// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
								<DataTableTr key={`${listId}-${itemIndex}`} name={name} bold valueAlign="end">
									{isLoading ? <Skeleton width={100} /> : <span className="text-nowrap">{value}</span>}
								</DataTableTr>
							);
						})}
					</DataTable>
					{listId < pricingBreakdown.length && <hr className="my-0 d-md-none" />}
				</Col>
			))}
		</>
	);
};
