import type { TConfigLocaleDateTimeValues, TLocale } from 'types/config';
import { getDateTimeLocale } from 'js/utils/locale';
import type { TApiDate, TDate } from 'types';
import { CONFIG } from 'config';
import { logError } from 'js/utils/app';
import { DateTime } from 'luxon';
import { API_DATE_FORMAT } from 'constant';
import { userLocaleInstance } from 'i18n/userLocaleInstance';
import { localesConfig } from 'i18n/locales';
import { isString } from 'lodash';

type TDateToFormat = string | null | Date | undefined;
type TFormatReturn = string | null;

export const dateFormatter = {
	default(date: TDateToFormat, formatKey: TConfigLocaleDateTimeValues = 'DATE', locale?: TLocale): TFormatReturn {
		if (!date) {
			return null;
		}

		// Parse date
		let d: DateTime;
		if (isString(date)) {
			d = DateTime.fromISO(date);
		} else {
			d = DateTime.fromJSDate(date);
		}

		// Invalid
		if (!d.isValid) {
			logError('Unable to parse date time', date, d.invalidReason, d.invalidExplanation, d);
			return null;
		}

		const formatLocale = locale || userLocaleInstance.format;
		const format = getDateTimeLocale(formatKey, formatLocale);
		return d.setZone(CONFIG.LOCALE.TIMEZONE).toFormat(format, { locale: localesConfig[formatLocale].format.datetime });
	},

	toDate(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'DATE');
	},

	toTimeZoneDate(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'DATE_TZ');
	},

	toDateTime(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'DATETIME');
	},

	toTimeZoneDateTime(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'DATETIME_TZ');
	},

	toTime(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'TIME');
	},

	toTimeZoneTime(date: TDateToFormat): TFormatReturn {
		return this.default(date, 'TIME_TZ');
	},
};

/**
 * Format date to format for Filter
 * @param {Date | null} date
 * @returns {TApiDate}
 */
export const dateTimeToFilterDate = (date: DateTime): TApiDate => date.toISODate();

/**
 * Convert date from API to Date form
 * @param {TApiDate} apiDate
 * @returns {Date}
 */
export const apiDateToDate = (apiDate?: TApiDate): Date | null => {
	if (!apiDate) {
		return null;
	}

	const date = DateTime.fromISO(apiDate);
	if (date.isValid) {
		return date.startOf('day').toJSDate();
	}
	return null;
};

export const minToMs = (minutes: number): number => minutes * 60 * 1000;

/**
 * Change date to form that API can receive.
 * @param {TDate} date
 * @param {boolean} isEndOfDay
 * @returns {string} date in <i>API_DATE_FORMAT</i> form
 */
export const dateToApiDate = (date: TDate, isEndOfDay = false) => {
	let apiDate = DateTime.fromISO(date, { zone: CONFIG.LOCALE.TIMEZONE });
	if (!apiDate.isValid) {
		logError('Wrong date value used: ', date, apiDate.invalidReason, apiDate.invalidExplanation, apiDate);
		return undefined;
	}

	if (isEndOfDay) {
		apiDate = apiDate.endOf('day');
	}
	return apiDate.toFormat(API_DATE_FORMAT);
};

/**
 * Convert diff between two dates in days
 * @param {number | undefined} date
 * @returns {number}
 *
 * @example undefined => 0
 * @example '(d = new Date(); d.setDate(d.getDate()+1))' => -1
 * @example '(d = new Date(); d.setDate(d.getDate()-1))' => 1
 */
export const diffInDaysFromNow = (date: number | undefined): number => {
	// Falsy date -> now -> no diff
	if (!date) {
		return 0;
	}

	const oneDay = 1000 * 60 * 60 * 24;
	const now = Date.now();
	const diff = now - date;
	return diff / oneDay;
};
