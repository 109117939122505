import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import type { IAsyncExportProps } from 'js/components/exports';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IAsyncExportLicensesRequest, ILicenseListFilter } from 'module/licenses';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useApiLicensesListExport } from 'module/licenses/hooks';
import type { IListMetaDataValues } from 'types/utils';
import { isEmpty } from 'lodash';
import { licensesFilterNormalizer } from 'module/licenses/normalizer';
import { isDefined } from 'js/utils/common';
import { LicenseSearchKeyEnum } from 'module/licenses/enums';

type TExportLicensesButtonProps = {
	detailed?: boolean;
	listMetaData: IListMetaDataValues<ILicenseListFilter>;
};

export const ExportLicensesButton = (props: TExportLicensesButtonProps) => {
	const { listMetaData, detailed } = props;
	const { filter, totalCount } = listMetaData;
	const { t } = useTranslation(licensesConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportLicensesRequest>>();
	const isDisabled = totalCount === 0 || isEmpty(filter);

	// Prepare request
	const request: IAsyncExportLicensesRequest = {
		format: detailed ? 'detailed' : 'basic',
	};
	if (filter?.search?.key === LicenseSearchKeyEnum.LICENSE_KEY && isDefined(filter.search.value)) {
		request.searchFilter = {
			licenseString: filter.search.value,
		};
	} else {
		request.filter = licensesFilterNormalizer.denormalize(filter || {});
	}

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				disabled={isDisabled}
				onClick={() =>
					exportRef.current?.show({
						request,
						useQuery: useApiLicensesListExport,
					})
				}
				testId={detailed ? 'exportLicensesDetailed' : 'exportLicenses'}
			>
				{t(`actions.${detailed ? 'exportDetailed' : 'export'}`)}
			</Button>
			<AsyncExportModal forwardedRef={exportRef} testId="exportLicenses" />
		</>
	);
};
