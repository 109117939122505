import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/nn');
const locale: TLocale = 'no-NO';

export const no_NO: ILocaleConfig = {
	name: 'Norwegian (Norway)',
	locale,
	language: 'no',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
