import { Button, ButtonComposition } from '@avast/react-ui-components';
import { homepageConfig } from 'module/homepage/homepageConfig';
import { useTranslation } from 'react-i18next';
import { useUserWidgetConfigUpdate } from 'submodule/widgets/hooks';
import { useWidgetEditContext } from 'submodule/widgets/context/WidgetEditContext';
import { useNavigate } from 'react-router';
import { RouteName } from 'module/RouteName';

export const EditWidgetsActions = () => {
	const { t } = useTranslation(homepageConfig.trNamespace);
	const onChange = useUserWidgetConfigUpdate();
	const navigate = useNavigate();
	const { state } = useWidgetEditContext();

	const onBackClick = () => navigate(RouteName.HOMEPAGE.DEFAULT);
	const onSaveClick = () => onChange(state).then(() => navigate(RouteName.HOMEPAGE.DEFAULT));

	return (
		<ButtonComposition size="sm" justify="center">
			<Button size="sm" variant="outline-primary" onClick={onBackClick}>
				{t('common:actions.cancel')}
			</Button>
			<Button size="sm" variant="primary" onClick={onSaveClick}>
				{t('common:actions.save')}
			</Button>
		</ButtonComposition>
	);
};
