import { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { FormControl, IconButton } from '@avast/react-ui-components';
import { faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
	useChangeLocaleFormat,
	useCurrentLocaleFormat,
	useLocaleFormatSelectOptions,
} from 'submodule/localeSwitch/hooks';
import type { ILocaleConfig, TLocale } from 'types/config';
import { resolveLocaleConfigFromLocaleCode } from 'i18n/utils';
import { LocaleFormatPreview } from 'submodule/localeSwitch/components/LocaleFormatPreview';

export const LocaleFormatControl = () => {
	const currentLocaleFormat = useCurrentLocaleFormat();
	const formatSelectOptions = useLocaleFormatSelectOptions();
	const changeLocaleFormat = useChangeLocaleFormat();

	const [isEditMode, setEditMode] = useState(false);
	const [config, setConfigFormat] = useState<ILocaleConfig>(currentLocaleFormat);

	if (isEditMode) {
		return (
			<InputGroup size="sm" className="component__search-combo-box">
				<FormControl.SingleSelect<TLocale>
					name="format"
					options={formatSelectOptions}
					value={config.locale}
					isClearable={false}
					size="sm"
					className="flex-grow-1"
					onChange={(value) => setConfigFormat(resolveLocaleConfigFromLocaleCode(value))}
				/>
				<IconButton
					size="sm"
					variant="success"
					iconFa={faCheck}
					onClick={async () => {
						if (currentLocaleFormat.locale !== config.locale) {
							await changeLocaleFormat(config);
						}
						setEditMode(false);
					}}
				/>
			</InputGroup>
		);
	}

	return (
		<div>
			<div className="component__current-language">
				<span className="d-block flex-grow-1 me-3">{currentLocaleFormat.name}</span>
				<IconButton size="xs" variant="outline-primary" iconFa={faPencilAlt} onClick={() => setEditMode(true)} />
			</div>
			<div className="fs-sm text-gray">
				<LocaleFormatPreview config={currentLocaleFormat} />
			</div>
		</div>
	);
};
