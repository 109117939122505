import type { IAuthDataUser } from 'js/reducers/authContextReducer';
import { getAuthSecurity } from 'js/contexts';
import { INVALID_PARTNER_STATES } from 'module/security/constants';
import { CONFIG } from 'config';
import { buildUrlFromLocation } from 'js/utils/common';
import type { NavigateLink } from 'types';

export const getInvalidStateLocation = (data: IAuthDataUser): NavigateLink | null => {
	const authSecurityState = getAuthSecurity(data.partner, data.company);
	const state = INVALID_PARTNER_STATES.find((state) => state.isInvalid(authSecurityState));
	if (!state) {
		return null;
	}

	return {
		to: state.route,
	};
};

export const getLoginLink = (origin: string, options?: { backlink?: NavigateLink['to']; app?: string }): string => {
	const url = new URL(origin);
	const redirectTo = buildUrlFromLocation(options?.backlink);

	if (options?.app) {
		url.searchParams.set('app', options.app);
	}

	if (redirectTo) {
		url.searchParams.set('RelayState', redirectTo);
	}

	return url.toString();
};

export const getSalesforceLoginLink = (backlink?: NavigateLink['to']): string => {
	return getLoginLink(`${CONFIG.APP.SALES_FORCE_URL}/idp/login`, { backlink, app: CONFIG.APP.SALES_FORCE_APP_ID });
};

export const getSalesforceLink = (): string => {
	return CONFIG.APP.SALES_FORCE_URL;
};

export const getGenSamlLoginLink = (backlink?: NavigateLink['to']): string => {
	return getLoginLink(CONFIG.APP.GEN_SAML_LOGIN_URL, { backlink });
};
