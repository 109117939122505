import { CustomTable } from 'js/components/molecules/CustomTable';
import {
	type ProductVariantModule,
	useSelectProductVariantTableProps,
} from 'js/components/table/selectProductVariant/useSelectProductVariantTableProps';
import type { IEntityProductGroup } from 'module/purchase';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { FormikControl } from 'js/components/formik/FormikControl';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { useFormikContext } from 'formik';
import type { TSelectVariantForm } from 'module/purchase/components/selectProducts';

export interface ISelectProductVariantTableProps {
	productGroup: IEntityProductGroup;
	module: ProductVariantModule;
}

export const SelectProductVariantTable = ({ module, productGroup }: ISelectProductVariantTableProps) => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { values } = useFormikContext<TSelectVariantForm>();
	const {
		getAdditionalHeaderCells,
		getAdditionalBodyCells,
		getVariantCheckboxProps,
		getQuantityInputProps,
		getUnitInputProps,
	} = useSelectProductVariantTableProps(module, productGroup);

	return (
		<CustomTable borderless>
			<thead>
				<tr>
					<CustomTable.Th />
					<CustomTable.Th align="center" description={t('tableVariant.tooltip.quantity')}>
						{t('common:entity.quantity')}
					</CustomTable.Th>
					<CustomTable.Th align="center" description={t(`tableVariant.tooltip.unitType.${productGroup.unitType}`)}>
						{t(`common:entity.unitType.${productGroup.unitType}`, { count: 0 })}
					</CustomTable.Th>
					{getAdditionalHeaderCells()}
				</tr>
			</thead>
			<tbody>
				{values.variant.map((row, i) => {
					return (
						<tr key={row.id}>
							<CustomTable.Td>
								<FormikControl groupProps={{ className: 'mb-0' }}>
									<FormikControl.Checkbox {...getVariantCheckboxProps(row, i)} />
								</FormikControl>
							</CustomTable.Td>
							<CustomTable.Td align="center">
								<FixedWidthWrapper width={160}>
									<FormikControl groupProps={{ className: 'mb-0' }}>
										<FormikControl.Number {...getQuantityInputProps(row, i)} />
									</FormikControl>
								</FixedWidthWrapper>
							</CustomTable.Td>
							<CustomTable.Td align="center">
								<FixedWidthWrapper width={160}>
									<FormikControl groupProps={{ className: 'mb-0' }}>
										<FormikControl.Number {...getUnitInputProps(row, i)} />
									</FormikControl>
								</FixedWidthWrapper>
							</CustomTable.Td>
							{getAdditionalBodyCells(row)}
						</tr>
					);
				})}
			</tbody>
		</CustomTable>
	);
};
