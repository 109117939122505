import { type ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RouteName } from 'module/RouteName';
import { securityConfig } from 'module/security/securityConfig';
import { useLogout } from 'module/security/hooks/useLogout';
import type { ILoginPageState, ILogoutPageState } from 'module/security/index';
import { useLocationState } from 'js/hooks/useLocationState';
import { getUserRolesFromJwtToken } from 'js/contexts';
import { CONFIG } from 'config';
import { getGenSamlLoginLink, getSalesforceLink, getSalesforceLoginLink } from 'module/security/utils/common';
import { UserRoleEnum } from 'js/enums';
import { customEvent, CustomEventEnum } from 'js/events';
import { SecurityFormLayout } from 'module/security/layouts';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { H2 } from '@avast/react-ui-components';
import { logDebug } from 'js/utils/app';

export const PageLogout = (): ReactElement => {
	const { t } = useTranslation(securityConfig.trNamespace);
	const logout = useLogout();
	const navigate = useNavigate();
	const { sessionExpired, redirectUrl, previousLocation, previousAuthToken } = useLocationState<ILogoutPageState>();
	const authUserRoles = getUserRolesFromJwtToken(previousAuthToken || null);
	// When prev auth token is not present, we predicate, that user is a partner
	const isUserRolePartner = authUserRoles.includes(UserRoleEnum.ROLE_PORTAL_PARTNER) || !previousAuthToken;
	const [loading, setLoading] = useState(false);

	// Redirect on init
	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		setLoading(true);
		customEvent.trigger(CustomEventEnum.CLOSE_MODALS);

		const _logout = async () => {
			await logout(sessionExpired);

			if (redirectUrl) {
				window.location.href = redirectUrl;
				return;
			}

			if (CONFIG.ENV === 'local') {
				const state: ILoginPageState = {
					authRedirectFrom: previousLocation,
				};
				logDebug('Auto-relogin by SSO skipped on LOCAL env', state);
				navigate(RouteName.SECURITY.LOGIN, { state });
				return;
			}

			// Redirect
			if (sessionExpired) {
				if (isUserRolePartner) {
					window.location.href = getSalesforceLoginLink(previousLocation?.to);
					return;
				}

				window.location.href = getGenSamlLoginLink(previousLocation?.to);
				return;
			}
			setLoading(false);
		};

		_logout();
	}, []);

	return (
		<SecurityFormLayout title="Logout">
			{loading ? (
				<LoadingPlaceholder minHeight={false} className="text-body fw-bold py-0">
					{t('page.logout.loading')}
				</LoadingPlaceholder>
			) : (
				<div className="text-center">
					<H2 bold>{t('page.logout.title')}</H2>
					<p className="mt-2 fs-md">{t('page.logout.text')}</p>
					{isUserRolePartner && (
						<p className="mt-3 mt-md-5">
							<a href={getSalesforceLink()} className="btn btn-primary">
								<span>{t('common.continueToPartnerPortal')}</span>
							</a>
						</p>
					)}
				</div>
			)}
		</SecurityFormLayout>
	);
};
