import type { IStandardOrderInstanceItem } from 'module/purchase';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useTranslation } from 'react-i18next';
import {
	getCustomerPricingBreakdown,
	getPartnerPricingBreakdown,
	type TPricingBreakdownLine,
} from 'module/purchase/utils/pricingBreakdown';
import { getOrderInstanceItemSavedCustomerPrice } from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';

const usePricingBreakdownCustomer = (item: IStandardOrderInstanceItem): TPricingBreakdownLine[] => {
	const { billableParty, getItemPricing } = useOrderContext();
	const { tPartyPrice } = usePartyPriceTranslation();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	const pricing = getItemPricing(item.id);
	const currencyCode = billableParty?.currencyCode;

	return getCustomerPricingBreakdown({
		pricing,
		currencyCode,
		t,
		tPartyPrice,
		quotedCustomerPricing: getOrderInstanceItemSavedCustomerPrice(item),
	});
};

const usePricingBreakdownPartner = (item: IStandardOrderInstanceItem): TPricingBreakdownLine[] | null => {
	const { billableParty, getItemPricing, isEndCustomerBillableParty } = useOrderContext();
	const { tPartyPrice } = usePartyPriceTranslation();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	if (isEndCustomerBillableParty) {
		return null;
	}

	const pricing = getItemPricing(item.id);
	const currencyCode = billableParty?.currencyCode;

	return getPartnerPricingBreakdown({
		pricing,
		currencyCode,
		t,
		tPartyPrice,
	});
};

export const usePricingBreakdown = (item: IStandardOrderInstanceItem): TPricingBreakdownLine[][] => {
	const pricingBreakdownCustomer = usePricingBreakdownCustomer(item);
	const pricingBreakdownPartner = usePricingBreakdownPartner(item);

	const cols: TPricingBreakdownLine[][] = [];
	cols.push(pricingBreakdownCustomer);
	if (pricingBreakdownPartner) {
		cols.push(pricingBreakdownPartner);
	}

	return cols;
};
