import { useEffect } from 'react';
import { titleStore } from 'js/components/molecules/DynamicPageTitle';

export type TDynamicPageTitleItemProps = string;

export const DynamicPageTitleItem = ({ text }: { text: TDynamicPageTitleItemProps }) => {
	const { setTitlePart, removeTitlePart } = titleStore();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		// Add the title part
		setTitlePart(text);

		return () => {
			// Remove the title part when unmounting
			removeTitlePart();
		};
	}, []);

	return <></>;
};
