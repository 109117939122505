import { type ReactElement, type ReactNode, useState } from 'react';
import { IconButton, Toast, Tooltip } from '@avast/react-ui-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { IStandardOrderInstanceItem } from 'module/purchase';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { FormikControl } from 'js/components/formik/FormikControl';
import { numberFormatter } from 'js/utils/number';
import { OrderTableRowDetail } from 'module/purchase/components/order';
import Skeleton from 'react-loading-skeleton';
import { isOrderProductBusiness, isOrderProductConsumer } from 'module/purchase/utils/selectors';
import { LicenseOperationEnum, licenseOperationEnumUtils } from 'module/licenses/enums';
import {
	getOrderInstanceItemSavedCustomerPrice,
	getOrderItemMaxQuantity,
	getProductValidityLabels,
	isQuantityChangeDisabled,
} from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

type TProductsTableRowProps = {
	cols: number;
	row: IStandardOrderInstanceItem;
	index: number;
	showDetailOnInit?: boolean;
};

export const OrderTableRow = (props: TProductsTableRowProps): ReactElement => {
	const { row, index, cols, showDetailOnInit = false } = props;

	const { t } = useTranslation(purchaseConfig.trNamespace);
	const orderContext = useOrderContext();
	const { removeItem, getItemPricing, isEndCustomerBillableParty, orderState, billableParty } = orderContext;
	const [showDetail, setShowDetail] = useState<boolean>(showDetailOnInit);
	const pricing = getItemPricing(row.id);
	const isBusiness = isOrderProductBusiness(row.product);
	const isConsumer = isOrderProductConsumer(row.product);
	const areChangesAllowed = row.licenseOperation === LicenseOperationEnum.NEW && !orderState.isLocked;
	const isQuantityDisabled = !isConsumer || !areChangesAllowed;
	const isUnitDisabled = !isBusiness || !areChangesAllowed || isQuantityChangeDisabled(row.product.group.code);
	const labels = getProductValidityLabels(row.product);

	return (
		<>
			<tr className={classNames('row-product', { 'is-open': showDetail })}>
				<CustomTable.Td>
					<IconButton
						onClick={() => setShowDetail((show) => !show)}
						iconFa={faChevronDown}
						size="xs"
						className="ico-detail-toggle"
					/>
				</CustomTable.Td>
				<CustomTable.Td>{row.product.sku}</CustomTable.Td>
				<CustomTable.Td>
					<span className="fw-bold">{row.product.group.name}</span>
					<br />
					<span className="fs-sm">{labels.value}</span>
				</CustomTable.Td>
				<CustomTable.Td style={{ width: orderState.isLocked ? '100px' : '160px' }}>
					<FormikControl groupProps={{ className: 'mb-0' }}>
						<FormikControl.Number
							name={`items.${index}.quantity`}
							min={1}
							max={getOrderItemMaxQuantity(row, isQuantityDisabled)}
							size="sm"
							disabled={isQuantityDisabled}
							hideControls={isQuantityDisabled}
						/>
					</FormikControl>
				</CustomTable.Td>
				<CustomTable.Td style={{ width: orderState.isLocked ? '100px' : '160px' }} className="pe-1">
					<FormikControl groupProps={{ className: 'mb-0' }}>
						<FormikControl.Number
							name={`items.${index}.unit`}
							min={1}
							max={getOrderItemMaxQuantity(row, isUnitDisabled)}
							disabled={isUnitDisabled}
							size="sm"
							hideControls={isUnitDisabled}
						/>
					</FormikControl>
				</CustomTable.Td>
				<CustomTable.Td className={classNames('ps-0 fs-sm', { 'text-muted': isConsumer })}>
					{t(`common:entity.unitType.${row.product.group.unitType}`, { count: row.unit }).toLowerCase()}
				</CustomTable.Td>
				<CustomTable.Td>{licenseOperationEnumUtils.getText(row.licenseOperation)}</CustomTable.Td>
				<CustomTable.Td align="right">
					{(() => {
						if (!pricing) {
							return <Skeleton width={100} />;
						}

						const customerPrice = isEndCustomerBillableParty ? pricing.linePriceWOTax : pricing.customerPrice?.total;
						let content: ReactNode = numberFormatter.currency(customerPrice, billableParty?.currencyCode);
						const savedCustomerPrice = getOrderInstanceItemSavedCustomerPrice(row);

						// Customized price
						if (isDefined(savedCustomerPrice)) {
							content = (
								<>
									<span className="text-gray">{content}</span>
									<br />
									<span>{numberFormatter.currency(savedCustomerPrice.total, billableParty?.currencyCode)}</span>
								</>
							);
						}

						return content;
					})()}
				</CustomTable.Td>
				{!isEndCustomerBillableParty && (
					<CustomTable.Td align="right">
						{pricing ? (
							numberFormatter.currency(pricing.linePriceWOTax, billableParty?.currencyCode)
						) : (
							<Skeleton width={100} />
						)}
					</CustomTable.Td>
				)}
				{!orderState.isLocked && (
					<CustomTable.Td>
						<Tooltip content={t('tooltips.remove')} placement="bottom">
							<IconButton
								onClick={() => {
									removeItem(row.id);
									toast.success(<Toast>{t('message.removedFromCart')}</Toast>);
								}}
								variant="light"
								iconFa={faTimes}
								size="sm"
								className="ico-remove"
								testId={`remove_${index}`}
							/>
						</Tooltip>
					</CustomTable.Td>
				)}
			</tr>
			<tr className={classNames('row-product-detail', { 'd-none': !showDetail })}>
				<CustomTable.Td colSpan={cols}>
					<OrderTableRowDetail row={row} index={index} />
				</CustomTable.Td>
			</tr>
			<tr className="divider">
				<td />
			</tr>
		</>
	);
};
