import type { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { DistributionPartnerRoutes } from 'module/distributionPartners/DistributionPartnerRoutes';

export const DistributionPartnersContainer: FunctionComponent = () => (
	<DefaultContainer>
		<ModuleContainer moduleMenuConfig={distributionPartnersConfig} />
		<DistributionPartnerRoutes />
	</DefaultContainer>
);
