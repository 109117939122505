import type { IEntityCustomer } from 'module/customers/index';
import type { IEntityPartner } from 'module/partners';
import { useAuthContext } from 'js/contexts';
import { useApiPartnerCustomer } from 'module/customers/hooks/useApiCustomers';

type TUseIsCustomerDetailLinkable = {
	result: boolean;
	isPending?: boolean;
};

export const useIsCustomerDetailLinkable = (
	customer?: IEntityCustomer | null,
	partner?: IEntityPartner | null,
): TUseIsCustomerDetailLinkable => {
	const { isGroupPartner } = useAuthContext();
	const { data, query } = useApiPartnerCustomer({
		filter: {
			partnerId: partner?.id!,
			salesforceId: customer?.id!,
		},
		queryConfig: {
			enabled: Boolean(customer) && Boolean(partner) && isGroupPartner,
		},
	});

	if (!customer) {
		return { result: false };
	}

	if (!partner && isGroupPartner) {
		return { result: false };
	}

	if (!isGroupPartner) {
		return { result: true };
	}

	return { result: Boolean(data), isPending: query.isFetching };
};
