import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import type { IAsyncExportProps } from 'js/components/exports';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IListMetaDataValues } from 'types/utils';
import type { IAsyncExportClosingBalanceRequest, IClosingBalanceListFilter } from 'module/closingBalance';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useApiClosingBalanceExport } from 'module/closingBalance/hooks/useApiClosingBalance';
import { camelCase } from 'lodash';
import { closingBalanceFilterNormalizer } from 'module/closingBalance/normalizer';

type TExportClosingBalanceButtonProps = Pick<IAsyncExportClosingBalanceRequest, 'format'> & {
	listMetaData: IListMetaDataValues<IClosingBalanceListFilter>;
};

export const ExportClosingBalanceButton = (props: TExportClosingBalanceButtonProps) => {
	const { listMetaData, format } = props;
	const { filter, totalCount } = listMetaData;
	const { t } = useTranslation(closingBalanceConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportClosingBalanceRequest>>();
	const isDisabled = totalCount === 0;

	return (
		<>
			<Button
				testId="exportClosingBalance"
				size="sm"
				variant="outline-primary"
				disabled={isDisabled}
				onClick={() =>
					exportRef.current?.show({
						request: { format, filter: closingBalanceFilterNormalizer.denormalize(filter) },
						useQuery: useApiClosingBalanceExport,
					})
				}
			>
				{t(`actions.export.${camelCase(format)}`)}
			</Button>
			<AsyncExportModal forwardedRef={exportRef} testId="exportClosingBalance" />
		</>
	);
};
