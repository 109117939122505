import type { IEntityProductGroup } from 'module/purchase';
import type { TSelectOptions } from '@avast/react-ui-components';
import { useApiListQuery } from 'js/queries/useApiListQuery';
import { CACHE_TIME } from 'appConstants';
import { useCallback, useMemo } from 'react';
import { productGroupEntityNormalizer } from 'module/purchase/normalizer';
import type { AxiosResponse } from 'axios';
import type { TApiListResponse } from 'types/api';
import type { MarketSegmentEnum } from 'js/enums';
import { filterData } from 'js/utils/common';

type TUseProductGroupSelectOptions = {
	options: TSelectOptions;
	isLoading: boolean;
	list: IEntityProductGroup[];
};

export const useProductGroupSelectOptions = (marketSegment?: MarketSegmentEnum): TUseProductGroupSelectOptions => {
	const {
		data: productGroupList,
		query: { isFetching },
	} = useApiListQuery<IEntityProductGroup, { onlyFirstPurchaseEnabled?: boolean }>(
		'product',
		{ filter: { onlyFirstPurchaseEnabled: true } },
		{
			queryConfig: {
				staleTime: CACHE_TIME.MEDIUM,
				select: useCallback(
					(response: AxiosResponse<TApiListResponse<IEntityProductGroup>>) => ({
						...response,
						data: productGroupEntityNormalizer.normalizeList(response.data),
					}),
					[],
				),
			},
		},
	);

	return {
		isLoading: isFetching,
		list: productGroupList,
		options: useMemo(
			() =>
				filterData<IEntityProductGroup>(productGroupList, { marketSegment }).map((group) => ({
					value: group.code,
					description: group.code,
					label: group.name,
				})),
			[marketSegment, productGroupList],
		),
	};
};
