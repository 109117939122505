import type { IEntityProductGroup, IPricingRequestItem } from 'module/purchase';
import { useLicenseOperationPricingItem } from './useLicenseOperationPricingItem';
import { type TUsePricingResponse, useLicenseOperationPricing } from './useLicenseOperationPricing';

/**
 * Pricing for selected variant component
 *
 * @param {IEntityProductGroup} productGroup
 * @returns {TUsePaymentTypesResponse}
 */
export const useLicenseOperationVariantsPricing = (productGroup: IEntityProductGroup): TUsePricingResponse => {
	const licenseOperationPricingItem = useLicenseOperationPricingItem();
	const lineItems =
		productGroup.products?.map<IPricingRequestItem>((product) => {
			return licenseOperationPricingItem(productGroup, product);
		}) || [];

	return useLicenseOperationPricing({ lineItems });
};
