import type { IEntityLegacyPaymentOption } from 'module/account';
import { CreditCardPreviewRow } from 'module/account/components/autoBilling/CreditCardPreviewRow';
import { useDeletePaymentOption } from 'module/account/hooks/useDeletePaymentOption';

type TLegacyPaymentOptionPreviewRowProps = {
	paymentOption: IEntityLegacyPaymentOption;
	onDelete?(): void;
};

export const LegacyPaymentOptionPreviewRow = (props: TLegacyPaymentOptionPreviewRowProps) => {
	const { paymentOption, onDelete } = props;
	const deletePaymentOption = useDeletePaymentOption(paymentOption);

	return (
		<CreditCardPreviewRow
			paymentOption={paymentOption}
			name={paymentOption.nickName}
			onDelete={async () => {
				if (await deletePaymentOption()) {
					onDelete?.();
				}
			}}
		/>
	);
};
