import type { IEntityOrder } from 'module/orders';
import type { IStandardOrderInstance } from 'module/purchase';
import { getCustomerCurrency, hasOrderSavedPricingResponse } from 'module/orders/utils/common';
import { useIsLocked } from 'module/orders/hooks/useIsLocked';
import { useOrderInstanceData } from 'module/orders/hooks/orderToInstance/useOrderInstanceData';
import type { TOrderToInstance } from 'module/orders/hooks/orderToInstance';
import { useAuthContext } from 'js/contexts';

export const useOrderToInstance = (order: IEntityOrder | null): TOrderToInstance => {
	const isLocked = useIsLocked();
	const { authPartnerId } = useAuthContext();

	// Order data
	const { parties, products, priceList, isFetching } = useOrderInstanceData(order);

	// Invalid object
	if (!order) {
		return { isFetching: false, orderInstance: null };
	}

	// Fetching state
	if (isFetching) {
		return { isFetching, orderInstance: null };
	}

	// Prepare instance data
	const orderInstance: IStandardOrderInstance = {
		timestamp: Date.now(),
		standardOrderId: order.id,
		creationSource: order.creationSource,
		authPartnerId,
		isReady: true,
		isLocked: isLocked(order),
		hasSavedPricingResponse: hasOrderSavedPricingResponse(order),

		// Order parties
		partner: parties.partner,
		distributionPartner: parties.distributionPartner,
		customer: parties.customer,
		customerCurrencyCode: getCustomerCurrency(order),

		// Items
		seqId: products.seqId,
		items: products.items,
		pricing: products.pricing,

		// Form data
		additionalInfo: {
			externalPurchaseNumber: order.purchaseOrderNumber,
			privateNotes: order.privateNotesHeader,
			publicNotes: order.publicNotesHeader,
			opportunityId: order.opportunityId,
			customerQuoteMessage: order.customerPriceEmail?.personalMessage,
		},
		discretionaryDiscount: order.discount?.percent,

		// Additional data
		quote: order.quote,
		action: null,
		priceListCode: priceList.code,
	};

	return { isFetching: false, orderInstance };
};
