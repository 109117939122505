import { useTranslation } from 'react-i18next';
import { Alert } from '@avast/react-ui-components';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TDiscountApprovalAlertProps = {
	className?: string;
};

export const DiscountApprovalAlert = (props: TDiscountApprovalAlertProps) => {
	const { className } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return <Alert variant="warning" caption={t('discretionaryDiscount.waitingApprovalMessage')} className={className} />;
};
