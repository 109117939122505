import { OrderItemLicenses } from 'module/orders/components/modalContent/OrderItemLicenses';
import { useTranslation } from 'react-i18next';
import type { IEntityOrderItem } from 'module/orders';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';

export type TLicenseModalProps = {
	orderItem: IEntityOrderItem;
};

export const AsyncLicensesModal = (props: TAsyncModalContainerProps<TLicenseModalProps>) => {
	const { t } = useTranslation('moduleOrders');
	const { forwardedRef } = props;

	return (
		<AsyncModal<TLicenseModalProps> ref={forwardedRef} size="lg" testId="licenses">
			{({ orderItem }) => (
				<>
					<Modal.Header>
						{t('common.itemLicenses')} #{orderItem.id}
					</Modal.Header>
					<Modal.Body>
						<OrderItemLicenses orderItem={orderItem} />
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
