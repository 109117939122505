import { useEffect } from 'react';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { isPaymentInstructionsEligible } from 'module/orders/utils/common';
import { Button } from '@avast/react-ui-components';
import { PaymentInstructionsModal } from 'module/orders/components/paymentInstructions/PaymentInstructionsModal';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';

type TPaymentInstructionsButtonButtonProps = {
	openOnInit?: boolean;
};

export const PaymentInstructionsButton = (props: TPaymentInstructionsButtonButtonProps) => {
	const {
		data: order,
		query: { isFetching },
	} = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const modalRef = useAsyncModalRef();
	const isEligible = isPaymentInstructionsEligible(order);
	const openOnInit = props.openOnInit && isEligible;

	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		if (openOnInit) {
			modalRef.current?.show();
		}
	}, []);

	if (!isEligible) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				loading={isFetching}
				variant="outline-primary"
				onClick={() => modalRef.current?.show()}
				testId="paymentInstructions"
			>
				{t('common.paymentInstructions')}
			</Button>
			<AsyncModal ref={modalRef} testId="paymentInstructions">
				<PaymentInstructionsModal />
			</AsyncModal>
		</>
	);
};
