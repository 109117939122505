import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { ordersConfig } from 'module/orders/ordersConfig';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { getBillablePartyRenderName, isClosingBalanceOrder, isOrderPaymentInStatus } from 'module/orders/utils/common';
import { dateFormatter } from 'js/utils/dateTime';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useOrderClosingBalance } from 'module/orders/hooks/useOrderClosingBalance';
import { PaymentStatusEnum, paymentStatusEnumUtils, paymentTypeEnumUtils } from 'js/enums';
import { billablePartyTypeEnumUtils, creationSourceEnumUtils, orderStatusEnumUtils } from 'module/orders/enums';
import { Link } from 'react-router';

export const OrderStatusOverview = () => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const { isFetching: isFetchingClosingBalanceEventId, closingBalance } = useOrderClosingBalance(order);

	const {
		query: { isFetching },
	} = usePageDetailContext();
	const isUnbilledClosingBalance =
		isClosingBalanceOrder(order) && isOrderPaymentInStatus(order, PaymentStatusEnum.UNBILLED);

	return (
		<Row multi md={2}>
			<Col>
				<DataTable isLoading={isFetching}>
					<DataTableTr name={t('common:entity.orderStatus')} highlighted>
						{orderStatusEnumUtils.getText(order.status)}
					</DataTableTr>
					<DataTableTr name={t('common:entity.paymentMethod')}>
						{paymentTypeEnumUtils.getText(order.payment?.type)}
					</DataTableTr>
					<DataTableTr name={t('common:entity.paymentStatus')}>
						{paymentStatusEnumUtils.getText(order.payment?.status)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.billing')}>
						{isUnbilledClosingBalance ? null : dateFormatter.toDate(order.billing?.date)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.paymentDue')}>
						{isUnbilledClosingBalance ? null : dateFormatter.toDate(order.payment?.dueDate)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.payment')}>{dateFormatter.toDate(order.payment?.date)}</DataTableTr>
					<DataTableTr name={t('common:dates.created')}>
						{dateFormatter.toTimeZoneDateTime(order.createdAt)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.expire')}>
						{dateFormatter.toTimeZoneDateTime(order.expirationDate)}
					</DataTableTr>
				</DataTable>
			</Col>
			<Col>
				<DataTable isLoading={isFetching}>
					<DataTableTr name={t('entity.originateAsQuote')} highlighted>
						{t(`common:boolean.${Boolean(order.quote?.flag)}`)}
					</DataTableTr>
					<DataTableTr name={t('common:entity.secondaryOrderId')}>{order.gcOrderId}</DataTableTr>
					<DataTableTr name={t('common:entity.poNumber')}>{order.purchaseOrderNumber}</DataTableTr>
					<DataTableTr name={t('entity.opportunityId')} noRenderEmpty>
						{order.opportunityId}
					</DataTableTr>
					<DataTableTr name={t('entity.billablePartyType')}>
						{billablePartyTypeEnumUtils.getText(order.billableParty?.type)}
					</DataTableTr>
					<DataTableTr name={t('entity.billablePartyName')}>{getBillablePartyRenderName(order)}</DataTableTr>
					<DataTableTr name={t('entity.createdBy')}>
						{order.createdByPartyId || order.transaction?.ownerIndividualId}
					</DataTableTr>
					<DataTableTr name={t('entity.creationSource')}>
						{creationSourceEnumUtils.getText(order.creationSource)}
					</DataTableTr>
					<DataTableTr name={t('entity.statementId')} isLoading={isFetchingClosingBalanceEventId}>
						{closingBalance ? (
							<Link to={closingBalanceConfig.detailLink(closingBalance)}>{closingBalance.id}</Link>
						) : (
							order.periodActivityUuid
						)}
					</DataTableTr>
				</DataTable>
			</Col>
		</Row>
	);
};
