import type { ReactElement } from 'react';
import type { IEntityCustomer } from 'module/customers';
import { SelectCustomerInternalCompany } from 'module/customers/components/SelectCustomerInternalCompany';
import { SelectPartnerCustomer } from '.';
import type { TPartnerId } from 'types';
import { useSelectCustomerColumns } from 'module/customers/hooks';
import { useAuthContext } from 'js/contexts';

export type TSelectCustomerContainerProps = {
	selectedCustomerId?: IEntityCustomer['id'];
	partnerId?: TPartnerId | null;
	enableCreate?: boolean;
};

type TTableData = IEntityCustomer;

export const SelectCustomerContainer = (
	props: TSelectCustomerContainerProps & {
		onSelect(partner: TTableData): void;
	},
): ReactElement => {
	const { onSelect, partnerId, selectedCustomerId } = props;
	const { isGroupInternal } = useAuthContext();

	// Define columns
	const columns = useSelectCustomerColumns();

	if (isGroupInternal && !partnerId) {
		return <SelectCustomerInternalCompany onSelect={onSelect} columns={columns} />;
	}

	return (
		<SelectPartnerCustomer
			selectedCustomerId={selectedCustomerId}
			columns={columns}
			onSelect={onSelect}
			partnerId={partnerId}
			enableCreate={props.enableCreate}
		/>
	);
};
