import type { IEntityLanguage } from 'submodule/localeSwitch';

export const languages: IEntityLanguage[] = [
	{
		locale: 'en-US',
		language: 'en',
		name: 'English',
	},
	{
		locale: 'de-DE',
		language: 'de',
		name: 'German',
	},
	{
		locale: 'it-IT',
		language: 'it',
		name: 'Italian',
	},
	{
		locale: 'es-ES',
		language: 'es',
		name: 'Spanish',
	},
	{
		locale: 'pt-PT',
		language: 'pt',
		name: 'Portuguese',
	},
	{
		locale: 'fr-FR',
		language: 'fr',
		name: 'French',
	},
	{
		locale: 'ja-JP',
		language: 'ja',
		name: 'Japanese',
	},
	{
		locale: 'zh-TW',
		language: 'zh',
		name: 'Chinese (Traditional)',
	},
];
