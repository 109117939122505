import * as Yup from 'yup';
import { useMemo } from 'react';
import { yupContextValidator } from 'js/utils/validator';
import type { IFormikContextValidator } from 'types/validator';
import { useTranslation } from 'react-i18next';
import type { IInitiateRefundForm } from 'submodule/refunds';
import type { RefundReasonEnum } from 'submodule/refunds/enums';

export type FormValues = Partial<IInitiateRefundForm>;

export const useInitiateRefundFormValidator = (): IFormikContextValidator<FormValues> => {
	const { t } = useTranslation('submodules');

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<FormValues>({
				refundReason: Yup.string<RefundReasonEnum>().label(t('refunds.entity.reason')).required(),

				refundReasonNote: Yup.string().ensure().label(t('refunds.entity.reasonNote')).required(),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) => yupContextValidator<FormValues>(validationSchema, values),
	};
};
