import { useCallback, useEffect, useState } from 'react';
import { useFetchAsyncJobRecursively } from 'js/asyncJobs/useFetchAsyncJobRecursively';
import { useDownloadAsyncJobFile } from 'js/asyncJobs/useDownloadAsyncJobFile';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import type { IAsyncExportProps } from 'js/components/exports';
import type { IAsyncJob } from 'js/asyncJobs';
import { useApiErrorContext } from 'js/contexts';

type TUseExportProps<Request extends {}> = IAsyncExportProps<Request> & {
	onSettled(): void;
};

export const useExport = <Filter extends {}>(props: TUseExportProps<Filter>) => {
	const { request, useQuery, onSettled } = props;
	const { setError } = useApiErrorContext();
	const [asyncJob, setAsyncJob] = useState<IAsyncJob | null>(null);
	const { mutateAsync } = useQuery();
	const downloadFile = useDownloadAsyncJobFile();
	const { t } = useTranslation('components');

	// Create an export request
	useEffect(() => {
		let running = true;

		mutateAsync(request)
			.then(({ data }) => {
				if (running) {
					setAsyncJob(data);
				}
			})
			.catch((error) => {
				if (running) {
					setError({ error });
					onSettled();
				}
			});

		return () => {
			running = false;
		};
	}, [request, mutateAsync, setError, onSettled]);

	// Fetch job recursively until is in success or in error
	useFetchAsyncJobRecursively(
		asyncJob,
		useCallback(
			async (asyncJob) => {
				await downloadFile(asyncJob);
				onSettled();
			},
			[onSettled, downloadFile],
		),
		useCallback(() => {
			toast.error(<Toast>{t('export.error')}</Toast>);
			onSettled();
		}, [onSettled, t]),
	);
};
