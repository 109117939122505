import { useState } from 'react';
import { type TUseStorageToggle, useStorageToggle } from 'js/hooks/useStorageToggle';
import { useIsMobileScreen } from 'js/hooks/useIsMobileScreen';

export const useSidebarCollapsed = (): TUseStorageToggle => {
	const isMobileScreen = useIsMobileScreen();
	const sidebarCollapsed = useStorageToggle('sidebarCollapsed', isMobileScreen);
	const [mobileScreenCollapsed, setMobileScreenCollapsed] = useState<boolean>(true);

	return {
		setValue(value) {
			if (isMobileScreen) {
				setMobileScreenCollapsed(value);
			} else {
				sidebarCollapsed.setValue(value);
			}
		},
		toggle() {
			if (isMobileScreen) {
				setMobileScreenCollapsed((value) => !value);
			} else {
				sidebarCollapsed.toggle();
			}
		},
		value: isMobileScreen ? mobileScreenCollapsed : sidebarCollapsed.value,
	};
};
