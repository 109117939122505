import { type ReactElement, useMemo } from 'react';
import { DetailTab, InformationTab, StatementTab } from 'module/closingBalance/pages/tabs';
import { useApiClosingBalance } from 'module/closingBalance/hooks/useApiClosingBalance';
import type { IEntityClosingBalance, IEntityOmsClosingBalance } from 'module/closingBalance';
import type { TDetailArguments } from 'types';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { Tab, Tabs } from 'react-bootstrap';
import { buildParametrizedRoute } from 'js/utils/common';
import { useTabsTestAttributes } from 'js/hooks/testAttributes/useTabsTestAttributes';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = TDetailArguments;
type TData = IEntityClosingBalance;

export const PageDetail = (): ReactElement => {
	const { t } = useTranslation(closingBalanceConfig.trNamespace);
	const params = useRouteParams<TArguments>();
	const queryProps = useMemo(() => ({ filter: params }), [params]);
	const testAttributes = useTabsTestAttributes('closingBalance');

	return (
		<PageDetailComponent<TData, TArguments, IEntityOmsClosingBalance>
			query={useApiClosingBalance}
			queryProps={queryProps}
			detailLink={buildParametrizedRoute(RouteName.CLOSING_BALANCE.DETAIL, params.id)}
		>
			<Tabs className="px-4" defaultActiveKey="informationView" {...testAttributes}>
				<Tab eventKey="informationView" title={t('tab.info')}>
					<InformationTab />
				</Tab>
				<Tab eventKey="statementView" title={t('tab.statement')}>
					<StatementTab />
				</Tab>
				<Tab eventKey="detailView" title={t('tab.detail')}>
					<DetailTab />
				</Tab>
			</Tabs>
		</PageDetailComponent>
	);
};
