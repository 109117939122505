import { Formik } from 'formik';
import type { IInitiateRefundForm } from 'submodule/refunds';
import { FormikForm } from 'js/components/formik/FormikForm';
import { useState } from 'react';
import { useInitiateRefundContext } from './InitiateRefundContext';
import { StepProducts, StepReview } from 'submodule/refunds/initiate/form';
import { InitiateRefundFormEnum } from 'submodule/refunds/enums';
import { useAppContext, usePageDetailContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import type { FormikHelpers } from 'formik/dist/types';
import { isClosingBalanceStatement } from 'module/orders/utils/common';
import { isFullRefund, isRefundTypeOrder } from 'submodule/refunds/utils';

export const InitiateRefundForm = () => {
	const [step, setStep] = useState<InitiateRefundFormEnum>(InitiateRefundFormEnum.PRODUCTS);
	const { onChangeView, initialValues, onSubmit, onCancel, validationSchema } = useInitiateRefundContext();
	const { data: orderLike } = usePageDetailContext();
	const { confirmationModalRef } = useAppContext();
	const { t } = useTranslation('submodules');

	onChangeView(step);

	async function showConfirmationModal() {
		return confirmationModalRef.current?.show({
			title: t('refunds.modal.confirmation.title'),
			messages: [t('refunds.modal.confirmation.message')],
			submitButtonText: t('common:actions.confirm'),
		});
	}

	async function submitHandler(values: IInitiateRefundForm, formikHelpers: FormikHelpers<IInitiateRefundForm>) {
		const hasToBeConfirmed = isRefundTypeOrder(values) && isFullRefund(values) && isClosingBalanceStatement(orderLike);

		if (hasToBeConfirmed) {
			// Show confirmation modal and wait for submit or reject
			const isConfirmed = await showConfirmationModal();

			if (isConfirmed) {
				onSubmit(values, formikHelpers);
			} else {
				onCancel();
			}
		} else {
			onSubmit(values, formikHelpers);
		}
	}

	return (
		<Formik<IInitiateRefundForm>
			initialValues={initialValues}
			onSubmit={submitHandler}
			validationSchema={validationSchema}
		>
			<FormikForm testId="initiateRefund">
				{step === InitiateRefundFormEnum.PRODUCTS ? (
					<StepProducts
						onSubmit={() => {
							setStep(InitiateRefundFormEnum.REVIEW);
						}}
						onCancel={onCancel}
					/>
				) : (
					<StepReview
						onCancel={() => {
							setStep(InitiateRefundFormEnum.PRODUCTS);
						}}
					/>
				)}
			</FormikForm>
		</Formik>
	);
};
