import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { Alert } from '@avast/react-ui-components';
import { dateFormatter } from 'js/utils/dateTime';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';

export const CurrentLicenseInfo = () => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { license } = useLicenseOperationContext();
	const { product } = license;
	const validity = t(`common:datePeriod.${product.validityUnit}`, { count: product.validity });
	const title = `${product.productGroupName} [${product.productGroup}] - ${validity}`;

	return (
		<Alert caption={title} icon={false} variant="info">
			<table className="fs-sm table-currentLicenseInfo">
				<tbody>
					<tr>
						<th>{t('common:entity.unitType.SEATS', { count: product.dimension })}:</th>
						<td>{product.dimension}</td>
					</tr>
					<tr>
						<th>{t('common:dates.expire')}:</th>
						<td>{dateFormatter.toTimeZoneDateTime(license.expiration)}</td>
					</tr>
				</tbody>
			</table>
		</Alert>
	);
};
