import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { hasAvailableDocuments } from 'module/orders/utils/common';
import { ChargeOrderDocumentsButton } from 'js/components/documents/ChargeOrderDocumentsButton';
import { useIsChargeOrderRefunded } from 'submodule/refunds/hooks/useIsChargeOrderRefunded';

type TOrderDocumentsButtonProps = {
	openOnInit?: boolean;
};

export const OrderDocumentsButton = (props: TOrderDocumentsButtonProps) => {
	const { data: order, query } = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const isChargeOrderRefunded = useIsChargeOrderRefunded(order.chargeOrderId);
	const availableDocuments = hasAvailableDocuments(order, isChargeOrderRefunded);

	if (!availableDocuments || typeof order.chargeOrderId === 'undefined') {
		return null;
	}

	return (
		<ChargeOrderDocumentsButton
			id={order.id}
			query={query}
			chargeOrderId={order.chargeOrderId}
			title={t('common.documents')}
			defaultOpen={props.openOnInit}
		/>
	);
};
