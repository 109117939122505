import type { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import type { IEntityPartnerDetail } from 'module/partners';
import { partnerSubTypeEnumUtils, partnerTypeEnumUtils } from 'module/partners/enums';
import { getPartnerFullName, getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { useCountryName } from 'js/entities/country/useCountryName';
import { useCountryStateName } from 'js/entities/country/useCountryStateName';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { EmailDisplay } from 'js/components/atoms/EmailDisplay';

type TPartnerDetailProps = {
	partner: IEntityPartnerDetail | null;
};

export const PartnerDetail = (props: TPartnerDetailProps): ReactElement => {
	const { partner } = props;
	const { t } = useTranslation(partnersConfig.trNamespace);
	const countryName = useCountryName(partner?.countryCode, partner?.countryName);
	const stateName = useCountryStateName(partner?.countryCode, partner?.stateCode, partner?.stateName);

	if (!partner || !partner?.id) {
		return <Alert caption={t('error:detailNotFound')} />;
	}

	return (
		<Row multi>
			<Col md="6">
				<DataTableCard caption={t('common:accountInformation')} variant="simple">
					<DataTableTr name={t('common:entity.company')}>{getPartnerRenderName(partner)}</DataTableTr>
					<DataTableTr name={t('entity.accountType')} noRenderEmpty>
						{partner.accountType ? partnerTypeEnumUtils.getText(partner.accountType) : null}
					</DataTableTr>
					<DataTableTr name={t('entity.accountSubType')} noRenderEmpty>
						{partner.accountSubType ? partnerSubTypeEnumUtils.getText(partner.accountSubType) : null}
					</DataTableTr>
					<DataTableTr name={t('entity.salesforceId')}>{partner.salesforceId}</DataTableTr>
					<DataTableTr name={t('common:contact.taxId')}>{partner.identifiers?.partyTaxId}</DataTableTr>
					<DataTableTr name={t('common:entity.currency')} noRenderEmpty>
						{partner.currencyCode}
					</DataTableTr>
					<DataTableTr name={t('common:contact.name')}>{getPartnerFullName(partner)}</DataTableTr>
					<DataTableTr name={t('common:contact.email')}>
						<Copy2Clipboard value={partner.email}>
							<EmailDisplay email={partner.email} />
						</Copy2Clipboard>
					</DataTableTr>
					<DataTableTr name={t('common:contact.phone')}>{partner.phone}</DataTableTr>
				</DataTableCard>
			</Col>
			<Col md="6">
				<DataTableCard caption={t('common:address.caption')} variant="simple">
					<DataTableTr name={t('common:address.street')}>{partner.address1}</DataTableTr>
					<DataTableTr name={t('common:address.city')}>{partner.city}</DataTableTr>
					<DataTableTr name={t('common:address.postalCode')}>{partner.postalCode}</DataTableTr>
					<DataTableTr name={t('common:address.state')} noRenderEmpty>
						{stateName}
					</DataTableTr>
					<DataTableTr name={t('common:address.country')}>{countryName}</DataTableTr>
				</DataTableCard>
			</Col>
		</Row>
	);
};
