import { useEffect, useRef } from 'react';

/**
 * Returns previous value which is saved in a reference.
 *
 * @template T
 * @param {T} value - Value of property which will be referenced.
 * @returns {T | undefined} - Value of the referenced property.
 */
export const usePrevious = <T,>(value: T) => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};
