import type { TAnnouncement } from 'submodule/Announcements/types';
import { Trans, useTranslation } from 'react-i18next';
import { LinkButton } from 'js/components/atoms/Button';
import { Tip } from 'assets/image/icon';
import { useAuthContext } from 'js/contexts';

export const useFlexibleBillingAvailableAnnouncement = (): TAnnouncement => {
	const { t } = useTranslation('submodules');
	const { isRoleReseller, authPartner } = useAuthContext();
	const learnMoreLink =
		'https://businesshelp.avast.com/Content/Products/AfB_Management_Consoles/UserandAccountManagement/FlexibleBilling.htm';

	return {
		id: 'badbf55c-ee40-4840-9cbc-7339dac33784',
		show: isRoleReseller && !!authPartner?.isBusinessPartner,
		variant: 'success',
		icon: <Tip />,
		caption() {
			return <Trans t={t}>{'announcements.flexibleBillingAvailable.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p className="mb-3">
						<Trans t={t} i18nKey="announcements.flexibleBillingAvailable.content" />
					</p>
					<p>
						<LinkButton to={learnMoreLink} size="xs" target="_blank" rel="noopener noreferrer">
							{t('announcements.flexibleBillingAvailable.action')}
						</LinkButton>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_PARTNER'],
	};
};
