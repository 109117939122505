import { ButtonComposition, Modal } from '@avast/react-ui-components';
import { useCallback, useEffect, useState } from 'react';
import { HelpBackButton } from 'js/help/components/HelpBackButton';
import { GuideSlide } from 'js/help/guide/GuideSlide';
import useEmblaCarousel from 'embla-carousel-react';
import { GuidePrevButton } from 'js/help/guide/GuidePrevButton';
import { GuideNextButton } from 'js/help/guide/GuideNextButton';
import { HELP_GUIDE_SLIDES } from 'js/help/guide/guideContent';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'js/contexts';

export const HelpGuide = () => {
	const { helpModalRef } = useAppContext();
	const [activeSlider, setActiveSlider] = useState(0);
	const [sliderRef, slider] = useEmblaCarousel({ watchDrag: false });
	const { t } = useTranslation('app');

	const onSelect = useCallback(() => {
		if (slider) {
			setActiveSlider(slider.selectedScrollSnap());
		}
	}, [slider]);

	useEffect(() => {
		slider?.on('select', onSelect);
	}, [slider, onSelect]);

	return (
		<>
			<Modal.Header className="pb-0">
				<HelpBackButton />
			</Modal.Header>
			<Modal.Body className="py-4 px-5 px-md-7">
				<div className="custom-slider">
					<div className="slider-viewport" ref={sliderRef}>
						<div className="slider-container">
							{HELP_GUIDE_SLIDES.map((slideKey) => (
								<div className="slide" key={slideKey}>
									<GuideSlide slideKey={slideKey} />
								</div>
							))}
						</div>
					</div>
				</div>
				<ButtonComposition className="align-items-start justify-content-center" marginY>
					<GuidePrevButton slider={slider} />
					<div className="text-center">
						<GuideNextButton slider={slider} onFinish={() => helpModalRef.current?.onCancel()} />
						<div className="mt-1 fs-md text-muted">
							{t('help.guide.paginator', {
								current: activeSlider + 1,
								total: HELP_GUIDE_SLIDES.length,
							})}
						</div>
					</div>
				</ButtonComposition>
			</Modal.Body>
		</>
	);
};
