import { type ReactElement, useCallback, useMemo, useState } from 'react';
import { ButtonComposition } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import type { IApiSortBy } from 'types/api';
import type { IDistributionPartnerListFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { DistributionPartnerFilter } from 'module/distributionPartners/components';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { Can } from 'js/components/molecules/Can';
import { useApiDistributionPartnersList } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { buildParametrizedRoute } from 'js/utils/common';
import type { TQueryDetailFilter } from 'module/distributionPartners/pages/PageDetail';
import { ExportAllDistributionPartnersButton } from 'module/distributionPartners/components/buttons';
import type { IListMetaDataValues } from 'types/utils';
import { useDistributionPartnerListColumns } from 'module/distributionPartners/hooks';
import { isFunction, omit } from 'lodash';
import { useNavigate } from 'react-router';
import { SortDirectionEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityDistributionPartner;
type TTableDataFilter = IDistributionPartnerListFilter;

export const PageList = (): ReactElement => {
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const { authCompanyId, isGroupInternal } = useAuthContext();
	const navigate = useNavigate();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({
		totalCount: 0,
	});

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);
	const filterStatic = useMemo<TTableDataFilter>(
		() => (isGroupInternal ? {} : { distributorId: authCompanyId }),
		[authCompanyId, isGroupInternal],
	);

	// Table args
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'name', direction: SortDirectionEnum.ASC });
	const onRowClick = useCallback(
		(row: TTableData) => {
			if (isGroupInternal) {
				return buildParametrizedRoute<TQueryDetailFilter>(RouteName.DISTRIBUTION_PARTNERS.DETAIL, {
					id: row.id,
					distributorId: row.distributorId,
				});
			}
			return distributionPartnersConfig.detailLink(row);
		},
		[isGroupInternal],
	);

	// Define columns
	const columns = useDistributionPartnerListColumns(onRowClick);

	return (
		<>
			<FilterPlaceholderPortal>
				<DistributionPartnerFilter
					onChange={setFilter}
					values={filter}
					defaultValues={filterStatic}
					controls={
						<ButtonComposition size="xs">
							<ExportAllDistributionPartnersButton
								partnerId={listMetaData.filter?.distributorId}
								isDisabled={listMetaData.totalCount === 0}
							/>
							<Can do={distributionPartnersConfig.aclModule} create>
								<LinkButton
									size="sm"
									variant="primary"
									to={RouteName.DISTRIBUTION_PARTNERS.CREATE}
									testId="newDistributionPartner"
								>
									{t('actions.create')}
								</LinkButton>
							</Can>
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>

			<ApiListTable<TTableData, TTableDataFilter>
				useLocation
				columns={columns}
				query={useApiDistributionPartnersList}
				sort={sort}
				filter={filter === null ? filter : omit(filter, 'search')}
				filterStatic={filterStatic}
				filterRequired={t('common:filter.partnerIsRequired')}
				filterIsEmpty={(values) => isGroupInternal && !values.distributorId}
				globalFilterUrlKey="search"
				table={{
					onGlobalFilterChange(value) {
						setFilter((values) => ({
							...values,
							search: isFunction(value) ? value(values?.search) : value,
						}));
					},
					testId: 'distributionPartners',
					state: { globalFilter: filter?.search },
					meta: {
						paginationComponent: 'Full',
						onRowClick: (row) => navigate(onRowClick(row)),
					},
				}}
				onMetaDataChange={setListMetaData}
			/>
		</>
	);
};
