import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityCustomer } from 'module/customers';
import { Headline } from 'js/components/molecules/Headline';
import { customersConfig } from 'module/customers/customersConfig';
import { Alert, Button, H1 } from '@avast/react-ui-components';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { CustomerDetail } from 'module/customers/components/CustomerDetail';
import { Trans, useTranslation } from 'react-i18next';
import type { TPartnerId } from 'types';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { DetailViewActions } from 'module/customers/components/DetailViewActions';
import { API_CUSTOMERS_KEYS } from 'module/customers/hooks';
import { useCommonContext } from 'js/contexts';
import Skeleton from 'react-loading-skeleton';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { useRefetchQueries } from 'js/hooks/useRefetchQueries';

type TDetailContentProps = {
	partnerId?: TPartnerId;
};

export const PartnerDetailContent = (props: TDetailContentProps) => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const {
		data: customer,
		query: { isFetching },
	} = usePageDetailContext<IEntityCustomer>();
	const { createCustomerRef } = useCommonContext();
	const partnerQuery = useApiPartner({ id: props.partnerId });
	const refetch = useRefetchQueries();

	return (
		<>
			<Headline
				className="align-items-center"
				rightContent={<DetailViewActions partnerId={props.partnerId} disabled />}
			>
				<H1 type="h2">{getCustomerRenderName(customer)}</H1>
			</Headline>

			<Alert variant="warning" className="mt-3">
				<p>
					<Trans
						t={t}
						values={{ partner: getPartnerRenderName(partnerQuery.data) }}
						components={{
							strong: partnerQuery.query.isLoading ? <Skeleton inline width={140} /> : <strong />,
						}}
					>
						{'error.partnerIsNotBound'}
					</Trans>
				</p>
				<p className="mt-2">
					<Button
						size="xs"
						variant="outline-warning"
						onClick={async () => {
							const response = await createCustomerRef.current?.show({
								initialValues: customer,
								partnerId: props.partnerId,
							});
							if (response) {
								refetch([API_CUSTOMERS_KEYS.PARTNER_DETAIL]);
							}
						}}
					>
						Add Customer
					</Button>
				</p>
			</Alert>

			<CustomerDetail
				customer={customer}
				partnerId={props.partnerId}
				isLoading={isFetching || partnerQuery.query.isFetching}
			/>
		</>
	);
};
