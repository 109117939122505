import { App } from 'App';
import { useEffect, useState } from 'react';
import { LoadingFullScreenPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { H3 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Navigate } from 'react-router';
import { useInvalidPartnerState } from 'module/security/hooks/useInvalidPartnerState';
import { useAuthContext, useOrderContext } from 'js/contexts';

export const AppContainer = () => {
	const {
		authState: { isReady: authIsReady, partner },
	} = useAuthContext();
	const {
		orderState: { isReady: orderIsReady },
	} = useOrderContext();
	const [isReady, setIsReady] = useState(false);
	const { t } = useTranslation('common');
	const invalidPartnerState = useInvalidPartnerState();

	Sentry.setUser({
		id: partner?.id?.toString() || partner?.salesforceId || undefined,
		email: partner?.email,
		username: partner?.companyName,
	});

	// Wait to data on init
	useEffect(() => {
		setIsReady(authIsReady && orderIsReady);
	}, [authIsReady, orderIsReady]);

	if (isReady && invalidPartnerState.state && !invalidPartnerState.isCurrentPage) {
		return <Navigate to={invalidPartnerState.state.route} replace />;
	}

	return isReady ? (
		<App />
	) : (
		<LoadingFullScreenPlaceholder>
			<H3 bold>{t('_.loading')}</H3>
		</LoadingFullScreenPlaceholder>
	);
};
