import type { IDigitalRiverPaymentOption } from 'module/account';
import { useState } from 'react';
import { getCreditCardExpiration, getCreditCardImageUrl, getCreditCardNumber } from 'js/utils/creditCard';
import { ButtonComposition, IconButton } from '@avast/react-ui-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Col, Row } from 'js/components/atoms/Row';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons/faHouseUser';
import { isDefined } from 'js/utils/common';

type TCreditCardPreviewRowProps = {
	paymentOption: IDigitalRiverPaymentOption;
	name?: string;
	expanded?: boolean;
	single?: boolean;
	onDelete?(): void;
};

export const CreditCardPreviewRow = (props: TCreditCardPreviewRowProps) => {
	const { paymentOption, expanded, single, onDelete, name } = props;
	const { creditCard, owner } = paymentOption;
	const brandImage = getCreditCardImageUrl(creditCard.brand);

	const [isExpanded, setIsExpanded] = useState(single || (expanded ?? false));

	return (
		<>
			<tr className={classNames('creditCard', { 'is-expanded': isExpanded })}>
				<td className="thumb">{brandImage && <img src={brandImage} alt={creditCard.brand} className="img-fluid" />}</td>
				<td>
					{Boolean(name) && <strong>{name}</strong>}
					<div className="text-dark">{getCreditCardNumber(creditCard)}</div>
				</td>
				<td className="text-dark">{getCreditCardExpiration(creditCard)}</td>
				<td className="actions">
					<ButtonComposition size="xs" justify="end">
						{!single && Boolean(owner) && (
							<IconButton iconFa={faEye} size="xs" variant="outline-primary" onClick={() => setIsExpanded((v) => !v)} />
						)}
						<IconButton iconFa={faTimes} size="xs" variant="outline-danger" onClick={onDelete} />
					</ButtonComposition>
				</td>
			</tr>
			{isDefined(owner) && isExpanded && (
				<tr className="creditCardDetail">
					<td colSpan={4} className="p-0 border-0">
						<div className="px-3 bg-light border mt-2">
							<Row multi>
								<Col sm={6}>
									<div className="icon-container">
										<div className="ico">
											<FontAwesomeIcon icon={faUser} />
										</div>
										<div>
											{owner.firstName} {owner.lastName}
										</div>
										<div>{owner.email}</div>
									</div>
								</Col>
								<Col sm={6}>
									<div className="icon-container">
										<div className="ico">
											<FontAwesomeIcon icon={faHouseUser} />
										</div>
										<div>{owner.address.line1}</div>
										<div>
											{owner.address.postalCode}, {owner.address.city}
										</div>
										<div>
											{owner.address.country}
											{owner.address.state && ` (${owner.address.state})`}
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</td>
				</tr>
			)}
		</>
	);
};
