import { pick } from 'lodash';
import en from './en/translation.json';
import de from './de/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import it from './it/translation.json';
import ja from './ja/translation.json';
import zh from './zh/translation.json';
import pt from './pt/translation.json';
import type { ResourceLanguage } from 'i18next';
import type { TLocaleLanguage } from 'types/config';
import { LOCALE } from 'config/locale';

export const languages: Partial<Record<TLocaleLanguage, ResourceLanguage>> = pick(
	{
		en,
		de,
		es,
		fr,
		it,
		ja,
		zh,
		pt,
	},
	LOCALE.LANGUAGES,
);
