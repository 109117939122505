import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import type { GcPageNameEnum } from 'js/enums';

export interface IHgopResponse {
	payload: string;
	type: GcPageNameEnum;
	url: string;
}

const API_HGOP_KEYS = {
	DETAIL: 'charge-order/:id/hgop',
};

export const useApiHgop: TUseApiDetailModule<IHgopResponse> = (props) => useApiDetailQuery(API_HGOP_KEYS.DETAIL, props);
