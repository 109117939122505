import type { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { FlexibleBillingRoutes } from 'module/flexibleBilling/FlexibleBillingRoutes';

export const FlexibleBillingContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={flexibleBillingConfig} />
		<FlexibleBillingRoutes />
	</>
);
