import type { ILocaleConfig, IUserLocale, TLocale, TLocaleLanguage } from 'types/config';
import { LOCALE } from 'config/locale';
import { localesConfig } from 'i18n/locales';
import { get } from 'lodash';
import { localeCodeNormalizer } from 'i18n/normalizer';

const extractLocaleCode = (locale: TLocale | string): { language: TLocaleLanguage; dialect: string } => {
	const [language, dialect] = locale.replace('_', '-').split('-');
	return { language: language as TLocaleLanguage, dialect };
};

export const resolveLocaleConfigFromLocaleCode = (locale?: TLocale | string | null): ILocaleConfig => {
	if (locale) {
		// Find exact match
		const exactConfig = get(localesConfig, locale, null) as ILocaleConfig | null;
		if (exactConfig) {
			return exactConfig;
		}

		// Find default for the language
		const { language } = extractLocaleCode(locale);
		const languageConfig = Object.values(localesConfig).find(
			(config) => config.default && config.language === language,
		);
		if (languageConfig) {
			return languageConfig;
		}
	}

	// Fallback to default locale
	return localesConfig[LOCALE.DEFAULT_LOCALE];
};

export const getDefaultUserLocale = (): IUserLocale => ({
	language: LOCALE.DEFAULT_LANGUAGE,
	format: LOCALE.DEFAULT_LOCALE,
});

export const validateLanguage = (language: TLocaleLanguage): TLocaleLanguage => {
	return LOCALE.LANGUAGES.includes(language) ? language : LOCALE.DEFAULT_LANGUAGE;
};

export const resolveLanguageFromLocaleCode = (locale?: TLocale | string): TLocaleLanguage => {
	const config = resolveLocaleConfigFromLocaleCode(locale);
	return validateLanguage(config.language);
};

export const resolveUserLocaleFromLocaleCode = (locale?: TLocale | string): IUserLocale => {
	const config = resolveLocaleConfigFromLocaleCode(localeCodeNormalizer.normalize(locale));
	return {
		language: validateLanguage(config.language),
		format: config.locale,
	};
};
