import { Formik } from 'formik';
import { useEffect } from 'react';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { JoinDeepPaths } from 'types/utils';
import { Col, Row } from 'js/components/atoms/Row';
import { Card, CardBody, CardHeader, FormMessage } from '@avast/react-ui-components';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { useCreditCardFormValidator } from 'module/account/hooks/useCreditCardFormValidator';
import { FormikCountrySelect } from 'js/components/formik/FormikCountrySelect';
import type { PartialDeep } from 'type-fest';
import type { FormikHelpers } from 'formik/dist/types';
import { mountDRElement, unmountDRElement } from 'module/account/utils/form';
import type { ICreditCard, ICreditCardOwner } from 'module/account';
import type { IDigitalRiverElement } from 'types/digitalRiver';
import { useAutoBillingContext } from 'module/account/context/AutoBillingContext';
import { useApiAutoBillingCountryList } from 'module/account/hooks/useApiAutoBilling';

export interface ICreditCardForm {
	type?: 'creditCard';
	owner?: PartialDeep<ICreditCardOwner>;
	creditCard?: PartialDeep<ICreditCard>;
	isPreferredPayAsYouGoPaymentMethod: boolean;
}

type TCreditCardFormDeepKeys = JoinDeepPaths<ICreditCardForm>;

type TCreditCardFormProps = {
	onSubmit(
		values: ICreditCardForm,
		cardNumber: IDigitalRiverElement,
		formikHelpers: FormikHelpers<ICreditCardForm>,
	): void;
};

export const CreditCardForm = (props: TCreditCardFormProps) => {
	const { onSubmit } = props;
	const { t } = useTranslation(accountConfig.trNamespace);
	const { cardNumber, cardExpiration, cardCVV } = useAutoBillingContext();
	const { validate } = useCreditCardFormValidator();

	useEffect(() => {
		mountDRElement(cardNumber, 'js-credit-card-number', 'js-credit-card-number-message');
		mountDRElement(cardExpiration, 'js-credit-card-expiration', 'js-credit-card-expiration-message');
		mountDRElement(cardCVV, 'js-credit-card-cvv', 'js-credit-card-cvv-message');

		return () => {
			unmountDRElement(cardNumber);
			unmountDRElement(cardExpiration);
			unmountDRElement(cardCVV);
		};
	}, [cardNumber, cardExpiration, cardCVV]);

	return (
		<Formik<ICreditCardForm>
			initialValues={{
				type: 'creditCard',
				isPreferredPayAsYouGoPaymentMethod: true,
			}}
			validate={validate}
			onSubmit={(values, formikHelpers) => onSubmit(values, cardNumber, formikHelpers)}
		>
			<FormikForm testId="creditCard">
				<Row multi>
					<Col md={6}>
						<Card>
							<CardHeader>{t('form.creditCard.owner')}</CardHeader>
							<CardBody>
								<Row size="sm">
									<Col sm={6}>
										<FormikControl label={t('common:contact.firstName')}>
											<FormikControl.Input name="owner.firstName" required />
										</FormikControl>
									</Col>
									<Col sm={6}>
										<FormikControl label={t('common:contact.lastName')}>
											<FormikControl.Input name="owner.lastName" required />
										</FormikControl>
									</Col>
								</Row>
								<FormikControl label={t('common:contact.email')}>
									<FormikControl.Input name="owner.email" required />
								</FormikControl>
								<hr />
								<FormikControl label={t('common:address.caption')}>
									<FormikControl.Input name="owner.address.line1" required />
								</FormikControl>
								<Row size="sm">
									<Col sm={7}>
										<FormikControl label={t('common:address.city')}>
											<FormikControl.Input name="owner.address.city" required />
										</FormikControl>
									</Col>
									<Col sm={5}>
										<FormikControl label={t('common:address.postalCode')}>
											<FormikControl.Input name="owner.address.postalCode" required />
										</FormikControl>
									</Col>
								</Row>
								<FormikCountrySelect<ICreditCardForm, TCreditCardFormDeepKeys>
									countryField="owner.address.country"
									countryStateField="owner.address.state"
									required
									customQuery={useApiAutoBillingCountryList}
								/>
							</CardBody>
						</Card>
					</Col>
					<Col md={6}>
						<Card>
							<CardHeader>{t('form.creditCard.creditCard')}</CardHeader>
							<CardBody>
								<Row size="sm">
									<Col xs={12}>
										<div className="form-group is-required">
											<label htmlFor="js-credit-card-number" className="form-label">
												{t('form.creditCard.number')}
											</label>
											<div id="js-credit-card-number" />
											<FormMessage type="danger" className="d-none" id="js-credit-card-number-message" />
										</div>
									</Col>
									<Col md={6}>
										<div className="form-group is-required">
											<label htmlFor="js-credit-card-expiration" className="form-label">
												{t('form.creditCard.expiration')}
											</label>
											<div id="js-credit-card-expiration" />
											<FormMessage type="danger" className="d-none" id="js-credit-card-expiration-message" />
										</div>
									</Col>
									<Col md={6}>
										<div className="form-group is-required">
											<label htmlFor="js-credit-card-cvv" className="form-label">
												{t('form.creditCard.cvv')}
											</label>
											<div id="js-credit-card-cvv" />
											<FormMessage type="danger" className="d-none" id="js-credit-card-cvv-message" />
										</div>
									</Col>
								</Row>
								<FormikControl groupProps={{ className: 'mb-0' }}>
									<FormikControl.Checkbox
										name="isPreferredPayAsYouGoPaymentMethod"
										label={t('form.creditCard.isPreferredPayAsYouGoPaymentMethod')}
									/>
								</FormikControl>
							</CardBody>
						</Card>

						<FormikFooterControls hr={false} submit={{ children: t('form.creditCard.submit') }} />
					</Col>
				</Row>
			</FormikForm>
		</Formik>
	);
};
