import type { IRegistrationData } from 'module/registration';
import type { IEntityPartnerDetail } from 'module/partners';

export const getRegistrationData = (partner: IEntityPartnerDetail): IRegistrationData => ({
	companyName: partner.companyName,
	firstName: partner.firstName,
	lastName: partner.lastName,
	email: partner.email,
	phone: partner.phone,
	address: partner.address1,
	countryCode: partner.countryCode,
	stateCode: partner.stateCode,
	city: partner.city,
	postalCode: partner.postalCode,
	taxId: partner.identifiers?.partyTaxId,
});
