import type { TableColumnsDef, TCurrency } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import type { IFlexibleBillingReport } from 'module/flexibleBilling';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useDateRangeFormat } from '@avast/react-ui-components';

type TTableData = IFlexibleBillingReport;

export const useFlexibleBillingReportsColumns = (
	onDocuments: (data: TTableData) => void,
	currency: TCurrency,
): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const dateRangeFormat = useDateRangeFormat();

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('startDate', {
				id: 'date',
				header: t('common:entity.period'),
				meta: {
					formatters: [
						(_, { row }) => {
							return dateRangeFormat([row.original.startDate, row.original.endDate]);
						},
						formatter.bold,
					],
				},
			}),
			columnHelper.money(
				'totalAmountWithoutTax',
				{
					header: t('common:entity.totalAmount'),
				},
				{ currency },
			),
			columnHelper.actions({
				button: {
					children: t('common:actions.download'),
					onClick: onDocuments,
					testId: 'download',
				},
				dropdown: {
					items(row) {
						return [
							<DropdownLink
								key="order"
								to={buildParametrizedRoute(RouteName.ORDER.DETAIL, row.standardOrderId)}
								testId="relatedOrder"
							>
								{t(licensesConfig.trPrefix('actions.relatedOrder'))}
							</DropdownLink>,
						];
					},
				},
			}),
		];
	}, [currency, t, onDocuments, dateRangeFormat]);
};
