import type { IPricingRequest, IPricingRequestHeader, IPricingRequestItem, IPricingResponse } from 'module/purchase';
import { useCompare } from 'js/hooks/useCompare';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApiPricing } from 'module/purchase/hooks/useApiPurchase';
import { pricingApiError } from 'module/purchase/utils/apiError';
import { useDebouncedCallback } from 'use-debounce';
import { useApiErrorContext } from 'js/contexts';

export type TUsePricingOptions = {
	debounceDelay?: number;
	isDisabled?: boolean;
};

export type TUsePricingResponse = {
	pricing: IPricingResponse | null;
	isPricingLoading: boolean;
};

/**
 * Call API pricing with passed data
 *
 * @param {IPricingRequestHeader | null} header
 * @param {IPricingRequestItem[]} items
 * @param {TUsePricingOptions} options
 * @return {TUsePricingResponse}
 */
export const usePricing = (
	header: IPricingRequestHeader | null,
	items: IPricingRequestItem[],
	options: TUsePricingOptions = {},
): TUsePricingResponse => {
	const { debounceDelay = 500, isDisabled } = options;
	const [pricing, setPricing] = useState<TUsePricingResponse['pricing']>(null);
	const { setError } = useApiErrorContext();
	const { isPending: isPricingPending, mutate } = useApiPricing();

	// API call cached
	const apiPricing = useCallback(
		(request: IPricingRequest) => {
			// Do not call API when no line items
			if (request.request_lineitems.length === 0) {
				setPricing(null);
				return;
			}

			mutate(request, {
				onSuccess({ data }) {
					setPricing(data);
				},
				onError(error) {
					setError({ error, resolve: pricingApiError });
					setPricing(null);
				},
			});
		},
		[mutate, setError],
	);

	// Prepare request
	const request: IPricingRequest | null = useMemo(() => {
		if (!header) {
			return null;
		}

		return {
			request_header: header,
			request_lineitems: items,
		};
	}, [header, items]);
	const requestChanged = useCompare<IPricingRequest | null>(request, true);
	const updateRequestData = useDebouncedCallback(apiPricing, debounceDelay);

	// Wait to change request data
	useEffect(() => {
		if (requestChanged && request && !isDisabled) {
			updateRequestData(request);
		}
	}, [requestChanged, request, updateRequestData, isDisabled]);

	return { pricing, isPricingLoading: isPricingPending };
};
