import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { ITaxExemption, TTaxExemptionStatus } from 'module/account';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';

type TTableData = ITaxExemption;
export const useTaxExemptionColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(accountConfig.trNamespace);

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('taxAuthority', { header: t('taxExemption.taxAuthority') }),
			columnHelper.date('startDate', {
				header: t('taxExemption.startDate'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.date('endDate', {
				header: t('taxExemption.endDate'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('fileName', {
				header: t('taxExemption.fileName'),
				meta: { formatters: [(value) => <code className="fs-sm text-dark">{value}</code>] },
			}),
			columnHelper.text('status', {
				header: t('common:entity.status'),
				meta: {
					formatters: [
						(status: TTaxExemptionStatus) => {
							switch (status) {
								case 'ELIGIBLE':
									return <span className="text-success">{t(`taxExemption.status.${status}`)}</span>;
								case 'NOT_ELIGIBLE':
									return <span className="text-danger">{t(`taxExemption.status.${status}`)}</span>;
								default:
									return status;
							}
						},
						formatter.bold,
					],
				},
			}),
		];
	}, [t]);
};
