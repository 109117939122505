import type { TUiTableRow } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import { LinkButton, type TLinkButtonProps } from 'js/components/atoms/Button/LinkButton';
import type { NavigateLink } from 'types';
import { navigateLinkNormalize } from 'js/utils/link';

export type TLinkButtonOptions<D extends {}> = Omit<TLinkButtonProps, 'to'> & {
	to: (row: D) => string | NavigateLink;
	conditionalProps?: (row: D) => Partial<TLinkButtonProps>;
};

export const linkButton =
	<Row extends TUiTableRow>(options: TLinkButtonOptions<Row>): TColumnFormatter<Row> =>
	(_, cell) => {
		const { to, conditionalProps, ...props } = options;
		return (
			<LinkButton
				size="xs"
				variant="outline-primary"
				{...props}
				{...conditionalProps?.(cell.row.original)}
				{...navigateLinkNormalize(to(cell.row.original))}
			/>
		);
	};
