import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import type { IEntityDistributionPartner, IFormDistributionPartnerFlexibleBilling } from 'module/distributionPartners';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { Formik } from 'formik';
import { FormikControl, FormikControlRadio } from 'js/components/formik/FormikControl';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { YesNoEnum } from 'js/enums';
import { FormikForm } from 'js/components/formik/FormikForm';
import type { INotification } from 'js/notifications';
import { dateFormatter } from 'js/utils/dateTime';

type TInviteToFlexibleBillingModalProps = {
	distributionPartner: IEntityDistributionPartner;
	notifications?: INotification[];
	onCancel(): void;
	onSubmit(values: IFormDistributionPartnerFlexibleBilling): Promise<boolean>;
};

export const InviteToFlexibleBillingModal = (props: TInviteToFlexibleBillingModalProps) => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);
	const notification = props.notifications?.[0];

	return (
		<>
			<Modal.Header>
				{t('invite.modal.title', { name: getDistributionPartnerRenderName(props.distributionPartner) })}
			</Modal.Header>
			<Modal.Body>
				<p className="fs-sm">{t('invite.description')}</p>
				<p className="fs-sm">{t('invite.note')}</p>
				<Formik<IFormDistributionPartnerFlexibleBilling>
					initialValues={{ differentEmail: YesNoEnum.NO }}
					onSubmit={async (values, formikHelpers) => {
						const response = await props.onSubmit(values);
						if (!response) {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({ values }) => (
						<FormikForm>
							<FormikControlRadio name="differentEmail" groupProps={{ className: 'mb-0' }}>
								<FormikControl.Radio
									name="differentEmail"
									label={t('invite.form.differentEmail.partner')}
									value={YesNoEnum.NO}
									description={props.distributionPartner.contact.email}
								/>
								<FormikControl.Radio
									name="differentEmail"
									label={t('invite.form.differentEmail.other')}
									value={YesNoEnum.YES}
								/>
							</FormikControlRadio>
							{values.differentEmail === YesNoEnum.YES && (
								<FormikControl label={t('common:contact.email')} required={values.differentEmail === YesNoEnum.YES}>
									<FormikControl.Input name="differentEmailValue" />
								</FormikControl>
							)}
							{notification && (
								<p className="fs-xs text-gray mt-5">
									{t('invite.lastSent', {
										email: notification.recipients.join(', '),
										date: dateFormatter.toDate(notification.createdAt),
									})}
								</p>
							)}
							<FormikFooterControls
								className="mt-4"
								hr={false}
								submit={{ children: notification ? t('invite.action.resend') : t('invite.action.send') }}
								cancel={{ onClick: props.onCancel }}
							/>
						</FormikForm>
					)}
				</Formik>
			</Modal.Body>
		</>
	);
};
