import { type IPageModuleConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';

export const creditStatusConfig = moduleConfigGenerator<IPageModuleConfig>({
	trNamespace: 'moduleCreditStatus',
	menuName: 'app:menu.creditStatus',
	menuLink: RouteName.CREDIT_STATUS.DEFAULT,
	aclModule: 'creditStatus',
	authGuards: { partnerProperties: { isCreditEligible: true } },
});
