import { useEffect, useState } from 'react';
import { H1, Modal, ProgressSpinner } from '@avast/react-ui-components';
import { HelpBackButton } from 'js/help/components/HelpBackButton';
import { VideosList } from 'js/help/videos/VideosList';
import { useHelpContext } from 'js/help/HelpContext';
import { getVideo } from 'js/help/videos/videoContent';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { Row } from 'js/components/atoms/Row';
import { Col } from 'react-bootstrap';
import { HelpStepEnum } from 'js/enums';

export const HelpVideos = () => {
	const { activeVideoKey, setStep } = useHelpContext();
	const { t } = useTranslation('app');
	const video = getVideo(activeVideoKey, t);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (!video) {
			setStep(HelpStepEnum.HOME);
		}
	}, [video, setStep]);

	return (
		<>
			<Modal.Header>
				<HelpBackButton />
			</Modal.Header>
			<Modal.Body>
				<H1 type="h2" className="mb-3 text-center">
					{t('help.videos.title')}
				</H1>
				<Row multi className="mb-5">
					<Col sm={8}>
						<div className="player-wrapper">
							{!isReady && (
								<div className="react-player">
									<ProgressSpinner size="lg" />
								</div>
							)}
							<YouTube
								videoId={video?.videoId}
								className="react-player"
								onReady={() => setIsReady(true)}
								opts={{
									width: '100%',
									height: '100%',
									playerVars: {
										autoplay: 1,
									},
								}}
							/>
						</div>
					</Col>
					<Col sm={4}>
						<VideosList activeKey={video?.key} />
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer cancelButton={false} />
		</>
	);
};
