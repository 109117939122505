import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import type { TToastProps } from '@avast/react-ui-components';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { toast } from 'react-toastify';

export const ToastSuccess = (props: TToastProps) => {
	const { t } = useTranslation(licensesConfig.trNamespace);

	return (
		<Toast
			{...props}
			variant="success"
			bottomPlaceholder={
				<LinkButton size="sm" to={RouteName.PURCHASE.ORDER} testId="goToCart" onClick={() => toast.dismiss()}>
					{t('common.operation.goToCart')}
				</LinkButton>
			}
		>
			{t('common.operation.addToCart')}
		</Toast>
	);
};
