import { createContext, type PropsWithChildren, useContext, useState } from 'react';
import type { IPriceListContext, TPriceListCode } from 'js/priceList';
import { PRICE_LIST_CODE_DEFAULT } from 'js/priceList/priceListConfig';
import { logError } from 'js/utils/app';

const PriceListContext = createContext<IPriceListContext>({
	priceListCode: PRICE_LIST_CODE_DEFAULT,
	setPriceListCode: () => logError('Price list code setter not defined.'),
});
PriceListContext.displayName = 'PriceListContext';

export const usePriceListContext = () => useContext(PriceListContext);

type TPriceListContextProviderProps = {
	defaultPriceListCode: TPriceListCode;
};

export const PriceListContextProvider = (props: PropsWithChildren<TPriceListContextProviderProps>) => {
	const { children, defaultPriceListCode } = props;
	const [priceListCode, setPriceListCode] = useState<TPriceListCode>(defaultPriceListCode);

	return (
		<PriceListContext.Provider
			value={{
				priceListCode,
				setPriceListCode,
			}}
		>
			{children}
		</PriceListContext.Provider>
	);
};
