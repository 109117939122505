import { Check, Cross } from 'assets/image/icon';
import type { ComparativeFeatureEnum } from 'submodule/comparativeFeatures/enums';
import { isFeatureAvailableAsAddon } from 'submodule/comparativeFeatures/comparativeFeaturesUtils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type TFeatureStatusCellProps = {
	value: boolean;
	feature: ComparativeFeatureEnum;
	isNew: boolean;
	isMulti: boolean;
};

export const FeatureStatusCell = (props: TFeatureStatusCellProps) => {
	const { isNew, isMulti, feature, value } = props;
	const { t } = useTranslation('submodules');
	const className = classNames({ 'is-new': isNew, 'is-fixed-width': isMulti });

	if (value) {
		return (
			<td className={classNames('is-positive', className)}>
				<Check />
			</td>
		);
	}

	return (
		<td className={classNames('is-negative', className)}>
			<Cross />
			{isFeatureAvailableAsAddon(feature) && (
				<small className="d-block">{t('comparativeFeatures.availableAsAddon')}</small>
			)}
		</td>
	);
};
