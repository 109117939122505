import type { TApiErrorResolve } from 'types/api';
import { ordersConfig } from 'module/orders/ordersConfig';
import i18n from 'i18next';

export const cancelStandardOrderApiError: TApiErrorResolve = () => ({
	title: i18n.t(ordersConfig.trPrefix('error.orderCancellation')),
});

export const resolveDiscountApprovalApiError: TApiErrorResolve = () => ({
	title: i18n.t(ordersConfig.trPrefix('discountApproval.status.error')),
});
