import type {
	IAffiliateConfigRequestParams,
	IEntityAffiliateConfig,
	IEntityPartner,
	IEntityPartnerDetail,
	IEntityPartnerTerms,
	IPartnerListFilter,
	IUpdatePartnerRequest,
	TAffiliateConfigApiValue,
} from 'module/partners';
import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import type {
	IAffiliateRequestParams,
	IPartnerRequestParams,
	IPartnerUpdateSapLocIdRequest,
	IPartnerUpdateTaxIdRequest,
	IPaymentTypes,
	IPaymentTypesRequestParams,
} from 'module/purchase';
import { CACHE_TIME } from 'appConstants';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { ApiMutationTypeEnum } from 'js/enums';
import { PartnerStatusEnum } from 'module/partners/enums';

export const API_PARTNERS_KEYS = {
	LIST: 'partner',
	DETAIL: 'partner/:id',
	SF_DETAIL: 'partner/findBySalesforceId/:id',
	PAYMENT_TYPE: 'partner/:partnerId/get-payment-types/:amount',
	UPDATE_TAX_ID: 'partner/:partnerId/tax-id',
	UPDATE_SAP_LOC_ID: 'partner/:partnerId/sap-loc-id',
	UPDATE_TERMS: 'partner/:partnerId/terms/accept-new-version',
	TAX_EXEMPTION_SYNC: 'partner/:partnerId/tax-application/sync-current-tax-exemption-certificate',
	TERMS: 'partner/:partnerId/terms',
	INTRO: 'affiliate/:affiliateId/intro-guide-displayed',
	UPDATE: 'affiliate/:affiliateId',
	CONFIG: 'affiliate/:id/config/:name',
};

export const useApiPartnerList: TUseApiPaginatedListModule<IEntityPartner, IPartnerListFilter> = (props) => {
	return useApiPaginatedListQuery(API_PARTNERS_KEYS.LIST, props, {
		filter: { status: PartnerStatusEnum.ACTIVE },
		queryConfig: {
			staleTime: CACHE_TIME.MEDIUM,
		},
	});
};

export const useApiPartner: TUseApiDetailModule<IEntityPartnerDetail> = (props) =>
	useApiDetailQuery(API_PARTNERS_KEYS.DETAIL, props, {
		queryConfig: {
			staleTime: CACHE_TIME.MEDIUM,
		},
	});

export const useApiPartnerBySalesforceId: TUseApiDetailModule<IEntityPartnerDetail> = (props) =>
	useApiDetailQuery(API_PARTNERS_KEYS.SF_DETAIL, props, {
		queryConfig: {
			staleTime: CACHE_TIME.MEDIUM,
		},
	});

export const useApiPartnerPaymentTypes: TUseApiDetailModule<IPaymentTypes, IPaymentTypesRequestParams> = (props) =>
	useApiDetailQuery(API_PARTNERS_KEYS.PAYMENT_TYPE, props);

export const useApiPartnerUpdateTaxId: TUseApiMutationModule<
	IPartnerUpdateTaxIdRequest,
	object,
	IPartnerRequestParams
> = (props) => useApiMutationQuery(API_PARTNERS_KEYS.UPDATE_TAX_ID, ApiMutationTypeEnum.PATCH, props);

export const useApiPartnerUpdateSapLocId: TUseApiMutationModule<
	IPartnerUpdateSapLocIdRequest,
	object,
	IPartnerRequestParams
> = (props) => useApiMutationQuery(API_PARTNERS_KEYS.UPDATE_SAP_LOC_ID, ApiMutationTypeEnum.PATCH, props);

export const useApiPartnerSyncTaxExemption: TUseApiMutationModule<object, object, IPartnerRequestParams> = (props) =>
	useApiMutationQuery(API_PARTNERS_KEYS.TAX_EXEMPTION_SYNC, ApiMutationTypeEnum.POST, props);

export const useApiPartnerTermsList: TUseApiListModule<IEntityPartnerTerms, IPartnerRequestParams> = (props) =>
	useApiListQuery(API_PARTNERS_KEYS.TERMS, props, {
		queryConfig: {
			staleTime: CACHE_TIME.HIGH,
		},
	});

export const useApiAcceptUpdatedTerms: TUseApiMutationModule<object, IEntityPartnerDetail, IPartnerRequestParams> = (
	props,
) => useApiMutationQuery(API_PARTNERS_KEYS.UPDATE_TERMS, ApiMutationTypeEnum.POST, props);

export const useApiSetIntroGuideDisplayed: TUseApiMutationModule<
	object,
	IEntityPartnerDetail,
	IAffiliateRequestParams
> = (props) => useApiMutationQuery(API_PARTNERS_KEYS.INTRO, ApiMutationTypeEnum.POST, props);

export const useApiAffiliateUpdate: TUseApiMutationModule<
	Partial<IUpdatePartnerRequest>,
	IEntityPartnerDetail,
	IAffiliateRequestParams
> = (props) => useApiMutationQuery(API_PARTNERS_KEYS.UPDATE, ApiMutationTypeEnum.PATCH, props);
export const useApiAffiliateConfig: TUseApiDetailModule<IEntityAffiliateConfig, IAffiliateConfigRequestParams> = (
	props,
) =>
	useApiDetailQuery(API_PARTNERS_KEYS.CONFIG, props, {
		queryConfig: {
			staleTime: CACHE_TIME.MEDIUM,
		},
	});

export const useApiAffiliateConfigUpdate: TUseApiMutationModule<
	{
		value: TAffiliateConfigApiValue;
	},
	IEntityAffiliateConfig<TAffiliateConfigApiValue>,
	IAffiliateConfigRequestParams
> = (props) =>
	useApiMutationQuery(API_PARTNERS_KEYS.CONFIG, ApiMutationTypeEnum.POST, props, {
		config: { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
		invalidateQueries: [API_PARTNERS_KEYS.CONFIG],
	});
