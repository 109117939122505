import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import { getPartnerFullName } from 'module/partners/utils/partnerSelectors';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { accountConfig } from 'module/account/accountConfig';
import type { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { useAuthContext } from 'js/contexts';

export const ContactInformationCard = (props: Partial<TDataTableCardProps>) => {
	const { authPartner: partner, isGroupPartner } = useAuthContext();
	const { t } = useTranslation(partnersConfig.trNamespace);
	const [tAccount] = useTranslation(accountConfig.trNamespace);

	if (!partner) {
		return null;
	}

	return (
		<DataTableCard caption={tAccount('cards.contactInformation')} variant="simple" {...props}>
			<DataTableTr name={t('common:contact.name')}>{getPartnerFullName(partner)}</DataTableTr>
			<DataTableTr name={t('common:contact.email')}>{partner.email}</DataTableTr>
			{isGroupPartner && (
				<>
					<DataTableTr name={t('common:contact.billingEmail')}>
						{partner.attributes?.billingEmailAddress || partner.email}
					</DataTableTr>
					<DataTableTr name={t('common:contact.phone')}>{partner.phone}</DataTableTr>
				</>
			)}
		</DataTableCard>
	);
};
