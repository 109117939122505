import { Alert, Button, ButtonComposition, Toast } from '@avast/react-ui-components';
import { useCallback } from 'react';
import { useIsDiscountApprovable, useIsDiscountResolvable } from 'module/orders/hooks';
import { useApiErrorContext, useAppContext, useAuthContext, usePageDetailContext } from 'js/contexts';
import type { IEntityOrder, IOrderDiscountApprovalParams } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import Skeleton from 'react-loading-skeleton';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { toast } from 'react-toastify';
import { resolveDiscountApprovalApiError } from 'module/orders/utils/apiError';
import { useApiApproveDiscount } from 'module/orders/hooks/useApiOrders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { isOrderInStatus } from 'module/orders/utils/common';
import { DiscountApprovalAlert } from 'module/purchase/components/DiscountApprovalAlert';
import { OrderStatusEnum } from 'module/orders/enums';

type TDiscountApprovalProps = {
	className?: string;
};

export const DiscountApproval = (props: TDiscountApprovalProps) => {
	const { className } = props;
	const {
		data: order,
		query: { isFetching, isPending, refetch },
	} = usePageDetailContext<IEntityOrder>();
	const { t } = useTranslation(ordersConfig.trNamespace);
	const isDiscountResolvable = useIsDiscountResolvable();
	const isDiscountApprovable = useIsDiscountApprovable();
	const { isGroupSales } = useAuthContext();
	const { loadingModalRef, confirmationModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const approveDiscount = useApiApproveDiscount({ config: { params: { standardOrderId: order.id } } });

	const onSubmit = useCallback(
		async (flag: IOrderDiscountApprovalParams['approvalFlag']) => {
			const translateKey = flag === 'Approved' ? 'approve' : 'reject';

			if (
				!(await confirmationModalRef.current?.show({
					title: t(`discountApproval.confirm.${translateKey}.title`),
					messages: [t(`discountApproval.confirm.${translateKey}.message`)],
					submitButtonText: t(`discountApproval.confirm.${translateKey}.button`),
				}))
			) {
				return;
			}

			loadingModalRef.current?.show({
				title: t(purchaseConfig.trPrefix('common.savingOrder')),
				disableBeforeUnload: true,
			});

			approveDiscount.mutate(
				{ approvalFlag: flag },
				{
					onSuccess() {
						toast.success(<Toast>{t(`discountApproval.status.success.${translateKey}`)}</Toast>);
					},
					onError(error) {
						setError({
							error,
							resolve: resolveDiscountApprovalApiError,
						});
					},
					onSettled() {
						loadingModalRef.current?.hide();
						refetch();
					},
				},
			);
		},
		[loadingModalRef, confirmationModalRef, approveDiscount, t, setError, refetch],
	);

	if (isPending || !isGroupSales) {
		return null;
	}

	if (isDiscountResolvable(order)) {
		const isApprovable = isDiscountApprovable(order);
		const translateKey = isApprovable ? 'alertApprove' : 'alert';

		if (isFetching) {
			return <Skeleton height={120} className={className} />;
		}
		return (
			<Alert variant="warning" caption={t(`discountApproval.${translateKey}.caption`)} className={className}>
				<p className="fw-bold">{t(`discountApproval.${translateKey}.text`)}</p>
				<ButtonComposition className="mt-3 mb-2">
					{!isApprovable && (
						<Button variant="danger" onClick={() => onSubmit('Rejected')} size="sm" testId="discountReject">
							{t('discountApproval.alert.reject')}
						</Button>
					)}
					<Button variant="success" onClick={() => onSubmit('Approved')} size="sm" testId="discountApprove">
						{t('discountApproval.alert.approve')}
					</Button>
				</ButtonComposition>
			</Alert>
		);
	}

	if (isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS)) {
		switch (order.discount?.approvalFlag) {
			case 'Approved':
				return (
					<Alert
						caption={t('discountApproval.approved')}
						icon={<FontAwesomeIcon icon={faThumbsUp} />}
						variant="success"
						className={className}
					/>
				);

			case 'Rejected':
				return <Alert caption={t('discountApproval.rejected')} variant="danger" className={className} />;
			case 'Approval Required':
				return <DiscountApprovalAlert className={className} />;
			default:
				return null;
		}
	}

	return null;
};
