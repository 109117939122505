import { isSafeInteger, toNumber } from 'lodash';
import type { TCustomerSalesforceId, TPartnerId } from 'types';
import { useRouteParams } from 'js/hooks/useRouteParams';
import type { TCustomerDetailArguments } from 'module/customers/index';

type TUseCustomerUrlParams = {
	id: TCustomerSalesforceId;
	partnerId?: TPartnerId;
};

export const useCustomerUrlParams = (): TUseCustomerUrlParams => {
	const params = useRouteParams<TCustomerDetailArguments>();
	const data: TUseCustomerUrlParams = { id: params.id };
	const partnerId = toNumber(params.partnerId);

	if (isSafeInteger(partnerId)) {
		data.partnerId = partnerId;
	}

	return data;
};
