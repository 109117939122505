import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TPartnerId } from 'types';
import type { IDistributionPartnerListFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { useApiDistributionPartnersList } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useSelectDistributionPartnerColumns } from 'module/distributionPartners/hooks';
import { SortDirectionEnum } from 'js/enums';
import { Col, Row } from 'js/components/atoms/Row';
import { Button } from '@avast/react-ui-components';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncCreateDistributionPartnerModal } from 'module/distributionPartners/components/AsyncCreateDistributionPartnerModal';
import { Can } from 'js/components/molecules/Can';

export type TSelectDistributionPartnerContainerProps = {
	selectedDistributionPartnerId?: IEntityDistributionPartner['id'];
	distributorId?: TPartnerId | null;
};

type TTableData = IEntityDistributionPartner;
type TTableFilter = IDistributionPartnerListFilter;

export const SelectDistributionPartnerContainer = (
	props: TSelectDistributionPartnerContainerProps & { onSelect(partner: TTableData): void },
): ReactElement => {
	const { onSelect, distributorId, selectedDistributionPartnerId } = props;

	// Component hooks
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const [searchValue, setSearchValue] = useState<string>('');
	const createRef = useAsyncModalRef();

	// Define columns
	const columns = useSelectDistributionPartnerColumns();

	return (
		<>
			<Row className="mb-2" justify="between" multi size="sm">
				<Col sm={6} lg={4}>
					<SearchBox size="sm" placeholder={t('select.filter.placeholder')} onChange={setSearchValue} autoFocus />
				</Col>
				<Col sm="auto">
					<Can create do={distributionPartnersConfig.aclModule}>
						<Button size="sm" onClick={() => createRef.current?.show()}>
							{t('actions.create')}
						</Button>
					</Can>
				</Col>
			</Row>

			<ApiListTable<TTableData, TTableFilter>
				columns={columns}
				query={useApiDistributionPartnersList}
				sort={{ direction: SortDirectionEnum.ASC, key: 'name' }}
				limit={8}
				filter={{ distributorId }}
				table={{
					testId: 'distributionPartners',
					state: { globalFilter: searchValue },
					onGlobalFilterChange: setSearchValue,
					meta: {
						nowrapCell: false,
						onRowClick: (row) => onSelect(row),
						isHighlightedRow: (row) =>
							Boolean(selectedDistributionPartnerId) && row.id === selectedDistributionPartnerId,
					},
				}}
			/>

			<AsyncModal ref={createRef} testId="createDistributionPartner" backdrop="static">
				<AsyncCreateDistributionPartnerModal
					forwardedRef={createRef}
					onCreate={(distributionPartner) => {
						onSelect(distributionPartner);
						createRef.current?.hide();
					}}
				/>
			</AsyncModal>
		</>
	);
};
