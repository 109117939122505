import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@avast/react-ui-components';
import type { TUseApiDetailModule, TUseApiDetailProps, TUseApiDetailQueryResponse } from 'js/queries/useApiDetailQuery';
import type { TDetailArguments } from 'types';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { PageDetailContextProvider } from 'js/contexts';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { RefetchButton } from 'js/components/atoms/Button/RefetchButton';
import type { QueryObserverBaseResult } from '@tanstack/react-query';
import type { IAxiosApiError } from 'types/api';
import type { AxiosResponse } from 'axios';
import type { MergeExclusive } from 'type-fest';
import type { TFunction } from 'i18next';
import { isFunction } from 'lodash';

export type TPageDetailChildrenProps<Data extends {}> = {
	data: Data;
	query: TUseApiDetailQueryResponse<Data>['query'];
	title: string;
};

type TManualErrorHandling<Data extends {}> = {
	getErrorContent?: (
		refetch: QueryObserverBaseResult<AxiosResponse<Data>, IAxiosApiError>['refetch'],
		status?: number,
	) => ReactElement;
};

type TAutoErrorHandling = {
	allowRefetch?: boolean;
};

type TPageDetailComponentProps<Data extends {}, Filter extends {}, QueryData = Data> = {
	query: TUseApiDetailModule<Data, Filter, QueryData>;
	children: ((props: TPageDetailChildrenProps<Data>) => ReactElement) | ReactElement;
	queryProps: TUseApiDetailProps<Data, Filter, QueryData>;
	trNamespace?: string;
	titleRender?: (entity: Data | null) => string;
	detailLink?: string;
} & MergeExclusive<TManualErrorHandling<Data>, TAutoErrorHandling>;

type TDefaultErrorContext = {
	refetch: QueryObserverBaseResult<AxiosResponse<Record<string, unknown>>, IAxiosApiError>['refetch'];
	t: TFunction;
	allowRefetch?: boolean;
};

/**
 * Wrapper for detail page - show loading, error state or content.
 * @param {TPageDetailComponentProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const PageDetailComponent = <Data extends {}, Filter extends {} = TDetailArguments, QueryData = Data>(
	props: TPageDetailComponentProps<Data, Filter, QueryData>,
): ReactElement => {
	const {
		children,
		query: useQuery,
		queryProps,
		trNamespace,
		allowRefetch,
		detailLink,
		titleRender,
		getErrorContent,
	} = props;
	const { t } = useTranslation(trNamespace);
	// @ts-ignore
	const id = queryProps.id || queryProps.filter?.id || '';
	const { query, data } = useQuery(queryProps);
	const { refetch, isError, isLoading, error } = query;
	const title = titleRender?.(data) || t('common:_.pageDetailTitle', { id });

	if (isLoading) {
		return (
			<>
				<LoadingPlaceholder />
				<DynamicBreadcrumbsItem key="loading" type="loading" />
			</>
		);
	}

	// Error state
	// @todo handle state when is loaded, no error, but no data - invalid id, etc. It is necessary to agree with the BE what kind of return code they will give.
	if (isError || !data) {
		return (
			<>
				{getErrorContent
					? getErrorContent(refetch, error?.response?.status)
					: getDefaultErrorContext({ allowRefetch, refetch, t })}
			</>
		);
	}

	return (
		<>
			<DynamicPageTitleItem text={title} />
			{detailLink && <DynamicBreadcrumbsItem href={detailLink}>{title}</DynamicBreadcrumbsItem>}

			<PageDetailContextProvider<Data> value={{ data, query, title }}>
				{isFunction(children) ? children({ data, query, title }) : children}
			</PageDetailContextProvider>
		</>
	);
};

const getDefaultErrorContext = ({ allowRefetch, refetch, t }: TDefaultErrorContext) => (
	<>
		<Alert variant="danger" caption={t('components:table.error')} />
		{allowRefetch !== false && <RefetchButton refetch={refetch} />}
	</>
);
