import React, { createContext, type Dispatch, type PropsWithChildren, type RefObject } from 'react';
import type {
	TWidgetActionPayload,
	TWidgetContextState,
	TWidgetContextStateAction,
	TWidgetGrid,
	TWidgetModalProps,
} from 'submodule/widgets/index';
import { useWidgetReducer } from 'submodule/widgets/hooks';
import type { DropResult } from 'react-beautiful-dnd';
import { type AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { isEditWidgetAction } from 'submodule/widgets/utils';
import { AsyncWidgetModal } from 'submodule/widgets/components/AsyncWidgetModal';
import invariant from 'invariant';

interface IWidgetEditContext {
	dispatch: Dispatch<TWidgetContextStateAction>;
	state: TWidgetContextState;
	onDragEnd: (dropResult: DropResult) => void;
	widgetModal: RefObject<AsyncModal<TWidgetModalProps>>;
}

const WidgetEditContext = createContext<IWidgetEditContext | null>(null);
WidgetEditContext.displayName = 'WidgetEditContext';

/**
 * Hook to get api error context
 */
export const useWidgetEditContext = () => {
	const context = React.useContext(WidgetEditContext);

	invariant(
		context !== null,
		'Widget Edit context is undefined, please verify you are calling useWidgetEditContext() as child of a <WidgetEditContextProvider> component.',
	);

	return context;
};

export const WidgetEditContextProvider = (props: PropsWithChildren<{ config: TWidgetGrid }>) => {
	const { dispatch, state, onDragEnd } = useWidgetReducer({ config: props.config });
	const widgetModal = useAsyncModalRef<TWidgetModalProps>();
	const onSubmit = (widgetActionData: TWidgetActionPayload) => {
		if (isEditWidgetAction(widgetActionData)) {
			return dispatch({ type: 'EDIT_WIDGET', payload: widgetActionData });
		}
		return dispatch({ type: 'ADD_WIDGET', payload: widgetActionData });
	};

	return (
		<WidgetEditContext.Provider
			value={{
				dispatch,
				state,
				onDragEnd,
				widgetModal,
			}}
		>
			{props.children}
			<AsyncWidgetModal forwardedRef={widgetModal} onSubmit={onSubmit} />
		</WidgetEditContext.Provider>
	);
};
