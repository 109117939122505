import { getLanguageFlagPath } from 'submodule/localeSwitch/utils/languageUtils';
import type { IEntityLanguage } from 'submodule/localeSwitch/index';

type TLocaleImageProps = {
	language: IEntityLanguage;
};

export const LanguageImage = (props: TLocaleImageProps) => {
	const { language } = props;

	return <img src={getLanguageFlagPath(language)} className="img-fluid" alt={language.locale} />;
};
