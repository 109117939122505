import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

export const PriceListMixWarning = () => {
	const { priceListCode } = usePriceListContext();
	const { orderState } = useOrderContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	if (orderState.priceListCode === priceListCode || orderState.items.length === 0) {
		return null;
	}

	return <Alert variant="warning" caption={t('priceList.mixWarning')} />;
};
