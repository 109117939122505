import type { IFlexibleBillingReport } from 'module/flexibleBilling';
import { DateTime } from 'luxon';
import { API_DATE_FORMAT } from 'constant';

export const flexibleBillingReportNormalizer = {
	normalize(report: IFlexibleBillingReport): IFlexibleBillingReport {
		const startDate = DateTime.fromISO(report.startDate).startOf('day').toFormat(API_DATE_FORMAT);
		const endDate = DateTime.fromISO(report.endDate).minus({ day: 1 }).endOf('day').toFormat(API_DATE_FORMAT);
		return {
			...report,
			startDate,
			endDate,
		};
	},

	normalizeList(items: IFlexibleBillingReport[]): IFlexibleBillingReport[] {
		return items.map(flexibleBillingReportNormalizer.normalize);
	},
};
