import type { ReactElement } from 'react';
import type { FormikFormProps } from 'formik';
import { Form } from 'formik';
import type { ITestId } from '@avast/react-ui-components';
import { TestIdContextProvider } from '@avast/react-ui-components';

type TFormProps = FormikFormProps & ITestId;

export const FormikForm = ({ children, testId, ...rest }: TFormProps): ReactElement => {
	return (
		<TestIdContextProvider prefix={testId}>
			<Form {...rest}>{children}</Form>
		</TestIdContextProvider>
	);
};
