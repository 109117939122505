import type { IEnumAliasFunctions, TEnumOptions, TFilterCriteria } from 'js/enums/generator';
import { forEach, get, has } from 'lodash';

export const generateAliasFunctions = <T extends string>(options: TEnumOptions<T>): IEnumAliasFunctions<T> => ({
	isAlias(key) {
		return has(options.aliases, key);
	},
	getAliasValue(key) {
		return get(options.aliases, key);
	},
	getAliases(key) {
		const list: T[] = [];
		forEach(options.aliases, (value, alias) => {
			if (value === key) {
				list.push(alias as T);
			}
		});
		return list;
	},
});

/**
 * Filters an array of values based on given criteria.
 *
 * @template T - The type of values in the array.
 * @param {T[]} values - The array of values to filter.
 * @param {TFilterCriteria<T>} criteria - The filter criteria.
 * @returns {T[]} - The filtered array of values.
 */
export const filterByCriteria = <T extends string>(values: T[], criteria: TFilterCriteria<T>): T[] => {
	// Check if criteria object is empty
	if (Object.keys(criteria).length === 0) {
		return values;
	}

	// When picked is not specified, pick all items
	// When omit is not specified, do not omit any item
	const { picked = values, omitted = [] } = criteria;

	const pickedSet = new Set(picked);
	const omittedSet = new Set(omitted);

	return values.filter((item) => pickedSet.has(item) && !omittedSet.has(item));
};
