import { useMemo } from 'react';
import { usePricingHeader } from 'module/purchase/hooks/pricing/usePricingHeader';
import { type TUsePricingResponse, usePricing } from 'module/purchase/hooks/pricing/usePricing';
import { useFormikContext } from 'formik';
import type { TSelectVariantForm } from 'module/purchase/components/selectProducts';
import type { IPricingRequestItem } from 'module/purchase';
import { selectVariantFormToPricingItems } from 'module/purchase/hooks/pricing/pricingUtils';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

/**
 * Pricing request for SelectVariant component
 *
 * @returns {TUsePricingResponse}
 */
export const useSelectVariantPricing = (): TUsePricingResponse => {
	const { priceListCode } = usePriceListContext();
	const { values } = useFormikContext<TSelectVariantForm>();
	const {
		orderState: { customer },
	} = useOrderContext();

	// Prepare pricing fragments
	const header = usePricingHeader(priceListCode);
	const items: IPricingRequestItem[] = useMemo(
		() => selectVariantFormToPricingItems(values, customer),
		[values, customer],
	);

	return usePricing(header, items);
};
