import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import type { IGenerateRetailCodesForm, IGenerateRetailCodesProduct } from 'module/retail';
import { UiTable } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { useGenerateRetailCodesColumns } from 'module/retail/hooks';
import type { Row } from '@tanstack/react-table';

export const GenerateRetailCodesTable = () => {
	const { values, setValues } = useFormikContext<IGenerateRetailCodesForm>();
	const { t } = useTranslation(retailConfig.trNamespace);

	// Define columns
	const onRemove = useCallback(
		(row: Row<IGenerateRetailCodesProduct>): void => {
			setValues((values) => ({
				...values,
				products: values.products.filter((product, index) => index !== row.index),
			}));
		},
		[setValues],
	);
	const columns = useGenerateRetailCodesColumns(onRemove);

	return (
		<UiTable<IGenerateRetailCodesProduct>
			data={values.products}
			columns={columns}
			meta={{
				noDataMessage: t('generate.cart.placeholder'),
			}}
		/>
	);
};
