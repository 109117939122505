import { get, isEmpty, isNumber, isObject, set } from 'lodash';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as iconTooltip } from '@fortawesome/free-solid-svg-icons';

/**
 * Remove 'empty' values from an object recursively
 */
export const filterPostValues = <T extends object>(values: T): T => {
	const result = {};
	for (const key of Object.keys(values)) {
		let value = get(values, key, null);
		let empty = !value;

		if (isNumber(value)) {
			empty = Number.isNaN(value);
		} else if (isObject(value)) {
			value = filterPostValues(value);
			empty = isEmpty(value);
		}

		if (!empty) {
			set(result, key, value);
		}
	}

	return result as T;
};

/**
 * Children prop used for FormikControl component in label prop.
 * @param {string} label - Input label
 * @param {string} tooltipText - Text content shown in tooltip
 *
 * @example
 * <FormikControl
 * 		label={
 * 			getLabelPropsForTooltip( t( 'quantityMin' ), t( 'zeroQuantity' ) )
 * 		}
 * >
 */
export const getLabelPropsForTooltip = (label: string, tooltipText: string) => ({
	children: (
		<>
			<span className="me-1">{label}</span>
			<Tooltip content={tooltipText} placement="top">
				<FontAwesomeIcon className="tooltip-icon" icon={iconTooltip} />
			</Tooltip>
		</>
	),
});
