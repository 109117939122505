import { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import type { ISelectOption } from '@avast/react-ui-components';
import { DefaultOptionContainer, FormControl, IconButton } from '@avast/react-ui-components';
import { faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
	useChangeLanguage,
	useCurrentLanguage,
	useLanguages,
	useLanguageSelectOptions,
} from 'submodule/localeSwitch/hooks';
import { LanguageImage } from 'submodule/localeSwitch/components/LanguageImage';
import type { IOptionContainerProps } from 'react-select';
import type { TLocaleLanguage } from 'types/config';

export const LanguageControl = () => {
	const currentLanguage = useCurrentLanguage();
	const languages = useLanguages();
	const changeLanguage = useChangeLanguage();
	const languageSelectOptions = useLanguageSelectOptions();

	const [isEditMode, setEditMode] = useState(false);
	const [language, setLanguage] = useState<TLocaleLanguage>(currentLanguage.language);

	if (isEditMode) {
		return (
			<InputGroup size="sm" className="component__search-combo-box">
				<FormControl.SingleSelect<TLocaleLanguage>
					name="language"
					options={languageSelectOptions}
					value={language}
					isClearable={false}
					size="sm"
					className="flex-grow-1"
					onChange={(value) => setLanguage(value!)}
					OptionContainer={(props: IOptionContainerProps<ISelectOption<TLocaleLanguage>>) => {
						const { option } = props;
						const language = languages.find((item) => item.language === option.value);
						if (language) {
							return (
								<div className="component__language-option-container">
									<div className="image">
										<LanguageImage language={language} />
									</div>
									<div className="flex-grow-1">
										<DefaultOptionContainer {...props} />
									</div>
								</div>
							);
						}

						return <DefaultOptionContainer {...props} />;
					}}
				/>
				<IconButton
					size="sm"
					variant="success"
					iconFa={faCheck}
					onClick={async () => {
						const newLanguage = languages.find((item) => item.language === language);
						if (newLanguage && newLanguage.language !== currentLanguage.language) {
							await changeLanguage(newLanguage);
						}
						setEditMode(false);
					}}
				/>
			</InputGroup>
		);
	}

	return (
		<div className="component__current-language">
			<LanguageImage language={currentLanguage} />
			<span className="d-block flex-grow-1 me-3">{currentLanguage.name}</span>
			<IconButton size="xs" variant="outline-primary" iconFa={faPencilAlt} onClick={() => setEditMode(true)} />
		</div>
	);
};
