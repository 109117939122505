import type { IApiPartnerCustomerListFilter } from 'module/customers/index';
import { isValidEmail } from 'js/utils/validator';
import { omit } from 'lodash';

export const customerFilterNormalizer = {
	denormalize(filter: IApiPartnerCustomerListFilter): IApiPartnerCustomerListFilter {
		let apiFilter: IApiPartnerCustomerListFilter = { ...filter };
		if (isValidEmail(apiFilter.filter)) {
			apiFilter.email = apiFilter.filter;
			apiFilter = omit(apiFilter, 'filter');
		}

		return apiFilter;
	},
};
