import { useApi } from 'js/hooks/api';
import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import type { IApiFlexibleBillingDetailFilter, IFlexibleBillingInviteRequest } from 'module/flexibleBilling';
import { fillUrlParameters } from 'js/utils/common';
import { API_FLEXIBLE_BILLING_KEYS } from './useApiFlexibleBilling';

export const useAsyncApiFlexibleBillingInvite = () => {
	const api = useApi();

	return useCallback(
		(params: IApiFlexibleBillingDetailFilter, data: IFlexibleBillingInviteRequest): Promise<AxiosResponse<null>> => {
			const [url, _params] = fillUrlParameters(API_FLEXIBLE_BILLING_KEYS.INVITE, params);
			return api.post<null, AxiosResponse<null>, IFlexibleBillingInviteRequest>(`/${url}`, data, {
				params: _params,
			});
		},
		[api],
	);
};
