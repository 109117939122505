import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IComparativeData } from 'submodule/comparativeFeatures';
import { PriceCell } from './PriceCell';
import { useComparativePricing } from 'submodule/comparativeFeatures/useComparativePricing';
import type { TCurrency } from 'types';
import type { ComparativeTableVariantEnum } from 'submodule/comparativeFeatures/enums';

export type TPricingRowProps = {
	comparativeData: IComparativeData;
	variant: ComparativeTableVariantEnum;
	currencyCode?: TCurrency;
};

export const PricingRow = (props: TPricingRowProps) => {
	const { comparativeData, variant, currencyCode } = props;
	const { t } = useTranslation('submodules');
	const { pricing, isPricingLoading } = useComparativePricing(comparativeData);
	const { products } = comparativeData;
	const isMulti = products.length !== 1;

	return (
		<tr>
			<td align="left">
				<small className="text-muted">*{t('comparativeFeatures.windowsOnly')}</small>
			</td>
			{products.map((list, listIndex) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
				<React.Fragment key={listIndex}>
					{isMulti && listIndex !== 0 && <td className="is-separator" />}
					{list.map((productData, index) => {
						const price = pricing?.lineitems.find(
							(i) => i.reference_lineitem_id === productData.product.sku,
						)?.unitPrice;

						if (variant === 'HOMEPAGE') {
							const count = list.length;
							if (index !== count - 1) {
								return null;
							}

							return (
								<PriceCell
									key={`${productData.productGroup.code}_price`}
									isLoading={isPricingLoading}
									currencyCode={currencyCode}
									price={price}
									productData={productData}
									colSpan={count}
									variant={comparativeData.variant}
								/>
							);
						}

						return (
							<PriceCell
								key={`${productData.productGroup.code}_price`}
								isLoading={isPricingLoading}
								currencyCode={currencyCode}
								price={price}
								productData={productData}
								variant={comparativeData.variant}
							/>
						);
					})}
				</React.Fragment>
			))}
		</tr>
	);
};
