import type { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { DistributionPartnerDetail } from 'module/distributionPartners/components/DistributionPartnerDetail';

export const DistributionPartnerTab = (): ReactElement | null => {
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			<DistributionPartnerDetail distributionPartner={distributionPartner} linkable hideIncompleteData />
		</DefaultContainer>
	);
};
