import type { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { CustomerCard } from 'module/customers/components';
import { Alert, IconButton, Tooltip } from '@avast/react-ui-components';
import { IconChange } from 'assets/image/icon';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { RequiredCustomerAlert } from 'module/purchase/components/RequiredCustomerAlert';
import { useCommonContext, useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

/**
 * Order customer tab
 * @return {ReactElement}
 * @constructor
 */
export const CustomerTab = (): ReactElement => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { orderState, setCustomer } = useOrderContext();
	const { selectCustomerRef } = useCommonContext();
	const { customer } = useRequiredOrderParties();

	// Return based on type
	if (isDefined(orderState.customer)) {
		const selectCustomer = async () => {
			const customer = await selectCustomerRef.current?.show({
				partnerId: orderState.partner?.id,
				selectedCustomerId: orderState.customer?.id,
				enableCreate: true,
			});

			if (customer) {
				setCustomer(customer);
			}
		};

		return (
			<div className="d-flex">
				<CustomerCard customer={orderState.customer} className="flex-grow-1 min-w-0" />
				{customer.editable && (
					<div className="flex-shrink-0 ps-3">
						<Tooltip content={t('widget.selectCustomerCard.tooltips.change')} placement="bottom">
							<IconButton
								variant="outline-primary"
								size="xs"
								icon={<IconChange />}
								onClick={selectCustomer}
								testId="changeCustomer"
							/>
						</Tooltip>
					</div>
				)}
			</div>
		);
	}

	if (!customer.valid) {
		return <RequiredCustomerAlert />;
	}

	return (
		<Alert variant="info">
			<Trans t={t}>{'widget.selectCustomerCard.note'}</Trans>
		</Alert>
	);
};
