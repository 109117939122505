import type { IFormikContextValidator } from 'types/validator';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { yupContextValidator } from 'js/utils/validator';
import { useTranslation } from 'react-i18next';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { hasCountryStates } from 'js/entities/country/countryUtils';
import type { IFormDistributionPartner } from 'module/distributionPartners';
import { YesNoEnum } from 'js/enums';

export const useDistributionPartnerFormValidator = (): IFormikContextValidator<IFormDistributionPartner> => {
	type Values = IFormDistributionPartner;
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				name: Yup.string().required().label(t('common:entity.company')).max(255),
				taxId: Yup.string().ensure().label(t('common:contact.taxId')).max(40),
				website: Yup.string().link().label(t('common:contact.website')).max(255),

				contact: Yup.object()
					.required()
					.shape({
						firstName: Yup.string().required().label(t('common:contact.firstName')).max(40),
						lastName: Yup.string().required().label(t('common:contact.lastName')).max(80),
						email: Yup.string().required().email().label(t('common:contact.email')).max(80),
					}),

				billing: Yup.object()
					.required()
					.shape({
						street: Yup.string().ensure().label(t('common:address.street')).max(255).required(),
						postalCode: Yup.string().ensure().label(t('common:address.postalCode')).max(20).required(),
						city: Yup.string().label(t('common:address.city')).max(40).required(),
						countryCode: Yup.string().ensure().label(t('common:address.country')).required(),
						stateCode: Yup.string().ensure().label(t('common:address.state')).when('countryCode', {
							is: hasCountryStates,
							then: Yup.string().required(),
						}),
					}),

				flexibleBilling: Yup.object().shape({
					differentEmailValue: Yup.string()
						.label(t('common:contact.email'))
						.max(80)
						.when(['$flexibleBilling.create', '$flexibleBilling.differentEmail'], {
							is: (create, differentEmail) => Boolean(create) && differentEmail === YesNoEnum.YES,
							then: Yup.string().email().required(),
						}),
				}),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) =>
			yupContextValidator<Values>(validationSchema, values, {
				flexibleBilling: values.flexibleBilling,
			}),
	};
};
