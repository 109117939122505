import { Headline, type THeadlineProps } from 'js/components/molecules/Headline';
import { H1 } from '@avast/react-ui-components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import type { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';
import { Link } from 'react-router';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import Skeleton from 'react-loading-skeleton';

type TDetailHeadlineProps = Pick<THeadlineProps, 'rightContent'>;

export const DetailHeadline = (props: TDetailHeadlineProps) => {
	const {
		data: { distributionPartner },
		query: { isRefetching },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	return (
		<DefaultContainer>
			<Headline rightContent={props.rightContent}>
				<H1 type="h2">
					{isRefetching ? (
						<Skeleton width={200} />
					) : (
						<Link to={distributionPartnersConfig.detailLink(distributionPartner)} className="text-link--hover">
							{getDistributionPartnerRenderName(distributionPartner)}
						</Link>
					)}
				</H1>
			</Headline>
		</DefaultContainer>
	);
};
