import type { ReactElement } from 'react';
import { SelectModal } from 'js/components/molecules/Modal/SelectModal';
import {
	SelectDistributionPartnerModal,
	type TSelectDistributionPartnerModalExtraProps,
	type TSelectDistributionPartnerModalProps,
} from 'module/distributionPartners/components/SelectDistributionPartnerModal';
import type { IEntityDistributionPartner } from 'module/distributionPartners';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';

export type TSelectDistributionPartnerProps = Omit<TSelectDistributionPartnerModalProps, 'onChange'> & {
	disabled?: boolean;
	onChange: (value: IEntityDistributionPartner['id'], distributionPartner: IEntityDistributionPartner | null) => void;
};

/**
 * Select distribution partner filter field
 * @param {TSelectDistributionPartnerProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectDistributionPartner = (props: TSelectDistributionPartnerProps): ReactElement => {
	const { onChange, ...rest } = props;

	return (
		<SelectModal<IEntityDistributionPartner, TSelectDistributionPartnerModalExtraProps>
			testId="selectDistributionPartner"
			component={SelectDistributionPartnerModal}
			trNamespace={distributionPartnersConfig.trNamespace}
			renderValue={getDistributionPartnerRenderName}
			onChange={(value) => onChange(value?.id || null, value)}
			{...rest}
		/>
	);
};
