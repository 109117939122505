import { useApi } from 'js/hooks/api';
import type { AxiosResponse } from 'axios';
import type { QueryFunctionContext } from '@tanstack/react-query';
import type { IApiPaginatedListResponse, IApiSortBy } from 'types/api';
import type { TUseApiListProps } from 'js/hooks/api/useApiList';
import { fillUrlParameters } from 'js/utils/common';

export type TUseApiPaginatedListQueryKey<Data extends {}, Filter> = [
	string,
	number,
	number,
	Filter,
	IApiSortBy<Data> | undefined,
	string | undefined,
];
type TUseApiPaginatedListContextQuery<Data extends {}, Filter> = QueryFunctionContext<
	TUseApiPaginatedListQueryKey<Data, Filter>
>;

type TUseApiPaginatedList<Data extends {}, Filter extends {}> = (
	context: TUseApiPaginatedListContextQuery<Data, Filter>,
) => Promise<AxiosResponse<IApiPaginatedListResponse<Data>>>;

type PaginatedFilter<Filter extends {} = Record<string, unknown>> = Filter & {
	limit?: number;
	page?: number;
};

export const useApiPaginatedList = <Data extends {}, Filter extends {}, ApiFilter extends {} = Filter>(
	props: TUseApiListProps<Filter, ApiFilter>,
): TUseApiPaginatedList<Data, Filter> => {
	const { config, apiFilterNormalizer } = props;
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (context) => {
		const [key, page, limit, filter, sortBy] = context.queryKey;

		let apiFilter = { ...config?.params, ...filter };
		if (apiFilterNormalizer) {
			apiFilter = apiFilterNormalizer(apiFilter);
		}

		const [route, _filter] = fillUrlParameters<PaginatedFilter<ApiFilter>>(key, {
			...apiFilter,
			page: page + 1,
			limit,
		});
		const abortController = new AbortController();

		const promise = api.get<IApiPaginatedListResponse<Data>>(`/${route}`, {
			signal: abortController.signal,
			...config,
			params: {
				..._filter,
				sort: sortBy?.key,
				direction: sortBy?.direction,
			},
		});

		// Cancel the request if TanStack Query signals to abort
		context.signal?.addEventListener('abort', () => {
			abortController.abort();
		});

		return promise;
	};
};
