import type { IEntityOrder } from 'module/orders';
import type { TEntityProductGroupListFilter } from 'module/purchase';
import { logError } from 'js/utils/app';
import { useApiProductGroupList } from 'module/purchase/hooks/useApiPurchase';
import { useCallback } from 'react';
import type { TOrderParties, TOrderPriceList, TProductGroupFinder } from 'module/orders/hooks/orderToInstance';

export const useProductGroupFinder = (
	order: IEntityOrder | null,
	orderParties: TOrderParties,
	priceList: TOrderPriceList,
): TProductGroupFinder => {
	const isEnabled = Boolean(order) && !priceList.isFetching && !orderParties.isFetching;
	let filter: TEntityProductGroupListFilter | undefined;
	// Partner price list
	if (orderParties.partner?.id) {
		filter = {
			priceListCode: priceList.code,
			partnerId: orderParties.partner.id,
		};
	}
	// Order price list (eg. for end customer orders)
	else if (order?.currency && order.billableParty?.countryCode) {
		filter = {
			priceListCode: priceList.code,
			currencyCode: order.currency,
			countryCode: order.billableParty.countryCode,
		};
	} else if (isEnabled) {
		logError('Unable to determinate product list filter attributes', order, orderParties, priceList);
	}

	// TODO: temp workaround for ISP order
	if (filter?.priceListCode === 'ISP_OMS') {
		filter.priceListCode = 'PREPAID_OMS';
	}

	const { data: productGroups, query } = useApiProductGroupList({
		filter,
		queryConfig: { enabled: isEnabled && Boolean(filter) },
	});

	return {
		isFetching: query.isFetching || !isEnabled,
		productGroups,
		getProductGroupByCode: useCallback(
			(code) => {
				if (!code) {
					return null;
				}

				return productGroups?.find((group) => group.code === code) || null;
			},
			[productGroups],
		),
		getProductBySku: useCallback(
			(sku, groupCode) => {
				if (!groupCode) {
					return null;
				}

				const group = productGroups?.find((group) => group.code === groupCode) || null;
				return group?.products.find((product) => product.sku === sku) || null;
			},
			[productGroups],
		),
	};
};
