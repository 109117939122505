import numbro from 'numbro';
import type { TLocale } from 'types/config';
import { numberFormatter } from 'js/utils/number';

export const getFormatCurrencyExample = (value: number, locale?: TLocale): string => {
	if (locale) {
		const currentLanguage = numbro.language();
		numberFormatter.setLocale(locale);
		const formattedValue = numberFormatter.currency(value);
		numbro.setLanguage(currentLanguage);
		return formattedValue;
	}

	return numberFormatter.currency(value);
};
