import type React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

type TEllipsisEmail<T extends HTMLElement> = {
	displayedEmail: string;
	containerRef: React.RefObject<T>;
	isEllipsis: boolean;
};

export function useEllipsisEmail<T extends HTMLElement>(email: string): TEllipsisEmail<T> {
	const [displayedEmail, setDisplayedEmail] = useState(email);
	const containerRef = useRef<T>(null);

	useLayoutEffect(() => {
		function ellipsis() {
			const container = containerRef.current;
			if (!container) {
				return;
			}

			// Reset the container content to the full email before checking
			container.textContent = email;

			// Split the email into the local part and domain
			const [localPart, domain] = email.split('@');
			if (!localPart || !domain) {
				return;
			}

			// Ellipsis the local part
			let truncatedEmail = email;
			let _localPart = localPart;
			while (container.scrollWidth > container.clientWidth && _localPart.length !== 0) {
				_localPart = _localPart.slice(0, -1);
				truncatedEmail = `${_localPart}...@${domain}`;
				container.textContent = truncatedEmail;
			}
			setDisplayedEmail(truncatedEmail);
		}

		// Call the ellipsis function initially
		ellipsis();

		window.addEventListener('resize', ellipsis);
		return () => {
			window.removeEventListener('resize', ellipsis);
		};
	}, [email]);

	return { displayedEmail, containerRef, isEllipsis: email !== displayedEmail };
}
