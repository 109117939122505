import { filterPostValues } from 'js/utils/form';
import type { IEntityDistributionPartner, IFormDistributionPartner } from 'module/distributionPartners';
import { YesNoEnum } from 'js/enums';
import { omit } from 'lodash';

export const distributionPartnerFormNormalizer = {
	normalize(values: IEntityDistributionPartner): IFormDistributionPartner {
		return {
			name: values.name,
			website: values.website,
			taxId: values.taxId,
			contact: {
				firstName: values.contact.firstName,
				lastName: values.contact.lastName,
				email: values.contact.email,
			},
			billing: {
				street: values.billing.street,
				city: values.billing.city,
				postalCode: values.billing.postalCode,
				countryCode: values.billing.countryCode,
				stateCode: values.billing.stateCode,
			},
		};
	},
	denormalize(values: IFormDistributionPartner, isUpdate = false): IFormDistributionPartner {
		const result = filterPostValues(values);

		if (isUpdate) {
			result.billing = omit(result.billing, ['countryCode', 'stateCode']);
		}

		if (result.flexibleBilling?.create && result.flexibleBilling.differentEmail !== YesNoEnum.YES) {
			result.flexibleBilling = omit(result.flexibleBilling, 'differentEmailValue');
		}

		return result;
	},
};
