import { WidgetRenderer } from 'submodule/widgets/view/WidgetRenderer';
import { Col, Row } from 'js/components/atoms/Row';
import type { TWidgetGrid, TWidgetGridRow } from 'submodule/widgets';

type TWidgetsViewProps = { config: TWidgetGrid };

const isEmptyRow = (row: TWidgetGridRow) => row.every((column) => column.length === 0);

export const WidgetsView = ({ config = [] }: TWidgetsViewProps) => (
	<>
		{config.map((row, rowIndex) => {
			if (isEmptyRow(row)) {
				return null;
			}

			return (
				// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
				<Row key={`row-${rowIndex}`} className="mt-2">
					{row.map((column, colIndex) => (
						// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
						<Col key={`column-${colIndex}`} lg={row.length === 1 ? 12 : 6}>
							{column.map((item, itemIndex) => (
								// biome-ignore lint/suspicious/noArrayIndexKey: Currently no better solution
								<Row multi key={`item-${itemIndex}`}>
									<Col className="col-12" key={`widget-${item.id}`}>
										<WidgetRenderer item={item.widget} />
									</Col>
								</Row>
							))}
						</Col>
					))}
				</Row>
			);
		})}
	</>
);
