import {
	ACL_ALL,
	ACL_CREATE,
	ACL_DELETE,
	ACL_READ,
	ACL_READ_DETAIL,
	ACL_READ_LIST,
	ACL_UPDATE,
	aclCheck,
	type TAclAction,
	type TAclModules,
} from 'config/acl';
import type { ReactElement } from 'react';
import { useAuthGuards } from 'js/hooks/useSecurityGuards';
import type { IAuthGuards } from 'types';
import { useAuthContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

type TUseCan = {
	do: TAclModules | null;
	not?: true;
	otherwise?: ReactElement;
	action?: TAclAction;
	isAllowed?: boolean;
	authGuards?: IAuthGuards;
};

export type TUseCanProps =
	| ({ create: true } & TUseCan)
	| ({ read: true } & TUseCan)
	| ({ readList: true } & TUseCan)
	| ({ readDetail: true } & TUseCan)
	| ({ update: true } & TUseCan)
	| ({ delete: true } & TUseCan)
	| ({ action: TAclAction } & TUseCan);

// @see https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#Handling-Authorization-in-React-Apps--the-Naive-Way
// @see https://medium.com/dailyjs/managing-user-permissions-in-your-react-app-a93a94ff9b40

export const useCan = (props: TUseCanProps) => {
	const { do: module, not, isAllowed = true, authGuards } = props;
	let { action = ACL_ALL } = props;
	const authContext = useAuthContext();
	const isAllowedByAuthGuards = useAuthGuards(authGuards);

	// Action
	if (Object.hasOwn(props, 'create')) {
		action = ACL_CREATE;
	}
	if (Object.hasOwn(props, 'read')) {
		action = ACL_READ;
	}
	if (Object.hasOwn(props, 'readList')) {
		action = ACL_READ_LIST;
	}
	if (Object.hasOwn(props, 'readDetail')) {
		action = ACL_READ_DETAIL;
	}
	if (Object.hasOwn(props, 'update')) {
		action = ACL_UPDATE;
	}
	if (Object.hasOwn(props, 'delete')) {
		action = ACL_DELETE;
	}

	// Is allowed
	let allowed = module === null;
	if (isDefined(authContext.authRole) && isDefined(module)) {
		allowed = aclCheck(authContext, module, action);
	}

	// Auth guards
	if (!isAllowed || !isAllowedByAuthGuards) {
		allowed = false;
	}

	// Normalize allowed value (not = true => negated condition)
	return allowed === !not;
};
