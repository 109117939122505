import type { IStandardOrderInstance } from 'module/purchase';

/**
 * Return sum of order products quantity
 * @param {IStandardOrderInstance | null} order
 * @returns {number}
 */
export const totalItemsSelector = (order: IStandardOrderInstance | null): number => {
	return order?.items?.length || 0;
};

/**
 * Return sum of order products
 * @param {IStandardOrderInstance | null} order
 * @returns {number}
 */
export const totalAmountSelector = (order: IStandardOrderInstance | null): number => {
	return order?.pricing?.headers.totalAmountWithoutTax || 0;
};

/**
 * Order is empty when total products quantity is 0
 * @param {IStandardOrderInstance | null} order
 * @returns {boolean}
 */
export const isEmptySelector = (order: IStandardOrderInstance | null): boolean => {
	return (order?.items?.length || 0) === 0;
};
