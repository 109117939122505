import React, { createContext, type PropsWithChildren, type ReactElement } from 'react';
import invariant from 'invariant';
import type { TPageDetailChildrenProps } from 'js/layouts/page/PageDetailComponent';

type TPageDetailContext<Data extends object> = TPageDetailChildrenProps<Data>;
type TPageDetailContextProviderProps<Data extends object> = {
	value: TPageDetailContext<Data>;
};

// biome-ignore lint/suspicious/noExplicitAny: The function is generic and has to have type "any"
const PageDetailContext = createContext<TPageDetailContext<any> | null>(null);
PageDetailContext.displayName = 'PageDetailContext';

/**
 * Typed provider of page detail context
 * @param {PropsWithChildren<TPageDetailContextProviderProps>} props
 * @returns {ReactElement}
 * @constructor
 */
export const PageDetailContextProvider = <Data extends object>(
	props: PropsWithChildren<TPageDetailContextProviderProps<Data>>,
): ReactElement => <PageDetailContext.Provider {...props} />;

/**
 * Hook to get page detail context
 */
export const usePageDetailContext = <Data extends object>(): TPageDetailContext<Data> => {
	const context = React.useContext(PageDetailContext);

	invariant(
		context !== null,
		'Page Detail context is undefined, please verify you are calling usePageDetailContext() as child of a <PageDetailContext> component.',
	);

	return context;
};
