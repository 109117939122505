import { isOrderCustomerRequired, isOrderDistributionPartnerRequired } from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

interface IUseRequiredOrderParty {
	required: boolean;
	filled: boolean;
	valid: boolean;
	editable: boolean;
}

interface IUseRequiredOrderParties {
	customer: IUseRequiredOrderParty;
	distributionPartner: IUseRequiredOrderParty;
	orderPartiesValid: boolean;
}

export const useRequiredOrderParties = (): IUseRequiredOrderParties => {
	const { orderState, isPartnerBillableParty, updateMode, partnerRolesAndRoleGroups } = useOrderContext();
	const isPartyEditable = !updateMode;

	// Customer
	const isCustomerEditable = isPartyEditable && isPartnerBillableParty;
	const isCustomerRequired =
		isCustomerEditable && isOrderCustomerRequired(orderState) && isPartnerBillableParty && !orderState.partner?.isTest;
	const hasCustomer = isDefined(orderState.customer);
	const isCustomerValid = hasCustomer || !isCustomerRequired;

	// Distribution partner
	const isDistributionPartnerRequired =
		isPartyEditable &&
		isOrderDistributionPartnerRequired(orderState) &&
		partnerRolesAndRoleGroups.isRoleDistributor &&
		!orderState.partner?.isTest;
	const hasDistributionPartner = isDefined(orderState.distributionPartner);
	const isDistributionPartnerValid = hasDistributionPartner || !isDistributionPartnerRequired;

	return {
		customer: {
			editable: isCustomerEditable,
			required: isCustomerRequired,
			filled: hasCustomer,
			valid: isCustomerValid,
		},
		distributionPartner: {
			editable: isPartyEditable,
			required: isDistributionPartnerRequired,
			filled: hasDistributionPartner,
			valid: isDistributionPartnerValid,
		},
		orderPartiesValid: isCustomerValid && isDistributionPartnerValid,
	};
};
