import type {
	IEntityProduct,
	IEntityProductGroup,
	IOmsPaymentType,
	IStandardOrderInstance,
	IStandardOrderInstanceItem,
	IStandardOrderInstanceProduct,
	TPaymentTypeAction,
	TStandardOrderInstanceAddItem,
} from 'module/purchase';
import { PAYMENT_TYPES_DETAIL, POST_SUBMIT_ACTIONS } from 'module/purchase/constants';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { isOrderProductBusiness } from 'module/purchase/utils/selectors';
import type { IEntityOrder, IEntityOrderItemComputedValues } from 'module/orders';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useAuthContext, useOrderContext } from 'js/contexts';
import { CONFIG } from 'config';
import type { TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { logError } from 'js/utils/app';
import { LicenseOperationEnum, type TransactionTypeEnum } from 'module/licenses/enums';
import { isIspOrder } from 'module/orders/utils/common';
import type { ITestId, TModalFooterProps } from '@avast/react-ui-components';
import type { TFunction } from 'i18next';
import i18n from 'i18next';
import type { NavigateLink, TProductGroupCode } from 'types';
import { CreationSourceEnum, OrderStatusEnum } from 'module/orders/enums';
import {
	PaymentTypeActionEnum,
	type PaymentTypeTranslationKeyEnum,
	PostSubmitActionEnum,
	ValidityTypeEnum,
} from 'module/purchase/enums';
import { isNumber } from 'lodash';
import { getMaxProductQuantity, isDefined } from 'js/utils/common';
import { MarketSegmentEnum } from 'js/enums';

export const getPaymentTypeTranslationKey = (action: TPaymentTypeAction, value: PaymentTypeTranslationKeyEnum) =>
	purchaseConfig.trPrefix(`paymentType.${action}.${value}`);

export const sortPaymentTypes = (first: IOmsPaymentType, second: IOmsPaymentType): number => {
	const firstPriority = PAYMENT_TYPES_DETAIL[first.name].priority;
	const secondPriority = PAYMENT_TYPES_DETAIL[second.name].priority;
	return firstPriority < secondPriority ? -1 : 0;
};

export const getOrderInstanceItemQuantity = (item: TSelectVariantItem | IStandardOrderInstanceItem): number =>
	(isOrderProductBusiness(item.product) ? item.unit : item.quantity) ?? 0;

export const getProductValidityLabels = (product: IStandardOrderInstanceProduct | IEntityProduct) => {
	let description: string | undefined;
	let { validityType } = product;
	let { validity } = product;

	if (validityType === ValidityTypeEnum.MONTH) {
		if (validity % 12 === 0) {
			validityType = ValidityTypeEnum.YEAR;
			validity /= 12;
		}
	}

	if (validityType === ValidityTypeEnum.YEAR) {
		const value = i18n.t('common:datePeriod.MONTH', { count: validity * 12 });
		description = i18n.t(purchaseConfig.trPrefix('entity.validityDescription'), { value });
	}

	const value = i18n.t(`common:datePeriod.${validityType}`, { count: validity });
	return {
		value,
		label: i18n.t(purchaseConfig.trPrefix('entity.validityLabel'), { value }),
		description,
	};
};

type TPostSubmitAction = {
	text: string;
	link: string | NavigateLink;
	disabled?: boolean;
} & ITestId;

export const getPostSubmitActions = (
	order: IEntityOrder,
	action: TPaymentTypeAction,
	t: TFunction,
): TPostSubmitAction[] => {
	const postSubmitActions = POST_SUBMIT_ACTIONS[action];
	const actions: TPostSubmitAction[] = [];

	for (const postSubmitAction of postSubmitActions) {
		switch (postSubmitAction) {
			case PostSubmitActionEnum.EDIT:
				if (action === PaymentTypeActionEnum.QUOTE || action === PaymentTypeActionEnum.END_CUSTOMER_QUOTE) {
					actions.push({
						text: t(`components.successOrder.action.QUOTE_${postSubmitAction}`),
						link: ordersConfig.quoteUpdateLink(order),
						testId: 'editQuote',
					});
				} else {
					actions.push({
						text: t(`components.successOrder.action.${postSubmitAction}`),
						link: ordersConfig.updateLink(order),
						testId: 'editOrder',
					});
				}
				break;
			case PostSubmitActionEnum.DETAIL:
				if (action === PaymentTypeActionEnum.QUOTE || action === PaymentTypeActionEnum.END_CUSTOMER_QUOTE) {
					actions.push({
						text: t(`components.successOrder.action.QUOTE_${postSubmitAction}`),
						link: ordersConfig.quoteDetailLink(order),
						testId: 'quoteDetail',
					});
				} else {
					actions.push({
						text: t(`components.successOrder.action.${postSubmitAction}`),
						link: ordersConfig.detailLink(order),
						testId: 'orderDetail',
					});
				}
				break;
			case PostSubmitActionEnum.LICENSES:
				if (!isIspOrder(order) && !order.isRetailOrder) {
					actions.push({
						text: t(`components.successOrder.action.${postSubmitAction}`),
						link: ordersConfig.detailLinkLicenses(order),
						testId: 'licenses',
						disabled: order.status === OrderStatusEnum.IN_REVIEW,
					});
				}
				break;
			case PostSubmitActionEnum.PAYMENT_INSTRUCTIONS:
				actions.push({
					text: t(`components.successOrder.action.${postSubmitAction}`),
					link: ordersConfig.detailLinkPaymentInstructions(order),
					testId: 'paymentInstructions',
				});
				break;
			default:
				logError(`Not supported postSubmit action: ${postSubmitAction}`);
		}
	}

	return actions;
};

export const postSubmitActionsToAdditionalButtons = (
	actions: TPostSubmitAction[],
	onClick: (action: TPostSubmitAction) => void,
): TModalFooterProps['additionalButtons'] => {
	let first = true;
	return actions
		.map((item, index) => {
			let variant = 'outline-primary';
			if (first && !item.disabled) {
				variant = 'primary';
				first = false;
			}

			return {
				children: item.text,
				onClick: () => onClick(item),
				disabled: item.disabled,
				id: index.toString(),
				variant,
				testId: item.testId,
			};
		})
		.reverse();
};

export const getProductGroupException = (productGroup: string | undefined, transactionType: TransactionTypeEnum) => {
	if (!productGroup) {
		return null;
	}

	return CONFIG.PRODUCT_GROUP.EXCEPTIONS.find((exception) => {
		const { transactionTypes, groups } = exception;
		if (!transactionTypes.includes(transactionType)) {
			return false;
		}
		return groups.includes(productGroup);
	});
};

export const isQuantityChangeDisabled = (productGroup?: string): boolean => {
	if (!productGroup) {
		return false;
	}

	return CONFIG.PRODUCT_GROUP.DISABLED_QUANTITY.some((group) => productGroup === group);
};

/**
 * Solves max product quantity for items in <b>NEW</b> orders.
 * @param {IOrderContext} orderContext
 * @param {IStandardOrderInstanceProduct} product
 * @param {boolean} disabled
 * @returns {number | undefined}
 */
export const getOrderItemMaxQuantity = (
	{ product }: { product: IStandardOrderInstanceProduct },
	disabled?: boolean,
): number | undefined => {
	const { group, isConsumer: isConsumerProduct } = product;

	if (disabled) {
		return undefined;
	}

	return getMaxProductQuantity({
		groupCode: group.code,
		isFirstPurchase: true,
		marketSegment: isConsumerProduct ? MarketSegmentEnum.CONSUMER : MarketSegmentEnum.BUSINESS,
	});
};

export const useIsOpportunityFieldDisabled = (): boolean => {
	const { isGroupSales } = useAuthContext();
	const { orderState } = useOrderContext();
	const availableCreationSource: CreationSourceEnum[] = [CreationSourceEnum.OMS, CreationSourceEnum.SMART_CHANNEL];

	if (isGroupSales) {
		if (!orderState.creationSource || availableCreationSource.includes(orderState.creationSource)) {
			return false;
		}
	}

	return true;
};

export const selectVariantItemsToInstanceAddItems = (
	formItems: TSelectVariantItem[],
): TStandardOrderInstanceAddItem[] => {
	const items: TStandardOrderInstanceAddItem[] = [];

	for (const item of formItems) {
		const { product, quantity = 1, unit } = item;
		if (product.isBusiness) {
			for (let i = 1; i <= quantity; i++) {
				items.push({ product, quantity: 1, unit, licenseOperation: LicenseOperationEnum.NEW });
			}
		} else {
			items.push({ product, quantity, unit, licenseOperation: LicenseOperationEnum.NEW });
		}
	}

	return items;
};

export const isConsumerProductGroup = (group: IEntityProductGroup) =>
	group.marketSegment === MarketSegmentEnum.CONSUMER;

export const hasProductGroupProducts = (group: IEntityProductGroup) => Boolean(group.products.length);

/**
 * Find a group code on the begging of the value by regex - returns the code, If found
 * e.g. result for 'bmp.0.12m' is 'bmp'
 *
 * @param {string | undefined} value - searched value
 * @returns {string | undefined}
 */
export const extractProductGroupCode = (value?: string): TProductGroupCode | undefined =>
	value?.match(/^\s*([a-zA-Z]{3,})[.-]/)?.[1];

/**
 * Customer is required when any business product is in a cart with quantity eq. to 1
 * @param {IStandardOrderInstance} instance
 * @return {boolean}
 */
export const isOrderCustomerRequired = (instance: IStandardOrderInstance): boolean => {
	const { items } = instance;

	const businessItems = items.filter(
		(item) => item.product.isBusiness && item.licenseOperation === LicenseOperationEnum.NEW,
	);
	return businessItems.some((item) => {
		const { sku } = item.product;
		const { unit } = item;

		const sameProducts = businessItems.filter((item) => item.product.sku === sku && item.unit === unit);
		return sameProducts.length === 1;
	});
};

export const isOrderDistributionPartnerRequired = (instance: IStandardOrderInstance): boolean => {
	const { items } = instance;
	return items.some((item) => item.product.isBusiness);
};

export const getOrderInstanceItemSavedCustomerPrice = (
	item: IStandardOrderInstanceItem,
): Required<IEntityOrderItemComputedValues['price']['quotedCustomer']> | undefined => {
	const isBusiness = isOrderProductBusiness(item.product);

	if (isNumber(item.savedCustomerPrice) && Number.isFinite(item.savedCustomerPrice)) {
		const qty = (isBusiness ? item.unit : item.quantity) ?? 1;
		return {
			unit: item.savedCustomerPrice,
			total: item.savedCustomerPrice * qty,
		};
	}

	return undefined;
};

export const hasOrderInstanceSavedCustomerPrice = (order: IStandardOrderInstance): boolean =>
	order.items.some((item) => {
		const itemSavedCustomerPrice = getOrderInstanceItemSavedCustomerPrice(item);
		return isDefined(itemSavedCustomerPrice);
	});

export const getOrderInstanceSavedCustomerPrice = (order: IStandardOrderInstance): number | undefined => {
	const savedCustomerPrice = order.items.reduce((acc, item) => {
		const itemSavedCustomerPrice = getOrderInstanceItemSavedCustomerPrice(item);
		return itemSavedCustomerPrice ? acc + itemSavedCustomerPrice.total : acc;
	}, 0);

	return savedCustomerPrice || undefined;
};
