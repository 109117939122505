import type { ReactElement } from 'react';
import type { TDetailArguments } from 'types';
import { PageUpdateContent } from 'module/orders/components/PageUpdateContent';
import { useRouteParams } from 'js/hooks/useRouteParams';

export const PageQuoteUpdate = (): ReactElement => {
	const { id } = useRouteParams<TDetailArguments>();
	// TODO: Errors can be thrown. Add ErrorBoundaries
	return <PageUpdateContent id={id} isQuote={true} />;
};
