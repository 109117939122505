import { useNavigate } from 'react-router';
import { useAuthContext } from 'js/contexts';
import { useCallback, useEffect } from 'react';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { RouteName } from 'module/RouteName';
import { CONFIG } from 'config';
import { logDebug } from 'js/utils/app';
import type { NavigateLink } from 'types';

export const useAutoLogin = (ssoLink: string, redirectTo?: NavigateLink) => {
	const navigate = useNavigate();
	const { isLogged } = useAuthContext();

	// On login callback
	const onLogin = useCallback(() => {
		const navigateObject = navigateLinkToNavigateObject(redirectTo || RouteName.HOMEPAGE.DEFAULT);
		navigate(navigateObject.to, navigateObject.options);
	}, [navigate, redirectTo]);

	// Redirect on init
	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		if (isLogged) {
			onLogin();
		} else if (CONFIG.ENV === 'local' || 'Cypress' in window) {
			logDebug('Auto-login by SSO skipped on LOCAL env');
		} else {
			window.location.href = ssoLink;
		}
	}, []);
};
