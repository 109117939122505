import { Component, type HTMLAttributes, type ReactNode, type RefObject, useRef } from 'react';
import { STATUS_CANCEL, STATUS_SUCCESS } from 'appConstants';
import classNames from 'classnames';

type TAsyncContainerResolve = (state: number) => void;
type TAsyncContainerReject = (reason?: unknown) => void;
type TAsyncContainerProps<P> = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
	children: ReactNode | ((props: P) => ReactNode);
};
type TAsyncContainerState<P> = {
	show: boolean;
	childrenProps?: P;
};

export type TAsyncContainerRef<P extends {}> = RefObject<AsyncContainer<P>>;

export type TAsyncContainerComponentProps<P extends {}> = {
	forwardedRef: RefObject<AsyncContainer<P>>;
};
export const useAsyncContainerRef = <P extends {}>() => useRef<AsyncContainer<P>>(null);

// TODO: Can throw errors
export class AsyncContainer<P extends {} = Record<string, unknown>> extends Component<
	TAsyncContainerProps<P>,
	TAsyncContainerState<P>
> {
	state: TAsyncContainerState<P> = {
		show: false,
	};
	promiseInfo: {
		resolve?: TAsyncContainerResolve;
		reject?: TAsyncContainerReject;
	} = {};

	async show(props?: P) {
		return new Promise<number>((resolve, reject) => {
			this.promiseInfo = {
				resolve,
				reject,
			};
			this.setState({
				show: true,
				childrenProps: props,
			});
		});
	}

	hide = () => {
		this.setState({
			show: false,
		});
	};

	isOpen(): boolean {
		return this.state.show;
	}

	onSuccess(state = STATUS_SUCCESS): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(state);
	}

	onCancel(): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(STATUS_CANCEL);
	}

	getResolve(): TAsyncContainerResolve {
		const { resolve } = this.promiseInfo;
		return (result) => {
			resolve?.(result);
			this.hide();
		};
	}

	getReject(): TAsyncContainerReject {
		const { reject } = this.promiseInfo;
		return (err) => {
			reject?.(err);
			this.hide();
		};
	}

	renderChildren(): ReactNode {
		const { children } = this.props;
		const { childrenProps } = this.state;

		if (typeof children === 'function') {
			if (!childrenProps) {
				throw new Error("Necessary property 'childrenProps' is not defined.");
			}
			return children(childrenProps);
		}

		return children;
	}

	render(): ReactNode {
		const { className, children, ...props } = this.props;
		const { show } = this.state;

		if (!show) {
			return null;
		}

		return (
			<div {...props} className={classNames(className, 'component__asyncContainer')}>
				{this.renderChildren()}
			</div>
		);
	}
}
