import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type { IPricingRequest, IPricingResponse } from 'module/purchase';
import { ApiMutationTypeEnum } from 'js/enums';

const API_PRICE_CALCULATOR_KEYS = {
	CALCULATE: 'price/calculate-virtual',
};

export const useApiPriceCalculator: TUseApiMutationModule<IPricingRequest, IPricingResponse> = (props) =>
	useApiMutationQuery(API_PRICE_CALCULATOR_KEYS.CALCULATE, ApiMutationTypeEnum.POST, props);
