import type { IDocument } from 'js/chargeOrder';
import { faFileCsv, faFilePdf, type IconDefinition } from '@fortawesome/free-solid-svg-icons';
import type { TColorVariant } from '@avast/react-ui-components';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';
import { DocumentTypeEnum } from 'js/enums';
import { compact, isString } from 'lodash';

export const getDocumentFileIcon = (document: IDocument): IconDefinition => {
	switch (document.documentType) {
		case DocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_DAILY_USAGE_REPORT:
			return faFileCsv;
		case DocumentTypeEnum.PAYG_REPORTS_ALL:
			return faFileArchive;
		default:
			return faFilePdf;
	}
};

export const getDocumentFileIconColorVariant = (document: IDocument): TColorVariant => {
	switch (document.documentType) {
		case DocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_DAILY_USAGE_REPORT:
			return 'success';
		case DocumentTypeEnum.PAYG_REPORTS_ALL:
			return 'warning';
		default:
			return 'danger';
	}
};

export const getDocumentFileExtension = (document: IDocument): string => {
	switch (document.documentType) {
		case DocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_USAGE_REPORT:
		case DocumentTypeEnum.PAYG_DISTRIBUTION_PARTNERS_DAILY_USAGE_REPORT:
			return 'csv';
		case DocumentTypeEnum.PAYG_REPORTS_ALL:
			return 'zip';
		default:
			return 'pdf';
	}
};

export const getDocumentFileName = (document: IDocument, prefix?: string): string => {
	if (isString(document.fileName)) {
		return document.fileName;
	}

	const extension = getDocumentFileExtension(document);
	const name: string[] = compact([prefix, `${document.documentType}.${extension}`]);
	return name.join('-');
};
