import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { HelpContextProvider } from 'js/help/HelpContext';
import { HelpContentSwitch } from 'js/help/HelpContentSwitch';

export const AsyncHelpModal = (props: TAsyncModalContainerProps) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal ref={forwardedRef} className="section__help" size="lg" testId="help">
			<HelpContextProvider>
				<HelpContentSwitch />
			</HelpContextProvider>
		</AsyncModal>
	);
};
