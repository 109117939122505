import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { ordersConfig } from 'module/orders/ordersConfig';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { dateFormatter } from 'js/utils/dateTime';
import { billablePartyTypeEnumUtils, creationSourceEnumUtils, orderStatusEnumUtils } from 'module/orders/enums';
import { getBillablePartyRenderName } from 'module/orders/utils/common';

export const QuoteStatusOverview = () => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const { data: order } = usePageDetailContext<IEntityOrder>();

	return (
		<Row multi md={2}>
			<Col>
				<DataTable>
					<DataTableTr name={t('entity.quoteStatus')} highlighted>
						{orderStatusEnumUtils.getText(order.status)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.created')}>
						{dateFormatter.toTimeZoneDateTime(order.createdAt)}
					</DataTableTr>
					<DataTableTr name={t('common:dates.expire')}>
						{dateFormatter.toTimeZoneDateTime(order.expirationDate)}
					</DataTableTr>
				</DataTable>
			</Col>
			<Col>
				<DataTable>
					<DataTableTr name={t('common:entity.poNumber')}>{order.purchaseOrderNumber}</DataTableTr>
					<DataTableTr name={t('entity.opportunityId')} noRenderEmpty>
						{order.opportunityId}
					</DataTableTr>
					<DataTableTr name={t('entity.billablePartyType')}>
						{billablePartyTypeEnumUtils.getText(order.billableParty?.type)}
					</DataTableTr>
					<DataTableTr name={t('entity.billablePartyName')}>{getBillablePartyRenderName(order)}</DataTableTr>
					<DataTableTr name={t('entity.createdBy')}>
						{order.createdByPartyId || order.transaction?.ownerIndividualId}
					</DataTableTr>
					<DataTableTr name={t('entity.creationSource')}>
						{creationSourceEnumUtils.getText(order.creationSource)}
					</DataTableTr>
				</DataTable>
			</Col>
		</Row>
	);
};
