import { useCallback } from 'react';
import { useApi } from 'js/hooks/api';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { logError } from 'js/utils/app';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'js/contexts';

// TODO: will be removed when https://butr.avast.com/browse/BOSS-3592
export const useDownloadFile = () => {
	const { loadingModalRef } = useAppContext();
	const api = useApi();
	const { t } = useTranslation();

	return useCallback(
		async (url: string, filename: string) => {
			loadingModalRef.current?.show({
				title: t('common:downloadFile.loading', { filename }),
				disableBeforeUnload: true,
			});
			const response = await api
				.get(url, {
					apiVersion: null,
					baseURL: '',
					responseType: 'blob',
				})
				.catch((error: unknown) => {
					logError(error);
					toast.error(<Toast>{t('common:downloadFile.error')}</Toast>);
					loadingModalRef.current?.hide();
				});

			if (response?.data && loadingModalRef.current?.isOpen()) {
				const htmlLink = window.document.createElement('a');
				const blob = new Blob([response.data], { type: response.headers['content-type'] });

				htmlLink.href = URL.createObjectURL(blob);
				htmlLink.download = filename;

				window.document.body.appendChild(htmlLink);
				htmlLink.click();
				htmlLink.remove();
			}

			loadingModalRef.current?.hide();
		},
		[api, loadingModalRef, t],
	);
};
