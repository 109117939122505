import { useCallback } from 'react';
import type { IEntityOrder } from 'module/orders';
import {
	isClosingBalanceOrder,
	isOrderInStatus,
	isOrderPaymentTypeInState,
	isOrderUnfinished,
} from 'module/orders/utils/common';
import type { IButtonVisibility } from 'types';
import { PaymentTypeEnum } from 'js/enums';
import { OrderStatusEnum } from 'module/orders/enums';
import { useAuthContext } from 'js/contexts';

/**
 * Hook what return function to resolve if the order is cancelable
 * @returns {Function}
 */
export const useCancelButtonVisibility = () => {
	const { isGroupPartner, isAuthorizedBuyer, isRoleSalesOperations, isGroupSales } = useAuthContext();

	return useCallback(
		(order: IEntityOrder | null): IButtonVisibility => {
			if (!order?.status) {
				return { isAllowed: false };
			}

			// Partner
			if (isGroupPartner) {
				return {
					isAllowed: isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS),
					isEnabled: isAuthorizedBuyer,
				};
			}

			if (!isGroupSales) {
				return { isAllowed: false };
			}

			// Closing balance
			if (isClosingBalanceOrder(order)) {
				if (!isRoleSalesOperations) {
					return { isAllowed: false };
				}

				// The consolidated order is complete and cancel is no longer an option.
				if (!order.cbConsolidatedComplete && isOrderInStatus(order, OrderStatusEnum.POSTED_TO_STATEMENT)) {
					return { isAllowed: true, isEnabled: true };
				}
			}

			// WireTransfer && Waiting to payment
			if (
				isOrderPaymentTypeInState(order, PaymentTypeEnum.WTOC) &&
				isOrderInStatus(order, OrderStatusEnum.WAITING_PAYMENT)
			) {
				return { isAllowed: true, isEnabled: true };
			}

			return {
				isAllowed: isOrderUnfinished(order) || isOrderInStatus(order, OrderStatusEnum.ISSUED_AS_CUSTOMER_QUOTE),
				isEnabled: true,
			};
		},
		[isGroupPartner, isGroupSales, isAuthorizedBuyer, isRoleSalesOperations],
	);
};
