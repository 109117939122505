import type { PropsWithChildren } from 'react';
import type { TStatusProps } from '@avast/react-ui-components';
import { ProgressBar, ProgressSpinner, StatusText } from '@avast/react-ui-components';
import classNames from 'classnames';

type TLoadingPlaceholderSpinner = {
	type?: 'SPINNER';
	text?: never;
	width?: never;
};
type TLoadingPlaceholderBar = {
	type: 'BAR';
	text?: string;
	width?: number;
};

type TLoadingPlaceholderProps = TStatusProps & {
	ellipsis?: boolean;
} & (PropsWithChildren<TLoadingPlaceholderSpinner> | TLoadingPlaceholderBar);

export const LoadingPlaceholder = (props: TLoadingPlaceholderProps) => {
	const { type, text, width = 280, ellipsis, ...rest } = props;
	return (
		<StatusText {...rest}>
			{type === 'BAR' ? (
				<ProgressBar
					indeterminate
					style={{ width: `${width}px` }}
					text={text ? `${text}${ellipsis && '...'}` : undefined}
				/>
			) : (
				<>
					<ProgressSpinner size="lg" />
					{Boolean(props.children) && (
						<div className="mt-3">
							{props.children}
							{ellipsis && '...'}
						</div>
					)}
				</>
			)}
		</StatusText>
	);
};

export const LoadingFullScreenPlaceholder = (props: PropsWithChildren<{ className?: string }>) => (
	<div className={classNames('section__fullscreen-loading', props.className)}>
		<ProgressSpinner size="lg" />
		{Boolean(props.children) && <div className="mt-3">{props.children}</div>}
	</div>
);
