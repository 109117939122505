import type { ReactElement } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { RouteName } from 'module/RouteName';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { dateFormatter } from 'js/utils/dateTime';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityLicense } from 'module/licenses';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { buildParametrizedRoute } from 'js/utils/common';
import {
	extractLicenseSku,
	getFulfillmentValue,
	getLicenseContextValue,
	getLicenseExternalIds,
	getLicenseId,
	getLicenseType,
	getVariantColorForLicenseStatus,
	showEntitlementId,
} from 'module/licenses/utils/common';
import { LicenseDetailHeader } from 'module/licenses/components/LicenseDetailHeader';
import { CustomerGroup, DistributionPartnerGroup, PartnerGroup } from 'module/licenses/components/table';
import { LicenseContextIdEnum, licenseStatusEnumUtils } from 'module/licenses/enums';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router';
import { useAuthContext } from 'js/contexts';

export const LicenseDetail = (): ReactElement => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const {
		data: license,
		query: { isFetching },
	} = usePageDetailContext<IEntityLicense>();
	const { orderId } = getLicenseExternalIds(license);
	const { isGroupPartner } = useAuthContext();

	return (
		<>
			<LicenseDetailHeader />
			<Row multi>
				<Col md={6}>
					<DataTable isLoading={isFetching}>
						<DataTableTr name={t('common:entity.productName')} highlighted>
							{license.product.productGroupName} ({extractLicenseSku(license)})
						</DataTableTr>
						{!isGroupPartner && (
							<DataTableTr name={t('entity.product.type')} highlighted>
								{license.product.productTypeName}
							</DataTableTr>
						)}
						<DataTableTr name={t('common:entity.unitType.SEATS', { count: license.product.dimension })}>
							{license.product.dimension}
						</DataTableTr>
						<DataTableTr name={t('entity.product.termLength')}>
							{t(`common:datePeriod.${license.product.validityUnit}`, { count: license.product.validity })}
						</DataTableTr>
						<DataTableTr name={t('entity.createdAt')}>
							{dateFormatter.toTimeZoneDateTime(license.createdAt)}
						</DataTableTr>
						<DataTableTr name={t('entity.modifiedAt')} defaultValue={t('common:_.NA')}>
							{dateFormatter.toTimeZoneDateTime(license.modifiedAt)}
						</DataTableTr>
						<DataTableTr name={t('entity.registeredAt')} defaultValue={t('common:_.NA')}>
							{dateFormatter.toTimeZoneDateTime(license.registeredAt)}
						</DataTableTr>
						<DataTableTr name={t('common:dates.expire')} defaultValue={t('common:_.NA')}>
							{dateFormatter.toTimeZoneDateTime(license.expiration)}
						</DataTableTr>
						<DataTableTr name={t('entity.orderId')} defaultValue={t('common:_.NA')}>
							{orderId ? <Link to={buildParametrizedRoute(RouteName.ORDER.DETAIL, orderId)}>{orderId}</Link> : null}
						</DataTableTr>
						<DataTableTr name={t('entity.entitlementId')}>{showEntitlementId(license.id)}</DataTableTr>
					</DataTable>
				</Col>
				<Col md={6}>
					<DataTable isLoading={isFetching} className="table-fixed">
						<DataTableTr name={t('common:entity.licenseKey')} highlighted>
							<Copy2Clipboard value={getFulfillmentValue(license)} />
						</DataTableTr>
						<DataTableTr name={t('entity.walletKey')}>
							<Copy2Clipboard
								value={getLicenseContextValue(license, LicenseContextIdEnum.WALLET_KEY)}
								defaultValue={t('common:_.NA')}
							/>
						</DataTableTr>
						<DataTableTr name={t('entity.status')}>
							<Badge bg={getVariantColorForLicenseStatus(license.status)}>
								{licenseStatusEnumUtils.getText(license.status)}
							</Badge>
						</DataTableTr>
						<DataTableTr name={t('entity.licenseId')} defaultValue={t('common:_.NA')}>
							{getLicenseId(license)}
						</DataTableTr>
						<DataTableTr name={t('entity.licenseType')}>{getLicenseType(license)}</DataTableTr>

						<PartnerGroup />
						<DistributionPartnerGroup />
						<CustomerGroup />
					</DataTable>
				</Col>
			</Row>
		</>
	);
};
