import { type IPageModuleConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import type { ICreditLimitForm } from 'module/creditLimit';

interface ICreditLimitConfigProps extends IPageModuleConfig {
	defaultFormValues: ICreditLimitForm;
}

export const creditLimitConfig = moduleConfigGenerator<ICreditLimitConfigProps>({
	trNamespace: 'moduleCreditLimit',
	menuName: 'app:menu.creditLimit',
	menuLink: RouteName.CREDIT_LIMIT.LIST,
	aclModule: 'creditLimit',
	defaultFormValues: {
		amount: 0,
		partnerId: null,
	},
});
