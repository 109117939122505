import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useClearLocationStateOnMount = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		// Clear state on init -> not persist after reload
		navigate({ ...location }, { replace: true, state: {} });
	}, []);
};
