import { Button } from '@avast/react-ui-components';
import { useCallback, useEffect, useRef } from 'react';
import { AsyncModal } from 'js/components/molecules/Modal/AsyncModal';
import {
	ChargeOrderDocumentsModalContent,
	type TChargeOrderDocumentsModalContentProps,
} from 'js/components/documents/ChargeOrderDocumentsModalContent';
import type { UseQueryResult } from '@tanstack/react-query';

type TChargeOrderDocumentsButtonProps = TChargeOrderDocumentsModalContentProps & {
	defaultOpen?: boolean;
	query: UseQueryResult;
};

type TModalProps = AsyncModal<TChargeOrderDocumentsModalContentProps>;

export const ChargeOrderDocumentsButton = (props: TChargeOrderDocumentsButtonProps) => {
	const { query, id, chargeOrderId, title, defaultOpen = false } = props;
	const documentsRef = useRef<TModalProps>(null);
	const initialRenderRef = useRef(defaultOpen);

	const showModal = useCallback(() => {
		documentsRef.current?.show({ id, chargeOrderId, title });
	}, [id, chargeOrderId, title]);

	useEffect(() => {
		// Only try to open on initial render if defaultOpen is true
		if (query.isSuccess && initialRenderRef.current) {
			showModal();
			initialRenderRef.current = false;
		}
	}, [query.isSuccess, showModal]);

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				loading={query.isFetching}
				onClick={showModal}
				testId="orderDocuments"
			>
				{title}
			</Button>
			<AsyncModal<TChargeOrderDocumentsModalContentProps> size="md" ref={documentsRef} testId="orderDocuments">
				{(modalProps) => <ChargeOrderDocumentsModalContent {...modalProps} />}
			</AsyncModal>
		</>
	);
};
