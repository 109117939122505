import type { ReactElement } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Navigate } from 'react-router';
import { OrderActions, OrderSummary, PartiesInfo } from 'module/purchase/components/order';
import { isEmptySelector } from 'js/selectors/order';
import { RouteName } from 'module/RouteName';
import type { IStandardOrderInstance, IStandardOrderInstanceItem } from 'module/purchase';
import { FormikForm } from 'js/components/formik/FormikForm';
import { AutoSave } from 'js/components/formik/AutoSave';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useReviewOrderPricing } from 'module/purchase/hooks/pricing/useReviewOrderPricing';
import { useOrderContext } from 'js/contexts';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';

export type TProductsTableForm = Required<Pick<IStandardOrderInstance, 'items' | 'additionalInfo'>> &
	Pick<IStandardOrderInstance, 'discretionaryDiscount'>;

// https://butr.avast.com/browse/BOSS-2573
const fillZerosForUndefinedProps = (items: IStandardOrderInstanceItem[]): IStandardOrderInstanceItem[] => {
	const props: ['unit', 'quantity'] = ['unit', 'quantity'];
	return items.map((item) => {
		for (const prop of props) {
			if (!item[prop]) {
				item[prop] = 0;
			}
		}
		return item;
	});
};

export const PageOrder = (): ReactElement => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { orderState, updateAdditionalData, updateDiscretionaryDiscount, updateItems, hasOrderParties } =
		useOrderContext();
	useReviewOrderPricing();

	if (isEmptySelector(orderState) || !hasOrderParties) {
		return <Navigate to={RouteName.PURCHASE.DEFAULT} replace />;
	}

	return (
		<Formik<TProductsTableForm>
			initialValues={orderState}
			enableReinitialize
			onSubmit={(values, { setSubmitting }) => {
				updateItems(fillZerosForUndefinedProps(values.items));
				updateAdditionalData(values.additionalInfo);
				updateDiscretionaryDiscount(values.discretionaryDiscount);
				setSubmitting(false);
			}}
			validationSchema={Yup.object().shape({
				additionalInfo: Yup.object().shape({
					externalPurchaseNumber: Yup.string().label(t('common:entity.poNumber')).max(45),
				}),
			})}
		>
			<FormikForm testId="reviewOrderForm">
				<DynamicPageTitleItem text={t('page.products.title')} />
				<DynamicPageTitleItem text={t('page.order.title')} />
				<DynamicBreadcrumbsItem href={RouteName.PURCHASE.PRODUCTS}>{t('page.products.title')}</DynamicBreadcrumbsItem>
				<DynamicBreadcrumbsItem href={RouteName.PURCHASE.ORDER}>{t('page.order.title')}</DynamicBreadcrumbsItem>
				<AutoSave />
				<OrderSummary />
				<div className="container-fluid pb-8">
					<div className="container-orderReview">
						<PartiesInfo />
						<OrderActions backLink={RouteName.PURCHASE.DEFAULT} />
					</div>
				</div>
			</FormikForm>
		</Formik>
	);
};
