import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/nl-NL');
const locale: TLocale = 'nl-NL';

export const nl_NL: ILocaleConfig = {
	name: 'Dutch (Netherlands)',
	locale,
	language: 'nl',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
