import type { ReactElement } from 'react';
import { type TUseCanProps, useCan } from 'js/hooks/useCan';
import { isFunction } from 'lodash';

type TCanProps = TUseCanProps & {
	children: ReactElement | ((allowed: boolean) => ReactElement);
};

/**
 * ACL can be shown content
 * @param {TCanProps} props
 * @returns {React.ReactElement | null}
 * @constructor
 */
export const Can = (props: TCanProps): ReactElement | null => {
	const { children, ...rest } = props;
	const allowed = useCan(rest);

	// Is children content function
	if (isFunction(children)) {
		return children(allowed);
	}

	// Render
	return allowed ? children : props.otherwise || null;
};
