import { AsyncJobStatusEnum } from 'js/enums';
import type { IAsyncJob } from 'js/asyncJobs';

export const isAsyncJobDone = (asyncJob: IAsyncJob): boolean => {
	return asyncJob.status === AsyncJobStatusEnum.PROCESSED;
};

export const isAsyncJobInError = (asyncJob: IAsyncJob): boolean => {
	return asyncJob.status === AsyncJobStatusEnum.ERROR;
};
