import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useTranslation } from 'react-i18next';
import {
	getCustomerPricingBreakdown,
	getPartnerPricingBreakdown,
	type TPricingBreakdownLine,
} from 'module/purchase/utils/pricingBreakdown';
import type { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { hasOrderSavedPricingResponse } from 'module/orders/utils/common';

const usePricingBreakdownCustomer = (order: IEntityOrder, item: IEntityOrderItem): TPricingBreakdownLine[] => {
	const { tPartyPrice } = usePartyPriceTranslation();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return getCustomerPricingBreakdown({
		pricing: item.pricing,
		currencyCode: order.currency,
		t,
		tPartyPrice,
		quotedCustomerPricing: item.computedValues.price.quotedCustomer,
	});
};

const usePricingBreakdownPartner = (order: IEntityOrder, item: IEntityOrderItem): TPricingBreakdownLine[] | null => {
	const { tPartyPrice } = usePartyPriceTranslation();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	if (order.computedValues.isEndCustomerOrder) {
		return null;
	}

	return getPartnerPricingBreakdown({
		pricing: item.pricing,
		currencyCode: order.currency,
		t,
		tPartyPrice,
	});
};

export const useOrderPricingBreakdown = (
	order: IEntityOrder,
	item: IEntityOrderItem,
): TPricingBreakdownLine[][] | null => {
	const pricingBreakdownCustomer = usePricingBreakdownCustomer(order, item);
	const pricingBreakdownPartner = usePricingBreakdownPartner(order, item);
	const hasSavedPricingResponse = hasOrderSavedPricingResponse(order);

	if (!hasSavedPricingResponse) {
		return null;
	}

	const cols: TPricingBreakdownLine[][] = [];
	cols.push(pricingBreakdownCustomer);
	if (pricingBreakdownPartner) {
		cols.push(pricingBreakdownPartner);
	}

	return cols;
};
