import type { ReactElement } from 'react';
import {
	GenerateRetailCodesCart,
	GenerateRetailCodesControls,
	GenerateRetailCodesPartner,
} from 'module/retail/components/generate';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { RouteName } from 'module/RouteName';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { Formik } from 'formik';
import type { IGenerateRetailCodesForm } from 'module/retail';
import { useSubmitRetailGenerateRequest } from 'module/retail/hooks/useSubmitRetailGenerateRequest';
import { FormikForm } from 'js/components/formik/FormikForm';
import { StepperVerticalContainer } from 'submodule/stepperVertical';
import { useNavigate } from 'react-router';

export const PageGenerate = (): ReactElement => {
	const { t } = useTranslation(retailConfig.trNamespace);
	const submitRetailGenerateRequest = useSubmitRetailGenerateRequest();
	const navigate = useNavigate();

	return (
		<>
			<DynamicPageTitleItem text={t('actions.generate')} />
			<DynamicBreadcrumbsItem href={RouteName.RETAIL.GENERATE}>{t('actions.generate')}</DynamicBreadcrumbsItem>

			<Formik<IGenerateRetailCodesForm>
				initialValues={{
					partner: null,
					products: [],
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					submitRetailGenerateRequest(values, () => navigate(RouteName.RETAIL.LIST));
				}}
			>
				<FormikForm testId="generateRetailCodes">
					<div className="d-flex flex-column min-vh-content-100">
						<StepperVerticalContainer variant="light" border className="py-5">
							<GenerateRetailCodesPartner />
						</StepperVerticalContainer>

						<StepperVerticalContainer border className="py-5 py-md-6 flex-grow-1">
							<GenerateRetailCodesCart />
						</StepperVerticalContainer>

						<StepperVerticalContainer variant="light" className="py-3">
							<GenerateRetailCodesControls />
						</StepperVerticalContainer>
					</div>
				</FormikForm>
			</Formik>
		</>
	);
};
