import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { LanguageControl, LocaleFormatControl } from 'submodule/localeSwitch/components';
import { useAllowedLanguages } from 'js/hooks/useAllowedLanguages';
import { useAuthContext } from 'js/contexts';

export const PersonalizationCard = (props: Partial<TDataTableCardProps>) => {
	const { authPartner: partner } = useAuthContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const allowedLanguages = useAllowedLanguages(partner);

	if (!partner || allowedLanguages.length === 1) {
		return null;
	}

	return (
		<DataTableCard caption={t('cards.personalization')} variant="simple" {...props}>
			<DataTableTr name={t('common.locale.language')} nameClassName="align-middle">
				<LanguageControl />
			</DataTableTr>
			<DataTableTr name={t('common.locale.format')} nameClassName="align-middle">
				<LocaleFormatControl />
			</DataTableTr>
		</DataTableCard>
	);
};
