import { useApi } from 'js/hooks/api';
import type { AxiosResponse } from 'axios';
import type { TUseApiProps } from 'js/hooks/api/useApi';
import type { QueryFunctionContext } from '@tanstack/react-query';
import type { TDetailId } from 'types';
import { fillUrlParameters } from 'js/utils/common';

type TUseApiDetailProps = TUseApiProps;

export type TUseApiDetailQueryKey<Filter> = [string, TDetailId, Filter];
type TUseApiDetailContextQuery<Filter> = QueryFunctionContext<TUseApiDetailQueryKey<Filter>>;

type TUseApiDetail<Data, Filter> = (context: TUseApiDetailContextQuery<Filter>) => Promise<AxiosResponse<Data>>;

export const useApiDetail = <Data, Filter>(props?: TUseApiDetailProps): TUseApiDetail<Data, Filter> => {
	const { config } = props || {};
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (context) => {
		const [key, id, filter] = context.queryKey;
		const [route, _filter] = fillUrlParameters<Filter>(key, { id, ...filter });
		const abortController = new AbortController();

		const promise = api.get<Data>(`/${route}`, {
			signal: abortController.signal,
			params: _filter,
			...config,
		});

		// Cancel the request if TanStack Query signals to abort
		context.signal?.addEventListener('abort', () => {
			abortController.abort();
		});

		return promise;
	};
};
