import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { PageCalculator } from 'module/priceCalculator/pages';

export const PriceCalculatorRoutes = () => {
	const { aclModule } = priceCalculatorConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ]} />}>
				<Route index element={<PageCalculator />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
