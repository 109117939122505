import type { ReactElement } from 'react';
import { ButtonComposition, H1 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { OrderStatusOverview } from 'module/orders/components/OrderStatusOverview';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { Col, Row } from 'js/components/atoms/Row';
import {
	CancelOrderButton,
	EditOrderButton,
	OrderDocumentsButton,
	PaymentInstructionsButton,
	ViewClosingBalanceButton,
	ViewRefundsButton,
} from 'module/orders/components/buttons';
import { DiscountApproval } from 'module/orders/components/DiscretionaryDiscount/DiscountApproval';
import { PriceSummary } from 'module/orders/components/PriceSummary';
import { Badge } from 'react-bootstrap';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { InitiateRefundButton } from 'submodule/refunds';

type TOrdersStatusTabProps = {
	showPaymentInstructions?: boolean;
	showDocuments?: boolean;
};

export const OrderStatusTab = (props: TOrdersStatusTabProps): ReactElement => {
	const { showPaymentInstructions = false, showDocuments } = props;
	const { t } = useTranslation(ordersConfig.trNamespace);
	const viewRefundsModalRef = useAsyncModalRef();
	const { data: order } = usePageDetailContext<IEntityOrder>();

	function showOrderRefunds() {
		viewRefundsModalRef.current?.show();
	}

	return (
		<DefaultContainer>
			<Row multi size="sm" justify="between">
				<Col md="auto">
					<H1 type="h2">{t('common.orderNumber', { id: order.id })}</H1>
					{order.isPayAsYouGoOrder && (
						<Badge pill bg="brand" className="mt-2">
							{t('common.flexibleBilling')}
						</Badge>
					)}
				</Col>
				<Col md="auto">
					<PriceSummary />
				</Col>
			</Row>

			<ButtonComposition marginY>
				<PaymentInstructionsButton openOnInit={showPaymentInstructions} />
				<OrderDocumentsButton openOnInit={showDocuments} />
				<ViewClosingBalanceButton />
				<InitiateRefundButton showRefundsModal={showOrderRefunds} />
				<ViewRefundsButton forwardedRef={viewRefundsModalRef} />
				<EditOrderButton />
				<CancelOrderButton />
			</ButtonComposition>

			<DiscountApproval className="mt-3" />
			<OrderStatusOverview />
		</DefaultContainer>
	);
};
