import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader } from '@avast/react-ui-components';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import {
	CreditStatusTab,
	CustomerCreditStatusTab,
	CustomerTab,
	DiscretionaryDiscountTab,
	DistributionPartnerTab,
	PartnerTab,
} from 'module/purchase/components/order/tabs';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useOrderContext } from 'js/contexts';

type TTabKeys = 'creditStatus' | 'publicNotes' | 'privateNotes' | 'discretionaryDiscount' | undefined;
type TPartyTabKeys = 'partner' | 'distributionPartner' | 'customer';

export const PartiesInfo = (): ReactElement => {
	const { isGroupInternal, isRoleReseller } = useAuthContext();
	const { isEndCustomerBillableParty, orderState } = useOrderContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const requiredOrderParties = useRequiredOrderParties();

	// Decisions
	const hasPartnerTab = isGroupInternal && !isEndCustomerBillableParty;
	const hasDistributionPartnerTab = !isRoleReseller && !isEndCustomerBillableParty;
	const hasCreditStatusTab = orderState.partner?.isCreditEligible || isEndCustomerBillableParty;
	const hasDiscountTab = isGroupInternal;
	const [activeTab, setActiveTab] = useState<TTabKeys>(
		hasCreditStatusTab ? 'creditStatus' : hasDiscountTab ? 'discretionaryDiscount' : 'publicNotes',
	);
	const activePartyTab = useMemo<TPartyTabKeys>(
		() => (hasPartnerTab ? 'partner' : hasDistributionPartnerTab ? 'distributionPartner' : 'customer'),
		[hasPartnerTab, hasDistributionPartnerTab],
	);

	useEffect(() => {
		if (hasCreditStatusTab) {
			setActiveTab('creditStatus');
		}
	}, [hasCreditStatusTab]);

	return (
		<Row multi justify="between">
			<Col lg={6}>
				<Tab.Container defaultActiveKey={activePartyTab}>
					<Card active className="h-100" testId="orderParties">
						<CardHeader className="has-tabs">
							<Nav variant="tabs">
								{hasPartnerTab && (
									<Nav.Item>
										<Nav.Link eventKey="partner">{t('widget.selectPartnerCard.title')}</Nav.Link>
									</Nav.Item>
								)}
								{hasDistributionPartnerTab && (
									<Nav.Item>
										<Nav.Link eventKey="distributionPartner">
											{!requiredOrderParties.distributionPartner.valid && (
												<FontAwesomeIcon icon={faExclamationTriangle} className="me-2 fs-sm text-warning" />
											)}
											{t('widget.selectDistributionPartnerCard.title')}
										</Nav.Link>
									</Nav.Item>
								)}
								<Nav.Item>
									<Nav.Link eventKey="customer">
										{!requiredOrderParties.customer.valid && (
											<FontAwesomeIcon icon={faExclamationTriangle} className="me-2 fs-sm text-warning" />
										)}
										{t('widget.selectCustomerCard.title')}
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</CardHeader>
						<CardBody>
							<Tab.Content>
								{hasPartnerTab && (
									<Tab.Pane eventKey="partner">
										<PartnerTab />
									</Tab.Pane>
								)}
								{hasDistributionPartnerTab && (
									<Tab.Pane eventKey="distributionPartner">
										<DistributionPartnerTab />
									</Tab.Pane>
								)}
								<Tab.Pane eventKey="customer">
									<CustomerTab />
								</Tab.Pane>
							</Tab.Content>
						</CardBody>
					</Card>
				</Tab.Container>
			</Col>
			<Col lg={6}>
				<Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key as TTabKeys)}>
					<Card className="h-100" testId="others">
						<CardHeader className="has-tabs">
							<Nav variant="tabs">
								{hasCreditStatusTab && (
									<Nav.Item>
										<Nav.Link eventKey="creditStatus">{t('app:menu.creditStatus')}</Nav.Link>
									</Nav.Item>
								)}
								{hasDiscountTab && (
									<Nav.Item>
										<Nav.Link eventKey="discretionaryDiscount">{t('discretionaryDiscount.title')}</Nav.Link>
									</Nav.Item>
								)}
								<Nav.Item>
									<Nav.Link eventKey="publicNotes">{t('entity.publicNotes')}</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="privateNotes">{t('entity.privateNotes')}</Nav.Link>
								</Nav.Item>
							</Nav>
						</CardHeader>
						<CardBody>
							<Tab.Content>
								{hasCreditStatusTab && (
									<Tab.Pane eventKey="creditStatus">
										{isEndCustomerBillableParty ? <CustomerCreditStatusTab /> : <CreditStatusTab />}
									</Tab.Pane>
								)}
								{hasDiscountTab && (
									<Tab.Pane eventKey="discretionaryDiscount">
										<DiscretionaryDiscountTab />
									</Tab.Pane>
								)}
								<Tab.Pane eventKey="publicNotes">
									<FormikControl>
										<FormikControl.Textarea name="additionalInfo.publicNotes" testId="publicNotes" size="sm" />
									</FormikControl>
								</Tab.Pane>
								<Tab.Pane eventKey="privateNotes">
									<FormikControl>
										<FormikControl.Textarea name="additionalInfo.privateNotes" testId="privateNotes" size="sm" />
									</FormikControl>
								</Tab.Pane>
							</Tab.Content>
						</CardBody>
					</Card>
				</Tab.Container>
			</Col>
		</Row>
	);
};
