import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import type { IEntityRefund, IEntityRefundableAmount, IInitiateRefundRequest } from 'submodule/refunds';
import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import type { IChargeOrderApiFilter } from 'js/chargeOrder';
import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { ApiMutationTypeEnum } from 'js/enums';

export const API_REFUNDS_KEYS = {
	PROCESS: 'refund/process',
	LIST: 'charge-order/:chargeOrderId/refunds',
	REFUNDABLE: 'charge-order/:id/refundableAmount',
};

export const useApiRefundProcess: TUseApiMutationModule<IInitiateRefundRequest, boolean> = (props) =>
	useApiMutationQuery(API_REFUNDS_KEYS.PROCESS, ApiMutationTypeEnum.POST, props);

export const useApiRefunds: TUseApiListModule<IEntityRefund, IChargeOrderApiFilter> = (props) =>
	useApiListQuery(API_REFUNDS_KEYS.LIST, props);

export const useApiRefundableAmount: TUseApiDetailModule<IEntityRefundableAmount> = (props) =>
	useApiDetailQuery(API_REFUNDS_KEYS.REFUNDABLE, props);
