import type { IEntityOrder } from 'module/orders';
import type { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { noValuePlaceholder } from 'js/utils/app';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { orderStatusEnumUtils } from 'module/orders/enums';
import { ordersConfig } from 'module/orders/ordersConfig';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import { useMemo } from 'react';
import { useIsEditable } from 'module/orders/hooks/useIsEditable';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityOrder;

export const useQuoteListColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(ordersConfig.trNamespace);
	const isEditable = useIsEditable();
	const { isGroupPartner, isRoleDistributor } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.link(
				'id',
				{
					header: t('entity.quoteNumberShort'),
				},
				{
					to: (quote) => ordersConfig.detailLink(quote),
					copy: true,
				},
			),
			columnHelper.ellipsis((row) => getPartnerRenderName(row.partner), {
				header: t('common:entity.partner'),
				enableSorting: false,
				id: 'partner',
				meta: {
					hidden: isGroupPartner,
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.ellipsis((row) => getDistributionPartnerRenderName(row.distributionPartner), {
				header: t('common:entity.distributionPartner'),
				enableSorting: false,
				id: 'distributionPartner',
				meta: {
					hidden: !isRoleDistributor,
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.ellipsis((row) => getCustomerRenderName(row.customer), {
				header: t('common:entity.customer'),
				enableSorting: false,
				meta: {
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.date('createdAt', { header: t('entity.created') }),
			columnHelper.date('expirationDate', { header: t('common:dates.expire') }),
			columnHelper.text('status', {
				header: t('entity.quoteStatus'),
				meta: { formatters: [orderStatusEnumUtils.getText] },
			}),
			columnHelper.money('totalAmountWithoutTax', { header: t('entity.totalAmount') }, { currencyKey: 'currency' }),
			columnHelper.actions({
				link: {
					children: isGroupPartner ? t('actions.pay') : t('actions.payEdit'),
					to: (quote: TTableData) => ordersConfig.quoteUpdateLink(quote),
					conditionalProps: (quote: TTableData) => ({
						hidden: !isEditable(quote),
					}),
					testId: 'pay',
				},
				dropdown: {
					items(quote) {
						return [
							<DropdownLink key="detail" to={ordersConfig.quoteDetailLink(quote)} testId="detail">
								{t('actions.detailQuote')}
							</DropdownLink>,
						];
					},
				},
			}),
		];
	}, [t, isEditable, isGroupPartner, isRoleDistributor]);
};
