import type {
	IEntityPartnerDetail,
	IPartnerRoleGroups,
	IPartnerRoles,
	TPartnerRolesAndRoleGroups,
} from 'module/partners';
import { PartnerRoleEnum, PartnerTypeEnum, SalesUserRoleEnum } from 'module/partners/enums';
import { logError } from 'js/utils/app';

export const determinatePartnerRole = (
	partner?: IEntityPartnerDetail | null,
	company?: IEntityPartnerDetail | null,
): PartnerRoleEnum | null => {
	if (!partner) {
		return null;
	}

	const partnerType = partner.accountType;
	const companyType = company?.accountType;
	const type = partnerType === PartnerTypeEnum.USER ? companyType : partnerType;

	switch (type) {
		case PartnerTypeEnum.RESELLER:
			return PartnerRoleEnum.RESELLER;
		case PartnerTypeEnum.DISTRIBUTOR:
			return PartnerRoleEnum.DISTRIBUTOR;
		case PartnerTypeEnum.INTERNAL_COMPANY:
			switch (partner?.attributes?.salesUserRole) {
				case SalesUserRoleEnum.FINANCE:
					return PartnerRoleEnum.FINANCE;
				case SalesUserRoleEnum.SUPPORT:
					return PartnerRoleEnum.SUPPORT;
				case SalesUserRoleEnum.SALES_MANAGER:
					return PartnerRoleEnum.SALES_MANAGER;
				case SalesUserRoleEnum.SALES_OPERATIONS:
					return PartnerRoleEnum.SALES_OPERATIONS;
				default:
					logError(`Not supported salesUser role: ${partner?.attributes?.salesUserRole}`);
					return null;
			}
		default:
			return null;
	}
};

export const getPartnerRoles = (role: PartnerRoleEnum | null): IPartnerRoles => ({
	isRoleDistributor: role === PartnerRoleEnum.DISTRIBUTOR,
	isRoleReseller: role === PartnerRoleEnum.RESELLER,
	isRoleSalesOperations: role === PartnerRoleEnum.SALES_OPERATIONS,
	isRoleSalesManager: role === PartnerRoleEnum.SALES_MANAGER,
	isRoleFinance: role === PartnerRoleEnum.FINANCE,
	isRoleSupport: role === PartnerRoleEnum.SUPPORT,
});

export const getPartnerRoleGroups = (roles: IPartnerRoles): IPartnerRoleGroups => ({
	isGroupPartner: roles.isRoleReseller || roles.isRoleDistributor,
	isGroupSales: roles.isRoleSalesOperations || roles.isRoleSalesManager,
	isGroupInternal:
		roles.isRoleSalesOperations || roles.isRoleSalesManager || roles.isRoleFinance || roles.isRoleSupport,
});

export const getPartnerRolesAndRoleGroups = (partner?: IEntityPartnerDetail | null): TPartnerRolesAndRoleGroups => {
	const partnerRole = determinatePartnerRole(partner);
	const roles = getPartnerRoles(partnerRole);
	const roleGroups = getPartnerRoleGroups(roles);

	return {
		...roles,
		...roleGroups,
	};
};
