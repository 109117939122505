import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import type {
	IAsyncExportLicensesRequest,
	IEntityLicense,
	ILicenseListApiFilter,
	ILicenseListByLicenseStringFilter,
	ILicenseListFilter,
	IRegisterLicenseParams,
	IRegisterLicenseRequestData,
	IRegisterLicenseResponse,
} from 'module/licenses';
import type { TDetailArguments } from 'types';
import { type TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { type TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { licensesFilterNormalizer } from 'module/licenses/normalizer';
import { ApiMutationTypeEnum } from 'js/enums';
import type { IAsyncJob } from 'js/asyncJobs';

export const API_LICENSES_KEYS = {
	LIST: 'entitlement',
	SEARCH: 'entitlement/search',
	DETAIL: 'entitlement/:id',
	REGISTER: 'entitlement/:entitlementId/register',
	EXPORT: 'export/entitlements',
};

export const useApiLicenseList: TUseApiPaginatedListModule<
	IEntityLicense,
	ILicenseListFilter,
	ILicenseListApiFilter
> = (props) =>
	useApiPaginatedListQuery(API_LICENSES_KEYS.LIST, props, {
		apiFilterNormalizer: (filter) => licensesFilterNormalizer.denormalize(filter),
	});

export const useApiLicenseListByLicenseString: TUseApiListModule<IEntityLicense, ILicenseListByLicenseStringFilter> = (
	props,
) => useApiListQuery(API_LICENSES_KEYS.SEARCH, props);

export const useApiLicense: TUseApiDetailModule<IEntityLicense, TDetailArguments> = (props) => {
	return useApiDetailQuery(API_LICENSES_KEYS.DETAIL, props);
};

export const useApiRegisterLicense: TUseApiMutationModule<
	IRegisterLicenseRequestData,
	IRegisterLicenseResponse,
	IRegisterLicenseParams
> = (props) =>
	useApiMutationQuery(API_LICENSES_KEYS.REGISTER, ApiMutationTypeEnum.PATCH, props, {
		invalidateQueries: Object.values(API_LICENSES_KEYS),
	});

export const useApiLicensesListExport: TUseApiMutationModule<IAsyncExportLicensesRequest, IAsyncJob> = (props) => {
	return useApiMutationQuery(API_LICENSES_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
};
