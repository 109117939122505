import type { ReactElement } from 'react';
import { DetailListModalContent } from 'module/promotions/components/DetailListModalContent';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { noValuePlaceholder } from 'js/utils/app';
import { isString } from 'lodash';

type TDetailListVariant = 'LINES';

type TDetailListProps = {
	values: string[] | TDetailItem[];
	title: string;
	defaultValue: string;
	maxShownItems?: number;
	variant?: TDetailListVariant;
	allValues?: string[];
};

export type TDetailItem = {
	name: string;
	description?: string;
};

const renderListItems = (values: TDetailItem[], variant?: TDetailListVariant) => (
	<ul className="my-0 reset">
		{values.map((item) => {
			let description = null;
			if (item.description) {
				if (variant === 'LINES') {
					description = (
						<>
							<br />
							<em className="text-gray">{item.description}</em>
						</>
					);
				} else {
					description = <span className="text-gray"> ({item.description})</span>;
				}
			}

			return (
				<li key={item.name} className="mb-1">
					{item.name}
					{description}
				</li>
			);
		})}
	</ul>
);

const noValueSelected = (values: TDetailItem[]) => values.length === 0;
const allValuesSelected = (values: TDetailItem[], allValues?: string[]) => values.length === allValues?.length;

export const DetailList = (props: TDetailListProps): ReactElement | null => {
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const { title, maxShownItems, defaultValue, variant, allValues } = props;
	const detailListModalRef = useAsyncModalRef();
	const selectedValues: TDetailItem[] =
		props.values?.map((value) => {
			if (isString(value)) {
				return { name: value };
			}
			return value;
		}) || [];

	if (noValueSelected(selectedValues) || allValuesSelected(selectedValues, allValues)) {
		return noValuePlaceholder(defaultValue);
	}

	if (maxShownItems && selectedValues.length > maxShownItems) {
		const showDetailList = () => detailListModalRef.current?.show();

		return (
			<>
				{renderListItems(selectedValues.slice(0, maxShownItems), variant)}
				<span className="text-link text-primary" onClick={showDetailList} onKeyDown={showDetailList}>
					{t('common:actions.showAll')} ({selectedValues.length})
				</span>

				<AsyncModal ref={detailListModalRef} testId="detailListModal">
					<DetailListModalContent title={title} values={selectedValues} />
				</AsyncModal>
			</>
		);
	}

	return renderListItems(selectedValues, variant);
};
