import { useFormikContext } from 'formik';
import type { IGenerateRetailCodesForm } from 'module/retail/index';
import { getMarketSegments } from 'module/partners/utils/partnerSelectors';
import { PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';

export const useGenerateRetailCodesMarketSegments = () => {
	const {
		values: { partner },
	} = useFormikContext<IGenerateRetailCodesForm>();

	return getMarketSegments({ partner, purchaseBillablePartyType: PurchaseBillablePartyTypeEnum.PARTNER });
};
