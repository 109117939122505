import { type IPageModuleConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import type { IAuthGuards } from 'types';

interface IRetailModuleConfig extends IPageModuleConfig {
	generateAuthGuards: IAuthGuards;
}

export const retailConfig = moduleConfigGenerator<IRetailModuleConfig>({
	trNamespace: 'moduleRetail',
	menuName: 'app:menu.retail',
	menuLink: RouteName.RETAIL.LIST,
	aclModule: 'retail',
	generateAuthGuards: { affiliateProperties: { isConsumerTeamMember: true } },
});
