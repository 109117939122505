import { useState } from 'react';
import type { IEntityOrderItem } from 'module/orders';
import { useApiLicenseList } from 'module/licenses/hooks';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import type { IEntityLicense, ILicenseListApiFilter, ILicenseListFilter } from 'module/licenses';
import { useOrderItemLicensesColumns } from 'module/orders/hooks';

type TTableData = IEntityLicense;
type TTableDataFilter = ILicenseListFilter;
type TTableDataApiFilter = ILicenseListApiFilter;
type TOrderItemLicensesProps = {
	orderItem: IEntityOrderItem;
};

export const OrderItemLicenses = (props: TOrderItemLicensesProps) => {
	const { orderItem } = props;
	const columns = useOrderItemLicensesColumns();

	const [filter] = useState<TTableDataFilter>({
		standardOrderLineItemId: orderItem.id,
	});

	return (
		<ApiPaginatedListTable<TTableData, TTableDataFilter, TTableDataApiFilter>
			columns={columns}
			query={useApiLicenseList}
			filter={filter}
			filterRequired
			table={{
				enableSorting: false,
				testId: 'licenses',
			}}
		/>
	);
};
