import { useState } from 'react';
import type { IEntityOrder } from 'module/orders';
import { useApiLicenseList, useOrderLicensesAreCancellable } from 'module/licenses/hooks';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import type { IEntityLicense, ILicenseListApiFilter, ILicenseListFilter } from 'module/licenses';
import { isLicenseCancellable } from 'module/licenses/utils/common';
import type { IListMetaDataValues } from 'types/utils';
import { ButtonComposition } from '@avast/react-ui-components';
import { ExportLicensesButton } from 'module/licenses/components/buttons';
import { useOrderLicensesColumns } from 'module/orders/hooks';
import { CancelOrderLicensesButton } from 'module/orders/components/buttons';
import { Col, Row } from 'js/components/atoms/Row';
import { RESULT_CANCEL_ORDER } from 'module/orders/hooks/useCancelOrderLicenses';
import type { RowSelectionState } from '@tanstack/react-table';
import { LicenseSearchKeyEnum } from 'module/licenses/enums';

type TTableData = IEntityLicense;
type TTableDataFilter = ILicenseListFilter;
type TTableDataApiFilter = ILicenseListApiFilter;
type TOrderLicensesProps = {
	order: IEntityOrder;
	onCancelOrder?(): void;
};

export const OrderLicenses = (props: TOrderLicensesProps) => {
	const { order, onCancelOrder } = props;
	const licensesAreCancellable = useOrderLicensesAreCancellable(order);
	const columns = useOrderLicensesColumns({ isRowSelectable: licensesAreCancellable });
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({});
	const [selectedLicenses, setSelectedLicenses] = useState<RowSelectionState>({});

	const [filter] = useState<TTableDataFilter>({
		search: {
			key: LicenseSearchKeyEnum.ORDER_NUMBER,
			value: order.id,
		},
	});

	return (
		<>
			<Row multi justify="between">
				<Col xs="auto" className="pt-0">
					<CancelOrderLicensesButton
						hidden={!licensesAreCancellable}
						multiple
						order={order}
						entitlementIds={Object.keys(selectedLicenses)}
						onCancel={(result) => {
							setSelectedLicenses({});
							if (result === RESULT_CANCEL_ORDER) {
								onCancelOrder?.();
							}
						}}
					/>
				</Col>
				<Col xs="auto" className="pt-0">
					<ButtonComposition size="sm">
						<ExportLicensesButton listMetaData={listMetaData} />
						<ExportLicensesButton listMetaData={listMetaData} detailed />
					</ButtonComposition>
				</Col>
			</Row>
			<ApiPaginatedListTable<TTableData, TTableDataFilter, TTableDataApiFilter>
				columns={columns}
				query={useApiLicenseList}
				filter={filter}
				filterRequired
				table={{
					enableSorting: false,
					testId: 'licenses',
					onRowSelectionChange: setSelectedLicenses,
					enableRowSelection: (row) => isLicenseCancellable(row.original),
					getRowId: (row) => String(row.id),
					state: { rowSelection: selectedLicenses },
				}}
				onMetaDataChange={setListMetaData}
			/>
		</>
	);
};
