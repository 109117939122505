import type { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import type { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { getDistributionPartnerFullName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import type { TQueryDetailFilter } from 'module/distributionPartners/pages/PageDetail';
import { useCountryName } from 'js/entities/country/useCountryName';
import { useCountryStateName } from 'js/entities/country/useCountryStateName';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { Link } from 'react-router';
import { useAuthContext } from 'js/contexts';
import { EmailDisplay } from 'js/components/atoms/EmailDisplay';

type TDistributionPartnerDetailProps = {
	distributionPartner: IEntityDistributionPartner | null;
	linkable?: boolean;
	isLoading?: boolean;
	hideIncompleteData?: boolean;
};

export const DistributionPartnerDetail = (props: TDistributionPartnerDetailProps): ReactElement => {
	const { distributionPartner, linkable, isLoading, hideIncompleteData } = props;
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const { isGroupPartner } = useAuthContext();
	const countryName = useCountryName(distributionPartner?.billing.countryCode, distributionPartner?.billing.country);
	const stateName = useCountryStateName(
		distributionPartner?.billing.countryCode,
		distributionPartner?.billing.stateCode,
		distributionPartner?.billing.state,
	);

	if (!distributionPartner?.id) {
		return <Alert caption={t('error:detailNotFound')} />;
	}

	const link = buildParametrizedRoute<TQueryDetailFilter>(RouteName.DISTRIBUTION_PARTNERS.DETAIL, {
		id: distributionPartner.id,
		distributorId: isGroupPartner ? undefined : distributionPartner.distributorId,
	});

	return (
		<Row multi>
			<Col md="6">
				<DataTableCard caption={t('common:accountInformation')} variant="simple">
					<DataTableTr name={t('common:entity.company')} isLoading={isLoading}>
						{linkable ? <Link to={link}>{distributionPartner.name}</Link> : distributionPartner.name}
					</DataTableTr>
					<DataTableTr name={t('common:contact.taxId')} isLoading={isLoading} noRenderEmpty={hideIncompleteData}>
						{distributionPartner.taxId}
					</DataTableTr>
					<DataTableTr name={t('common:contact.name')} isLoading={isLoading}>
						{getDistributionPartnerFullName(distributionPartner)}
					</DataTableTr>
					<DataTableTr name={t('common:contact.email')} isLoading={isLoading}>
						<Copy2Clipboard value={distributionPartner.contact.email}>
							<EmailDisplay email={distributionPartner.contact.email} />
						</Copy2Clipboard>
					</DataTableTr>
					<DataTableTr name={t('common:contact.website')} isLoading={isLoading} noRenderEmpty={hideIncompleteData}>
						{distributionPartner.website}
					</DataTableTr>
				</DataTableCard>
			</Col>
			<Col md="6">
				<DataTableCard caption={t('common:address.caption')} variant="simple">
					<DataTableTr name={t('common:address.street')} isLoading={isLoading}>
						{distributionPartner.billing.street}
					</DataTableTr>
					<DataTableTr name={t('common:address.city')} isLoading={isLoading}>
						{distributionPartner.billing.city}
					</DataTableTr>
					<DataTableTr name={t('common:address.postalCode')} isLoading={isLoading}>
						{distributionPartner.billing.postalCode}
					</DataTableTr>
					<DataTableTr name={t('common:address.state')} noRenderEmpty isLoading={isLoading}>
						{stateName}
					</DataTableTr>
					<DataTableTr name={t('common:address.country')} isLoading={isLoading}>
						{countryName}
					</DataTableTr>
				</DataTableCard>
			</Col>
		</Row>
	);
};
