import type { IEntityDistributionPartner, IFormDistributionPartner } from 'module/distributionPartners/index';
import { createDistributionPartnerApiError } from 'module/distributionPartners/utils/apiError';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useCallback } from 'react';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { useApiCreateDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { useTranslation } from 'react-i18next';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useInviteToFlexibleBilling } from 'module/flexibleBilling/hooks';

type TCreateDistributionPartner = (values: IFormDistributionPartner) => Promise<IEntityDistributionPartner | null>;

export const useCreateDistributionPartner = (): TCreateDistributionPartner => {
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutateAsync: createDistributionPartner } = useApiCreateDistributionPartner({
		config: { params: authCompanyId ? { distributorId: authCompanyId } : undefined },
	});
	const inviteToFlexibleBilling = useInviteToFlexibleBilling();

	return useCallback(
		async (values) => {
			const response = await createDistributionPartner(values).catch((error) => {
				setError({ error, resolve: createDistributionPartnerApiError });
			});

			if (response) {
				toast.success(<Toast>{t('page.create.success')}</Toast>);

				if (values.flexibleBilling?.create) {
					await inviteToFlexibleBilling(response.data, { email: values.flexibleBilling.differentEmailValue });
				}

				return response.data;
			}

			return null;
		},
		[createDistributionPartner, setError, t, inviteToFlexibleBilling],
	);
};
