import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useCompare } from 'js/hooks/useCompare';
import type { TUseTableSortingState } from 'js/components/molecules/DataTable/useTableSortingState';
import type { PaginationState } from '@tanstack/table-core/src/features/RowPagination';
import type { InitialTableState } from '@tanstack/table-core/src/types';
import type { SortingState } from '@tanstack/react-table';
import type { IUrlFilter } from 'types/filter';
import { object2UrlParams, orderObject, tableSortingStateToSortBy, urlParams2object } from 'js/utils/common';
import { useLocation, useNavigate } from 'react-router';
import { flushSync } from 'react-dom';
import { SortDirectionEnum } from 'js/enums';
import { useUiLocaleContext } from '@avast/react-ui-components';

type TUseTableStateHelperProps<Filter extends object> = {
	initialState: InitialTableState;
	loadFromUrl?: boolean;
	tableFilter?: Filter;
	disabled?: boolean;
};

type TUseTableStateHelper<Data extends object, Filter extends object> = TUseTableSortingState & {
	pagination: PaginationState;
	setPagination: Dispatch<SetStateAction<PaginationState>>;
	filter: IUrlFilter<Filter, Data>;
	locationLoaded: boolean;
};

export const useTableStateHelper = <Data extends object, Filter extends {}>(
	props: TUseTableStateHelperProps<Filter>,
): TUseTableStateHelper<Data, Filter> => {
	const { initialState, loadFromUrl = false, tableFilter = {} as Filter, disabled = false } = props;
	const { table: context } = useUiLocaleContext();
	const navigate = useNavigate();
	const [filter, setFilter] = useState<IUrlFilter<Filter, Data>>({});
	const [locationLoaded, setLocationLoaded] = useState(!loadFromUrl);

	// Pagination
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: context.pageSizeDefault,
		...initialState.pagination,
	});
	const isPaginationChanged = useCompare(pagination, true);

	// Sorting
	const [sorting, setSorting] = useState<SortingState>(initialState.sorting || []);
	const isSortingChanged = useCompare(sorting, true);

	// Load form location
	const location = useLocation();
	useEffect(() => {
		if (!locationLoaded && !disabled) {
			const locationArguments: IUrlFilter<Record<string, unknown>, Data> = urlParams2object(location.search);
			const { page, sortBy, sortDirection = SortDirectionEnum.DESC, limit } = locationArguments;
			flushSync(() => {
				setPagination((values) => ({
					pageIndex: page || values.pageIndex,
					pageSize: limit || values.pageSize,
				}));
				sortBy &&
					setSorting([
						{
							id: sortBy,
							desc: sortDirection === SortDirectionEnum.DESC,
						},
					]);
			});
			setLocationLoaded(true);
		}
	}, [locationLoaded, location.search, disabled]);

	// Update URL
	const _tableFilter = JSON.stringify(orderObject(tableFilter));
	useEffect(() => {
		if (loadFromUrl && locationLoaded) {
			navigate(
				{
					search: object2UrlParams({
						...filter,
						filter: JSON.parse(_tableFilter),
					}),
				},
				{ replace: true },
			);
		}
	}, [filter, navigate, _tableFilter, loadFromUrl, locationLoaded]);

	// On change
	useEffect(() => {
		if (isPaginationChanged || isSortingChanged) {
			const sortBy = tableSortingStateToSortBy<Data>(sorting);
			setFilter({
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				sortBy: sortBy?.key,
				sortDirection: sortBy?.direction,
			});
		}
	}, [pagination, isPaginationChanged, sorting, isSortingChanged]);

	return {
		pagination,
		setPagination,
		sorting,
		setSorting,
		filter,
		locationLoaded,
	};
};
