import { INVALID_PARTNER_STATES } from 'module/security/constants';
import { useLocation } from 'react-router';
import { RouteName } from 'module/RouteName';
import { useMatchLocation } from 'js/hooks/useMatchLocation';
import { useAuthContext } from 'js/contexts';

export const useInvalidPartnerState = () => {
	const authContext = useAuthContext();
	const { pathname } = useLocation();
	const invalidState = INVALID_PARTNER_STATES.find((state) => state.isInvalid(authContext));
	const allowedRoutes = [RouteName.SECURITY.LOGOUT];
	if (!invalidState?.force) {
		allowedRoutes.push(
			RouteName.SECURITY.LOGIN,
			RouteName.SECURITY.SSO,
			RouteName.SECURITY.OMS_SSO,
			RouteName.SECURITY.LOGIN_AS_AFFILIATE,
		);
	}
	const matchLocation = useMatchLocation(allowedRoutes);

	return {
		state: invalidState,
		isCurrentPage: invalidState?.route === pathname || matchLocation,
	};
};
