import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/pt-PT');
const locale: TLocale = 'pt-PT';

export const pt_PT: ILocaleConfig = {
	name: 'Portuguese (Portugal)',
	locale,
	language: 'pt',
	default: true,
	format: {
		numbro,
		datetime: locale,
		datetimeConfig: {
			WEEK_DAY: 'ccccc',
		},
	},
};
