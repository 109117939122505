import { type ReactElement, useMemo } from 'react';
import { H1, Toast } from '@avast/react-ui-components';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { TDetailArguments } from 'types';
import { DistributionPartnerForm } from 'module/distributionPartners/forms/DistributionPartnerForm';
import { RouteName } from 'module/RouteName';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import {
	useApiDistributionPartner,
	useApiUpdateDistributionPartner,
} from 'module/distributionPartners/hooks/useApiDistributionPartners';
import type { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import type { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { Headline } from 'js/components/molecules/Headline';
import { LinkButton } from 'js/components/atoms/Button';
import { buildParametrizedRoute } from 'js/utils/common';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { updateDistributionPartnerApiError } from 'module/distributionPartners/utils/apiError';
import { distributionPartnerFormNormalizer } from 'module/distributionPartners/normalizer';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { useApiErrorContext, useAuthContext, useOrderContext } from 'js/contexts';
import { isObject } from 'lodash';

type TData = IEntityDistributionPartner;
type TArguments = TDetailArguments;
type TQueryFilter = IDistributionPartnerSearchFilter;
type TQueryProps = TUseApiDetailProps<TData, TQueryFilter>;

export const PageUpdate = (): ReactElement => {
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { orderState, setDistributionPartner } = useOrderContext();
	const { setError } = useApiErrorContext();
	const params = useRouteParams<TArguments>();
	const navigate = useNavigate();
	const { mutate: updateDistributionPartner } = useApiUpdateDistributionPartner({
		config: { params: { distributorId: authCompanyId!, salesforceId: params.id } },
	});

	/**
	 * Setup query props/filter
	 * @type {TQueryProps}
	 */
	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: {
				...params,
				distributorId: authCompanyId,
			},
		}),
		[params, authCompanyId],
	);

	return (
		<PageDetailComponent<TData, TQueryFilter>
			query={useApiDistributionPartner}
			queryProps={queryProps}
			trNamespace={distributionPartnersConfig.trNamespace}
			titleRender={getDistributionPartnerRenderName}
			detailLink={buildParametrizedRoute(RouteName.DISTRIBUTION_PARTNERS.UPDATE, params.id)}
		>
			{({ data }) => {
				const detailLink = distributionPartnersConfig.detailLink(data);
				return (
					<>
						<Headline
							className="align-items-center"
							rightContent={
								<LinkButton size="sm" variant="outline-primary" to={detailLink} iconLeftFa={faChevronLeft}>
									{t('common:actions.back')}
								</LinkButton>
							}
						>
							<H1 type="h2">{data.name}</H1>
						</Headline>

						<div className="section__content mt-3">
							<DistributionPartnerForm
								initialValues={distributionPartnerFormNormalizer.normalize(data)}
								onSubmit={(values, { setSubmitting }) => {
									updateDistributionPartner(values, {
										onSuccess(response) {
											setSubmitting(false);
											// Redirect
											if (isObject(response?.data)) {
												// Update in order instance
												if (orderState.distributionPartner?.id === response.data.id) {
													setDistributionPartner(response.data);
												}

												toast.success(<Toast>{t('page.update.success')}</Toast>);
												navigate(detailLink);
											}
										},
										onError(error) {
											setError({ error, resolve: updateDistributionPartnerApiError });
											setSubmitting(false);
										},
									});
								}}
								onCancel={() => {
									navigate(detailLink);
								}}
							/>
						</div>
					</>
				);
			}}
		</PageDetailComponent>
	);
};
