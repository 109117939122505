import type { IEntityDistributionPartner } from 'module/distributionPartners';
import { joinValues } from 'js/utils/common';

export const getDistributionPartnerFullName = (distributionPartner: IEntityDistributionPartner | null): string => {
	if (!distributionPartner) {
		return '';
	}

	return joinValues([distributionPartner.contact.firstName, distributionPartner.contact.lastName], ' ');
};

export const getDistributionPartnerRenderName = (
	distributionPartner: IEntityDistributionPartner | null | undefined,
): string => {
	if (!distributionPartner) {
		return '';
	}

	return distributionPartner.name || getDistributionPartnerFullName(distributionPartner);
};
