import { filterPostValues } from 'js/utils/form';
import { isCustomerConsumer } from 'module/customers/utils/customerSelectors';
import type { ICustomerForm } from 'module/customers/index';
import { omit } from 'lodash';

export const customerFormNormalizer = {
	denormalize(values: ICustomerForm): ICustomerForm {
		let result = filterPostValues(values);
		result = omit(result, ['partnerId', 'currency']);
		if (isCustomerConsumer(result)) {
			result = omit(result, ['companyName', 'requestedDiscountType', 'industry']);
		}
		return result;
	},
};
