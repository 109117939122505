import { Col, Row } from 'js/components/atoms/Row';
import { ButtonComposition } from '@avast/react-ui-components';
import {
	LicenseOperationButton,
	LicenseRegistrationButton,
	LinkToLicenseDetail,
} from 'module/licenses/components/buttons';
import { ButtonGroup } from 'react-bootstrap';
import type { IEntityLicense } from 'module/licenses';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { getFollowingLicenseId, getPreviousLicenseId } from 'module/licenses/utils/common';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { AsyncLicenseOperationContainer } from 'module/licenses/components/licenseOperation';
import { useAsyncContainerRef } from 'js/components/molecules/Modal/AsyncContainer';
import type { TAsyncLicenseOperationContainerProps } from 'module/licenses/components/licenseOperation/AsyncLicenseOperationContainer';
import { CancelOrderLicensesButton } from 'module/orders/components/buttons';
import { useLicenseIsCancellable } from 'module/licenses/hooks';
import { useAuthContext } from 'js/contexts';

export const LicenseDetailHeader = () => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { isAuthorizedBuyer } = useAuthContext();
	const { data: license, query } = usePageDetailContext<IEntityLicense>();
	const previousId = getPreviousLicenseId(license);
	const followingId = getFollowingLicenseId(license);

	const licenseOperationRef = useAsyncContainerRef<TAsyncLicenseOperationContainerProps>();
	const { isCancellable, licenseOrder } = useLicenseIsCancellable(license);

	return (
		<>
			<Row multi justify="between">
				<Col xs="auto">
					<ButtonComposition size="sm">
						<LicenseOperationButton license={license} onClick={() => licenseOperationRef.current?.show({ license })} />
						<LicenseRegistrationButton license={license} />
						<CancelOrderLicensesButton
							hidden={!isCancellable}
							order={licenseOrder}
							entitlementIds={[license.id]}
							onCancel={() => query.refetch()}
						/>
					</ButtonComposition>
				</Col>
				<Col xs="auto">
					<ButtonGroup size="sm">
						<LinkToLicenseDetail
							licenseId={previousId}
							text={t('actions.precedingLicense')}
							testId="precedingLicense"
						/>
						<LinkToLicenseDetail
							licenseId={followingId}
							text={t('actions.followingLicense')}
							testId="followingLicense"
						/>
					</ButtonGroup>
				</Col>
			</Row>

			{isAuthorizedBuyer && <AsyncLicenseOperationContainer forwardedRef={licenseOperationRef} />}
		</>
	);
};
