import type { ILocaleConfig, TLocale } from 'types/config';

const locale: TLocale = 'en-US';

export const en_US: ILocaleConfig = {
	name: 'English (United States)',
	locale,
	language: 'en',
	default: true,
	format: {
		numbro: locale,
		datetime: locale,
	},
};
