import type { IEntityOrder } from 'module/orders';
import type { TOrderParties, TOrderPriceList } from 'module/orders/hooks/orderToInstance';
import { isIspOrder } from 'module/orders/utils/common';
import { useApiPartnerPriceLists } from 'js/priceList/useApiPartnerPriceLists';
import { getDefaultPriceListCode } from 'js/priceList/priceListUtils';
import { PRICE_LIST_CODE_DEFAULT } from 'js/priceList/priceListConfig';

export const useOrderPriceList = (order: IEntityOrder | null, orderParties: TOrderParties): TOrderPriceList => {
	const hasOrderPriceList = Boolean(order?.priceListCode);
	const isEntityIspOrder = isIspOrder(order);
	const arePartiesLoaded = !orderParties.isFetching;
	const isPriceListQueryEnabled =
		!hasOrderPriceList && !isEntityIspOrder && arePartiesLoaded && Boolean(orderParties.partner?.id);

	// Partner price list query
	const { data: priceLists, query: priceListsQuery } = useApiPartnerPriceLists({
		filter: { partnerId: orderParties.partner?.id },
		queryConfig: { enabled: isPriceListQueryEnabled },
	});

	// Price list from the order
	if (order?.priceListCode) {
		return { code: order.priceListCode, isFetching: false };
	}

	// ISP orders
	if (isEntityIspOrder) {
		return { code: 'ISP_OMS', isFetching: false };
	}

	// Get by order partner
	if (isPriceListQueryEnabled) {
		return {
			code: getDefaultPriceListCode(priceLists),
			isFetching: priceListsQuery.isFetching,
		};
	}

	// Not determinate -> return default (eg. for end customer orders)
	return { code: PRICE_LIST_CODE_DEFAULT, isFetching: !arePartiesLoaded };
};
