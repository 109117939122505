import { useTranslation } from 'react-i18next';
import { Alert } from '@avast/react-ui-components';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TCreditExceedAlertProps = {
	className?: string;
};

export const CreditExceedAlert = (props: TCreditExceedAlertProps) => {
	const { className } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return (
		<Alert variant="warning" caption={t('error.creditExceed.title')} className={className}>
			{t('error.creditExceed.text')}
		</Alert>
	);
};
