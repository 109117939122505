import type { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityOrder } from 'module/orders';
import { CustomerDetail } from 'module/customers/components';
import { useIsCustomerDetailLinkable } from 'module/customers/hooks';

export const CustomerTab = (): ReactElement => {
	const {
		data: { customer, partner },
	} = usePageDetailContext<IEntityOrder>();
	const isLinkable = useIsCustomerDetailLinkable(customer, partner);

	return (
		<DefaultContainer>
			<CustomerDetail
				customer={customer}
				partnerId={partner?.id}
				linkable={isLinkable.result}
				hideIncompleteData
				isLoading={isLinkable.isPending}
			/>
		</DefaultContainer>
	);
};
