import { useFormikContext } from 'formik';
import type { IPricingRequest, IPricingRequestHeader, IPricingRequestItem, IPricingResponse } from 'module/purchase';
import { useEffect, useMemo, useState } from 'react';
import type { ILicenseOperationForm } from 'module/licenses';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useCompare } from 'js/hooks/useCompare';
import { useApiPricing } from 'module/purchase/hooks/useApiPurchase';
import { pricingApiError } from 'module/purchase/utils/apiError';
import { useDebouncedCallback } from 'use-debounce';
import type { TPriceListCode } from 'js/priceList';
import { useApiErrorContext } from 'js/contexts';

export type TUsePricingResponse = {
	pricing: IPricingResponse | null;
	isPricingLoading: boolean;
};

export type TUsePricingProps = {
	lineItems: IPricingRequestItem[];
	onChange?: (data: IPricingResponse | null) => void;
	debounceWait?: number;
	disabled?: boolean;
	priceListCode?: TPriceListCode;
};

/**
 * Set pricing request data, use debounce to delay data delivery
 *
 * @param {IPricingRequestItem[]} lineItems
 * @param {number} debounceWait
 * @returns {IPricingRequest | null}
 */
const usePricingRequest = (lineItems: IPricingRequestItem[], debounceWait = 500): IPricingRequest | null => {
	const { priceListCode } = useLicenseOperationContext();
	const {
		values: { licenseBillableParty },
	} = useFormikContext<ILicenseOperationForm>();
	const [requestData, setRequestData] = useState<IPricingRequest | null>(null);
	const updateRequestData = useDebouncedCallback(setRequestData, debounceWait);

	// Prepare data
	const data: IPricingRequest | null = useMemo(() => {
		if (
			!licenseBillableParty?.billablePartyType ||
			!licenseBillableParty?.countryCode ||
			!licenseBillableParty?.currencyCode ||
			!priceListCode
		) {
			return null;
		}

		const header: IPricingRequestHeader = {
			product_pricelist_currency_code: licenseBillableParty.currencyCode,
			bill_to_country: licenseBillableParty.countryCode,
			billablePartyID: licenseBillableParty.partnerId,
			billablePartyType: licenseBillableParty.billablePartyType,
			applyPromoPricing: 'Yes',
			priceRequestSource: 'scUIOrder',
			priceListCode,
		};

		return {
			request_header: header,
			request_lineitems: lineItems.filter((item) => item.unitsToPrice),
		} as IPricingRequest;
	}, [lineItems, licenseBillableParty, priceListCode]);

	// Watch changes
	const dataChanged = useCompare<IPricingRequest | null>(data, true);

	useEffect(() => {
		if (dataChanged) {
			updateRequestData(data);
		}
	}, [dataChanged, data, updateRequestData]);

	return requestData;
};

/**
 * Create an object of license operation pricing header
 * @param {TUsePricingProps} props
 * @returns {IPricingRequestHeader | null}
 */
export const useLicenseOperationPricing = (props: TUsePricingProps): TUsePricingResponse => {
	const { lineItems, onChange, debounceWait } = props;
	const requestData = usePricingRequest(lineItems, debounceWait);
	const requestDataChanged = useCompare<IPricingRequest | null>(requestData, true);
	const [pricing, setPricing] = useState<TUsePricingResponse['pricing']>(null);
	const { setError } = useApiErrorContext();

	// Prepare API
	const { isPending, mutate } = useApiPricing();

	useEffect(() => {
		if (requestDataChanged && requestData) {
			if (requestData.request_lineitems.length === 0) {
				setPricing(null);
			} else {
				mutate(requestData, {
					onSuccess({ data }) {
						setPricing(data);
					},
					onError(error) {
						setError({ error, resolve: pricingApiError });
						setPricing(null);
					},
				});
			}
		}
	}, [requestDataChanged, requestData, mutate, setError]);

	useEffect(() => {
		if (onChange) {
			onChange(pricing);
		}
	}, [onChange, pricing]);

	return {
		pricing,
		isPricingLoading: isPending,
	};
};
