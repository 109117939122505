import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { PartnerName } from 'module/partners/components';
import { skuListFormatter } from 'module/promotions/utils/common';
import { promotionStateEnumUtils } from 'module/promotions/enums';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IEntityPromotion } from 'module/promotions';
import type { TableColumnsDef } from 'types';

type TTableData = IEntityPromotion;

export const usePromotionListColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(promotionsConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.link('id', { header: t('common:entity.id') }, { to: promotionsConfig.detailLink }),
			columnHelper.ellipsis('name', {
				header: t('common:entity.name'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.percentage('rate', { header: t('entity.rate') }, {}),
			columnHelper.text('partnerId', {
				header: t('common:entity.partner'),
				enableSorting: false,
				meta: { formatters: [(partnerId) => <PartnerName partnerId={partnerId} />] },
			}),
			columnHelper.date('startDate', { header: t('common:dates.start') }),
			columnHelper.date('endDate', { header: t('common:dates.end') }),
			columnHelper.text('skuList', {
				header: t('entity.product'),
				enableSorting: false,
				meta: { formatters: [skuListFormatter] },
			}),
			columnHelper.text('state', {
				header: t('common:entity.status'),
				meta: {
					formatters: [
						(state) => (
							<span className={`text-${promotionStateEnumUtils.getColorVariant(state)} fw-bold`}>
								{promotionStateEnumUtils.getText(state)}
							</span>
						),
					],
				},
			}),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: promotionsConfig.detailLink,
				},
			}),
		];
	}, [t]);
};
