import { Route, Routes } from 'react-router';
import { RegistrationLayout } from 'module/registration/layouts/RegistrationLayout';
import { PageDefault, PageTerms } from 'module/registration/pages';
import { TermsLayout } from 'module/registration/layouts/TermsLayout';
import { Error404 } from 'module/error/page/Error404';

export const RegistrationRoutes = () => (
	<Routes>
		<Route element={<RegistrationLayout />}>
			<Route index element={<PageDefault />} />
		</Route>
		<Route element={<TermsLayout />}>
			<Route path="terms" element={<PageTerms />} />
		</Route>
		<Route path="*" element={<Error404 />} />
	</Routes>
);
