import { type ReactElement, useState } from 'react';
import { useCountrySelectOptions } from 'js/entities/country/useCountrySelectOptions';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useFormikContext } from 'formik';
import type { TEntityKey } from 'types';
import { get } from 'lodash';
import { useCountryStatesOptions } from 'js/entities/country/useCountryStatesOptions';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import type { TUseApiCountryListModule } from 'js/entities/country/useApiCountry';
import classNames from 'classnames';
import type { FormControlProps } from 'react-bootstrap';

type TFormikCountrySelectProps<Keys> = {
	countryField: Keys;
	countryStateField: Keys;
	required?: boolean;
	disabled?: boolean;
	disableGrid?: boolean;
	size?: FormControlProps['size'];
	customQuery?: TUseApiCountryListModule;
};

/**
 * Formik fields for select country and state
 *
 * @param {TFormikCountrySelectProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const FormikCountrySelect = <Data extends {}, Keys extends string = TEntityKey<Data>>(
	props: TFormikCountrySelectProps<Keys>,
): ReactElement => {
	const { countryField, countryStateField, required, disabled, disableGrid, size, customQuery } = props;
	const { countrySelectOptions, isFetching: isCountryFetching } = useCountrySelectOptions({ customQuery });
	const { setFieldValue, initialValues } = useFormikContext<Data>();
	const gridSize = disableGrid ? 12 : 6;
	const { t } = useTranslation('common');

	// States
	const [countryValue, setCountryValue] = useState<string | undefined>(
		get(initialValues, countryField, undefined) as string | undefined,
	);
	const {
		countryStatesOptions,
		hasCountryStates,
		isFetching: isCountryStatesFetching,
	} = useCountryStatesOptions(countryValue);

	return (
		<Row size="md" className={classNames({ 'g-0': Boolean(disableGrid) })}>
			<Col sm={gridSize}>
				<FormikControl label={t('address.country')}>
					<FormikControl.SingleSelect
						name={countryField}
						disabled={disabled}
						required={required}
						options={countrySelectOptions}
						isLoading={isCountryFetching}
						onChange={(value) => {
							setFieldValue(countryStateField, '', true);
							setCountryValue(value as string);
						}}
						filterOption={createFilter({ matchFrom: 'start' })}
						size={size}
					/>
				</FormikControl>
			</Col>
			{hasCountryStates && (
				<Col sm={gridSize}>
					<FormikControl label={t('address.state')}>
						<FormikControl.SingleSelect
							name={countryStateField}
							required={required}
							disabled={disabled}
							options={countryStatesOptions}
							filterOption={createFilter({ matchFrom: 'start' })}
							isLoading={isCountryStatesFetching}
							size={size}
						/>
					</FormikControl>
				</Col>
			)}
		</Row>
	);
};
