import React, { createContext, type PropsWithChildren, useCallback, useState } from 'react';
import { AsyncModalLoading, type TAsyncModalLoadingProps } from 'js/components/molecules/Modal/AsyncModalLoading';
import { type TAsyncModalRef, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncConfirmationModal,
	type TAsyncConfirmationModalProps,
} from 'js/components/molecules/Modal/AsyncConfirmationModal';
import { AsyncSuccessModal, type TAsyncSuccessModalProps } from 'js/components/molecules/Modal/AsyncSuccessModal';
import { AsyncHelpModal } from 'js/help/AsyncHelpModal';
import { useAppVersionGuard } from 'js/hooks/useAppVersionGuard';
import { type TUsePrevLocationListener, usePrevLocationListener } from 'js/hooks/usePrevLocationListener';
import { AsyncApiErrorModal, type TAsyncApiErrorModalProps } from 'js/components/molecules/Modal/AsyncApiErrorModal';
import { useSidebarCollapsed } from 'js/hooks/useSidebarCollapsed';
import type { TUseStorageToggle } from 'js/hooks/useStorageToggle';
import type { IUiLocaleContext } from '@avast/react-ui-components';
import { UiLocaleContextProvider } from '@avast/react-ui-components';
import { getUiLocaleContext } from 'js/utils/locale';
import { GtmListener } from 'js/analytics/GtmListener';
import type { TLocale } from 'types/config';
import { CustomEventEnum, useCustomEventListener } from 'js/events';
import invariant from 'invariant';

interface IAppContext {
	sidebarCollapsed: TUseStorageToggle;
	loadingModalRef: TAsyncModalRef<TAsyncModalLoadingProps>;
	confirmationModalRef: TAsyncModalRef<TAsyncConfirmationModalProps>;
	successModalRef: TAsyncModalRef<TAsyncSuccessModalProps>;
	apiErrorModalRef: TAsyncModalRef<TAsyncApiErrorModalProps>;
	helpModalRef: TAsyncModalRef;
	prevLocationListener: TUsePrevLocationListener;
	refreshUILocaleContext: (locale?: TLocale) => void;
}

const AppContext = createContext<IAppContext | null>(null);
AppContext.displayName = 'AppContext';

/**
 * Hook to get app context
 */
export const useAppContext = () => {
	const context = React.useContext(AppContext);

	invariant(
		context !== null,
		'App context is undefined, please verify you are calling useAppContext() as child of a <AppContextProvider> component.',
	);

	return context;
};

export const AppContextProvider = ({ children }: PropsWithChildren) => {
	const sidebarCollapsed = useSidebarCollapsed();
	const [uiLocaleContextValue, setUILocaleContextValue] = useState<IUiLocaleContext>(getUiLocaleContext());

	const loadingModalRef = useAsyncModalRef<TAsyncModalLoadingProps>();
	const confirmationModalRef = useAsyncModalRef<TAsyncConfirmationModalProps>();
	const successModalRef = useAsyncModalRef<TAsyncSuccessModalProps>();
	const apiErrorModalRef = useAsyncModalRef<TAsyncApiErrorModalProps>();
	const helpModalRef = useAsyncModalRef();

	const prevLocationListener = usePrevLocationListener();
	useAppVersionGuard();

	const closeModalsHandler = useCallback(() => {
		loadingModalRef.current?.hide();
		confirmationModalRef.current?.hide();
		successModalRef.current?.hide();
		apiErrorModalRef.current?.hide();
		helpModalRef.current?.hide();
	}, [loadingModalRef, confirmationModalRef, successModalRef, apiErrorModalRef, helpModalRef]);

	useCustomEventListener(CustomEventEnum.CLOSE_MODALS, closeModalsHandler);

	return (
		<UiLocaleContextProvider {...uiLocaleContextValue}>
			<GtmListener />
			<AppContext.Provider
				value={{
					sidebarCollapsed,
					prevLocationListener,
					loadingModalRef,
					confirmationModalRef,
					successModalRef,
					apiErrorModalRef,
					helpModalRef,
					refreshUILocaleContext(locale) {
						setUILocaleContextValue(getUiLocaleContext(locale));
					},
				}}
			>
				{children}
				<AsyncModalLoading forwardedRef={loadingModalRef} />
				<AsyncConfirmationModal forwardedRef={confirmationModalRef} />
				<AsyncSuccessModal forwardedRef={successModalRef} />
				<AsyncApiErrorModal forwardedRef={apiErrorModalRef} />
				<AsyncHelpModal forwardedRef={helpModalRef} />
			</AppContext.Provider>
		</UiLocaleContextProvider>
	);
};
