import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityCustomer } from 'module/customers';
import { Headline } from 'js/components/molecules/Headline';
import { H1 } from '@avast/react-ui-components';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { CustomerDetail } from 'module/customers/components/CustomerDetail';
import type { TPartnerId } from 'types';
import { DetailViewActions } from './DetailViewActions';

type TDetailContentProps = {
	partnerId?: TPartnerId;
};

export const DetailContent = (props: TDetailContentProps) => {
	const {
		data: customer,
		query: { isFetching },
	} = usePageDetailContext<IEntityCustomer>();

	return (
		<>
			<Headline className="align-items-center" rightContent={<DetailViewActions partnerId={props.partnerId} />}>
				<H1 type="h2">{getCustomerRenderName(customer)}</H1>
			</Headline>

			<CustomerDetail customer={customer} partnerId={props.partnerId} isLoading={isFetching} />
		</>
	);
};
