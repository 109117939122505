import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { Alert, Card, CardBody, CardHeader, StatusText, type TCardProps } from '@avast/react-ui-components';
import { useApiLegacyPaymentOptionList } from 'module/account/hooks/useApiPaymentOption';
import { CreditCardPreviewTable } from 'module/account/components/autoBilling/CreditCardPreviewTable';
import { LegacyPaymentOptionPreviewRow } from 'module/account/components/LegacyPaymentOptionPreviewRow';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useAuthContext } from 'js/contexts';

export const LegacyPaymentOptionsCard = (props: Partial<TCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const { data: options, query } = useApiLegacyPaymentOptionList({ filter: { affiliateId: company?.id } });

	const hasError = query.isError || (!query.isFetching && !options);

	return (
		<Card {...props}>
			<CardHeader>{t('cards.legacyPaymentOptions')}</CardHeader>
			<CardBody>
				{(() => {
					if (query.isFetching) {
						return <LoadingPlaceholder minHeight={false} className="py-3" />;
					}

					if (hasError) {
						return <Alert variant="warning" caption={t('legacyPaymentOptions.unableToLoad')} />;
					}

					if (options.length === 0) {
						return (
							<StatusText minHeight={false} className="py-3">
								{t('legacyPaymentOptions.noStoredCards')}
							</StatusText>
						);
					}

					return (
						<CreditCardPreviewTable>
							{options.map((option) => (
								<LegacyPaymentOptionPreviewRow key={option.id} paymentOption={option} onDelete={query.refetch} />
							))}
						</CreditCardPreviewTable>
					);
				})()}
			</CardBody>
		</Card>
	);
};
