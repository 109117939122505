import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import type { IEntityProduct, IEntityProductGroup } from 'module/purchase';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import type { TableColumnsDef } from 'types';
import { constant } from 'lodash';
import { useGenerateRetailCodesMarketSegments } from 'module/retail/hooks/useGenerateRetailCodesMarketSegments';
import { noValuePlaceholder } from 'js/utils/app';
import { marketSegmentEnumUtils } from 'js/enums';

type TTableData = IEntityProductGroup;

export const useSelectProductsColumns = (onClick: (row: TTableData) => void): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const marketSegments = useGenerateRetailCodesMarketSegments();
	const hasMultiMarketSegments = marketSegments.length > 1;

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		const columns = [
			columnHelper.text('code', { header: t('common:entity.sku') }),
			columnHelper.text('name', {
				header: t('common:entity.productName'),
				meta: { formatters: [formatter.bold] },
			}),
		];

		if (hasMultiMarketSegments) {
			columns.push(
				columnHelper.text('marketSegment', {
					header: t('entity.marketSegment'),
					meta: {
						defaultValue: { value: noValuePlaceholder() },
						formatters: [marketSegmentEnumUtils.getText],
					},
				}),
			);
		}

		columns.push(
			columnHelper.text('products', {
				id: 'products',
				header: constant(null),
				enableSorting: false,
				meta: {
					formatters: [
						(products: IEntityProduct[]) => (
							<em className="text-gray">{t('common:entity.variantCount', { count: products.length })}</em>
						),
					],
				},
			}),
			columnHelper.actions({
				button: {
					children: t('table.action.selectVariant'),
					onClick,
					testId: 'selectVariant',
				},
			}),
		);

		return columns;
	}, [t, onClick, hasMultiMarketSegments]);
};
