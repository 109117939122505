import type { ReactElement } from 'react';
import { H3, Modal } from '@avast/react-ui-components';
import { RouteName } from 'module/RouteName';
import { CheckCircleAnimated } from 'assets/image/icon';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { AsyncModal, type TAsyncModalContainerProps, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IEntityOrder } from 'module/orders';
import {
	getPaymentTypeTranslationKey,
	getPostSubmitActions,
	postSubmitActionsToAdditionalButtons,
} from 'module/purchase/utils/common';
import type { TPaymentTypeAction } from 'module/purchase';
import { PaymentTypeTranslationKeyEnum } from 'module/purchase/enums';
import type { NavigateLink } from 'types';

export type TAsyncSuccessModalOrderProps = {
	order: IEntityOrder;
	action: TPaymentTypeAction;
};

export type TAsyncSuccessModalOrderResult = string | NavigateLink;

export const useAsyncSuccessOrderModalRef = () =>
	useAsyncModalRef<TAsyncSuccessModalOrderProps, TAsyncSuccessModalOrderResult>();

export const AsyncSuccessOrderModal = (
	props: TAsyncModalContainerProps<TAsyncSuccessModalOrderProps, TAsyncSuccessModalOrderResult>,
): ReactElement | null => {
	const { forwardedRef } = props;
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return (
		<AsyncModal<TAsyncSuccessModalOrderProps, TAsyncSuccessModalOrderResult>
			ref={forwardedRef}
			backdrop="static"
			testId="successOrder"
		>
			{({ order, action }) => (
				<>
					<Modal.Header className="pb-0">&nbsp;</Modal.Header>
					<Modal.Body className="text-center py-md-9">
						<p>
							<CheckCircleAnimated />
						</p>
						<H3 bold variant="success" className="mb-3">
							{t('common:_.success')}
						</H3>
						<p>
							{t(getPaymentTypeTranslationKey(action, PaymentTypeTranslationKeyEnum.SUCCESS_MESSAGE))}
							<br />
							{t(ordersConfig.trPrefix('entity.orderNumber'))}: <strong>#{order.id}</strong>
						</p>
					</Modal.Body>
					<Modal.Footer
						cancelButton={{
							children: t('components.successOrder.action.NEW_ORDER'),
							onClick() {
								forwardedRef.current?.onSuccess(RouteName.PURCHASE.DEFAULT);
							},
						}}
						additionalButtons={postSubmitActionsToAdditionalButtons(
							getPostSubmitActions(order, action, t),
							(action) => {
								forwardedRef.current?.onSuccess(action.link);
							},
						)}
					/>
				</>
			)}
		</AsyncModal>
	);
};
