import { type ReactElement, useMemo } from 'react';
import type { TDetailArguments } from 'types';
import { RouteName } from 'module/RouteName';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useApiLicense } from 'module/licenses/hooks';
import type { IEntityLicense } from 'module/licenses';
import { LicenseDetail } from 'module/licenses/components';
import { buildParametrizedRoute } from 'js/utils/common';
import { useTranslation } from 'react-i18next';
import { Alert } from '@avast/react-ui-components';
import { isUndefined, partial } from 'lodash';
import { RefetchButton } from 'js/components/atoms/Button/RefetchButton';
import type { QueryObserverBaseResult } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { IAxiosApiError } from 'types/api';
import type { TFunction } from 'i18next';
import { showEntitlementId } from 'module/licenses/utils/common';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TData = IEntityLicense;
type TArguments = TDetailArguments;

type TErrorContent = (
	t: TFunction,
	id: string,
	refetch: QueryObserverBaseResult<AxiosResponse<object>, IAxiosApiError>['refetch'],
	status?: number,
) => ReactElement;

export const PageDetail = (): ReactElement | null => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const params = useRouteParams<TArguments>();
	const queryProps = useMemo(() => ({ filter: params }), [params]);

	if (isUndefined(params.id)) {
		return null;
	}

	return (
		<PageDetailComponent<TData>
			query={useApiLicense}
			trNamespace={licensesConfig.trNamespace}
			queryProps={queryProps}
			detailLink={buildParametrizedRoute(RouteName.LICENSE.DETAIL, params.id)}
			getErrorContent={partial(getErrorContent, t, params.id)}
			titleRender={() => t('common:_.pageDetailTitle', { id: showEntitlementId(params.id!) })}
		>
			<LicenseDetail />
		</PageDetailComponent>
	);
};

const getErrorContent: TErrorContent = (t, id, refetch, status): ReactElement => {
	switch (status) {
		case 404:
			return <Alert variant="warning" caption={t('error.detailNotFound', { id })} />;
		default:
			return (
				<>
					<Alert variant="danger" caption={t('components:table.error')} />
					<RefetchButton refetch={refetch} />
				</>
			);
	}
};
