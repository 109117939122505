import type { IEntityRefund } from 'submodule/refunds';
import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { refundReasonEnumUtils, refundStatusEnumUtils, refundTypeEnumUtils } from 'submodule/refunds/enums';
import { noValuePlaceholder } from 'js/utils/app';
import { PaymentTypeEnum } from 'js/enums';
import { useTranslation } from 'react-i18next';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityOrder } from 'module/orders';

type TTableData = IEntityRefund;

export const useRefundListColumns = (): TableColumnsDef<TTableData> => {
	const { t } = useTranslation('submodules');
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const { currency, payment } = order;

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('refundId', { header: t('common:entity.id') }),
			columnHelper.text('refundItemId', { header: t('refunds.entity.itemId') }),
			columnHelper.date('refundDate', {
				header: t('refunds.entity.date'),
				meta: { defaultValue: { value: noValuePlaceholder() } },
			}),
			columnHelper.text('orderLineItemId', { header: t('refunds.entity.orderLineItemId') }),
			columnHelper.text('refundReason', {
				header: t('refunds.entity.reason'),
				meta: { formatters: [refundReasonEnumUtils.getText] },
			}),
			columnHelper.text('refundType', {
				header: t('refunds.entity.type'),
				meta: { formatters: [refundTypeEnumUtils.getText] },
			}),
			columnHelper.ellipsis('productDescription', {
				header: t('common:entity.product'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('productSku', { header: t('common:entity.sku') }),
			columnHelper.text('quantity', { header: t('common:entity.quantity') }),
			columnHelper.copy2clipboard('licenseKey', {
				header: t('common:entity.licenseKey'),
				meta: { hidden: payment?.type === PaymentTypeEnum.LOC },
			}),
			columnHelper.text('refundStatus', {
				header: t('common:entity.status'),
				meta: { formatters: [refundStatusEnumUtils.getText, formatter.bold] },
			}),
			columnHelper.date('refundInitiatedDate', { header: t('refunds.entity.initiatedDate') }),
			columnHelper.money('refundAmount', { header: t('common:entity.amount') }, { currency }),
			columnHelper.money('refundTax', { header: t('common:entity.tax') }, { currency }),
			columnHelper.money(
				'refundAmountWithTax',
				{
					header: t('common:entity.totalWTax'),
					meta: { formatters: [formatter.bold] },
				},
				{ currency },
			),
		];
	}, [t, currency, payment]);
};
