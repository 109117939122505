import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { IEntityPromotion } from 'module/promotions';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { PromotionPartyTypeEnum, promotionPartyTypeEnumUtils } from 'module/promotions/enums';
import { DetailList } from 'module/promotions/components/DetailList';

export const PartyTypesRow = () => {
	const { data: promotion } = usePageDetailContext<IEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const values = promotion.partnerTypeList?.map(promotionPartyTypeEnumUtils.getText) || [];

	return (
		<DataTableTr name={t('entity.partyType', { count: values.length })}>
			<DetailList
				title={t('entity.partyType', { count: values.length })}
				defaultValue={t('placeholder.partyTypes')}
				values={values}
				allValues={Object.values(PromotionPartyTypeEnum)}
			/>
		</DataTableTr>
	);
};
