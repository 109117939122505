import type { FunctionComponent, ReactElement } from 'react';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { H1 } from '@avast/react-ui-components';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import error404Image from 'assets/image/error404.png';

/**
 * Represents 404 Error function component.
 *
 * @returns {React.ReactElement}
 * @constructor
 */
export const Error404: FunctionComponent = (): ReactElement => {
	const { t } = useTranslation('error');

	return (
		<div className="verticalCenter-layout min-h-100 text-center py-8">
			<H1 bold>{t('page.404.title')}</H1>
			<p className="text-danger mt-1 mb-0">{t('page.404.message')}</p>
			<p className="mt-8 mb-0">
				<LinkButton variant="danger" to={RouteName.HOMEPAGE.DEFAULT}>
					{t('page.404.back')}
				</LinkButton>
			</p>
			<div className="mt-8">
				<FixedWidthWrapper width={400} max>
					<img src={error404Image} alt={t('page.404.title')} className="img-fluid" />
				</FixedWidthWrapper>
			</div>
		</div>
	);
};
