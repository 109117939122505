import * as Yup from 'yup';
import type { TWidget, TWidgetColumn, TWidgetConfig } from 'submodule/widgets/index';
import { difference } from 'lodash';
import { getAvailableColumnsKeys, validateWidgetSettingsFields } from 'submodule/widgets/utils';
import type { WidgetTypeEnum } from 'submodule/widgets/enums';

export const getWidgetValidator = (allowedWidgets: TWidgetConfig[]) => {
	const allowedTypes = allowedWidgets.map((widget) => widget.type);

	return Yup.object().shape({
		type: Yup.mixed<WidgetTypeEnum>().required().oneOf(allowedTypes),
		label: Yup.string().notRequired(),
		settings: Yup.object()
			.required()
			.test({
				message: 'Invalid settings value',
				test(settings) {
					const { type } = this.parent as TWidget;
					const widgetConfig = allowedWidgets.find((item) => item.type === type);

					if (!widgetConfig) {
						return false;
					}

					const invalidSettings = difference(
						Object.keys(settings as TWidget['settings']),
						Object.keys(widgetConfig.getSettingsFields()),
					);

					if (invalidSettings.length) {
						return false;
					}

					const invalidFields = validateWidgetSettingsFields(settings, widgetConfig);

					return !invalidFields.length;
				},
			}),
		columns: Yup.array<TWidgetColumn>()
			.notRequired()
			.test({
				message: 'Invalid columns value',
				test(columns) {
					const { type } = this.parent as TWidget;
					const widgetConfig = allowedWidgets.find((item) => item.type === type);

					if (!widgetConfig) {
						return false;
					}

					// Validate columns
					if (columns) {
						const invalidColumns = difference(columns as TWidgetColumn[], getAvailableColumnsKeys(widgetConfig));
						if (invalidColumns.length) {
							return false;
						}
					}

					return true;
				},
			}),
	});
};
