import type { IEnumValidators, TEnumType } from 'js/enums/generator';

export const enumValidators = <T extends string>(enumValues: TEnumType<T>): IEnumValidators<T> => {
	const validateValues: IEnumValidators<T>['validateValues'] = (values) => {
		const _values = values.filter((item) => validateValue(item));
		return _values.length ? _values : null;
	};

	const validateValue: IEnumValidators<T>['validateValue'] = (value) =>
		Object.values(enumValues).includes(value) ? value : null;

	const validateOneOf: IEnumValidators<T>['validateOneOf'] = (value, allowedValues) =>
		value ? allowedValues.includes(value) : false;

	return {
		validateValue,
		validateValues,
		validateOneOf,
	};
};
