import type { IEntityPartner } from 'module/partners';
import { createAxiosInstance } from 'api/setupInterceptors';
import { fillUrlParameters } from 'js/utils/common';
import type { IPartnerRequestParams } from 'module/purchase';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import type { TPartnerPriceLists } from 'js/priceList';
import { API_PRICE_LIST_KEYS } from 'js/priceList/priceListConfig';
import { logError } from 'js/utils/app';
import { priceListEntityNormalizer } from 'js/priceList/normalizer';

export const apiPartnerPriceListsPromise = async (
	partnerId: IEntityPartner['id'],
	config?: AxiosRequestConfig,
	axiosInstance?: AxiosInstance,
) => {
	const api = axiosInstance || createAxiosInstance();
	const [url] = fillUrlParameters<IPartnerRequestParams>(API_PRICE_LIST_KEYS.PARTNER_PRICE_LISTS, { partnerId });

	try {
		const response = await api.get<TPartnerPriceLists>(`/${url}`, {
			...config,
		});

		if (!response) {
			throw new Error('No response for partner price list.');
		}

		return priceListEntityNormalizer.normalizeList(response.data);
	} catch (err) {
		logError(err);
		throw err;
	}
};
