import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_DETAIL, ACL_READ_LIST, ACL_UPDATE } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { PageDetail, PageDetailReports, PageDistributionPartners, PageReports, PageUpdate } from './pages';
import { flexibleBillingConfig } from './flexibleBillingConfig';
import { FlexibleBillingDetailLayout } from 'module/flexibleBilling/layouts/FlexibleBillingDetailLayout';
import { flexibleBillingRouteName } from 'module/flexibleBilling/RouteName';

export const FlexibleBillingRoutes = () => {
	const { aclModule, authGuards } = flexibleBillingConfig;
	const routes = flexibleBillingRouteName({ withoutPrefix: true });

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} authGuards={authGuards} />}>
				<Route index path={routes.DISTRIBUTION_PARTNERS} element={<PageDistributionPartners />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} authGuards={authGuards} />}>
				<Route path={routes.REPORTS} element={<PageReports />} />
			</Route>
			<Route element={<FlexibleBillingDetailLayout />}>
				<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} authGuards={authGuards} />}>
					<Route path={routes.DETAIL} element={<PageDetail />} />
				</Route>
				<Route element={<AuthRoute acl={[aclModule, ACL_UPDATE]} authGuards={authGuards} />}>
					<Route path={routes.UPDATE} element={<PageUpdate />} />
				</Route>
				<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} authGuards={authGuards} />}>
					<Route path={routes.DETAIL_REPORTS} element={<PageDetailReports />} />
				</Route>
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};
