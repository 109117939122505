import { useApiList } from 'js/hooks/api';
import type { IEntityLicense, ILicenseListByLicenseStringFilter } from 'module/licenses';
import { useCallback, useEffect, useState } from 'react';
import { API_LICENSES_KEYS } from 'module/licenses/hooks/useApiLicenses';

export const useAsyncApiLicenseListByLicenseString = () => {
	const api = useApiList<IEntityLicense, ILicenseListByLicenseStringFilter>({});
	const [abortController] = useState(new AbortController());

	useEffect(() => {
		return () => {
			abortController.abort();
		};
	}, [abortController.abort]);

	return useCallback(
		(licenseString: string) =>
			api({
				queryKey: [API_LICENSES_KEYS.SEARCH, { licenseString }],
				meta: undefined,
				signal: abortController.signal,
			}),
		[api, abortController.signal],
	);
};
