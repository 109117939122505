import { SpendingOverview } from 'module/flexibleBilling/components/SpendingOverview';
import { useAuthContext } from 'js/contexts';
import { useApiFlexibleBillingSpendingOverview } from 'module/flexibleBilling/hooks';

type TDistributorSpendingOverviewProps = {
	className?: string;
};

export const DistributorSpendingOverview = (props: TDistributorSpendingOverviewProps) => {
	const { authCompanyId, authCompany } = useAuthContext();
	const { data, query } = useApiFlexibleBillingSpendingOverview({
		filter: { distributorId: authCompanyId },
	});

	return (
		<SpendingOverview
			currency={authCompany?.currencyCode}
			className={props.className}
			isLoading={query.isPending}
			values={data}
			type="distributor"
		/>
	);
};
