import { AsyncModal, type TAsyncModalContainerProps, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import {
	SelectDistributionPartnerContainer,
	type TSelectDistributionPartnerContainerProps,
} from './SelectDistributionPartnerContainer';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import type { IEntityDistributionPartner } from 'module/distributionPartners';

export type TAsyncSelectDistributionPartnerModalProps = TSelectDistributionPartnerContainerProps;

export const useAsyncSelectDistributionPartnerRef = () => {
	return useAsyncModalRef<TAsyncSelectDistributionPartnerModalProps, IEntityDistributionPartner>();
};

export const AsyncSelectDistributionPartnerModal = (
	props: TAsyncModalContainerProps<TAsyncSelectDistributionPartnerModalProps, IEntityDistributionPartner>,
) => {
	const { forwardedRef } = props;
	const { t } = useTranslation(distributionPartnersConfig.trNamespace);

	return (
		<AsyncModal<TAsyncSelectDistributionPartnerModalProps, IEntityDistributionPartner>
			ref={forwardedRef}
			size="lg"
			testId="selectDistributionPartner"
		>
			{(props) => (
				<>
					<Modal.Header className="pb-0">{t('select.title')}</Modal.Header>
					<Modal.Body className="mt-3">
						<SelectDistributionPartnerContainer
							{...props}
							onSelect={(partner) => forwardedRef.current?.onSuccess(partner)}
						/>
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
