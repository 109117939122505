import type { IRetailRequestListApiFilter, IRetailRequestListFilter } from 'module/retail';
import { dateToApiDate } from 'js/utils/dateTime';

export const retailRequestFilterNormalizer = {
	denormalize(filter: IRetailRequestListFilter): IRetailRequestListApiFilter {
		const { dateRange, ...rest } = filter;
		const apiFilter: IRetailRequestListApiFilter = { ...rest };

		if (dateRange) {
			apiFilter.transactionDateFrom = dateToApiDate(dateRange[0]);
			apiFilter.transactionDateTo = dateToApiDate(dateRange[1], true);
		}

		return apiFilter;
	},
};
