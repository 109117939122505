import type { ReactElement } from 'react';
import { SegmentButton, SegmentControl, SingleSelect } from '@avast/react-ui-components';
import classNames from 'classnames';
import { trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DataFilter } from 'js/components/molecules/DataFilter';
import type { TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { partnersConfig } from 'module/partners/partnersConfig';
import {
	type PartnerSubTypeEnum,
	partnerSubTypeEnumUtils,
	type PartnerTypeEnum,
	partnerTypeEnumUtils,
} from 'module/partners/enums';
import { Col, Row } from 'js/components/atoms/Row';
import type { IPartnerListFilter } from 'module/partners';
import { getAccountSubTypeOptions } from 'module/partners/utils/common';

type TSelectPartnerModalFilterProps = TDataFilterProps<IPartnerListFilter> & {
	accountTypes: PartnerTypeEnum[];
	accountSubTypes?: PartnerSubTypeEnum[];
	className?: string;
};

export const SelectPartnerModalFilter = (props: TSelectPartnerModalFilterProps): ReactElement => {
	const { accountTypes, accountSubTypes, className, ...rest } = props;
	const { t } = useTranslation(partnersConfig.trNamespace);
	const isAccountTypesMulti = accountTypes.length > 1;
	const isAccountSubTypesMulti = accountSubTypes ? accountSubTypes.length !== 1 : true;

	return (
		<DataFilter<IPartnerListFilter> {...rest}>
			{({ updateFilter, values }) => (
				<div className={classNames(className)}>
					<SearchBox
						autoFocus
						size="sm"
						value={trim(values.search, '%')}
						placeholder={t('select.filter.placeholder')}
						onChange={(value) => {
							updateFilter({ search: value ? `%${value}%` : undefined });
						}}
					/>

					<Row
						justify="between"
						size="sm"
						multi
						className={classNames({ 'mt-3': isAccountTypesMulti || isAccountSubTypesMulti })}
					>
						{isAccountTypesMulti && (
							<Col xs="auto">
								<SegmentControl size="sm">
									<SegmentButton
										testId="allPartners"
										active={values.accountTypes?.length === accountTypes?.length}
										onClick={() => {
											updateFilter({ accountTypes });
										}}
									>
										{t('common.allPartnerType')}
									</SegmentButton>
									{accountTypes?.map((key) => (
										<SegmentButton
											testId={key}
											key={key}
											active={values.accountTypes?.includes(key) && values.accountTypes?.length === 1}
											onClick={() => {
												updateFilter({
													accountTypes: [key],
													accountSubTypes: partnerSubTypeEnumUtils.validateValuesByPartnerType(
														key,
														values.accountSubTypes,
													),
												});
											}}
										>
											{partnerTypeEnumUtils.getText(key)}
										</SegmentButton>
									))}
								</SegmentControl>
							</Col>
						)}
						{isAccountSubTypesMulti && (
							<Col sm={4}>
								<SingleSelect<PartnerSubTypeEnum>
									name="accountSubType"
									options={getAccountSubTypeOptions(values.accountTypes, accountSubTypes)}
									value={values.accountSubTypes?.[0]}
									size="sm"
									placeholder={t('entity.accountSubType')}
									onChange={(value) => {
										updateFilter({ accountSubTypes: value ? [value] : undefined });
									}}
								/>
							</Col>
						)}
					</Row>
				</div>
			)}
		</DataFilter>
	);
};
