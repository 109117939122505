import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import type { TPriceListCode } from 'js/priceList';
import { useTestAttributesCallback } from '@avast/react-ui-components';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

type TPriceListSwitchProps = {
	className?: string;
};

export const PriceListSwitch = (props: TPriceListSwitchProps) => {
	const { className } = props;
	const { priceListCode, setPriceListCode } = usePriceListContext();
	const {
		orderState: { partnerPriceLists },
	} = useOrderContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const testAttributesCallback = useTestAttributesCallback();

	if (!partnerPriceLists || partnerPriceLists.length <= 1) {
		return null;
	}

	return (
		<Nav
			variant="tabs"
			activeKey={priceListCode}
			onSelect={(key) => setPriceListCode(key as TPriceListCode)}
			className={className}
		>
			{partnerPriceLists.map((priceList) => (
				<Nav.Item key={priceList.code}>
					<Nav.Link eventKey={priceList.code} {...testAttributesCallback(priceList.code, { type: 'priceList' })}>
						{t(`priceList.code.${priceList.code}`)}
					</Nav.Link>
				</Nav.Item>
			))}
		</Nav>
	);
};
