import type { ReactElement } from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import type { IEntityCreditStatus } from 'module/creditStatus';
import { useTranslation } from 'react-i18next';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';

export const CreditStatusDetail = (): ReactElement => {
	const { data } = usePageDetailContext<IEntityCreditStatus>();
	const { t } = useTranslation(creditStatusConfig.trNamespace);

	return (
		<Row multi md={2}>
			<Col>
				<DataTable>
					<DataTableTr name={t('entity.companyName')} highlighted>
						{data.companyName}
					</DataTableTr>
					<DataTableTr name={t('common:entity.partnerId')} highlighted>
						{data.partnerId}
					</DataTableTr>
					<DataTableTr name={t('entity.countryCode')}>{data.countryCode}</DataTableTr>
					<DataTableTr name={t('common:entity.currency')}>{data.currency}</DataTableTr>
				</DataTable>
			</Col>
			<Col>
				<DataTable>
					<DataTableTr name={t('entity.creditLimit')} highlighted>
						{numberFormatter.currency(data.creditLimit, data.currency)}
					</DataTableTr>
					<DataTableTr name={t('entity.creditAvailable')}>
						{numberFormatter.currency(data.creditAvailable, data.currency)}
					</DataTableTr>
					<DataTableTr name={t('entity.percentConsumed')}>
						{numberFormatter.percentage(data.percentConsumed, { isNumber: false })}
					</DataTableTr>
					<DataTableTr name={t('entity.unbilledBalance')}>
						{numberFormatter.currency(data.unbilledBalance, data.currency)}
					</DataTableTr>
					<DataTableTr name={t('entity.unpaidBalance')}>
						{numberFormatter.currency(data.unpaidBalance, data.currency)}
					</DataTableTr>
				</DataTable>
			</Col>
		</Row>
	);
};
