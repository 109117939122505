import type { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { ButtonComposition, UiTable } from '@avast/react-ui-components';
import type { IEntityClosingBalance, IStatementDetailItem } from 'module/closingBalance';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import {
	AsyncStatementDetailModal,
	type TAsyncStatementDetailModalProps,
} from 'module/closingBalance/components/modal/AsyncStatementDetailModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { ExportClosingBalanceButton } from 'module/closingBalance/components/buttons';
import { useClosingBalanceStatementColumns } from 'module/closingBalance/hooks';
import { ClosingBalanceSearchKeyEnum } from 'module/closingBalance/enums';

type TTableData = IStatementDetailItem;

export const StatementTab = (): ReactElement => {
	const { data } = usePageDetailContext<IEntityClosingBalance>();
	const statementDetailRef = useAsyncModalRef<TAsyncStatementDetailModalProps>();
	const columns = useClosingBalanceStatementColumns(statementDetailRef);

	return (
		<>
			<DefaultContainer>
				<ButtonComposition marginY>
					<ExportClosingBalanceButton
						format="line"
						listMetaData={{
							totalCount: data.lineItems.length,
							filter: {
								search: {
									key: ClosingBalanceSearchKeyEnum.ORDER_NUMBER,
									value: data.id,
								},
							},
						}}
					/>
				</ButtonComposition>
				<UiTable<TTableData>
					data={data.lineItems}
					columns={columns}
					initialState={{ sorting: [{ desc: false, id: 'id' }] }}
					testId="statementView"
					meta={{
						isScrollable: true,
						nowrapCell: true,
					}}
				/>
			</DefaultContainer>

			<AsyncStatementDetailModal forwardedRef={statementDetailRef} />
		</>
	);
};
