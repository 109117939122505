import type { TAnnouncementPlacement } from 'submodule/Announcements/types';
import { useValidAnnouncements } from 'submodule/Announcements/hooks';
import Skeleton from 'react-loading-skeleton';
import { Announcement } from 'submodule/Announcements/Announcement';

export type TAnnouncementsProps = {
	className?: string;
	placement: TAnnouncementPlacement;
};

export const Announcements = (props: TAnnouncementsProps) => {
	const { announcements, isLoading: isAnnouncementsLoading } = useValidAnnouncements(props.placement);

	if (isAnnouncementsLoading) {
		return (
			<div className="my-3">
				<Skeleton />
				<Skeleton width="30%" />
			</div>
		);
	}

	return (
		<>
			{announcements.map((announcement) => (
				<Announcement key={announcement.id} announcement={announcement} {...props} />
			))}
		</>
	);
};
