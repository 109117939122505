import type { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import type { IEntityFlexibleBillingDetail } from 'module/flexibleBilling';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { noValuePlaceholder } from 'js/utils/app';
import type { TColumnFormatter } from '@tanstack/react-table';
import { hasGroupDisabledUnitSetting } from 'module/flexibleBilling/utils';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';

type TTableData = IEntityFlexibleBillingDetail;

export const useFlexibleBillingOverviewColumns = (currency: string): TableColumnsDef<TTableData> => {
	const { t } = useTranslation(flexibleBillingConfig.trNamespace);

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<IEntityFlexibleBillingDetail>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.ellipsis('productLabel', {
				header: t('common:entity.product'),
				meta: {
					formatters: [formatter.bold],
				},
			}),
			columnHelper.enableStatus(
				'enabled',
				{
					header: t('common:entity.status'),
				},
				{ t, colored: true },
			),
			columnHelper.text('usageLimitEnabled', {
				header: t('entity.usageLimit'),
				meta: {
					formatters: [
						(hasLimit) => {
							return hasLimit ? t('common.limit') : t('common.noLimit');
						},
						disabledColumnFormatter,
						clearColumnWithDisabledUnitSetting,
					],
				},
			}),
			columnHelper.number(
				'usageLimit',
				{
					header: t('common:entity.unit_other'),
					meta: {
						defaultValue: { value: noValuePlaceholder() },
						formatters: [disabledColumnFormatter, clearColumnWithDisabledUnitSetting],
					},
				},
				{ optionalMantissa: true },
			),
			columnHelper.money(
				'currentPrice',
				{
					header: t('entity.yourPrice'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ currency },
			),
			columnHelper.number(
				'currentUsage',
				{
					header: t('common:entity.active'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ optionalMantissa: true },
			),
			columnHelper.money(
				'currentSpend',
				{
					header: t('common.currentSpend'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ currency },
			),
			columnHelper.money(
				'maxSpend',
				{
					header: t('common.maxSpend'),
					meta: {
						formatters: [
							(formattedValue, cell) => {
								const row = cell.row.original;
								return row.enabled && row.maxSpend === FLEXIBLE_BILLING_UNLIMITED_VALUE
									? t('common.unlimited')
									: formattedValue;
							},
							disabledColumnFormatter,
						],
					},
				},
				{ currency },
			),
		];
	}, [currency, t]);
};

const disabledColumnFormatter: TColumnFormatter<IEntityFlexibleBillingDetail> = (value, cell) => {
	const row = cell.row.original;
	return row.enabled ? value : <span className="text-gray">{value}</span>;
};

const clearColumnWithDisabledUnitSetting: TColumnFormatter<IEntityFlexibleBillingDetail> = (value, cell) => {
	const row = cell.row.original;
	return hasGroupDisabledUnitSetting(row.productGroupCode) ? null : value;
};
