import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import Axios from 'axios';

/**
 * @readonly
 * @type {AxiosRequestConfig}
 */
const apiConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	headers: { 'content-type': 'application/json' },
};

/**
 * @readonly
 * @type {AxiosInstance}
 */
const api: AxiosInstance = Axios.create(apiConfig);

export { api, apiConfig };
