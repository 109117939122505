import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { DataFilter } from 'js/components/molecules/DataFilter';
import type { TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@avast/react-ui-components';
import type { ILicenseListFilter } from 'module/licenses';
import {
	LicenseDateRangeTypeEnum,
	licenseDateRangeTypeEnumUtils,
	LicenseStatusEnum,
	licenseStatusEnumUtils,
} from 'module/licenses/enums';
import { FilterTypedRangeDatepicker } from 'js/components/molecules/Datepicker/FilterTypedRangeDatepicker';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { PartnerSubTypeEnum, PartnerTypeEnum } from 'module/partners/enums';

type TFilter = ILicenseListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
};

export const RetailLicensesFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const { t } = useTranslation(licensesConfig.trNamespace);
	const { controls, ...rest } = props;

	return (
		<DataFilter<TFilter> useLocation {...rest}>
			{({ values, updateFilter }) => (
				<>
					{controls}
					<Row multi size="sm" align="center">
						<Col lg={3} sm={6}>
							<SelectPartner
								size="sm"
								value={values.partnerId}
								onChange={(partnerId) => updateFilter({ partnerId })}
								accountSubTypesWhiteList={[PartnerSubTypeEnum.RETAIL]}
								accountTypesWhiteList={[PartnerTypeEnum.RESELLER]}
							/>
						</Col>
						<Col lg={3} sm={6}>
							<FormControl label={t('entity.status')}>
								<FormControl.MultiSelect<LicenseStatusEnum>
									name="statuses"
									value={values.statuses}
									onChange={(value) => updateFilter({ statuses: value || undefined })}
									size="sm"
									placeholder={t('entity.statusPlaceholder')}
									options={licenseStatusEnumUtils.getSelectOptions({
										omitted: [LicenseStatusEnum.BLACKLISTED],
									})}
								/>
							</FormControl>
						</Col>
						<Col lg={3} sm={6}>
							<FormControl label={t('entity.date')}>
								<FilterTypedRangeDatepicker<LicenseDateRangeTypeEnum>
									testId="date"
									defaultValue={LicenseDateRangeTypeEnum.CREATED_AT}
									typeValues={licenseDateRangeTypeEnumUtils.getSelectOptions()}
								/>
							</FormControl>
						</Col>
					</Row>
				</>
			)}
		</DataFilter>
	);
};
