import { type TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import type { IAsyncJob } from 'js/asyncJobs';

const API_ASYNC_JOB_KEYS = {
	DETAIL: 'async-job/:id',
};

export const useApiAsyncJob: TUseApiDetailModule<IAsyncJob> = (props) => {
	return useApiDetailQuery(API_ASYNC_JOB_KEYS.DETAIL, props, {
		queryConfig: {
			staleTime: 0,
		},
	});
};
