import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { FormMessage } from '@avast/react-ui-components';
import type { IEntityPaymentInstructions } from 'js/chargeOrder';

type TPaymentInstructionsProps = {
	paymentInstructions: IEntityPaymentInstructions;
};

const renderNoteSymbol = (count = 1) => <span className="text-danger">{'*'.repeat(count)}</span>;

export const PaymentInstructions = (props: TPaymentInstructionsProps) => {
	const { paymentInstructions } = props;
	const { t } = useTranslation(ordersConfig.trNamespace);
	const tr = (name: string) => t(`paymentInstructions.${name}`);
	const {
		amount,
		paymentReferenceNumber,
		accountHolderName,
		bankName,
		bankAccountNumber,
		iban,
		swiftCode,
		currencyCode,
		bankAch,
		bankAba,
	} = paymentInstructions;

	return (
		<>
			<p>{tr('description')}:</p>
			<DataTable className="mb-4">
				<DataTableTr name={tr('amount')}>
					<Copy2Clipboard value={amount}>
						{numberFormatter.currency(amount ? Number.parseFloat(amount) : null, currencyCode)}
					</Copy2Clipboard>
				</DataTableTr>

				<DataTableTr name={tr('referenceNumber.label')}>
					<Copy2Clipboard value={paymentReferenceNumber}>
						{paymentReferenceNumber} {renderNoteSymbol()}
					</Copy2Clipboard>
				</DataTableTr>

				<DataTableTr name={tr('accountHolder')}>{accountHolderName}</DataTableTr>

				<DataTableTr name={tr('bankName')}>{bankName}</DataTableTr>

				<DataTableTr name={tr('accountNumber')}>
					<Copy2Clipboard value={bankAccountNumber} />
				</DataTableTr>

				<DataTableTr name={tr('iban.label')}>
					<Copy2Clipboard value={iban}>
						{iban} {renderNoteSymbol(2)}
					</Copy2Clipboard>
				</DataTableTr>

				<DataTableTr name={tr('bic')}>
					<Copy2Clipboard value={swiftCode} />
				</DataTableTr>

				<DataTableTr name={tr('aba')} noRenderEmpty>
					{bankAba ? <Copy2Clipboard value={bankAba} /> : null}
				</DataTableTr>

				<DataTableTr name={tr('ach')} noRenderEmpty>
					{bankAch ? <Copy2Clipboard value={bankAch} /> : null}
				</DataTableTr>
			</DataTable>
			{paymentReferenceNumber && (
				<FormMessage type="info">
					{renderNoteSymbol()} {tr('referenceNumber.note')}
				</FormMessage>
			)}
			{iban && (
				<FormMessage type="info">
					{renderNoteSymbol(2)} {tr('iban.note')}
				</FormMessage>
			)}
		</>
	);
};
