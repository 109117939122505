import { FormikControl } from 'js/components/formik/FormikControl';
import { useFormikContext } from 'formik';
import type { TWidget } from 'submodule/widgets';
import { useAllowedWidgets } from 'submodule/widgets/hooks';
import { widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { resetSelectedColumns } from 'submodule/widgets/utils';
import { useTranslation } from 'react-i18next';

export const TypeSelectControl = () => {
	const { t } = useTranslation('submodules');
	const formContext = useFormikContext<TWidget>();
	const widgets = useAllowedWidgets();
	const typeOptions = widgetTypeEnumUtils.getSelectOptions({ picked: widgets.map((widget) => widget.type) });

	return (
		<FormikControl label={t('widgets.config.type')}>
			<FormikControl.SingleSelect
				size="sm"
				name="type"
				required
				options={typeOptions}
				isClearable={false}
				onChange={(type) => {
					if (type) {
						resetSelectedColumns(widgets, formContext, type);
					}
				}}
			/>
		</FormikControl>
	);
};
