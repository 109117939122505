import { useCallback } from 'react';
import { logDebug } from 'js/utils/app';
import { useAsyncLoadOrderInstancePartner } from 'module/purchase/hooks/useAsyncLoadOrderInstancePartner';
import { usePartnerCustomerGuard, usePartnerPriceListGuard, usePartnerTermsGuard } from 'module/purchase/hooks/guards';
import type { TPartnerId } from 'types';
import { useOrderContext } from 'js/contexts';
import type { IEntityCustomer } from 'module/customers';

export const useSetPartnerToOrderInstance = () => {
	const {
		setPartner,
		setCustomer,
		orderState: { customer },
	} = useOrderContext();
	const asyncLoadOrderInstancePartner = useAsyncLoadOrderInstancePartner();
	const partnerPriceListGuard = usePartnerPriceListGuard();
	const partnerTermsGuard = usePartnerTermsGuard();
	const partnerCustomerGuard = usePartnerCustomerGuard();

	return useCallback(
		async (partnerId: TPartnerId): Promise<boolean> => {
			// Get partner detail & price lists
			const orderPartner = await asyncLoadOrderInstancePartner(partnerId);
			logDebug('order partner and allowed price lists', orderPartner);

			if (!orderPartner) {
				throw new Error('Load partner detail and price lists failed.');
			}

			// Check terms
			const termsGuard = await partnerTermsGuard(orderPartner.partner);
			logDebug('termsGuard', termsGuard);
			if (!termsGuard) {
				return false;
			}

			// Check price lists
			const priceListGuard = await partnerPriceListGuard(orderPartner.priceLists);
			logDebug('priceListGuard', priceListGuard);
			if (!priceListGuard) {
				return false;
			}

			// Check link between partner and customer
			let orderCustomer: IEntityCustomer | null = null;
			if (customer) {
				const customerGuardResult = await partnerCustomerGuard(orderPartner.partner, customer);
				// User do not want link customer with the partner or create customer failed
				if (!customerGuardResult) {
					return false;
				}
				orderCustomer = customerGuardResult;
			}

			setPartner(orderPartner.partner, orderPartner.priceLists);
			if (orderCustomer) {
				setCustomer(orderCustomer);
			}
			return true;
		},
		[
			asyncLoadOrderInstancePartner,
			partnerTermsGuard,
			partnerPriceListGuard,
			customer,
			setPartner,
			partnerCustomerGuard,
			setCustomer,
		],
	);
};
