import { useState } from 'react';
import { FormMessage, Toast } from '@avast/react-ui-components';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { useRefundLicenseByLicenseString } from 'submodule/refunds/hooks/useRefundLicenseByLicenseString';
import { toast } from 'react-toastify';
import {
	AsyncSelectRefundLicenseModal,
	type TAsyncSelectRefundLicenseModalProps,
} from 'submodule/refunds/initiate/search/AsyncSelectRefundLicenseModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import type { IEntityLicense } from 'module/licenses';
import { useFormikContext } from 'formik';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import type { IInitiateRefundForm } from 'submodule/refunds';
import { FormLabel } from 'react-bootstrap';
import { RefundTypeEnum } from 'submodule/refunds/enums';
import { isString } from 'lodash';

export const SearchLicensesForRefund = () => {
	const { t } = useInitiateRefundContext();
	const { values, setValues } = useFormikContext<IInitiateRefundForm>();
	const [error, setError] = useState<string | null>(null);
	const selectLicenseRef = useAsyncModalRef<TAsyncSelectRefundLicenseModalProps, IEntityLicense>();
	const search = useRefundLicenseByLicenseString({
		selectLicenseRef,
		onError(error: string) {
			setError(error);
		},
		onSuccess(item) {
			toast.success(<Toast>{t('refunds.license.search.success', { licenseKey: item.licenseKey })}</Toast>);
			setError(null);

			setValues((values) => ({
				...values,
				licenses: [
					...values.licenses.filter((refundLicense) => refundLicense.entitlementId !== item.entitlementId),
					item,
				],
			}));
		},
	});

	if (values.refundType !== RefundTypeEnum.KEY) {
		return null;
	}

	return (
		<div className="bg-light border p-4 rounded my-3">
			<FormLabel>{t('refunds.license.search.title')}:</FormLabel>
			<SearchComboBox
				name="licenseString"
				onSubmit={(value) => search(value?.value)}
				onCancel={() => search(undefined)}
				autoFocus
			/>
			{isString(error) && <FormMessage type="danger">{error}</FormMessage>}
			<AsyncSelectRefundLicenseModal forwardedRef={selectLicenseRef} />
		</div>
	);
};
