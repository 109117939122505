import { useCallback } from 'react';
import { useAsyncApiPartnerDetail } from 'module/partners/api/useAsyncApiPartners';
import { logDebug } from 'js/utils/app';
import { isEqual } from 'lodash';
import type { IOrderReducerSetPartnerPayload } from 'js/reducers/orderReducer';
import type { IEntityPartnerDetail } from 'module/partners';
import type { TPartnerPriceLists } from 'js/priceList';
import { useAsyncApiPartnerPriceLists } from 'js/priceList/useAsyncApiPartnerPriceLists';
import type { IRefreshOrderStateProps } from 'js/hooks/useRefreshOrderState';

export const useRefreshOrderPartnerState = () => {
	const asyncApiPartnerDetail = useAsyncApiPartnerDetail();
	const asyncApiPartnerPriceLists = useAsyncApiPartnerPriceLists();

	return useCallback(
		async (props: IRefreshOrderStateProps): Promise<null | IOrderReducerSetPartnerPayload> => {
			const { state, authData, dispatch } = props;

			// Partner
			if (!state?.partner || !state.partnerPriceLists) {
				return null;
			}

			let refreshedPartner: IEntityPartnerDetail | null = null;
			let refreshedPriceLists: TPartnerPriceLists | null = null;

			// Take partner data from Auth data
			if (state.partner.id === authData?.company?.id) {
				refreshedPartner = authData.company;
				refreshedPriceLists = authData.priceLists;
			}

			// Otherwise from API
			else {
				const partnerResponse = await asyncApiPartnerDetail(state.partner.id, {
					authToken: authData?.token,
				}).catch((error) => logDebug('Get Order partner detail failed.', state.partner?.id, error));

				const priceListsResponse = await asyncApiPartnerPriceLists(state.partner.id, {
					authToken: authData?.token,
				}).catch((error) => logDebug('Get Order partner price lists failed.', state.partner?.id, error));

				if (partnerResponse && priceListsResponse) {
					refreshedPartner = partnerResponse.data;
					refreshedPriceLists = priceListsResponse;
				}
			}

			const payload: IOrderReducerSetPartnerPayload = {
				partner: refreshedPartner || state.partner,
				partnerPriceLists: refreshedPriceLists || state.partnerPriceLists,
			};

			if (!isEqual(state.partner, refreshedPartner) || !isEqual(state.partnerPriceLists, refreshedPriceLists)) {
				dispatch({ type: 'SET_PARTNER', payload });
			}

			return payload;
		},
		[asyncApiPartnerDetail, asyncApiPartnerPriceLists],
	);
};
