import type { TFlexibleBillingForm, TFlexibleBillingUpdateRequest } from 'module/flexibleBilling';

export const flexibleBillingFormNormalizer = {
	denormalize(values: TFlexibleBillingForm): TFlexibleBillingUpdateRequest {
		return values.map((formItem) => {
			return {
				enabled: formItem.enabled,
				usageLimitEnabled: formItem.usageLimitEnabled,
				usageLimit: formItem.usageLimit,
				productId: formItem.productId,
			};
		});
	},
};
