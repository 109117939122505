import type { ILocaleConfig, TLocale } from 'types/config';
import { en_US } from 'i18n/locales/en_US';

const locale: TLocale = 'en-MY';

export const en_MY: ILocaleConfig = {
	name: 'English (Malaysia)',
	locale,
	language: 'en',
	format: {
		...en_US.format,
		datetime: locale,
	},
};
