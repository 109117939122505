import type { TAclModules } from 'config/acl';
import type { IAuthGuards } from 'types';

export interface IModuleConfig {
	trNamespace: string;
}

export interface IModuleMenuConfig {
	menuName: string;
	menuLink: string;
}

export interface IPageModuleConfig extends IModuleConfig, IModuleMenuConfig {
	aclModule: TAclModules;
	authGuards?: IAuthGuards;
}

export interface IPageModuleLinksConfig<Entity extends object> {
	detailLink: (entity: Entity) => string;
}

export interface IModuleConfigFn {
	/* Prefix key by translation namespace */
	trPrefix: (key: string) => string;
}

/**
 * Add functions what relied on a module config to config options
 *
 * @template C
 * @param {C} config
 * @return {IModuleConfig}
 */
export const moduleConfigGenerator = <C extends IModuleConfig = IModuleConfig>(config: C): IModuleConfigFn & C => ({
	...config,
	trPrefix: (key) => `${config.trNamespace}:${key}`,
});
