import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { CreditLimitCreateModalContent } from 'module/creditLimit/components/CreditLimitCreateModalContent';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { useDataFilterContext } from 'js/components/molecules/DataFilter';
import type { ICreditLimitListFilter } from 'module/creditLimit';

export const CreditLimitCreateButton = () => {
	const { t } = useTranslation(creditLimitConfig.trNamespace);
	const { values } = useDataFilterContext<ICreditLimitListFilter>();
	const modalRef = useAsyncModalRef();

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				onClick={() => {
					modalRef.current?.show();
				}}
				testId="creditLimit"
			>
				{t('actions.create')}
			</Button>
			<AsyncModal ref={modalRef} size="sm" testId="creditLimit" backdrop="static">
				<CreditLimitCreateModalContent forwardedRef={modalRef} initialPartnerId={values.partnerId} />
			</AsyncModal>
		</>
	);
};
