import type { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/tr-TR');
const locale: TLocale = 'tr-TR';

export const tr_TR: ILocaleConfig = {
	name: 'Turkish (Turkey)',
	locale,
	language: 'tr',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
