import type { TPriceListCode } from 'js/priceList';
import type { IPricingRequestHeader } from 'module/purchase';
import { useMemo } from 'react';
import { StandardOrderDiscountCategoryEnum } from 'module/orders/enums';
import { useOrderContext } from 'js/contexts';

/**
 * Prepare pricing request header from the order context
 *
 * @param {TPriceListCode} priceListCode
 * @return {IPricingRequestHeader | null}
 */
export const usePricingHeader = (priceListCode: TPriceListCode): IPricingRequestHeader | null => {
	const {
		billableParty,
		hasLineDiscount,
		orderState: { discretionaryDiscount },
	} = useOrderContext();

	return useMemo(() => {
		if (!billableParty) {
			return null;
		}

		const header: IPricingRequestHeader = {
			product_pricelist_currency_code: billableParty.currencyCode,
			bill_to_country: billableParty.countryCode,
			billablePartyID: billableParty.partnerId,
			billablePartyType: billableParty.billablePartyType,
			applyPromoPricing: 'Yes',
			priceRequestSource: 'scUIOrder',
			discountCategory: StandardOrderDiscountCategoryEnum.NONE,
			priceListCode,
		};

		// Add discount
		if (discretionaryDiscount) {
			header.discountOrderPercent = discretionaryDiscount;
			header.discountCategory = StandardOrderDiscountCategoryEnum.DISCRETIONARY;
		} else if (hasLineDiscount) {
			header.discountOrderPercent = 0;
		}

		return header;
	}, [priceListCode, billableParty, discretionaryDiscount, hasLineDiscount]);
};
