import { type KeyboardEvent, type ReactElement, useEffect, useRef } from 'react';
import { FormControl, FormMessage, IconButton, SingleSelect, type TSelectOptions } from '@avast/react-ui-components';
import type { TSearchFilter } from 'types/filter';
import { InputGroup } from 'react-bootstrap';
import { useCompare } from 'js/hooks/useCompare';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { type TUseSearchComboBoxProps, useSearchComboBox } from 'js/components/molecules/SearchBox/useSearchComboBox';

export type TSearchComboBoxProps<KeyType extends string> = {
	keys?: TSelectOptions<KeyType>;
	controlledValue?: TSearchFilter<KeyType>;
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	autoFocus?: boolean;
} & Omit<TUseSearchComboBoxProps<KeyType>, 'initialKey'>;

export const SearchComboBox = <KeyType extends string>(props: TSearchComboBoxProps<KeyType>): ReactElement => {
	const ref = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	// Props
	const {
		autoFocus,
		name = 'search',
		placeholder = t('components:searchComboBox.value.placeholder'),
		disabled,
		keys,
		controlledValue = {},
		...rest
	} = props;

	const {
		validationFailed,
		setSearchKey,
		setSearchValue,
		resetComponentState,
		setSearchObject,
		state: { searchValue, searchKey, validateState },
		submit,
	} = useSearchComboBox<KeyType>({
		initialKey: keys?.[0].value,
		...rest,
	});

	const isControlledValueChanged = useCompare<TSearchFilter<KeyType> | undefined>(controlledValue, true);

	/**
	 * Focus on mount
	 */
	// biome-ignore lint/correctness/useExhaustiveDependencies: Run only on mount
	useEffect(() => {
		if (autoFocus) {
			ref.current?.focus();
		}
	}, []);

	/**
	 * Update values from outside
	 */
	useEffect(() => {
		const { key, value } = controlledValue;

		if (isControlledValueChanged) {
			if (key && value) {
				setSearchObject({
					searchKey: key,
					searchValue: value,
				});
			}
		}
	}, [isControlledValueChanged, controlledValue, setSearchObject]);

	/**
	 * Component for selector part in searcher.
	 * @returns {ReactElement | null}
	 */
	const SearchSelector = (): ReactElement | null => {
		if (!keys) {
			return null;
		}

		const oneKey = keys.length === 1;

		return (
			<SingleSelect<KeyType>
				options={keys}
				name={`${name}-key`}
				size={'sm'}
				value={searchKey}
				disabled={disabled}
				onChange={setSearchKey}
				className={classNames({ 'select__single-option': oneKey })}
				isClearable={false}
				isInvalid={validationFailed}
			/>
		);
	};

	return (
		<>
			<InputGroup size={'sm'} className="component__search-combo-box">
				<SearchSelector />
				<FormControl.Input
					placeholder={placeholder}
					name={`${name}-value`}
					type="text"
					value={searchValue}
					size={'sm'}
					disabled={disabled}
					onChange={setSearchValue}
					onBlur={() => {
						if (!searchValue) {
							submit();
						}
					}}
					onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
						if (e.key.toLocaleLowerCase() === 'enter' && !validationFailed) {
							submit();
						}
					}}
					forwardedRef={ref}
					isInvalid={validationFailed}
				/>
				<IconButton onClick={submit} disabled={disabled || validationFailed} iconFa={faSearch} size={'sm'} />
				{searchValue && (
					<IconButton onClick={resetComponentState} disabled={disabled} variant="reset" iconFa={faTimes} size={'sm'} />
				)}
			</InputGroup>
			{validationFailed && 'message' in validateState && (
				<FormMessage type="danger">{validateState.message}</FormMessage>
			)}
		</>
	);
};
