import type { TButtonProps } from '@avast/react-ui-components';
import { Button } from '@avast/react-ui-components';
import { Link } from 'react-router';
import type { NavigateLink } from 'types';

export type TLinkButtonProps<State = object> = Omit<TButtonProps, 'to' | 'state'> & NavigateLink<State>;

export const LinkButton = <State extends object = Record<string, unknown>>(props: TLinkButtonProps<State>) => {
	if (props.disabled) {
		return <Button {...props} />;
	}

	return (
		<Button
			// @ts-ignore
			as={Link}
			{...props}
		/>
	);
};
