import { useCallback } from 'react';
import { H1, H2 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { isScheduledMaintenance, scheduledMaintenanceEnd } from 'module/security/utils/maintenance';
import { dateFormatter } from 'js/utils/dateTime';
import { Navigate, useNavigate } from 'react-router';
import { RouteName } from 'module/RouteName';
import { RetryWrapper } from 'js/components/RetryWrapper';

export const ScheduledMaintenance = () => {
	const { t } = useTranslation(securityConfig.trNamespace);
	const endDate = scheduledMaintenanceEnd();
	const navigate = useNavigate();

	const healthCheck = useCallback(
		() =>
			new Promise<unknown>((resolve) => {
				const endDate = scheduledMaintenanceEnd();

				// Is end date in the past -> reload the page
				if (endDate && endDate.diffNow().toMillis() < 0) {
					navigate(0);
					return;
				}

				resolve(0);
			}),
		[navigate],
	);

	if (!isScheduledMaintenance()) {
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} replace />;
	}

	return (
		<RetryWrapper infinite retry={healthCheck} delay={60}>
			<H1 bold>{t('page.maintenance.scheduled.title')}</H1>
			<H2 type="h3" className="mt-1">
				{t('page.maintenance.scheduled.text')}
			</H2>
			{endDate && (
				<p className="text-danger mt-3 mb-0">
					{t('page.maintenance.scheduled.end')}: <strong>{dateFormatter.toTimeZoneDateTime(endDate.toJSDate())}</strong>
				</p>
			)}
		</RetryWrapper>
	);
};
