import React, { createContext, type PropsWithChildren, type ReactElement } from 'react';
import invariant from 'invariant';
import type { TDataFilterChildrenProps } from 'js/components/molecules/DataFilter/DataFilter';

type TDataFilterContext<T extends object> = TDataFilterChildrenProps<T>;
type TDataFilterContextProviderProps<T extends object> = {
	value: TDataFilterContext<T>;
};

// biome-ignore lint/suspicious/noExplicitAny: The function is generic and has to have type "any"
const DataFilterContext = createContext<TDataFilterContext<any> | null>(null);
DataFilterContext.displayName = 'DataFilterContext';

/**
 * Typed provider of data filter context
 * @param {React.PropsWithChildren<TDataFilterContextProviderProps>} props
 * @returns {ReactElement}
 * @constructor
 */
export const DataFilterContextProvider = <T extends object>(
	props: PropsWithChildren<TDataFilterContextProviderProps<T>>,
): ReactElement => <DataFilterContext.Provider {...props} />;

/**
 * Hook to get filter context
 * @returns {TDataFilterContext}
 */
export const useDataFilterContext = <T extends object>(): TDataFilterContext<T> => {
	const context = React.useContext(DataFilterContext);

	invariant(
		context !== null,
		'Data filter context is undefined, please verify you are calling useDataFilterContext() as child of a <DataFilterContextProvider> component.',
	);

	return context;
};
